import moment from 'moment';
import { PartialDeep } from 'type-fest/source/partial-deep';

import * as BaseModels from '@/Models/—BaseModels—';
import * as HelperModels from '@/Models/—HelperModels—';
import * as StaffModels from '@/Models/StaffModels';
import ModelUtils from '@/Models/—ModelUtils—';
import PayrollFormulaUtils from '@/Utilities/PayrollFormulaUtils';

//* Payroll Forms *//
export class PayrollSummary {
  public Id: number = 0;
  public CompanyId: number = 0;
  public SummaryData: HelperModels.PayrollSummaryData = new HelperModels.PayrollSummaryData();
  public TimePeriod: moment.Moment = moment();
  public Status: HelperModels.ApprovalState = 1;
  public LastModifiedDate!: moment.Moment;
  public LastModifiedBy!: number;

  constructor(init?: PartialDeep<PayrollSummary>) {
    Object.assign(this, init);
    Object.assign(this, {
      SummaryData: new HelperModels.PayrollSummaryData(this.SummaryData),
    });
    
    ModelUtils.InitializeMoments(this, ['TimePeriod', 'LastModifiedDate']);
  }
}
export class OneTimePayment {
  public Id: number = 0;
  public CompanyId: number = 0;
  public Name: string = '';
  public TimePayment!: moment.Moment;
  public Status: number = 1;
  public PaymentList: HelperModels.OneTimePaymentItem[] = [];
  public LastModifiedDate!: moment.Moment;
  public LastModifiedBy!: number;

  constructor(init?: Partial<OneTimePayment>) {
    Object.assign(this, init);
    ModelUtils.InitializeMoments(
      this, ['TimePayment', 'LastModifiedDate']
    );
  }
}
export class OtpCreationForm {
  public Name: string = '';
  public TimePayment: moment.Moment = moment();
  public IsAutoGenerate: boolean = false;
  public BaseValue: number = 0;
  public SourceType: number = 1;
  public SourcePercentage: number = 0;
  public TaxCategory: number = 7;
  public StaffSelection: StaffModels.StaffSelection 
    = new StaffModels.StaffSelection();

  public get IsValueZero(): boolean {
    return this.BaseValue == 0 && this.SourcePercentage == 0;
  }

  constructor(init?: Partial<OneTimePayment>) {
    Object.assign(this, init);
    ModelUtils.InitializeMoments(
      this, ['TimePayment', 'LastModifiedDate']
    );
  }
}

//* Summary *//
export class PsColumn {
  public Key!: string;
  public PaidTo!: 0 | 1 | 100;
    //* L0 – Payout Targets *//
    //  0  : Staff
    //  1  : Goverment
    //  100: Grand Total
  public CalculationGroup!: HelperModels.CalculationGroup | 100;
    //* L1 – Calculation Groups – See `HelperModels.PayslipItem` for details *//
    //  100: Total
  public OrderWeight: number = 0;
  public Value: number = 0;
  public Name: string = '';

  constructor(init?: Partial<PsColumn>) {
    Object.assign(this, init);
  }
}
export class PsCompanySummaryData {
  public Company!: BaseModels.BaseCompany;
  public CompanyCuts!: BaseModels.BaseCompanyCuts;
  public StaffPayslips: HelperModels.StaffPayslip[] = [];

  constructor(init?: Partial<PsCompanySummaryData>) {
    Object.assign(this, init);
  }
}

//* e-Banking *//
export class PsEbankingForm {
  public CompanyBankId: number = 1;
  public CompanyAccountNumber: string = '';
  public TransferDate: moment.Moment = moment();
  public Description: string = '';
  public IsUniversalTransfer: boolean = false;

  constructor(init?: Partial<PsEbankingForm>) {
    Object.assign(this, init);
  }
}

//* Tax *//
export class PsTaxForm {
  public CorrectionNum: number = 0;
  public SignDate: moment.Moment = moment();
  public StaffAccId: number = 0;

  constructor(init?: Partial<PsTaxForm>) {
    Object.assign(this, init);
  }
}
export class PsTaxMonthSummary{
  public ObjectSummaryRec: Record<number, PsTaxObjectSummary> = {};
  public StpValue: number = 0;
  public ExcessTax: PsTaxItem = new PsTaxItem();
  public FixTax: PsTaxItem = new PsTaxItem();
  public TaxAttchPgSzRec: Record<string, number> = {
    'Annual': 0,      'AnnualYear': 0,
    'NonFinal': 0,
    'Final': 0
  };
  public get TotalObjectSummary(): PsTaxObjectSummary {
    return Object.values(this.ObjectSummaryRec)
      .filter((os: PsTaxObjectSummary) => {
        return 2110001 <= os.TaxEmploymentStatus && os.TaxEmploymentStatus <= 2110099 
          || os.TaxEmploymentStatus == 2710099; 
      }).reduce(
      (osTotal: PsTaxObjectSummary, os: PsTaxObjectSummary) => {
        osTotal.Quantity += os.Quantity;
        osTotal.BrutoIncome += os.BrutoIncome;
        osTotal.TaxValue += os.TaxValue;

        return osTotal;
      }, new PsTaxObjectSummary()
    );
  }
  public get TotalExcessTax(): number {
    return this.StpValue + this.ExcessTax.Value;
  }
  public get SubOsToExcessTax(): number {
    return this.TotalObjectSummary.TaxValue - this.TotalExcessTax;
  }
  public get SubOsToFixTax(): number {
    return this.SubOsToExcessTax - this.FixTax.Value;
  }
  public get TotalFinalObjectSummary(): PsTaxObjectSummary {
    return Object.values(this.ObjectSummaryRec)
      .filter((os: PsTaxObjectSummary) => {
        return 2140101 <= os.TaxEmploymentStatus && os.TaxEmploymentStatus <= 2149999;
      }).reduce(
      (osTotal: PsTaxObjectSummary, os: PsTaxObjectSummary) => {
        osTotal.Quantity += os.Quantity;
        osTotal.BrutoIncome += os.BrutoIncome;
        osTotal.TaxValue += os.TaxValue;

        return osTotal;
      }, new PsTaxObjectSummary()
    );
  }

  constructor(
    monthlyPs: PayrollSummary, approvedYearlyPs: PayrollSummary[], 
    isYearlyReport: boolean, ext: Partial<PsTaxMonthSummary>
  ) {
    Object.assign(this, ext);
    this._InputTaxSummary(monthlyPs, approvedYearlyPs, isYearlyReport);
    this._InputTaxAttchPageSize(monthlyPs, approvedYearlyPs);
  }

  private _InputTaxAttchPageSize(
    monthlyPs: PayrollSummary, approvedYearlyPs: PayrollSummary[]
  ) {
    const taxTypeSetRec: Record<string, Set<number>> = {
      'Annual': new Set<number>(),      'AnnualYear': new Set<number>(),
      'NonFinal': new Set<number>(),
      'Final': new Set<number>()
    };

    /* Monthly Tax Attachments Page-Size */ 
    let hasAnnualZero: boolean = false;
    monthlyPs.SummaryData.StaffPayslips.forEach((sp: HelperModels.StaffPayslip) => {
      const taxType = PayrollFormulaUtils.GetTaxType(sp);

      if (taxType == 'Annual' && sp.PayslipSummary.TaxSum == 0) {
        hasAnnualZero = true;
      } else {
        this.TaxAttchPgSzRec[taxType]++;
      }
    });

    this.TaxAttchPgSzRec['Annual'] = Math.max(
      Math.ceil(this.TaxAttchPgSzRec['Annual'] / 20),
      hasAnnualZero ? 1 : 0
    );
    ['NonFinal', 'Final'].forEach((taxType: string) => {
      this.TaxAttchPgSzRec[taxType] = Math.ceil(this.TaxAttchPgSzRec[taxType] / 20);
    });

    /* Yearly Tax Attachments Page-Size */ 
    if (monthlyPs.TimePeriod.month() == 11) {
      const accIdSet = new Set<number>(); 
      let hasAnnYearZero: boolean = false;

      approvedYearlyPs.forEach((ps: PayrollSummary) => {
        ps.SummaryData.StaffPayslips.forEach((sp: HelperModels.StaffPayslip) => {
          if (PayrollFormulaUtils.GetTaxType(sp) == 'Annual') {
            if (sp.PayslipSummary.TaxSum > 0) {
              accIdSet.add(sp.AccountId);
            } else {
              hasAnnYearZero = true;
            }
          }
        });
      });

      this.TaxAttchPgSzRec['AnnualYear'] = Math.max(
        Math.ceil(accIdSet.size / 20),
        hasAnnYearZero ? 1 : 0
      );
    } 
  }
  private _InputTaxSummary(
    ps: PayrollSummary, approvedYearlyPs: PayrollSummary[],
    isYearlyReport: boolean
  ) {
    const tosRec: Record<number, PsTaxObjectSummary> = this.ObjectSummaryRec;
    const accToSet: Set<string> = new Set<string>();
    const staffPayslips: HelperModels.StaffPayslip[] = isYearlyReport ?
      approvedYearlyPs.reduce(
        (arr: HelperModels.StaffPayslip[], payrollSum: PayrollSummary) => {
          return arr.concat(payrollSum.SummaryData.StaffPayslips);
        }, []
      ) : ps.SummaryData.StaffPayslips;
    
    /* Summarize Tax Objects via Merged Payslips */
    staffPayslips.forEach((sp: HelperModels.StaffPayslip) => {
      const taxEmpStatus: number = Math.abs(sp.ExtensionData.TaxEmploymentStatus);
      const accToKey: string = `${taxEmpStatus}–${sp.AccountId}`;

      if (tosRec[taxEmpStatus]) {
        tosRec[taxEmpStatus].BrutoIncome += sp.PayslipSummary.BrutoIncome;
        tosRec[taxEmpStatus].TaxValue += sp.PayslipSummary.TaxSum;
        tosRec[taxEmpStatus].Quantity += !accToSet.has(accToKey) ? 1 : 0;
      } else {
        tosRec[taxEmpStatus] = new PsTaxObjectSummary({
          TaxEmploymentStatus: taxEmpStatus,
          Quantity: 1,
          BrutoIncome: sp.PayslipSummary.BrutoIncome,
          TaxValue: sp.PayslipSummary.TaxSum
        });
      }

      accToSet.add(accToKey);
    });
  }
}
export class PsTaxItem {
  public DatePeriod!: moment.Moment;
  public Value: number = 0;

  constructor(init?: Partial<PsTaxItem>) {
    Object.assign(this, init);
  }
}
export class PsTaxObjectSummary {
  public TaxEmploymentStatus: number = 2110001;
  public Quantity: number = 0;
  public BrutoIncome: number = 0;
  public TaxValue: number = 0;

  constructor(init?: Partial<PsTaxObjectSummary>) {
    Object.assign(this, init);
  }
}
export class PsTaxPic {
  public Name!: string;
  public TaxCredentialParts!: string[];

  constructor(init?: Partial<PsTaxPic>) {
    Object.assign(this, init);
  }
}

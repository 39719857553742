
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import CompanyQueries from '@/Repositories/Graphql/CompanyQueries';

import { Company } from '@/Models/CompanyModels';
import { SetupSteps } from '@/Router/Routes';

import XeoFormImage from '☆XeoApp/Vue/Components/Base/XeoFormImage.vue';
import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';
import XeoTextArea from '☆XeoApp/Vue/Components/Base/XeoTextArea.vue';

@Component({
  name: 'CompanyDetails',
  filters: {
    ToDotTrainCase(str: string): string {
      return XeoBibliotheca.UtilCodex.ToDotTrainCase(str);
    }
  }
})
export default class CompanyDetails extends Mixins(
  XeoBaseMixin, XeoFormMixin, AppAccessMixin, AppRenderMixin, AppToastMixin
) {
  $refs!: {
    FimCompanyBrand: XeoFormImage,

    TxtCompanyName: XeoFormInput,
    TxtCompanyCode: XeoFormInput,
    TaAddress: XeoTextArea,    
    TxtCity: XeoFormInput,
    TxtEmailAddress: XeoFormInput,
    TxtPhoneNumber: XeoFormInput,

    AmConfirmation: XeoModalMixin
  }
  
  private get Account() { return DataStore.Account; }
  private get Company() { return DataStore.CompanyHq.Data; }

  @Prop() isSetup!: boolean;
  private FormCompany = new Company();
    private BrandFile: File | null = null;
    private TempCompanyId: number = 0;
  private Mode: 'CreateHq' | 'Others' = 'CreateHq';
  private IsCompanyCodeEdited: boolean = false;
  private TransitionKey: number = 0;
  private get IsFormCompanyHq(): boolean {
    return this.FormCompany.Id == this.Company.Id;
  }
  private get IsNotDeletable(): boolean {
    return this.FormCompany.UsageCount > 0;
  }

  protected created() {
    this.IsLoading = true;
    this.Mode = !this.Account.SetupStep ? 'CreateHq' : 'Others';
    
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${CompanyQueries.Xeo_GetUserCompanyList}
      }
    `).xeoThen(
      (data) => {
        DataStore.AssignCompanyList(data.Xeo_GetUserCompanyList);
        this._ConstructForm(DataStore.CompanyHq.Data);
        this.IsLoading = false;
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });
  }

  protected BtnAmCancel_Click() {
    this.$refs.AmConfirmation.close();
  }
  protected BtnAmOk_Click() {
    this._DeleteCompany();
  }
  protected BtnDelete_Click() {
    this.$refs.AmConfirmation.open();
  }
  protected BtnSaveAndContinue_Click() {
    this._UpsertCompanyAndContinue();
  }
  protected LoadCompanyForm_Act(id: number) {
    const normId = Math.max(id, 0);

    if (this.FormCompany.Id != normId) {
      this._ConstructForm(id);
      this.TransitionKey = id;
    }
  }
  protected TxtCompanyName_KeyUp() {
    this._AssignCompanyAcronymAsCode();
  }
  protected TxtCompanyCode_Change() {
    this.IsCompanyCodeEdited = true;
  }

  private _AssignCompanyAcronymAsCode() {
    if (!this.IsCompanyCodeEdited && this.Mode == 'CreateHq') {
      let companyName: string = this.FormCompany.CompanyName || '';
      const companyPrefix = [ 'PT.', 'PT ', 'PT. ', 'CV.', 'CV ', 'CV. ' ];

      companyName = companyName.toLowerCase();
      companyPrefix.some((prefix) => {
        if (companyName.startsWith(prefix)) {
          companyName = companyName.slice(prefix.length);
          return;
        }
      });

      this.FormCompany.CompanyCode = 
        XeoBibliotheca.UtilCodex.GetAcronym(companyName).slice(0, 8);
    }
  }
  private _ConstructForm(c: Company | number) {
    this.FormCompany = new Company(
      !isNaN(c as any) ? DataStore.CompanyList[c as number] : c as Company
    );
    this.FormCompany.PhoneNumber = (this.FormCompany.PhoneNumber || '').substring(3);
    this.BrandFile = null;
  
    this.TempCompanyId = this.FormCompany.Id || -1;
  }
  private _DeleteCompany() {
    this.IsSavingForm = true;

    AppRepositories.Graphql.DoAuthGraphql(`
      mutation { ${
        CompanyQueries.Xeo_DeleteCompany(this.FormCompany.Id)
      } }`
    ).xeoThen(
      (data) => {
        DataStore.DeleteCompanyById(this.FormCompany.Id);
        this.MakeSuccessToast(this.$t('Success.delete', { name: 'Perusahaan' }));
        this.$refs.AmConfirmation.close();
        
        this._ConstructForm(DataStore.CompanyHq.Data);
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false;
    });
  }
  private _UpdateBrandAndContinue(id: number) {
    if (!this.BrandFile) {
      this.__FinishSave('✓-data', id);
      return;
    }

    AppRepositories.UploadRest.Uploader({
      Type: 'company-brand',      File: this.BrandFile,
      QueryParams: `b=${id}`,
    }).xeoThen(
      (data) => {
        DataStore.AssignCompanyList({ 
          Id: id,
          BrandUrl: data.BrandUrl 
        });
        this.__FinishSave('✓-all', id);
      },
      (errors) => {
        const isInvalidErr: boolean = [
          'http: invalid-file-type', 'http: file-size-exceeded'
        ].includes(errors[0].Code);

        if (isInvalidErr) {
          this.$refs.FimCompanyBrand.AddValidationResultData(
            new XeoTypes.ValidationResultData({
              Message: this.$t(`CompanyDetails.Errors.${errors[0].Code}`).toString(),
              Status: false
            })
          );
          this.IsSavingForm = false;
        } else {
          this.__FinishSave('✗-others', id);
        }
      }
    ).catch((err) => {
      this.__FinishSave('✗-others', id);
    });
  }
  private _UpsertCompanyAndContinue() {
    if (XeoBibliotheca.FormCodex.ValidateFormViaRefs(this.$refs)) {
      const req = new Company(this.FormCompany);
        req.PhoneNumber = '+62' + req.PhoneNumber;
        req.SetupStep = this.isSetup ? SetupSteps.CompanyCuts : 0;

      this.IsSavingForm = true;
      AppRepositories.Graphql.DoAuthGraphql(`
        mutation {
          ${CompanyQueries.Xeo_UpsertCompany(req)}
        }
      `).xeoThen(
        (data) => {
          const c = data.Xeo_UpsertCompany;

          DataStore.AssignCompanyList(c);
          DataStore.AssignAccount(
            Object.assign(
              c.Id == c.HeadquarterId ? { CompanyName: data.Xeo_UpsertCompany.CompanyName } : {},
              this.Mode == 'CreateHq' ? { DivisionId: 1, JobId: 1 } : {}
            ) 
          );
          
          this._UpdateBrandAndContinue(c.Id);
        }, 
        (errors) => {
          const dupFields = XeoBibliotheca.ErrorCodex.GetDuplicateFieldCode(errors[0]);
        
          if (dupFields.length > 0) {
            dupFields.forEach((dupField: string) => {
              if (dupField == 'company.CompanyCode') {
                this.$refs.TxtCompanyCode.AddValidationResultData(
                  new XeoTypes.ValidationResultData({
                    Message: 'Kode Perusahaan telah digunakan.',
                    Status: false
                  })
                );
              } else {
                this.MakeErrorToast(this.$t('Errors.server'));
              }
            });
          } else {
            this.MakeErrorToast(this.$t('Errors.server'));
          }
          this.IsSavingForm = false; 
        }
      ).catch((err) => {
        this.MakeErrorToast(this.$t('Errors.network'), 'sd');
        this.IsSavingForm = false; 
      });
    }
  }

  private __FinishSave(statusType: '✓-all' | '✓-data' | '✗-others', id: number) {
    switch (statusType) {
      case '✓-all':
        this.MakeSuccessToast(this.$t('Success.save', { name: 'Seluruh item' }));
        break;
      case '✓-data':
        this.MakeSuccessToast(this.$t('Success.save', { name: 'Data' }));
        break;
      default:
        this.MakeWarnToast(this.$t('CompanyDetails.Errors.update-brand-failed'), 'sd');
    } 

    if (this.isSetup) {
      DataStore.AssignAccount({
        SetupStep: Math.max(this.Account.SetupStep, SetupSteps.CompanyCuts)
      });
      this.$router.push({ name: 'Setup_CompanyCuts' });
      
      return;
    }

    this._ConstructForm(id);
    this.IsSavingForm = false;
  }
}

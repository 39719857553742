import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import moment from 'moment';

import { PayrollSummary } from '@/Models/PayrollModels';

export default new class PayrollUtils {
  public GenerateInitialTp(rawApprvPs: any[]) {
    return moment.max(
      ...rawApprvPs.map(aps => moment(aps.TimePeriod).add(1, 'month')),
      moment()
    ).startOf('month');
  }
  public GenerateTpDateEnabler(rawApprovedPs: any[]): XeoTypes.DateEnabler {
    const tpDateEnabler: XeoTypes.DateEnabler = new XeoTypes.DateEnabler();
    const processedYears: number[] = [];

    rawApprovedPs.map((rawPs: any) => new PayrollSummary(rawPs))
      .sort((a, b) => b.TimePeriod.diff(a.TimePeriod))
      .forEach((ps: PayrollSummary) => {
        const payrollTp: moment.Moment = ps.TimePeriod;
        if (processedYears.includes(payrollTp.year())) {
          return;
        }

        tpDateEnabler.DisabledDates.push([ 
          payrollTp.clone().startOf('year'), payrollTp.endOf('month') 
        ]);
        processedYears.push(payrollTp.year());
      });

    return tpDateEnabler;
  }
}
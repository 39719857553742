
import { Vue, Component, Mixins, Prop } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { SetupSteps } from '@/Router/Routes';
import { DataStore, StateStore } from '@/Store/—–AppStore–—';

import { ServerTime } from '@/Models/PublicModels';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import AccountQueries from '@/Repositories/Graphql/AccountQueries';
import AppQueries from '@/Repositories/Graphql/AppQueries';
import CompanyQueries from '@/Repositories/Graphql/CompanyQueries';
import DivisionQueries from '@/Repositories/Graphql/DivisionQueries';
import JobQueries from '@/Repositories/Graphql/JobQueries';
import PublicQueries from '@/Repositories/Graphql/PublicQueries';
import StaffQueries from '@/Repositories/Graphql/StaffQueries';

import SideNavMenuModule from '@/Components/App/SideNavMenuModule.vue';
import NavAccountModule from '@/Components/Account/NavAccountModule.vue';
import SideNavAccountModule from '@/Components/Account/SideNavAccountModule.vue';

import XeoTimer from '☆XeoApp/Vue/Components/Base/XeoTimer.vue';

@Component({
  name: 'Application',
  components: { SideNavMenuModule, SideNavAccountModule, NavAccountModule },
  metaInfo() { 
    return {
      title: (this as any).RouteNameTitle, 
      titleTemplate: `%s – Axenta.id` 
    };
  }
})
export default class Application extends Mixins(XeoBaseMixin, AppAccessMixin, AppToastMixin) {
  $refs!: {
    TmrSyncServerTime: XeoTimer,
    TmrUpdateTime: XeoTimer,
  };

  private IsAppLoading: boolean = false;
  private ServerTime: ServerTime = new ServerTime();
  private get IsOwner(): boolean { 
    return DataStore.Account.DivisionId == 0 && DataStore.Account.JobId == 0; 
  }
  private get BrandCmptType(): 'router-link' | 'div' {
    return DataStore.Account.SetupStep >= SetupSteps.Complete ?
      'router-link' : 'div';
  }
  private get RouteNameTitle(): string {
    return this.$t(`AppMenuList.${this.$route.name || this.$route.meta?.title}`) as string;
  }
  private get IsShowSideNav(): boolean { return StateStore.SideNavigation; }
  private set IsShowSideNav(state: boolean) { 
    StateStore.SetAppStates({ 'SideNavigation': state }); 
  }

  protected NavGuardHandler!: Function;
  protected created() {
    this._LoadAppDatas();
    this._PdfRegisterFonts();
}
  protected mounted() {
    this.$refs.TmrSyncServerTime.Start();
    this.$refs.TmrUpdateTime.Start();
  }

  protected NavMenu_Navigate() {
    this.IsShowSideNav = XeoBibliotheca.DisplayCodex.IsDisplayExceed('lg');
  }
  protected TogSideNav_Click() {
    this.IsShowSideNav = !this.IsShowSideNav;
  }
  protected TmrSyncServerTime_Tick() {
    this._LoadServerTime();
  }
  protected TmrUpdateTime_Tick() {
    DataStore.AssignServerNow(this.ServerTime.Now());
  }

  private _LoadAppDatas() {
    this.IsAppLoading = true;

    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${AccountQueries.Xeo_GetUserContext}
      }
    `).xeoThen(
      (data) => {
        DataStore.AssignAccount(data.Xeo_GetUserContext.User);
        DataStore.AssignAccessMaps(data.Xeo_GetUserContext.Access);
        this.__InitializeAccessGuard();
        this.__RedirectOnConds(data.Xeo_GetUserContext.User.SetupStep);

        return AppRepositories.Graphql.DoAuthGraphql(`
          query {
            ${AppQueries.Xeo_GetAppConstants}
            ${CompanyQueries.Xeo_GetUserCompanyList}
            ${DivisionQueries.Xeo_GetCompanyDivisions}
            ${JobQueries.Xeo_GetCompanyJobs}
            ${StaffQueries.Axenta_GetCompanyStaffDatas({ IsDisplayOnly: true })}
          }
        `);
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).xeoThen(
      (data) => {
        DataStore.AssignConstants(data.Xeo_GetAppConstants);
        DataStore.InitializeCompanyList(data.Xeo_GetUserCompanyList);
        DataStore.InitializeDivisions(data.Xeo_GetCompanyDivisions);
        DataStore.InitializeJobs(data.Xeo_GetCompanyJobs);
        DataStore.InitializeStaffs(data.Axenta_GetCompanyStaffDatas);

        return AppRepositories.Graphql.DoGraphql(`
          query {
            ${PublicQueries.Xeo_GetPublicCalendarEvents('id', 'indonesian')}
          }
        `);
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).xeoThen(
      (data) => {
        DataStore.InitializeCalendarEvents(data.Xeo_GetPublicCalendarEvents);
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => { 
      this.IsAppLoading = false;
    });
  }
  private _LoadServerTime() {
    AppRepositories.Graphql.DoGraphql(`
      query {
        ${PublicQueries.Xeo_GetServerTime}
      }
    `).xeoThen(
      (data) => {
        this.ServerTime = new ServerTime(data.Xeo_LoadServerTime);
      },  
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    );
  }
  private _PdfRegisterFonts() {
    /* Montserrat Fonts */
    XeoBibliotheca.FileCodex.PdfRegisterFont(
      'Montserrat', 'semibold',  
      require('☆XeoApp/Assets/Fonts/Montserrat/Montserrat-Semibold.ttf')
    );

    /* Lato Fonts */
    XeoBibliotheca.FileCodex.PdfRegisterFont(
      'Lato', 'semibold', require('☆XeoApp/Assets/Fonts/Lato/Lato-Semibold.ttf')
    );
    XeoBibliotheca.FileCodex.PdfRegisterFont(
      'Lato', 'bold', require('☆XeoApp/Assets/Fonts/Lato/Lato-Bold.ttf')
    );
  }

  private __InitializeAccessGuard() {
    StateStore.SetAppStates({ 
      PageAccess: this.GetUserAccessState('Client', this.$router.currentRoute.meta?.accessId) 
    });

    this.NavGuardHandler = this.$router.beforeEach((to, _, next) => {
      if (this.IsRouteAccessValid(to)) {
        /* No Navigation Block — Continue */
        StateStore.SetAppStates({ 
          PageAccess: this.GetUserAccessState('Client', to.meta?.accessId) 
        });
        next(); 
      } else {
        /* Redirect to Dashboard on Invalid Access */
        StateStore.SetAppStates({ PageAccess: 100 });
        next({ name: 'Dashboard' }); 
      }
    });
  } 
  private __RedirectOnConds(setupStep: number) {
    /* Invalid Page Access ->> Dashboard */
    if (!this.IsRouteAccessValid(this.$router.currentRoute)) {
      this.$router.replace({ name: 'Dashboard' });
      return;
    }

    /* Incomplete Setup ->> Setup Page */
    setupStep = setupStep || 0;
    if (setupStep < SetupSteps.Complete) {
      const routeName: string = (this.$router as any).options.routes
        .find((route: any) => route.meta?.title == 'Application').children
        .find((route: any) => route.meta?.title == 'Setup').children
        .find((route: any) => route.meta.setupStep == setupStep).name;

      if (routeName && this.$route.name != routeName) {
        this.$router.replace({ name: routeName });
      }
    } 
  }
}

import moment from "moment";
import ModelUtils from "@/Models/—ModelUtils—";

export type CalendarEventType = 'workday' | 'event' | 'holiday' | 'company-holiday';

export class CalendarEvent {
  public Summary!: string;
  public Type!: CalendarEventType;
  public Date!: moment.Moment;
  public IsConfirmed!: boolean;

  constructor(init?: Partial<CalendarEvent>) {
    Object.assign(this, init);
    ModelUtils.InitializeMoments(this, ['Date']);
  }
}
export class ServerTime {
  private _serverTime!: moment.Moment | null;
  private _clientTime!: moment.Moment;

  constructor(st?: string | moment.Moment) {
    if (st) {
      this._serverTime = moment(st);
      this._clientTime = moment();
    }
  }

  public Now(): moment.Moment {
    return this._serverTime?.clone().add(moment().diff(this._clientTime)) || moment();
  }
}
import createNumberMask from '☆Node/text-mask-addons/dist/createNumberMask';
import createAutoCorrectedDatePipe from '☆Node/text-mask-addons/dist/createAutoCorrectedDatePipe';

/* Reference : https://github.com/text-mask/text-mask */
export default class MaskCodex {
  public CreateNumberMask = createNumberMask;
  public CreateAutoCorrectedDatePipe = createAutoCorrectedDatePipe;
  public MaskRawString(template: string, rawString: string, maskPlaceholder: string = '_') {
    const maskRegex = new RegExp(maskPlaceholder, 'g');
    const v: string = rawString.toString();
    let i: number = 0;

    return template.replace(maskRegex, _ => v[i++] || '');
  }
}
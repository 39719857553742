import { Vue, Component } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

import { DataStore } from '@/Store/—–AppStore–—';

@Component
export default class AppRenderMixin extends Vue {
  protected ColorWheelSet = XeoBibliotheca.ColorCodex.GetColorSet(
    'base', 0, [ 'White', 'Midnight', 'TrueWhite' ]
  );

  public _GetCompanyBrandUrl(id: number): string {
    return DataStore.CompanyList[id]?.BrandUrl 
      || require("@/Assets/Images/Monocolor/Building-Line.png");
  }
  public _GetCompanyName(id: number): string {
    return DataStore.CompanyList[id]?.CompanyName || '';
  }
  public _GetDivJobName(type: 'd' | 'j' | string, id: number): string {
    if (type.toLowerCase().startsWith('d')) {
      return DataStore.Divisions[id]?.DivisionName || '';
    } else if (type.toLowerCase().startsWith('j')) {
      return DataStore.Jobs[id]?.JobName || '';
    }

    return '';
  }
  public _GetCircColorByIdx(idx: number, offset: number = 0) {    
    return this.ColorWheelSet[(idx + offset) % this.ColorWheelSet.length];
  }
}

import { Vue, Component, Mixins, Prop } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import { DataStore } from '@/Store/—–AppStore–—';
import PayrollFormulaUtils from '@/Utilities/PayrollFormulaUtils';

import moment from 'moment';
import numeral from '☆Node/numeral';

import * as HelperModels from '@/Models/—HelperModels—';
import { Company } from '@/Models/CompanyModels';
import * as PayrollModels from '@/Models/PayrollModels';

import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';
import XeoDateTimePicker from '☆XeoApp/Vue/Components/Base/XeoDateTimePicker.vue';
import { Staff } from '@/Models/StaffModels';

@Component({
  name: 'PsDocTaxModule'
})
export default class PsDocTaxModule extends 
  Mixins(XeoBaseMixin, XeoFormMixin, XeoModalMixin, AppRenderMixin) 
{
  $refs!: {
    BarModal: HTMLElement,
    TxtCorrectionNum: XeoFormInput,
    DtpSignDate: XeoDateTimePicker
  }

  private get Company() { return DataStore.CompanyHq.Data; }
  private get CompanyCuts() { return DataStore.CompanyHq.Cuts; }
  private get StaffList() { return DataStore.Staffs; }

  @Prop() readonly payrollSummary!: PayrollModels.PayrollSummary;
  @Prop() readonly approvedYearlyPs!: PayrollModels.PayrollSummary[];
  
  private FormTaxSummary: PayrollModels.PsTaxForm = new PayrollModels.PsTaxForm();
  private TabName: 'Company' | 'Staff' = 'Company';
    private TabList = [ 'Company', 'Staff' ];
    private get IndexEnabler(): XeoTypes.IndexEnabler {
      return Object.values(this.TaxDocuments).reduce(
        (ie: XeoTypes.IndexEnabler, val: any, i: number) => {
          if (this.IsVarEmpty(val)) {
            ie.DisabledIndexes.push(i);
          }

          return ie;
        }, new XeoTypes.IndexEnabler()
      );
    }
  private get CompanyTaxCredParts(): string[] {
    return this.__GenerateTaxCredentialParts(this.CompanyCuts.TaxCredential);
  }
  private get FnPrefix(): string {
    return `${this.payrollSummary.TimePeriod.format('YYYYMM')} — `;
  }
  private get TaxPicData(): PayrollModels.PsTaxPic {
    return new PayrollModels.PsTaxPic({
      Name: this.CompanyCuts.TaxPicAccountId > 0 ?
        this.StaffList[this.CompanyCuts.TaxPicAccountId]?.DisplayName || '–' :
        this.Company.CompanyName,
      TaxCredentialParts: this.CompanyCuts.TaxPicAccountId > 0 ?
        this.__GenerateTaxCredentialParts(
          this.StaffList[this.CompanyCuts.TaxPicAccountId]?.Credentials.Tax
        ) : this.CompanyTaxCredParts
    });
  }
  private get PayrollStaffList(): Staff[] {
    const staffAccIdSet = new Set<number>();

    if (this.payrollSummary.TimePeriod.year() == 11) {
      this.approvedYearlyPs.forEach((ps: PayrollModels.PayrollSummary) => {
        ps.SummaryData.StaffPayslips.forEach((sp: HelperModels.StaffPayslip) => {
          staffAccIdSet.add(sp.AccountId);
        });
      });
    } else {
      this.payrollSummary.SummaryData.StaffPayslips.forEach(
        (sp: HelperModels.StaffPayslip) => staffAccIdSet.add(sp.AccountId)
      );
    }

    return Array.from(staffAccIdSet).map((id: number) => this.StaffList[id]);
  }
  private get TaxDocuments(): any {
    const taxTypeCountRec = this.__CountPayslipByTaxType();
    const tpRec: Record<string, string> = {
      'MonthYear': this.payrollSummary.TimePeriod.format('MMMM YYYY'),
      'Year': this.payrollSummary.TimePeriod.format('YYYY')
    };
    const documentRec: any = {
      Company: {
        Year: {
          '1721.pdf': [ `Form 1721 – SPT Masa Tahun ${tpRec['Year']}`, 'pdf' ],
          '1721-I.pdf': [ `Form 1721-I – Tahun ${tpRec['Year']}`, 'pdf' ]
        },
        Month: {
          '1721.pdf': [ `Form 1721 – SPT Masa ${tpRec['MonthYear']}`, 'pdf' ],
          '1721-I.pdf': [ `Form 1721-I – ${tpRec['MonthYear']}`, 'pdf' ],
          '1721-II.pdf': [ `Form 1721-II – ${tpRec['MonthYear']}`, 'pdf' ],
          '1721-I.csv': [ `1721-I – ${tpRec['MonthYear']}`, 'csv' ],
          '1721-II.csv': [ `1721 Tidak Final – ${tpRec['MonthYear']}`, 'csv' ]
        }
      },
      Staff: {
        Year: {
          '1721-A1.pdf': [ `Form 1721-A1 – Tahun ${tpRec['Year']}`, 'pdf' ]
        },
        Month: {
          '1721-VI.pdf': [ `Form 1721-VI – ${tpRec['MonthYear']}`, 'pdf' ]
        }
      }
    };

    /* Delete Empty Reports */
    if (taxTypeCountRec['Annual'] == 0) {
      Vue.delete(documentRec.Company.Month, '1721-I.pdf');
      Vue.delete(documentRec.Company.Month, '1721-I.csv');
      Vue.delete(documentRec.Staff.Year, '1721-A1.pdf');
    }
    if (taxTypeCountRec['NonFinal'] == 0) {
      Vue.delete(documentRec.Company.Month, '1721-II.pdf');
      Vue.delete(documentRec.Company.Month, '1721-II.csv');
      Vue.delete(documentRec.Staff.Month, '1721-VI.pdf');
    }
    if (this.FormTaxSummary.StaffAccId) {
      const staffTaxType = PayrollFormulaUtils.GetTaxType( 
        this.payrollSummary.SummaryData.StaffPayslips.find(
          (sp: HelperModels.StaffPayslip) => sp.AccountId == this.FormTaxSummary.StaffAccId
        ) as HelperModels.StaffPayslip
      );

      if (staffTaxType != 'NonFinal') {
        Vue.delete(documentRec.Staff.Month, '1721-VI.pdf');
      }
    }

    if (this.payrollSummary.TimePeriod.month() < 11) {
      Vue.delete(documentRec.Company, 'Year');
      Vue.delete(documentRec.Staff, 'Year');
    }

    /* Delete Empty Routes */
    Object.keys(documentRec).forEach((owner: string) => {
      Object.keys(documentRec[owner]).forEach((periodType: string) => {
        if (this.IsVarEmpty(documentRec[owner][periodType])) {
          Vue.delete(documentRec[owner], periodType);
        }
      });
    });

    return documentRec;
  }

  protected BtnSaveForm_Click(
    fileId: string, periodType: 'Month' | 'Year', 
    fileType: 'csv' | 'pdf'
  ) {
    switch (fileId.split('.')[0]) {
      case '1721':      this._GenerateTaxForm_1721(periodType); break;
      case '1721-I':    this._GenerateTaxForm_1721I(periodType, fileType); break;
      case '1721-II':   this._GenerateTaxForm_1721II(fileType); break;
      case '1721-VI':   this._GenerateTaxForm_1721VI(); break;
      case '1721-A1':   this._GenerateTaxForm_1721A1(); break;
    }
  }
  protected MdlPsTaxForm_Show() {
    this.FormTaxSummary = new PayrollModels.PsTaxForm();
    this.TabName = 'Company';
  }
  protected MiTaxMenu_Click() {
    this.open();
  }

  private async _GenerateTaxForm_1721(reportPeriod: 'Month' | 'Year') {
    /* Initialize Pdf & Monthly Tax Summary */
    const taxPdf: XeoTypes.XeoPdf = this.__InitializeTaxPdf('p');
    const isYearlyReport: boolean = reportPeriod == 'Year';
    const monthlyTaxSummary: PayrollModels.PsTaxMonthSummary = 
      this.__GenerateTaxMonthSummary(isYearlyReport);

    /* Page 1 */
    taxPdf.addImage(
      require('@/Assets/Images/Form/Tax/SptMasa1721-01.png'), 
      'PNG', 0, 0, 216, 330, undefined, 'FAST'
    );

    /* Form Header */
    taxPdf.writef(this.payrollSummary.TimePeriod, 'MM', 33, 54, { align: 'center' });
    taxPdf.writef(this.payrollSummary.TimePeriod, 'YYYY', 51, 54, { align: 'center' });
    this.__WriteSignX(
      taxPdf, this.FormTaxSummary.CorrectionNum == 0 ? 70.5 : 117.5, 54.7
    );
    if (this.FormTaxSummary.CorrectionNum > 0) {
      taxPdf.writef(
        this.FormTaxSummary.CorrectionNum, '(0,0)', 143.5, 56, { align: 'center' }
      );
    }

    /* A. Identitas Pemotong */
    const cmpAddressParts: string[] = 
      this.__GenerateAddressParts(this.Company.Address, 3);

    taxPdf.write(this.CompanyTaxCredParts[0], 40, 73);
      taxPdf.write(this.CompanyTaxCredParts[1], 97, 73);
      taxPdf.write(this.CompanyTaxCredParts[2], 115, 73);
    taxPdf.write(this.Company.CompanyName, 40, 80);
    taxPdf.write(cmpAddressParts[0], 40, 87);
      taxPdf.write(cmpAddressParts[1], 40, 93);
    taxPdf.write(this.Company.PhoneNumber, 40, 100);
    taxPdf.write(this.Company.EmailAddress, 146, 100);

    /* B. Objek Pajak */
    const bRowSpacingRec: Record<any, number> = {
      '-': 6,
      'ExcessTax': 10.5,      'TotalExcessTax': 10.5,
      'FixTax': 8,            'SubOsToFixTax': 8
    };
    let bCursorY: number = 133 - 7;    // Cursor Y on -1 position

    [...[1, 2, 3].map(i => 2110000 + i), '-',
        ...[4,5,6,7,8,9,10,11,12,13,99].map(i => 2110000 + i),
        2710099, 'TotalObjectSummary', '-',
      'StpValue', 'ExcessTax', 'TotalExcessTax', 'SubOsToExcessTax', 
        '-', 'FixTax', 'SubOsToFixTax'
    ].forEach((rowCode: any) => {
      bCursorY += bRowSpacingRec[rowCode] || 7;

      if (rowCode >= 2110001 || rowCode == 'TotalObjectSummary') {
        this.__WriteTaxObjectSummary(
          taxPdf,
          monthlyTaxSummary.ObjectSummaryRec[rowCode] 
            || (monthlyTaxSummary as any)[rowCode]
            || new PayrollModels.PsTaxObjectSummary(), 
          bCursorY
        );
      } else if (rowCode == 'ExcessTax') {
        const excessTax: PayrollModels.PsTaxItem = monthlyTaxSummary.ExcessTax;

        if (excessTax.Value && excessTax.DatePeriod) {
          this.__WriteSignX(taxPdf, 39.2 + excessTax.DatePeriod.month() * 7.5, 266.6);
          taxPdf.writef(excessTax.DatePeriod, 'YYYY', 145, 268, { align: 'center' });
        }

        taxPdf.writef(excessTax.Value, '(0,0)', 205, bCursorY, { align: 'right' });
      } else if (rowCode == 'FixTax') {
        const fixTax: PayrollModels.PsTaxItem = monthlyTaxSummary.FixTax;

        taxPdf.writef(fixTax.Value, '(0,0)', 205, bCursorY, { align: 'right' });

        if (fixTax.Value && fixTax.DatePeriod) {
          taxPdf.writef(fixTax.DatePeriod, 'MM', 177, 314.5, { align: 'center' });
          taxPdf.writef(fixTax.DatePeriod, 'YYYY', 195, 314.5, { align: 'center' });
        }
      } else if (rowCode != '-') {
        taxPdf.writef(
          (monthlyTaxSummary as any)[rowCode], '(0,0)', 
          205, bCursorY, { align: 'right' }
        );
      }
    });

    /* Page 2 + Tax Credential */
    taxPdf.addPage();
    taxPdf.addImage(
      require('@/Assets/Images/Form/Tax/SptMasa1721-02.png'), 
      'PNG', 0, 0, 216, 330, undefined, 'FAST'
    );

    taxPdf.write(this.CompanyTaxCredParts[0], 45, 21);
      taxPdf.write(this.CompanyTaxCredParts[1], 102, 21);
      taxPdf.write(this.CompanyTaxCredParts[2], 120, 21);

    /* C. Object Pajak Final */ 
    const cRowSpacingRec: Record<any, number> = {
      2140201: 8.5,     2149999: 8.5
    };
    let cCursorY: number = 52 - 7;    // Cursor Y on -1 position
    
    [...[101, 102, 201, 9999].map(i => 2140000 + i), 'TotalFinalObjectSummary']
      .forEach((rowCode: any) => {
        cCursorY += cRowSpacingRec[rowCode] || 7;
        this.__WriteTaxObjectSummary(
          taxPdf,
          monthlyTaxSummary.ObjectSummaryRec[rowCode] 
            || (monthlyTaxSummary as any)[rowCode]
            || new PayrollModels.PsTaxObjectSummary(), 
          cCursorY
        );
      });

    /* D. Lampiran */
    ['Annual', 'AnnualYear', 'NonFinal', 'Final'].forEach(
      (taxType: string, i: number) => {
        if (monthlyTaxSummary.TaxAttchPgSzRec[taxType] > 0) {
          const cursorY: number = 98 + 9 * i;
    
          this.__WriteSignX(taxPdf, 11.5, cursorY);
          taxPdf.writef(
            monthlyTaxSummary.TaxAttchPgSzRec[taxType], '0,0',
            70.5, cursorY, { alignment: 'center-middle' }
          );
        }
      }
    );

    /* E. Pernyataan dan Tanda Tangan Pemotong */
    const signDate: moment.Moment = this.FormTaxSummary.SignDate;

    this.__WriteSignX(taxPdf, 19.5 + (this.CompanyCuts.TaxPicType ? 51 : 0), 152.5);
    taxPdf.write(this.TaxPicData.TaxCredentialParts[0], 34, 161.5);
      taxPdf.write(this.TaxPicData.TaxCredentialParts[1], 91, 161.5);
      taxPdf.write(this.TaxPicData.TaxCredentialParts[2], 109, 161.5);
    taxPdf.write(this.TaxPicData.Name, 34, 169.5);
    taxPdf.writef(signDate, 'DD', 41, 177.5, { align: 'center' });
      taxPdf.writef(signDate, 'MM', 54, 177.5, { align: 'center' });
      taxPdf.writef(signDate, 'YYYY', 72, 177.5, { align: 'center' });
    taxPdf.write(this.Company.City, 38, 185.5);

    /* Save Pdf */
    taxPdf.saveAs(this.FnPrefix + this.TaxDocuments.Company[reportPeriod]['1721.pdf'][0]);
  }
  private async _GenerateTaxForm_1721I(
    reportPeriod: 'Month' | 'Year', fileType: 'pdf' | 'csv'
  ) {
    /* Summarize Annual Tax Datas */
    const annPayslipSumRec: Record<number, HelperModels.StaffPayslip> = {};
    const nztPayslips: HelperModels.StaffPayslip[] = [];
    const nztTotalSummary = new PayrollModels.PsTaxObjectSummary();
    const ztTotalSummary = new PayrollModels.PsTaxObjectSummary();
    const isYearlyReport: boolean = reportPeriod == 'Year';
    let pageCount: number = 0;

    if (isYearlyReport) {
      this.approvedYearlyPs.reverse().forEach((ps: PayrollModels.PayrollSummary) => {
        ps.SummaryData.StaffPayslips.forEach(
          (sp: HelperModels.StaffPayslip) => this.__InputAnnPayslipSumRec(
            annPayslipSumRec, sp, ps.TimePeriod.month()
          )
        ); 
      });
    } else {
      this.payrollSummary.SummaryData.StaffPayslips.forEach(
        (sp: HelperModels.StaffPayslip) => this.__InputAnnPayslipSumRec(
          annPayslipSumRec, sp, this.payrollSummary.TimePeriod.month()
        )
      );
    }

    Object.values(annPayslipSumRec).forEach((sp: HelperModels.StaffPayslip) => {
      if (sp.PayslipSummary.TaxSum > 0) {
        nztPayslips.push(sp);
        nztTotalSummary.BrutoIncome += sp.PayslipSummary.BrutoIncome;
        nztTotalSummary.TaxValue += sp.PayslipSummary.TaxSum;
      } else {
        ztTotalSummary.Quantity++;
        ztTotalSummary.BrutoIncome += sp.PayslipSummary.BrutoIncome;
      }
    });

    nztPayslips.sort((a, b: HelperModels.StaffPayslip) => {
      return b.PayslipSummary.TaxSum - a.PayslipSummary.TaxSum;
    });
    
    pageCount = Math.max(
      Math.ceil(nztPayslips.length / 20), ztTotalSummary.Quantity > 0 ? 1 : 0
    );

    /* Fill Forms */
    if (pageCount == 0) {
      return;
    } else if (fileType == 'pdf') {
      const taxPdf: XeoTypes.XeoPdf = this.__InitializeTaxPdf('l');
      taxPdf.addImage(
        require('@/Assets/Images/Form/Tax/1721-I.png'), 
        'PNG', 0, 0, 330, 216, undefined, 'FAST'
      );

      for (let page: number = 0; page < pageCount; page++) {
        /* Render 1721-I Form */
        if (page > 0) {
          taxPdf.addPage([330, 216], 'l');
          taxPdf.addImage(
            require('@/Assets/Images/Form/Tax/1721-I.png'), 
            'PNG', 0, 0, 330, 216, undefined, 'FAST'
          );
        }

        /* Form Header */
        taxPdf.setFontSize(10);
        taxPdf.writef(this.payrollSummary.TimePeriod, 'MM', 92, 34, { align: 'center' });
        taxPdf.writef(this.payrollSummary.TimePeriod, 'YYYY', 110, 34, { align: 'center' });
        this.__WriteSignX(taxPdf, 126.85, 28.94 + (isYearlyReport ? 5 : 0), 13);
        taxPdf.write(this.CompanyTaxCredParts[0], 190, 34);
          taxPdf.write(this.CompanyTaxCredParts[1], 247, 34);
          taxPdf.write(this.CompanyTaxCredParts[2], 265, 34);

        /* Form Item List */
        taxPdf.setFontSize(8.5);
        const payslipIdxRange: number[] = [
          page * 20,
          Math.min((page + 1) * 20, nztPayslips.length)
        ];
        for (let i: number = payslipIdxRange[0]; i < payslipIdxRange[1]; i++) {
          const nztExtData: HelperModels.PayslipExtData = 
            nztPayslips[i].ExtensionData;
          const nztPayslipSum: HelperModels.PayslipSummary = 
            nztPayslips[i].PayslipSummary; 
          const cursorY: number = 67.5 + 6 * i;

          taxPdf.writef(i + 1, '0,0', 19, cursorY, { alignment: 'center-middle' });
          taxPdf.write(nztExtData.TaxCredential, 25, cursorY, { baseline: 'middle' });
          taxPdf.write(nztPayslips[i].Name, 68, cursorY, { baseline: 'middle' });
          taxPdf.write(
            this.__FormatSpTaxCode(nztPayslips[i]), 
            205.5, cursorY, { alignment: 'center-middle' }
          );
          taxPdf.writef(
            nztPayslipSum.BrutoIncome, '(0,0)', 256, cursorY, { alignment: 'right-middle' }
          );
          taxPdf.writef(
            nztPayslipSum.TaxSum, '(0,0)', 289, cursorY, { alignment: 'right-middle' }
          );
          taxPdf.write(
            nztExtData.OriginCountryCode, 314, cursorY, { alignment: 'center-middle' }
          );

          if (isYearlyReport) {
            taxPdf.write(
              this.__FormatSpTaxReceiptId(nztPayslips[i]), 
              130.15, cursorY, { alignment: 'center-middle' }
            );
            taxPdf.writef(
              this.FormTaxSummary.SignDate, 'DD-MM-YYYY', 
              172.15, cursorY, { alignment: 'center-middle' }
            );
            taxPdf.write(
              this.__FormatSpMonthPeriodRange(nztPayslips[i]), 
              299.5, cursorY, { alignment: 'center-middle' }
            );
          }
        }
      }

      /* Form Summary */
      taxPdf.setFontSize(10);
      taxPdf.writef(
        nztTotalSummary.BrutoIncome, '(0,0)', 256, 187.5, { alignment: 'right-middle' }
      );
      taxPdf.writef(
        nztTotalSummary.TaxValue, '(0,0)', 289, 187.5, { alignment: 'right-middle' }
      );

      taxPdf.writef(
        ztTotalSummary.Quantity, '0,0', 204, 195, { alignment: 'right-middle' }
      );
      taxPdf.writef(
        ztTotalSummary.BrutoIncome, '0,0', 256, 195, { alignment: 'right-middle' }
      );

      taxPdf.writef(
        nztTotalSummary.BrutoIncome + ztTotalSummary.BrutoIncome, '(0,0)',
        256, 202.5, { alignment: 'right-middle' }
      );
      taxPdf.writef(
        nztTotalSummary.TaxValue, '(0,0)', 289, 202.5, { alignment: 'right-middle' }
      );

      /* Save Pdf */
      taxPdf.saveAs(this.FnPrefix + this.TaxDocuments.Company[reportPeriod]['1721-I.pdf'][0]);
    } else if (fileType == 'csv') {
      const timePeriod: moment.Moment = this.payrollSummary.TimePeriod;
      let csvContent: string = `Masa Pajak;Tahun Pajak;Pembetulan;` 
        + `NPWP;Nama;Kode Pajak;Jumlah Bruto;Jumlah PPh;Kode Negara` + `\r\n`;
      
      nztPayslips.forEach((sp: HelperModels.StaffPayslip) => {
        csvContent += `${timePeriod.month() + 1};${timePeriod.year()};`
          + `${this.FormTaxSummary.CorrectionNum};`
          + `${sp.ExtensionData.TaxCredential};${sp.Name};`
          + `${this.__FormatSpTaxCode(sp)};`
          + `${numeral(sp.PayslipSummary.BrutoIncome).format('0')};`
          + `${numeral(sp.PayslipSummary.TaxSum).format('0')};`
          + `${sp.ExtensionData.OriginCountryCode}`
          + `\r\n`;
      });

      XeoBibliotheca.FileCodex.SaveFile(
        this.FnPrefix + `${this.TaxDocuments.Company.Month['1721-I.csv'][0]}.csv`, 
        csvContent
      );
    }
  }
  private async _GenerateTaxForm_1721II(fileType: 'pdf' | 'csv') {
    /* Initialize Pdf & Variables */
    const taxPdf: XeoTypes.XeoPdf = this.__InitializeTaxPdf('l');
    const nftPayslips: HelperModels.StaffPayslip[] = 
      this.payrollSummary.SummaryData.StaffPayslips.filter(
        (sp: HelperModels.StaffPayslip) => 
          PayrollFormulaUtils.GetTaxType(sp) == 'NonFinal'
      ).sort((a, b: HelperModels.StaffPayslip) => {
        return b.PayslipSummary.TaxSum - a.PayslipSummary.TaxSum;
      });
    const nftTaxSummary: PayrollModels.PsTaxObjectSummary = 
      nftPayslips.reduce(
        (tos: PayrollModels.PsTaxObjectSummary, ps: HelperModels.StaffPayslip) => {
          tos.BrutoIncome += ps.PayslipSummary.BrutoIncome;
          tos.TaxValue += ps.PayslipSummary.TaxSum;

          return tos;
        }, new PayrollModels.PsTaxObjectSummary()
      );
    const pageCount: number = Math.ceil(nftPayslips.length / 20);

    /* Fill Forms */
    if (pageCount == 0) {
      return;
    } else if (fileType == 'pdf') {
      for (let page: number = 0; page < pageCount; page++) {
        /* Render 1721-II Form */
        if (page > 0) {
          taxPdf.addPage([330, 216], 'l');
        }
        taxPdf.addImage(
          require('@/Assets/Images/Form/Tax/1721-II.png'), 
          'PNG', 0, 0, 330, 216, undefined, 'FAST'
        );

        /* Form Header */
        taxPdf.setFontSize(10);
        taxPdf.writef(
          this.payrollSummary.TimePeriod, 'MM', 92, 34, { align: 'center' }
        );
        taxPdf.writef(
          this.payrollSummary.TimePeriod, 'YYYY', 110, 34, { align: 'center' }
        );
        taxPdf.write(this.CompanyTaxCredParts[0], 160, 34);
          taxPdf.write(this.CompanyTaxCredParts[1], 217, 34);
          taxPdf.write(this.CompanyTaxCredParts[2], 235, 34);

        /* Form Item List */
        taxPdf.setFontSize(8.5);
        const payslipIdxRange: number[] = [
          page * 20,
          Math.min(nftPayslips.length, (page + 1) * 20)
        ];
        for (let i: number = payslipIdxRange[0]; i < payslipIdxRange[1]; i++) {
          const nftExtData: HelperModels.PayslipExtData = nftPayslips[i].ExtensionData;
          const nftPayslipSum: HelperModels.PayslipSummary = 
            nftPayslips[i].PayslipSummary;
          const cursorY: number = 62.5 + 7 * i;

          taxPdf.writef(i + 1, '0,0', 13.5, cursorY, { alignment: 'center-middle' });
          taxPdf.write(nftExtData.TaxCredential, 19.5, cursorY, { baseline: 'middle' });
          taxPdf.write(nftPayslips[i].Name, 61.5, cursorY, { baseline: 'middle' });
          taxPdf.write(
            this.__FormatSpTaxReceiptId(nftPayslips[i]), 
            129.75, cursorY, { alignment: 'center-middle' }
          );
          taxPdf.writef(
            this.FormTaxSummary.SignDate, 'DD-MM-YYYY', 
            172.15, cursorY, { alignment: 'center-middle' }
          );
          taxPdf.write(
            this.__FormatSpTaxCode(nftPayslips[i]), 
            206, cursorY, { alignment: 'center-middle' }
          );
          taxPdf.writef(
            nftPayslipSum.BrutoIncome, '0,0', 
            260, cursorY, { alignment: 'right-middle' }
          );
          taxPdf.writef(
            nftPayslipSum.TaxSum, '0,0', 302, cursorY, { alignment: 'right-middle' }
          );
          taxPdf.write(
            nftExtData.OriginCountryCode, 312, cursorY, { alignment: 'center-middle' }
          );
        }
      }

      /* Form Summary */
      taxPdf.setFontSize(10);
      taxPdf.writef(
        nftTaxSummary.BrutoIncome, '0,0', 260, 202.75, { alignment: 'right-middle' }
      );
      taxPdf.writef(
        nftTaxSummary.TaxValue, '0,0', 302, 202.75, { alignment: 'right-middle' }
      );

      /* Save Pdf */
      taxPdf.saveAs(this.FnPrefix + this.TaxDocuments.Company.Month['1721-II.pdf'][0]);
    } else if (fileType == 'csv') {
      const timePeriod: moment.Moment = this.payrollSummary.TimePeriod;
      let csvContent: string = `Masa Pajak;Tahun Pajak;Pembetulan;Nomor Bukti Potong;`
        + `NPWP;NIK;Nama;Alamat;WP Luar Negeri;Kode Negara;Kode Pajak;`
        + `Jumlah Bruto;Jumlah DPP;Tanpa NPWP;Tarif;Jumlah PPh;`
        + `NPWP Pemotong;Nama Pemotong;Tanggal Bukti Potong` + '\r\n';

      nftPayslips.forEach((sp: HelperModels.StaffPayslip) => {
        csvContent += `${timePeriod.month() + 1};${timePeriod.year()};`
          + `${this.FormTaxSummary.CorrectionNum};`
          + `${sp.ExtensionData.TaxCredential};${sp.ExtensionData.PersonalId};`
          + `${sp.Name};${sp.ExtensionData.Address};`
          + `${sp.ExtensionData.OriginCountryCode ? 'Y' : 'N'};`
          + `${sp.ExtensionData.OriginCountryCode};`
          + `${this.__FormatSpTaxCode(sp)};`
          + `${numeral(sp.PayslipSummary.BrutoIncome).format('0')};`
          + `${numeral(sp.PayslipSummary.TaxableIncome).format('0')};`
          + `${PayrollFormulaUtils.HasTaxCredential(sp) ? 'N' : 'Y'};`
          + `${sp.PayslipSummary.LastTaxPercentage};`
          + `${numeral(sp.PayslipSummary.TaxSum).format('0')};`
          + `${this.CompanyCuts.TaxCredential};${this.Company.CompanyName};`
          + `${moment(this.FormTaxSummary.SignDate).format('DD-MM-YYYY')}`
          + `\r\n`;
      });

      XeoBibliotheca.FileCodex.SaveFile(
        this.FnPrefix + `${this.TaxDocuments.Company.Month['1721-II.csv'][0]}.csv`, 
        csvContent
      );
    }
  }
  private async _GenerateTaxForm_1721VI() {
    const taxPdf: XeoTypes.XeoPdf = this.__InitializeTaxPdf('p');
    const nftPayslips: HelperModels.StaffPayslip[] =
      this.payrollSummary.SummaryData.StaffPayslips.filter(
        (sp: HelperModels.StaffPayslip) => (
          !this.FormTaxSummary.StaffAccId || sp.AccountId == this.FormTaxSummary.StaffAccId
        ) && PayrollFormulaUtils.GetTaxType(sp) == 'NonFinal'
      );

    nftPayslips.forEach((sp: HelperModels.StaffPayslip, idx: number) => {
      /* Render 1721-VI Form */
      if (idx > 0) {
        taxPdf.addPage([216, 330], 'p');
      }
      taxPdf.addImage(
        require('@/Assets/Images/Form/Tax/1721-VI.png'), 
        'PNG', 0, 0, 216, 330, undefined, 'FAST'
      );
      
      /* Form Header */
      taxPdf.setFontSize(10);
      taxPdf.writef(this.payrollSummary.TimePeriod, 'MM', 109, 42.35, { align: 'center' });
      taxPdf.writef(this.payrollSummary.TimePeriod, 'YY', 122, 42.35, { align: 'center' });
      taxPdf.write(
        sp.PayslipSummary.TaxReceiptId.toString().padStart(7, '0'), 
        150, 42.35, { align: 'center' }
      );

      /* A. Identitas Penerima Penghasilan yang Dipotong */
      const staffTaxCredParts: string[] = 
        this.__GenerateTaxCredentialParts(sp.ExtensionData.TaxCredential);
      const staffAddressParts: string[] =
        this.__GenerateAddressParts(sp.ExtensionData.Address, 3);

      taxPdf.write(staffTaxCredParts[0], 34, 60);
        taxPdf.write(staffTaxCredParts[1], 87, 60);
        taxPdf.write(staffTaxCredParts[2], 105, 60);
      taxPdf.write(sp.ExtensionData.PersonalId, 159, 60);
      taxPdf.write(sp.Name, 34, 67);
      taxPdf.write(staffAddressParts[0], 34, 74);
        taxPdf.write(staffAddressParts[1], 34, 80);

      if (sp.ExtensionData.TaxEmploymentStatus == 2710099) {
        this.__WriteSignX(taxPdf, 63.5, 86.7);
        taxPdf.write(sp.ExtensionData.OriginCountryCode, 178, 88, { align: 'center' });
      }

      /* B. PPh Pasal 21 dan/atau Pasal 26 yang Dipotong */
      const spacedTaxCode: string = this.__FormatSpTaxCode(sp)
        .replaceAll('-100-', '    -       100       -    ');

      taxPdf.write(spacedTaxCode, 36, 130.5, { alignment: 'center-middle' });
      taxPdf.writef(
        sp.PayslipSummary.BrutoIncome, '0,0', 82, 130.5, { alignment: 'center-middle' }
      );
      taxPdf.writef(
        sp.PayslipSummary.TaxableIncome, '0,0', 
        117.5, 130.5, { alignment: 'center-middle' }
      );
      taxPdf.writef(
        sp.PayslipSummary.LastTaxPercentage, '0',
        164, 130.5, { alignment: 'center-middle' }
      );
      taxPdf.writef(
        sp.PayslipSummary.TaxSum, '0,0', 190.5, 130.5, { alignment: 'center-middle' }
      );
      if (!PayrollFormulaUtils.HasTaxCredential(sp)) {
        this.__WriteSignX(taxPdf, 145.5, 130.5);
      }

      /* C. Identitas Pemotong */
      taxPdf.write(this.TaxPicData.TaxCredentialParts[0], 31, 149);
        taxPdf.write(this.TaxPicData.TaxCredentialParts[1], 84, 149);
        taxPdf.write(this.TaxPicData.TaxCredentialParts[2], 102, 149);
      taxPdf.write(this.TaxPicData.Name, 31, 158);
      taxPdf.writef(this.FormTaxSummary.SignDate, 'DD', 126, 158, { align: 'center' });
        taxPdf.writef(this.FormTaxSummary.SignDate, 'MM', 139, 158, { align: 'center' });
        taxPdf.writef(
          this.FormTaxSummary.SignDate, 'YYYY', 157.5, 158, { align: 'center' }
        );
    });

    taxPdf.saveAs(this.FnPrefix + this.TaxDocuments.Staff.Month['1721-VI.pdf'][0]);
  }
  private async _GenerateTaxForm_1721A1() {
    const taxPdf: XeoTypes.XeoPdf = this.__InitializeTaxPdf('p');

    this.__GenerateYearlyAnnTaxSummary(
      this.approvedYearlyPs, this.FormTaxSummary.StaffAccId
    ).forEach((sp: HelperModels.StaffPayslip, idx: number) => {
      /* Initialize Page */
      if (idx > 0) {
        taxPdf.addPage([216, 330], 'p');
      }
      taxPdf.addImage(
        require('@/Assets/Images/Form/Tax/1721-A1.png'), 
        'PNG', 0, 0, 216, 330, undefined, 'FAST'
      );

      /* Form Header */
      taxPdf.setFontSize(10);
      taxPdf.writef(this.payrollSummary.TimePeriod, 'MM', 107, 42.35, { align: 'center' });
      taxPdf.writef(this.payrollSummary.TimePeriod, 'YY', 120, 42.35, { align: 'center' });
      taxPdf.write(
        sp.PayslipSummary.TaxReceiptId.toString().padStart(7, '0'), 
        148, 42.35, { align: 'center' }
      );
      taxPdf.write(
        (sp.ExtensionData.MonthPeriodRange[0] + 1).toString().padStart(2, '0'), 
        183, 43, { align: 'center' }
      );
      taxPdf.write(
        (sp.ExtensionData.MonthPeriodRange[1] + 1).toString().padStart(2, '0'), 
        196, 43, { align: 'center' }
      );

      taxPdf.write(this.CompanyTaxCredParts[0], 35, 52);
        taxPdf.write(this.CompanyTaxCredParts[1], 92, 52);
        taxPdf.write(this.CompanyTaxCredParts[2], 110, 52);
      taxPdf.write(this.Company.CompanyName, 35, 59);

      /* A. Identitas Penerima Penghasilan yang Dipotong */
      const staffTaxCredParts: string[] = 
        this.__GenerateTaxCredentialParts(sp.ExtensionData.TaxCredential);
      const staffAddressParts: string[] = 
        this.__GenerateAddressParts(sp.ExtensionData.Address, 2);
      const maritalStatsX: number = 130.5 + (sp.ExtensionData.TaxMaritalStatus - 1) * 23;

      taxPdf.write(staffTaxCredParts[0], 30, 77.5);
        taxPdf.write(this.CompanyTaxCredParts[1], 83, 77.5);
        taxPdf.write(this.CompanyTaxCredParts[2], 101, 77.5);
      taxPdf.write(sp.ExtensionData.PersonalId, 30, 85);
      taxPdf.write(sp.Name, 30, 92);
      taxPdf.write(staffAddressParts[0], 30, 99);
        taxPdf.write(staffAddressParts[1], 30, 106);
      this.__WriteSignX(
        taxPdf, 42.85 + (sp.ExtensionData.Gender == 2 ? 28 : 0), 111.675
      );
      
      taxPdf.writef(
        sp.ExtensionData.TaxTotalDependents, '0,0', 
        maritalStatsX, 83, { alignment: 'center-middle' }
      );
      taxPdf.write(sp.Job, 152, 92);
      if (sp.ExtensionData.TaxEmploymentStatus < 0) {
        this.__WriteSignX(taxPdf, 163.7, 97.67);
        taxPdf.write(sp.ExtensionData.OriginCountryCode, 167.5, 106, { align: 'center' });
      }
      
      /* B. Rincian Penghasilan dan Penghitungan PPh Pasal 21 */
      const taxEsX: number = 40.4 + 
        (Math.abs(sp.ExtensionData.TaxEmploymentStatus) == 2110001 ? 0 : 21);

      taxPdf.setFontSize(9);
      this.__WriteSignX(taxPdf, taxEsX, 132.5, 15);
      Object.entries(sp.PayslipSummary.YearlyFormItemRec).forEach(
        ([row, val]) => {
          taxPdf.writef(
            val || 0, '0,0', 205.5, 143.5 + ((row as any) - 1) * 5.5, 
            { alignment: 'right-middle' }
          );
        }
      );

      /* C. Identitas Pemotong */
      const signDate: moment.Moment = this.FormTaxSummary.SignDate;

      taxPdf.setFontSize(10);
      taxPdf.write(this.TaxPicData.TaxCredentialParts[0], 31, 275.5);
        taxPdf.write(this.TaxPicData.TaxCredentialParts[1], 84, 275.5);
        taxPdf.write(this.TaxPicData.TaxCredentialParts[2], 102, 275.5);
      taxPdf.write(this.TaxPicData.Name, 31, 284.5);
      taxPdf.writef(signDate, 'DD', 126, 284.5, { align: 'center' });
        taxPdf.writef(signDate, 'MM', 139, 284.5, { align: 'center' });
        taxPdf.writef(signDate, 'YYYY', 157, 284.5, { align: 'center' });
    });

    /* Save Pdf */
    taxPdf.saveAs(this.FnPrefix + this.TaxDocuments.Staff.Year['1721-A1.pdf'][0]);
  }

  private __CountPayslipByTaxType(): Record<string, number> {
    const staffAccId: number = this.FormTaxSummary.StaffAccId;
    const taxTypeCountRec: Record<string, number> = {
      'Annual': 0,
      'NonFinal': 0,
      'Final': 0
    };

    this.payrollSummary.SummaryData.StaffPayslips.forEach(
      (sp: HelperModels.StaffPayslip) => {
        if (!staffAccId || sp.AccountId == staffAccId) {
          taxTypeCountRec[PayrollFormulaUtils.GetTaxType(sp)]++;
        }
      }
    );

    return taxTypeCountRec;
  }
  private __FormatSpMonthPeriodRange(sp: HelperModels.StaffPayslip): string {
    return sp.ExtensionData.MonthPeriodRange.reduce(
      (str: string, month: number) => str + (month + 1).toString().padStart(2, '0'), ''
    );
  }
  private __FormatSpTaxCode(sp: HelperModels.StaffPayslip): string {
    const tcStr: string = sp.ExtensionData.TaxEmploymentStatus.toString();
    return `${tcStr.slice(0, 2)}-${tcStr.slice(2, 5)}-${tcStr.slice(5)}`;
  }
  private __FormatSpTaxReceiptId(sp: HelperModels.StaffPayslip): string {
    if (sp.PayslipSummary.TaxReceiptId) {
      const taxType = PayrollFormulaUtils.GetTaxType(sp);
      const timePeriodStr: string = this.payrollSummary.TimePeriod.format('MM.YY');
      const taxReceiptId: string = 
        sp.PayslipSummary.TaxReceiptId.toString().padStart(7, '0');
      const taxTypeId: string = taxType == 'Annual' ? '1' :
        taxType == 'NonFinal' ? '3' :
        taxType == 'Final' ? '4' :
        '';

      return `1.${taxTypeId}-${timePeriodStr}-${taxReceiptId}`;
    }

    return '';
  }
  private __GenerateAddressParts(address: string, partLength: number): string[] {
    const addressParts: any[] = address.replaceAll('\n', ', ').split(', ');
    return [ 
      addressParts.slice(0, partLength).join(', '), 
      addressParts.slice(Math.max(addressParts.length - partLength, partLength)).join(', ') 
    ];
  }
  private __GenerateTaxCredentialParts(taxCred: string): string[] {
    return (taxCred || PayrollFormulaUtils.EmptyTaxCredentialValue).split('-').map(
      (tcPart: string, idx: number) => {
        return idx == 0 ? tcPart : tcPart.split('.')
      }
    ).flat();
  }
  private __GenerateTaxMonthSummary(
    isYearlyReport: boolean
  ): PayrollModels.PsTaxMonthSummary{
    return new PayrollModels.PsTaxMonthSummary(
      this.payrollSummary, this.approvedYearlyPs, isYearlyReport, {}
    );
  }
  private __GenerateYearlyAnnTaxSummary(
    approvedPs: PayrollModels.PayrollSummary[], accountId: number = 0
  ): HelperModels.StaffPayslip[] {
    const spSumRec: Record<number, HelperModels.StaffPayslip> = {};

    /* Summarize & Input Yearly Payslip Items */
    approvedPs.sort(
      (a, b) => b.TimePeriod.diff(a.TimePeriod)
    ).forEach((ps: PayrollModels.PayrollSummary) => {
      ps.SummaryData.StaffPayslips.forEach((sp: HelperModels.StaffPayslip) => {
        if (
          PayrollFormulaUtils.GetTaxType(sp) != 'Annual' 
          || (accountId && sp.AccountId != accountId)
        ) {
          return;
        } 

        const monthPeriod: number = ps.TimePeriod.month();
      
        if (!spSumRec[sp.AccountId]) {
          spSumRec[sp.AccountId] = new HelperModels.StaffPayslip(sp);
          spSumRec[sp.AccountId].ExtensionData.MonthPeriodRange = 
            [ monthPeriod, monthPeriod ];
          spSumRec[sp.AccountId].PayslipSummary.YearlyFormItemRec = 
            this.__InitializeFormItemRec();
        }

        const spSum: HelperModels.StaffPayslip = spSumRec[sp.AccountId];
        const spFormItemRec = spSum.PayslipSummary.YearlyFormItemRec;

        spSum.ExtensionData.MonthPeriodRange[0] = monthPeriod;
        sp.StaffItems.forEach((si: HelperModels.PayslipItem) => {
          this.__InputPayslipItemToRecord(spFormItemRec, si);
        });
        sp.GovermentItems.forEach((gi: HelperModels.PayslipItem) => {
          if (gi.TaxCategory == 5) {
            this.__InputPayslipItemToRecord(spFormItemRec, gi);
          }
        });
        spFormItemRec[10] += sp.PayslipSummary.JobFee;
      });
    });

    /* Input Tax Summary */
    Object.entries(spSumRec).forEach(([accId, sp]) => {
      const spFormItemRec = spSumRec[accId as any].PayslipSummary.YearlyFormItemRec;

      spFormItemRec[8] = (Object.keys(spFormItemRec) as any).reduce(
        (sum: number, taxCatg: number) => {
          return sum + (1 <= taxCatg && taxCatg <= 7 ? spFormItemRec[taxCatg] : 0);
        }, 0
      );

      spFormItemRec[12] = spFormItemRec[10] + spFormItemRec[11];

      spFormItemRec[14] = Math.max(spFormItemRec[8] - spFormItemRec[12], 0);
      spFormItemRec[15] = sp.ExtensionData.InitialNetIncome;
      spFormItemRec[16] = sp.PayslipSummary.YearlyNettIncome;
      spFormItemRec[17] = sp.PayslipSummary.YearlyUntaxedIncome;
      spFormItemRec[18] = this.__RoundToThousand(
        Math.max(spFormItemRec[16] - spFormItemRec[17], 0)
      );
      spFormItemRec[19] = sp.PayslipSummary.YearlyTaxValue;
      spFormItemRec[20] = sp.ExtensionData.PrepaidTax;
      spFormItemRec[21] = Math.max(spFormItemRec[19] - spFormItemRec[20], 0);
      spFormItemRec[22] = spFormItemRec[21];
    });

    return Object.values(spSumRec);
  }
  private __InputAnnPayslipSumRec(
    spSumRec: Record<number, HelperModels.StaffPayslip>, 
    sp: HelperModels.StaffPayslip, monthPeriod: number
  ) {
    if (PayrollFormulaUtils.GetTaxType(sp) == 'Annual') {
      if (spSumRec[sp.AccountId]) {
        spSumRec[sp.AccountId].PayslipSummary.BrutoIncome += sp.PayslipSummary.BrutoIncome;
        spSumRec[sp.AccountId].PayslipSummary.TaxSum += sp.PayslipSummary.TaxSum;
        spSumRec[sp.AccountId].ExtensionData.MonthPeriodRange[0] = monthPeriod;
      } else {
        spSumRec[sp.AccountId] = sp;
        spSumRec[sp.AccountId].ExtensionData.MonthPeriodRange = [ monthPeriod, monthPeriod ];
      }
    }
  }
  private __InputPayslipItemToRecord(
    rec: Record<number, number>, pi: HelperModels.PayslipItem,
  ) {
    if (pi.TaxCategory > 0) {
      rec[pi.TaxCategory] = (rec[pi.TaxCategory] || 0) 
        + (pi.TaxCategory == 11 ? Math.abs(pi.Value) : pi.Value);
    }
  }

  private __InitializeTaxPdf(orientation: 'p' | 'l'): XeoTypes.XeoPdf {
    const taxPdf: XeoTypes.XeoPdf = XeoBibliotheca.FileCodex.PdfNew({
      orientation,
      unit: 'mm',
      format: [216, 330],
      compress: true,
    });
    taxPdf.setFont('Helvetica');
    taxPdf.setFontSize(10);

    return taxPdf;
  }
  private __WriteSignX(
    pdf: XeoTypes.XeoPdf, x: number, y: number, fontSize: number = 17
  ) {
    pdf.write('×', x, y, { alignment: 'center-middle', fontSize });
  }
  private __WriteTaxObjectSummary(
    taxPdf: XeoTypes.XeoPdf, toSummary: PayrollModels.PsTaxObjectSummary, 
    cursorY: number
  ) {
    taxPdf.writef(toSummary.Quantity, '(0,0)', 121.3, cursorY, { align: 'center' });
    taxPdf.writef(toSummary.BrutoIncome, '(0,0)', 169, cursorY, { align: 'right' });
    taxPdf.writef(toSummary.TaxValue, '(0,0)', 205, cursorY, { align: 'right' });
  }

  private __InitializeFormItemRec(): Record<number, number> {
    const formItemRec: Record<number, number> = {};
    for (let i = 1; i <= 22; i++) {
      if (i != 9 && i != 13) {
        formItemRec[i] = 0;
      }
    }

    return formItemRec;
  }
  private __RoundToThousand(val: number): number {
    return Math.floor(val / 1000) * 1000;
  }
}

import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

import { Division } from '@/Models/DivisionJobModels';

export default new class DivisionQueries {
  public Xeo_GetCompanyDivisions: string = 'Xeo_GetCompanyDivisions';

  public Xeo_DeleteCompanyDivision(id: number): string {
    return `Xeo_DeleteCompanyDivision(id: ${id})`;
  }
  public Xeo_UpsertCompanyDivision(req: Division): string {
    return `Xeo_UpsertCompanyDivision(req: ${
      XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)
    })`;
  }
}
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

import { Job } from '@/Models/DivisionJobModels';

export default new class JobQueries {
  public Xeo_GetCompanyJobs: string = `Xeo_GetCompanyJobs`;

  public Xeo_DeleteCompanyJob(id: number): string {
    return `Xeo_DeleteCompanyJob(id: ${id})`;
  }
  public Xeo_UpsertCompanyJob(req: Job): string {
    return `Xeo_UpsertCompanyJob(req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)})`;
  }
}
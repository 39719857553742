
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import { DataStore } from '@/Store/—–AppStore–—';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import DivisionQueries from '@/Repositories/Graphql/DivisionQueries';
import JobQueries from '@/Repositories/Graphql/JobQueries';

import { Division, Job } from '@/Models/DivisionJobModels';

import XeoDropdown from '☆XeoApp/Vue/Components/Base/XeoDropdown.vue';
import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';

@Component({
  name: 'DivJobEditorModal'
})
export default class DivJobEditorModal extends Mixins(
  XeoBaseMixin, XeoFormMixin, XeoModalMixin, AppRenderMixin, AppToastMixin
) {
  $refs!: {
    TxtName: XeoFormInput,
    DdlParentId: XeoDropdown,
    AmConfirmation: XeoModalMixin
  };

  private get Divisions() { return DataStore.Divisions; }
  private get Jobs() { return DataStore.Jobs; }

  @Prop() readonly type!: 'Divisi' | 'Jabatan';
  @Prop({ default: 0 }) readonly id!: number;
  private FormDivisionJob: Division | Job | any = {};
  private get HasDescendant(): boolean {
    return Object.values(this.type == 'Divisi' ? this.Divisions : this.Jobs).filter(
      (dj: Division | Job) => dj.ParentId == this.id
    ).length > 0;
  }
  private get IsNotDeletable(): boolean {
    return this.FormDivisionJob.UsageCount > 0 || this.HasDescendant;
  }
  
  private BtnAmOk_Click() {
    this._DeleteDivisionJob();
  }
  private BtnAmCancel_Click() {
    this.$refs.AmConfirmation.close();
  }
  private BtnDelete_Click() {
    this.$refs.AmConfirmation.open();
  }
  private BtnSave_Click() {
    this._SaveDivisionJob();
  }
  private MdlAddDivJob_Show() {
    this._InitializeForm();
  }

  private _DeleteDivisionJob() {
    this.IsSavingForm = true;

    AppRepositories.Graphql.DoAuthGraphql(`
      mutation { ${
        this.type == 'Divisi' ?
          DivisionQueries.Xeo_DeleteCompanyDivision(this.id) :
          JobQueries.Xeo_DeleteCompanyJob(this.id)
      } }`
    ).xeoThen(
      (data) => {
        this.type == 'Divisi' ?
          DataStore.DeleteDivisionById(this.id) : DataStore.DeleteJobById(this.id);
        this.MakeSuccessToast(this.$t('Success.delete', { name: this.type }));
        this.__CompleteAction();
      },
      (errors) => {
        if (errors[0].Code == 'app: dj-has-children') {
          this.MakeErrorToast(
            this.$t('DivJobEditorModal.Errors.app: dj-has-children', { type: this.type })
          );
        } else if (errors[0].Code == 'app: dj-on-use') {
          this.MakeErrorToast(
            this.$t('DivJobEditorModal.Errors.app: dj-on-use', { type: this.type })
          );
        } else {
          this.MakeErrorToast(this.$t('Errors.server'));
        }

        this.__CompleteAction();
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false;
    });
  }
  private _InitializeForm() {
    this.FormDivisionJob = this.type == 'Divisi' ? 
      new Division(this.Divisions[this.id]) : new Job(this.Jobs[this.id]);
  }
  private _SaveDivisionJob() {
    if (XeoBibliotheca.FormCodex.ValidateFormViaRefs(this.$refs)) {
      this.IsSavingForm = true;

      AppRepositories.Graphql.DoAuthGraphql(`
        mutation { ${
          this.type == 'Divisi' ?
            DivisionQueries.Xeo_UpsertCompanyDivision(this.FormDivisionJob as Division) :
            JobQueries.Xeo_UpsertCompanyJob(this.FormDivisionJob as Job)
        } }`
      ).xeoThen(
        (data) => {
          this.type == 'Divisi' ?
            DataStore.AssignDivisions(data.Xeo_UpsertCompanyDivision) :
            DataStore.AssignJobs(data.Xeo_UpsertCompanyJob);
          this.MakeSuccessToast(this.$t('Success.save', { name: this.type }));
          this.__CompleteAction();
        },
        (errors) => {
          if (errors[0].Code == 'app: circular-parent') {
            const errMessage: string = this.$t(
              'DivJobEditorModal.Errors.app: circular-parent', { type: this.type.toLowerCase() }
            ).toString();
            
            this.$refs.DdlParentId.AddValidationResultData(
              new XeoTypes.ValidationResultData({
                Message: errMessage, 
                Status: false
              })
            );
          } else if (XeoBibliotheca.ErrorCodex.GetDuplicateFieldCode(errors[0]).length > 0) {
            this.$refs.TxtName.AddValidationResultData(
              new XeoTypes.ValidationResultData({
                Message: `Nama ${this.type} ini telah digunakan.`,
                Status: false
              })
            );
          } else {
            this.MakeErrorToast(this.$t('Errors.server'));
          }
        }
      ).catch((err) => {
        this.MakeErrorToast(this.$t('Errors.network'), 'sd');
      }).finally(() => {
        this.IsSavingForm = false;
      });
    }
  }

  private __CompleteAction() {
    this.$emit('action-done');
    this.$refs.AmConfirmation.close();
    this.close();
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import { DataStore } from '@/Store/—–AppStore–—';

@Component({
  name: 'SideNavAccountModule'
})
export default class SideNavAccountModule extends Mixins(XeoBaseMixin) {
  private get Account() { return DataStore.Account; }
  private get JobName() { 
    return (DataStore.Jobs[this.Account.JobId] || {}).JobName;
  } 
}


import { Component, Mixins, Prop } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import AccountQueries from '@/Repositories/Graphql/AccountQueries';
import { ChangePasswordRequest } from '@/Models/AccountModels';

import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';

@Component({
  name: 'SmChangePassModule'
})
export default class SmChangePassModule extends Mixins(XeoFormMixin, AppToastMixin) {
  $refs!: {
    TxtPassword: XeoFormInput,
    TxtNewPassword: XeoFormInput,
    TxtConfirmPassword: XeoFormInput
  }

  private FormChangePassword: ChangePasswordRequest = new ChangePasswordRequest();

  protected BtnChangePass_Click() {
    this._ChangePassword();
  }

  private _ChangePassword() {
    /* Form Validations */
    if (!XeoBibliotheca.FormCodex.ValidateFormViaRefs(this.$refs)) {
      return;
    } else if (!this.FormChangePassword.IsPasswordMatch()) {
      this.$refs.TxtConfirmPassword.AddValidationResultData(
        new XeoTypes.ValidationResultData({
          Message: 'Isi tidak sesuai dengan password baru',
          Status: false
        })
      );
      return;
    }

    this.IsSavingForm = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${AccountQueries.Xeo_ChangePassword(this.FormChangePassword)}
      }
    `).xeoThen(
      (data) => {
        this.MakeSuccessToast(this.$t('Success.change', { name: 'Password' }));
      }, 
      (errors) => {
        this.$refs.TxtPassword.AddValidationResultData(
          new XeoTypes.ValidationResultData({
            Message: this.$t(`AuthModule.Errors.${errors[0].Code}`).toString(),
            Status: false
          })
        );
        this.$refs.TxtPassword.focus();
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false; 
    });
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormElementMixin from '☆XeoApp/Vue/Mixins/XeoFormElementMixin';

@Component({
  name: 'XeoFilePicker',
  inheritAttrs: false
})
export default class XeoFilePicker extends Mixins(XeoBaseMixin, XeoFormElementMixin) {
  $refs!: {
    FpInput: HTMLInputElement,
    FpFile: HTMLButtonElement
  }

  @Prop({ default: '—' }) readonly placeholder!: string;
  private get IsMultiple() {
    return this.$attrs.multiple != null;
  }

  protected mounted() {
    this.RootElement = this.$refs.FpFile;
  }

  protected OpenFileExplorer_Act() {
    this.$refs.FpInput.click();
  }
  protected FpInput_Change(e: any) {
    this.Value = this.IsMultiple ? Array.from(e.target.files) : e.target.files[0];
    ['input', 'change'].forEach(
      (eventName: string) => this.$emit(eventName, this.Value)     
    );
    this.ValidateValue();
  }

  private _DisplayFileValue(): string {
    return this.IsMultiple ? 
      this.Value.map((file: File) => file.name).join(', ') :
      this.Value.name;
  }
}

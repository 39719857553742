import Graphql from '@/Repositories/Graphql/—Graphql—';
import AccountRest from '@/Repositories/Rest/AccountRest';
import UploadRest from '@/Repositories/Rest/UploadRest';

export default new class AppRepositories {
  public Graphql!: Graphql;
  public AccountRest: AccountRest = new AccountRest();
  public UploadRest: UploadRest = new UploadRest();

  public Initialize($router: any) {
    this.Graphql = new Graphql($router);
  } 
}
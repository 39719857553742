import moment from 'moment';

export default new class ModelUtils {
  public InitializeDurations(model: any, fields: string[]) {
    fields.forEach((field: string) => {
      const value: any = model[field];
      if (typeof value == 'number') {
        model[field] = moment.duration(value);
      }
    });
  }
  public InitializeMoments(model: any, fields: string[]) {
    fields.forEach((field: string) => {
      const value: any = model[field];
      if (typeof value == 'string' || typeof value == 'number') {
        model[field] = moment(value);
      }
    });
  }
}


import { Vue, Component } from '☆Node/vue-property-decorator';
import { StateStore } from '@/Store/—–AppStore–—';

@Component({
  name: 'Landing',
  metaInfo() { 
    return {
      title: (this as any).RouteNameTitle, 
      titleTemplate: `Axenta – %s` 
    };
  }
})
export default class Landing extends Vue {
  private get RouteNameTitle(): string {
    return this.$t(`AppMenuList.${this.$route.name || this.$route.meta?.title}`) as string;
  }
  private get NavClass() {
    return {
      'scrolled': StateStore.Window.ScrollY > 0
    };
  }
}

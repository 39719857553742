
import { Vue, Component } from '☆Node/vue-property-decorator';
import AppRepositories from '@/Repositories/—–AppRepositories–—';
import { StateStore } from '@/Store/—–AppStore–—';

@Component({
  name: 'XeoApp'
})
export default class XeoApp extends Vue {
  protected created() {
    AppRepositories.Initialize(this.$router);
    this._InitializeWindowEventListeners();
  }

  private _InitializeWindowEventListeners() {
    window.addEventListener("resize", function() {
      StateStore.SetWindowStates({
        InnerWidth: window.innerWidth,  InnerHeight: window.innerHeight
      });
    });
    window.addEventListener("scroll", function() {
      StateStore.SetWindowStates({
        ScrollX: window.scrollX,  ScrollY: window.scrollY
      });
    });
  }
}

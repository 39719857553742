
import { Vue, Component } from '☆Node/vue-property-decorator';
import AppMenuList from '@/Components/App/AppMenuList.vue';

@Component({
  name: 'SideNavMenuModule',
  components: { AppMenuList }
})
export default class SideNavMenuModule extends Vue {
  $refs!: {
    MenuSetup: AppMenuList,
    MenuApplication: AppMenuList
  }

  private get IsSetupPath(): boolean { return Boolean(this.$route.name?.startsWith('Setup')); }
  private get RoutesRec(): Record<string, any> {
    const routes: any[] = (this.$router as any).options.routes.find(
      (route: any) => route.meta?.title == 'Application'
    ).children;

    return {
      'Setup': routes.find((route: any) => route.meta?.title == 'Setup').children,
      'Application': routes.filter((route: any) => route.meta?.title != 'Setup')
    }
  }

  protected MenuList_Navigate(route: any) {
    this.$emit('navigate');
  }

  private _GetMenuRef(): AppMenuList {
    return this.IsSetupPath ? this.$refs.MenuSetup : this.$refs.MenuApplication;
  }
}

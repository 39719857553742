
import { Vue, Component, Prop } from '☆Node/vue-property-decorator';
import { ValidationResultData } from '☆XeoApp/Typescript/XeoTypes';

@Component({
  name: 'XeoValidationWidget'
})
export default class XeoValidationWidget extends Vue {
  @Prop(String) readonly partType!: string;
  @Prop(Boolean) readonly isValidating!: boolean;
  @Prop(Array) readonly validationResultData!: ValidationResultData[];

  private get ValidResultDatas(): ValidationResultData[] {
    return this.validationResultData.filter(vrd => vrd.Status)
  }
  private get InvalidResultDatas(): ValidationResultData[] {
    return this.validationResultData.filter(vrd => !vrd.Status)
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import { DataStore } from '@/Store/—–AppStore–—';

import * as HelperModels from '@/Models/—HelperModels—';

import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';
import XeoDropdown from '☆XeoApp/Vue/Components/Base/XeoDropdown.vue';
import XeoTextArea from '☆XeoApp/Vue/Components/Base/XeoTextArea.vue';

@Component({
  name: 'AcEditorModal'
})
export default class AcEditorModal extends 
  Mixins(XeoBaseMixin, XeoFormMixin, XeoModalMixin) 
{
  $refs!: {
    TxtName: XeoFormInput, TxtValue: XeoFormInput,
      DdlPeriodType: XeoDropdown,
      TaDescription: XeoTextArea,
      DdlTaxCategory: XeoDropdown,
  };

  private get Constants() { return DataStore.Constants; }
  private get Divisions() { return DataStore.Divisions; }
  private get Jobs() { return DataStore.Jobs; }
  private get TaxCategories() {
    return Object.entries(DataStore.Constants.TaxCategories || {})
      .map(([key, val]) => ({ Id: Number(key), Value: val }))
      .filter((tc: any) => tc.Id != 2 && tc.Id < 11);
  }

  @Prop() readonly allowanceCut!: 
    HelperModels.CompanyAllowanceCut | HelperModels.AllowanceCut;
  @Prop(Boolean) readonly showReceivers!: boolean;
  @Prop(Boolean) readonly showPeriods!: boolean;
  private FormAc: HelperModels.CompanyAllowanceCut | HelperModels.AllowanceCut = 
    this.showReceivers ? new HelperModels.CompanyAllowanceCut() : new HelperModels.AllowanceCut();
  private get AcType(): string {
    return this.FormAc.Value >= 0 ? 'Tunjangan' : 'Potongan';
  }

  protected BtnDelete_Click() {
    this.$emit('delete', this.FormAc.Id);
    this.close();
  }
  protected BtnSave_Click() {
    if (XeoBibliotheca.FormCodex.ValidateFormViaRefs(this.$refs)) {
      this.$emit('save', new HelperModels.OneTimePaymentItem(this.FormAc));
      this.close();
    }
  }
  protected MdlAcEditor_Show() {
    this.FormAc = new HelperModels.CompanyAllowanceCut(this.allowanceCut);
  }
}

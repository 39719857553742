
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';

import AppConfig from '@/App/Typescript/AppConfig';
import { GoogleAuthRequest, LogInRequest, SignUpRequest } from '@/Models/AccountModels';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import AccountQueries from '@/Repositories/Graphql/AccountQueries';

import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';

@Component({
  name: 'AuthModule'
})
export default class AuthModule extends Mixins(XeoBaseMixin, XeoFormMixin, AppToastMixin) {
  $refs!: {
    TxtLgnUsernameOrEmail: XeoFormInput,
    TxtLgnPassword: XeoFormInput,

    TxtSuFullname: XeoFormInput,
    TxtSuUsername: XeoFormInput,
    TxtSuEmailAddress: XeoFormInput,
    TxtSuPassword: XeoFormInput
  }

  private get Account() { return DataStore.Account; }

  private FormLogIn = new LogInRequest();
  private FormSignUp = new SignUpRequest();
  private Mode: 'LogIn' | 'SignUp' | 'ContinueAs' = 'LogIn';
  private IsLoggingIn: boolean = false;
  private IsSigningUp: boolean = false;
  private get GoogleAuthParams() { return AppConfig.GoogleAuthParam; }
  private get CardClass() {
    return this.Mode == 'ContinueAs' ? 'card-continueas' : '';
  }

  protected created() {
    this.Mode = this.Account.Id ? 'ContinueAs' : 'LogIn';
  }

  protected BtnContinueAs_Click() {
    this.$router.push({ name: 'Dashboard' });
  }
  private BtnGoogle_LogIn(googleUser: any) {
    this._DoGoogleAuth(googleUser);
  }
  protected BtnLogIn_Click() {
    this._DoLogin();
  }
  protected BtnSignUp_Click() {
    this._DoSignUp();
  }
  private GoogleIdentity_Success(resp: any) {
    this._DoGoogleAuth(resp.credential);
  }
  protected LnkAuth_Click() {
    this.Mode = this.Mode == 'LogIn' ? 'SignUp' : 'LogIn';
  }
  protected TxtUsernameOrEmail_KeyUpEnter() {
    this.$refs['TxtLgnUsernameOrEmail'].select();
  }
  protected TxtLgnPassword_KeyUpEnter() {
    this._DoLogin();
  }

  private _DoGoogleAuth(idToken: string) {
    AppRepositories.AccountRest.GoogleAuth(new GoogleAuthRequest({
      IdToken: idToken
    })).xeoThen(
      (data) => {
        this._NavigatePostLogIn();
      }, 
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });
  }
  private _DoLogin() {
    this.IsLoggingIn = true;

    XeoBibliotheca.FormCodex.ResetValidationsViaRefs(this.$refs);
    AppRepositories.AccountRest.LogIn(this.FormLogIn).xeoThen(
      (data) => {
        this._NavigatePostLogIn();
      }, 
      (errors) => {
        switch (errors[0].Code) {
          case 'app: user-not-found':
            this.$refs.TxtLgnUsernameOrEmail.AddValidationResultData(
              new XeoTypes.ValidationResultData({
                Message: this.$t('AuthModule.Errors.app: user-not-found').toString(),
                Status: false
              })
            );
            this.$refs.TxtLgnUsernameOrEmail.focus();
            break;
          case 'app: wrong-password':
            this.$refs.TxtLgnPassword.AddValidationResultData(
              new XeoTypes.ValidationResultData({
                Message: this.$t('AuthModule.Errors.app: wrong-password').toString(),
                Status: false
              })
            );
            this.$refs.TxtLgnPassword.focus();
            break;
          case 'app: user-deleted':
            this.MakeErrorToast(this.$t('AuthModule.Errors.app: user-deleted'));
            break;
          default:
            this.MakeErrorToast(this.$t('Errors.server'));
        }
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
      this.$refs['TxtLgnUsernameOrEmail'].focus();
    }).finally(() => {
      this.IsLoggingIn = false;
    });
  }  
  private _DoSignUp() {
    this.IsSigningUp = true;

    if (XeoBibliotheca.FormCodex.ValidateFormViaRefs(this.$refs)) {
      AppRepositories.AccountRest.SignUp(this.FormSignUp).xeoThen(
        (data) => {
          this.$router.push({ name: 'Setup_Welcome' });
        }, 
        (errors) => {
          const dupFields = XeoBibliotheca.ErrorCodex.GetDuplicateFieldCode(errors[0]);

          if (dupFields.length > 0) {
            dupFields.forEach((dupField: string) => {
              if (dupField == 'user_account.UserName') {
                this.$refs.TxtSuUsername.AddValidationResultData(
                  new XeoTypes.ValidationResultData({
                    Message: 'Username telah dipakai oleh user lain.',
                    Status: false
                  })
                );
              } else if (dupField = 'user_account.EmailAddress') {
                this.$refs.TxtSuEmailAddress.AddValidationResultData(
                  new XeoTypes.ValidationResultData({
                    Message: 'Email ini sudah pernah didaftarkan.',
                    Status: false
                  })
                );
              } else {
                this.MakeErrorToast(this.$t('Errors.server'));
              }
            });
          } else {
            this.MakeErrorToast(this.$t('Errors.server'));
          }
        }
      ).catch((err) => {
        this.MakeErrorToast(this.$t('Errors.network'), 'sd');
      }).finally(() => {
        this.IsSigningUp = false;
      });
    } else {
      this.IsSigningUp = false;
    }
  }
  private _NavigatePostLogIn() {
    /* Query param `redirect` exist — Redirect to path */
    const redirectPath: any = this.$router.currentRoute.query.redirect;
    this.$router.push(
      redirectPath ? { path: redirectPath } : { name: 'Dashboard' }
    );
  }
}

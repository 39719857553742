import { customAlphabet } from '☆Node/nanoid'
import XeoUtilities from '☆XeoApp/Typescript/XeoUtilities';

export default class CryptoCodex {
  public RandomGenerator: RandomGenerator;

  constructor() {
    this.RandomGenerator = new RandomGenerator();
  }
}

class RandomGenerator {
  public randCharSet: string = 
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz+/';
    
  public GenerateString(length: number, charSet: string = this.randCharSet): string {
    return customAlphabet(
      XeoUtilities.CharacterSetMap.get(charSet) || charSet, length
    )();
  }
  public GenerateNumber(maxValue: number = Number.MAX_SAFE_INTEGER): number {
    return Math.floor(Math.random() * maxValue);
  }
}
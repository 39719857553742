
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import { DataStore } from '@/Store/—–AppStore–—';

import * as HelperModels from '@/Models/—HelperModels—';
import { PayrollSummary } from '@/Models/PayrollModels';
import PdfUtils from '@/Utilities/PdfUtils';
import PayrollFormulaUtils from '@/Utilities/PayrollFormulaUtils';

import AcEditorModal from '@/Components/Company/AcEditorModal.vue';

@Component({
  name: 'PsDetailsModal',
  components: { AcEditorModal }
})
export default class PsDetailsModal extends Mixins(
  XeoBaseMixin, XeoFormMixin, XeoModalMixin, AppAccessMixin
) {
  $refs!: {
    BarPayslip: HTMLElement,
    MdlAcEditor: AcEditorModal,
    FormPayslip: HTMLElement
  }

  private get Constants() { return DataStore.Constants; }
  private get Company() { return DataStore.CompanyHq.Data; }
  private get Jobs() { return DataStore.Jobs; }
  private get StaffList() { return DataStore.Staffs; }

  @Prop() value!: HelperModels.StaffPayslip;
  @Prop() payrollSummary!: PayrollSummary;
  private EditedAc: HelperModels.AllowanceCut = new HelperModels.AllowanceCut();
  private EmptyTaxCredential: string = PayrollFormulaUtils.EmptyTaxCredentialValue;
  private StaffPayslip: HelperModels.StaffPayslip = new HelperModels.StaffPayslip();
  private get IsPsApproved(): boolean { 
    return this.payrollSummary.Status == 100; 
  }
  private get IsPsEditable(): boolean {
    return this.payrollSummary.Status < 100 && this.IsPageFullAccess;
  }
  private get PayslipItemsSummary() {
    const piSummary = {
      Goverment: {
        ItemRec: {
          'Pajak': [] as HelperModels.PayslipItem[],
          'BPJS': [] as HelperModels.PayslipItem[],
        } as Record<string, HelperModels.PayslipItem[]>,
        Summary: {
          'Σ': 0
        }
      },
      Staff: {
        ItemRec: {
          'Tetap': [] as HelperModels.PayslipItem[],
          'Tidak Tetap': [] as HelperModels.PayslipItem[],
          'Pajak': [] as HelperModels.PayslipItem[],
          'BPJS': [] as HelperModels.PayslipItem[]
        } as Record<string, HelperModels.PayslipItem[]>,
        Summary: {
          '+': 0,
          '-': 0,
          'Σ': 0
        }
      }
    };

    /* Summarize Goverment Items */
    const giGroupKeyRec: Record<number, string> = {
      2: 'Pajak',
      3: 'BPJS'
    };
    this.StaffPayslip.GovermentItems.sort(
      (a, b) => b.Value - a.Value
    ).forEach((gi: HelperModels.PayslipItem) => {
      const itemGroupKey = giGroupKeyRec[gi.CalculationGroup];

      if (itemGroupKey && gi.Value != 0) {
        piSummary.Goverment.ItemRec[itemGroupKey].push(gi);
        piSummary.Goverment.Summary.Σ += gi.Value;
      }
    });

    /* Summarize Staff Items */
    const siGroupKeyRec: Record<number, string> = {
      0: 'Tetap',
      1: 'Tidak Tetap',
      2: 'Pajak',
      3: 'BPJS'
    }; 
    this.StaffPayslip.StaffItems.sort(
      (a, b) => Number(b.CalculationGroup == 0 && b.Name == 'Gaji Pokok')
        || Number(b.Value >= 0) - Number(a.Value >= 0)
        || Math.abs(b.Value) - Math.abs(a.Value)
    ).forEach((si: HelperModels.PayslipItem) => {
      const itemGroupKey = siGroupKeyRec[si.CalculationGroup];
      const sign = si.Value >= 0 || si.ExtensionData?.IsHiddenOnPayslip ? '+' : '-';

      if (itemGroupKey && si.Value != 0) {
        this._AdditStaffItemRec(piSummary.Staff.ItemRec, itemGroupKey, si);
        piSummary.Staff.Summary[sign] += si.Value;
        piSummary.Staff.Summary.Σ += si.Value;
      }
    });

    XeoBibliotheca.UtilCodex.DeepOmitEmpty(piSummary);
    return piSummary;
  }
  private get PaymentBankName(): string | null {
    return this.Constants.BankList?.[
      this.StaffPayslip.ExtensionData.BankAccount.BankId
    ]?.Name;
  }
  private get TaxCategory(): string {
    const extData: HelperModels.PayslipExtData = this.StaffPayslip.ExtensionData;
    return (extData.TaxMaritalStatus == 1 ? 'TK' : 'K') +
      (extData.IsMergeUntaxedIncome ? '/I' : '') +
      '/' + extData.TaxTotalDependents;
  }

  protected BtnAddAc_Click() {
    this.EditedAc = new HelperModels.AllowanceCut();
    this.$refs.MdlAcEditor.open();
  }
  protected BtnSave_Click() {
    this.$emit('input', this.StaffPayslip);
    this.close();
  }
  protected BtnGeneratePdf_Click() {
    this.IsSavingForm = true;
    this._AsyncGeneratePsPdf().then(() => {
      this.IsSavingForm = false;
    });
  }
  protected ItmEditPi_Click(pi: HelperModels.PayslipItem) {
    if (this._IsPsItemEditable(pi)) {
      this.EditedAc = new HelperModels.AllowanceCut(pi);
      this.$refs.MdlAcEditor.open();
    }
  }
  protected MdlAcEditor_Delete(id: number) {
    this._DeleteOneTimeAcById(id);
  }
  protected MdlAcEditor_Save(ac: HelperModels.AllowanceCut) {
    this._AssignOneTimeAc(ac);
  }
  protected MdlPsDetails_Show() {
    this.StaffPayslip = XeoBibliotheca.UtilCodex.DeepClone(this.value);
  }

  private _AdditStaffItemRec(
    itemRec: Record<string, HelperModels.PayslipItem[]>, itemGroupKey: string,
    si: HelperModels.PayslipItem
  ) {
    if (si.ExtensionData?.IsHiddenOnPayslip) {
      itemRec['Tetap'].find(itm => itm.Name == 'Gaji Pokok')!.Value += si.Value;
    } else {
      itemRec[itemGroupKey].push(si);
    }
  }
  private _AssignOneTimeAc(ac: HelperModels.AllowanceCut) {
    /* Upsert One-Time AllowanceCut */
    const pi: HelperModels.PayslipItem = Object.assign(
      new HelperModels.PayslipItem(ac), { CalculationGroup: 1 }
    );

    if (pi.Id == 0) {
      this.StaffPayslip.StaffItems.push(
        Object.assign(pi, { 
          Id: XeoBibliotheca.CryptoCodex.RandomGenerator.GenerateNumber() 
        })
      );
    } else {
      const piIdx: number = this.StaffPayslip.StaffItems.findIndex(
        (si: HelperModels.PayslipItem) => si.Id == pi.Id
      );
      this.StaffPayslip.StaffItems[piIdx] = pi;
    }

    /* Recalculate Tax */ 
    PayrollFormulaUtils.InputTaxToMonthlyPs(
      this.StaffPayslip, this.payrollSummary
    );
  }
  private async _AsyncGeneratePsPdf() {
    /* Initialize Pdf & Variables */
    const psPdf: XeoTypes.XeoPdf = XeoBibliotheca.FileCodex.PdfNew({
      orientation: 'p',
      unit: 'mm',
      format: [210, 297],
      compress: true,
    });
    psPdf.setFont('Lato', 'semibold');   
      psPdf.setFontSize(10);

    this.__WritePdfHeader(psPdf);
    this.__WritePdfMainTitle(psPdf);

    const cursorsY: number[] = [65, 65];
    cursorsY[0] = this.__WriteStaffItems(psPdf, cursorsY[0]);
    cursorsY[1] = this.__WriteGovermentItems(psPdf, cursorsY[1]);

    /* Save Pdf */
    psPdf.saveAs(
      `${this.payrollSummary.TimePeriod.format('YYYYMM')} — ` +
      `Slip Gaji ${this.value.EmployeeId}_${this.value.Name}.pdf`
    );
  }
  private _DeleteOneTimeAcById(id: number) {
    /* Delete One-Time Ac by Id */
    const siIdx: number = this.StaffPayslip.StaffItems.findIndex(
      (pi: HelperModels.PayslipItem) => pi.Id == id
    );
    if (siIdx > -1) {
      Vue.delete(this.StaffPayslip.StaffItems, siIdx);
    }

    /* Recalculate Tax */ 
    PayrollFormulaUtils.InputTaxToMonthlyPs(
      this.StaffPayslip, this.payrollSummary
    );
  }
  private _IsPsItemEditable(item: HelperModels.PayslipItem): boolean {
    return this.IsPsEditable && item.Id > 0;
  }

  private __WritePdfHeader(pdf: XeoTypes.XeoPdf) {
    const colorMap = XeoBibliotheca.ColorCodex.ColorMap;

    PdfUtils.RenderTitle(
      pdf, 'Slip Gaji', 
      `Periode ${this.payrollSummary.TimePeriod.format('MMMM YYYY')}`,
      this.Company.BrandUrl
    );

    pdf.setTextColor('#000');
    pdf.write('Nama', 15, 37);
      pdf.write('Id Karyawan', 15, 42);
      pdf.write('Divisi', 15, 47);
      pdf.write('Jabatan', 15, 52);
    pdf.write('NPWP', 111, 47);
      pdf.write('Kategori PPh 21', 111, 52);
    
    pdf.setTextColor(colorMap.Midnight.Dark);
    pdf.write(this.StaffPayslip.Name, 45, 37);
      pdf.write(this.StaffPayslip.EmployeeId, 45, 42);
      pdf.write(this.StaffPayslip.Division, 45, 47);
      pdf.write(this.StaffPayslip.Job, 45, 52);
    pdf.write(this.StaffPayslip.ExtensionData.TaxCredential, 141, 47);
      pdf.write(this.TaxCategory, 141, 52);
  }
  private __WritePdfMainTitle(pdf: XeoTypes.XeoPdf) {
    const colorMap = XeoBibliotheca.ColorCodex.ColorMap;
    const imgWidth = 391 / 129 * 6.75;

    pdf.drawShape('rect', { x: 15, y: 57, w: 180, h: 6.75 }, {
      style: 'F', fillColor: colorMap.Fog.Dark 
    });
    pdf.addImage(
      require('@/Assets/Images/Gradient/Pdf-FormTitle.png'), 
      'PNG', 195 - imgWidth, 57, imgWidth, 6.75, undefined, 'FAST'
    );

    pdf.drawShape('triangle', 
      { x1: 15, y1: 59, x2: 15, y2: 61.75, x3: 16.75, y3: 60.375 }, 
      { style: 'F', fillColor: colorMap.Aqua.Base }
    );
    pdf.write('Penerimaan & Potongan', 19, 60.375, { 
      baseline: 'middle', color: colorMap.Midnight.Dark
    });
    pdf.drawShape('triangle',
      { x1: 112, y1: 59, x2: 112, y2: 61.75, x3: 113.75, y3: 60.375 }, 
      { style: 'F', fillColor: colorMap.Aqua.Base }
    );
    pdf.write('Dibayarkan Ke Negara', 116, 60.375, { 
      baseline: 'middle', color: colorMap.Midnight.Dark
    });
  }
  private __WriteStaffItems(pdf: XeoTypes.XeoPdf, cursorY: number): number {
    const colorMap = XeoBibliotheca.ColorCodex.ColorMap;
    const rangeX = [15, 98];
    const rowHeight = 5.65;

    pdf.setFontSize(10);      pdf.setTextColor(colorMap.Midnight.Dark);
    cursorY += rowHeight * .5;

    /* Item List */
    Object.entries(this.PayslipItemsSummary.Staff.ItemRec).reduce(
      (ris: any[], [groupName, items]) => {
        ris.push(groupName, ...items, .25);
        return ris;
      }, []
    ).forEach((rowItem: string | HelperModels.PayslipItem | number) => {
      switch (typeof rowItem) {
        case 'string':
          pdf.write(rowItem, rangeX[0], cursorY, { baseline: 'middle', color: '#000' });
          cursorY += rowHeight;
          break;
        case 'object':
          pdf.drawShape('rect', 
            { x: rangeX[0] + .2, y: cursorY - rowHeight / 2, w: .4, h: rowHeight },
            { style: 'F', fillColor: colorMap[rowItem.Value <= 0 ? 'Red' : 'Mint'].Base }
          );
          pdf.write(rowItem.Name, rangeX[0] + 2.7, cursorY, { 
            baseline: 'middle' 
          });
          pdf.writef(rowItem.Value, '$0,0', rangeX[1], cursorY, { 
            alignment: 'right-middle', color: '#000'
          });
          cursorY += rowHeight;
          break;
        case 'number':
          cursorY += rowHeight * rowItem;
          break;
      }
    });
    cursorY += rowHeight * .25;

    /* Item Summary */
    pdf.write(
      this.PaymentBankName ? 'Ditransfer Melalui Bank' : 'Dibayarkan Tunai', 
      rangeX[0], cursorY, { baseline: 'middle', color: '#000' }
    );
    pdf.writef(
      this.PayslipItemsSummary.Staff.Summary.Σ, '$0,0', rangeX[1], cursorY, 
      { alignment: 'right-middle', color: colorMap.Aqua.Dark, fontType: 'bold'  }
    );
    cursorY += rowHeight * 1.25;

    if (this.PaymentBankName) {
      const bankAccount = this.StaffPayslip.ExtensionData.BankAccount;

      [ 
        ['No. Rek.', `${this.PaymentBankName} — ${bankAccount.AccountNumber}`],
        ['Nama Akun', bankAccount.HolderName] 
      ].forEach((ri: string[]) => {
        pdf.write(ri[0], rangeX[0], cursorY, { baseline: 'middle' });
        pdf.write(ri[1], rangeX[0] + 27, cursorY, { baseline: 'middle', color: '#000' });
        cursorY += rowHeight;
      });
      cursorY += rowHeight * .25;
    }

    return cursorY;
  }
  private __WriteGovermentItems(pdf: XeoTypes.XeoPdf, cursorY: number): number {
    const colorMap = XeoBibliotheca.ColorCodex.ColorMap;
    const rangeX = [112, 195];
    const rowHeight = 5.65;

    pdf.setFontSize(10);      pdf.setTextColor(colorMap.Midnight.Dark);
    cursorY += rowHeight * .5;

    /* Item List */
    Object.entries(this.PayslipItemsSummary.Goverment.ItemRec).reduce(
      (ris: any[], [groupName, items]) => {
        ris.push(groupName, ...items, .25);
        return ris;
      }, []
    ).forEach((rowItem: string | HelperModels.PayslipItem | number) => {
      switch (typeof rowItem) {
        case 'string':
          pdf.write(rowItem, rangeX[0], cursorY, { baseline: 'middle', color: '#000' });
          cursorY += rowHeight;
          break;
        case 'object':
          pdf.drawShape('rect', 
            { x: rangeX[0] + .2, y: cursorY - rowHeight / 2, w: .4, h: rowHeight },
            { style: 'F', fillColor: colorMap.Aqua.Base}
          );
          pdf.write(rowItem.Name, rangeX[0] + 2.7, cursorY, { 
            baseline: 'middle' 
          });
          pdf.writef(rowItem.Value, '$0,0', rangeX[1], cursorY, { 
            alignment: 'right-middle', color: '#000' 
          });
          cursorY += rowHeight;
          break;
        case 'number':
          cursorY += rowHeight * rowItem;
          break;
      }
    });
    cursorY += rowHeight * .25;

    /* Item Summary */
    pdf.write('Dibayarkan ke Negara', rangeX[0], cursorY, { baseline: 'middle', color: '#000' });
    pdf.writef(
      this.PayslipItemsSummary.Goverment.Summary.Σ, '$0,0', rangeX[1], cursorY, 
      { alignment: 'right-middle', color: colorMap.Aqua.Dark, fontType: 'bold'  }
    );
    cursorY += rowHeight * 1.25;

    return cursorY;
  }











  ///* F OFF LINE */
  ///* F OFF LINE */
  ///* F OFF LINE */



  private _DrawPaperGrid(pdf: any) {
    /* grid buls */
    pdf.setFont('Helvetica');
    pdf.setFontSize(6);
    for (let y = 0; y < pdf.getPageHeight(); y += 10) {
      for (let x = 0; x < pdf.getPageWidth(); x += 10) {
        pdf.se
        pdf.setFillColor('#e74c3c')
        pdf.circle(x, y, .5, 'F');
        pdf.setTextColor('#20bf6b');
        pdf.write([x.toString(), y.toString()], x+.5, y + 2);
      }
    }
  }
}

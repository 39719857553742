import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import * as BaseModels from '@/Models/—BaseModels—';
import ModelUtils from '@/Models/—ModelUtils—';

export interface Staff 
  extends BaseModels.BaseAccount, BaseModels.BaseUserMap, BaseModels.BaseStaffPayroll {}
export class Staff {
  constructor(init?: Partial<Staff>) {
    Object.assign(this, 
      new BaseModels.BaseAccount(),
      new BaseModels.BaseUserMap(),
      new BaseModels.BaseStaffPayroll(),
      init
    );
    ModelUtils.InitializeMoments(this, [
      'BirthDate',
      'EndEmploymentDate', 'JoinDate', 'ResignDate',
      'LastTaxPaymentDate'
    ]);
  }
}

export class GetStaffDatasRequest extends XeoTypes.Pagination {
  public IsDisplayOnly: boolean = false;

  constructor(init?: Partial<GetStaffDatasRequest>) {
    super();
    Object.assign(this, init);
  }
}
export class StaffSelection {
  public AccountIds: number[] = [];
  public CompanyBranchIds: number[] = [];
  public Divisions: number[] | string[] = [];
  public Jobs: number[] | string[] = [];

  constructor(init?: Partial<StaffSelection>) {
    Object.assign(this, init);
  }

  public IsStaffSelected(staff: any): boolean {
    return this._IsItemSelected(this.AccountIds, staff.AccountId)
      && this._IsItemSelected(this.CompanyBranchIds, staff.CompanyBranchId)
      && this._IsItemSelected(this.Divisions, staff.DivisionId || staff.Division)
      && this._IsItemSelected(this.Jobs, staff.JobId || staff.Job);
  }
  
  private _IsItemSelected(arr: any[], item: any): boolean {
    return arr.length == 0 || arr.includes(item);
  }
}
export class ResetPasswordRequest {
  public Id!: number;
  public NewPassword!: string;

  constructor(init?: Partial<ResetPasswordRequest>) {
    Object.assign(this, init);
  }
}

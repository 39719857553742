
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppConstantsMixin from '@/Mixins/AppConstantsMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';
import moment from 'moment';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import TmAttendanceQueries from '@/Repositories/Graphql/TmAttendanceQueries';
import TmSettingsQueries from '@/Repositories/Graphql/TmSettingsQueries';

import { Attendance, GetAttendanceListRequest } from '@/Models/TimeManagementModels';

import AttendanceEditorModal from '@/Components/TimeManagement/AttendanceEditorModal.vue';

@Component({
  name: 'TmAttendanceModule',
  components: { AttendanceEditorModal }
})
export default class TmAttendanceModule extends Mixins(
  XeoBaseMixin, XeoFormMixin, XeoModalMixin, 
  AppAccessMixin, AppConstantsMixin, AppRenderMixin, AppToastMixin
) {
  $refs!: {
    MdlAttendanceEditor: AttendanceEditorModal
  };

  private get StaffList() { return DataStore.Staffs; }

  @Prop() readonly id!: number;
  @Prop(Number) readonly accountId!: number;
  private EditedAtd: Attendance = new Attendance();
  private AttendanceList: Attendance[] = [];
  private PageSize: number = 12;
  private FormRequest = {
    AccountId: 0,
    TimePeriod: [ moment().startOf('day'), moment().endOf('day') ]
  };
  private get IsAddEnabled(): boolean {
    return this.GetUserAccessState('Client', 'Tm_Attendance') == 100;
  }
  private get IsMainPage(): boolean {
    return this.$route.name == 'Tm_Attendance';
  }

  protected created() {
    this._LoadAttendanceList(0, this.PageSize * 2, true);
  }

  protected LoadAttendanceList_Act() {
    this._LoadAttendanceList(0, this.PageSize * 2);
  }
  protected MdlAtdEditor_Save() {
    this._LoadAttendanceList(0, this.PageSize * 2);
  }
  protected TblAttendanceList_LastPage() {
    const atdListLen = this.AttendanceList.length,
          pgSize = this.PageSize;
    this._LoadAttendanceList(
      atdListLen, pgSize + XeoBibliotheca.NumberCodex.Mod(-atdListLen, pgSize)
    );
  }
  protected UpsertAttendance_Act(atd: Attendance | '+') {
    this._OpenAtdEditor(atd);
  }

  private _GenerateTimeClass(beforeTime: moment.Moment, afterTime: moment.Moment): any {
    return (!moment.isMoment(beforeTime) || !moment.isMoment(afterTime)) ? 'text-midnight' :
      beforeTime.isAfter(afterTime) ? 'text-red' :
      'text-mint';
  }
  private _LoadAttendanceList(offset: number, limit: number, isCreated: boolean = false) {
    const isAppendList: boolean = offset > 0; 

    this[isAppendList ? 'IsNextPageLoading' : 'IsLoading'] = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${isCreated ? TmSettingsQueries.Axenta_GetTmSettings : ''}
        ${TmAttendanceQueries.Axenta_GetAttendanceList(
          new GetAttendanceListRequest({
            AccountId: this.accountId || this.FormRequest.AccountId,
            TpStart: this.FormRequest.TimePeriod?.[0].clone().startOf('day'),
            TpEnd: this.FormRequest.TimePeriod?.[1].clone().endOf('day'),
            Offset: offset,     Limit: limit
          })
        )}
      }
    `).xeoThen(
      (data) => {
        if (isCreated)    DataStore.AssignTmSettings(data.Axenta_GetTmSettings);

        const atdList: Attendance[] = data.Axenta_GetAttendanceList.map(
          (atd: any) => new Attendance(atd)
        );
        if (isAppendList) {
          this.AttendanceList.push(...atdList);
          this.IsNextPageLoading = false;
        } else {
          this.AttendanceList = atdList;
          this.IsLoading = false;
        }
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });
  }
  private _OpenAtdEditor(atd: Attendance | '+') {
    this.EditedAtd = atd == '+' ? new Attendance() : atd;
    this.$refs.MdlAttendanceEditor.open();
  }
}

import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import XeoUtilities from '☆XeoApp/Typescript/XeoUtilities';

import excelJs from 'exceljs';
import fileSaver from 'file-saver';
import { jsPDFOptions } from 'jspdf';

export default class FileCodex {
  public PdfNew(opts?: jsPDFOptions | undefined): XeoTypes.XeoPdf {
    return new XeoTypes.XeoPdf(opts);
  }
  public async ExcelLoadFile(srcType: 'url' | 'input-file', file: any): Promise<excelJs.Workbook> {
    switch (srcType) {
    case 'input-file':
      return this.ReadFileAs('ArrayBuffer', file)
        .then(ab => new excelJs.Workbook().xlsx.load(ab as ArrayBuffer));
    case 'url':
      return fetch(file)
        .then(resp => resp.arrayBuffer())
        .then(ab => new excelJs.Workbook().xlsx.load(ab));
    default:
      return Promise.reject('Invalid file type');
    }
  }
  public ReadFileAs(
    type: 'ArrayBuffer' | 'BinaryString' | 'DataURL' | 'Text', file: Blob
  ): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader[`readAs${type}`](file);

      fileReader.onload = () => resolve(fileReader.result);
      fileReader.onerror = () => reject(fileReader.error);
    });
  }  
  public SaveFile(fileName: string, content: any, contentType?: string) {
    fileSaver.saveAs(
      contentType == 'url' ? content : new Blob([content], { type: contentType }), 
      fileName
    );
  }

  public ExcelBatchInput(ws: excelJs.Worksheet, valueMap: Map<[number, number], any>) {
    valueMap.forEach((val, rcParts) => {
      if (rcParts.length == 2) {
        ws.getCell(rcParts[0], rcParts[1]).value = val;
      }
    });
  }
  public ExcelDuplicateWorksheet(
    wb: excelJs.Workbook, srcId: string | number, dupName: string = ''
  ): excelJs.Worksheet {
    const dupWs = wb.addWorksheet(),
          srcWsModel = wb.getWorksheet(srcId).model as any;

    dupWs.model = XeoUtilities.DeepClone(srcWsModel);
    dupWs.name = dupName;
    srcWsModel.merges.forEach((m: string) => dupWs.mergeCells(m));

    return dupWs;
  }
  public async PdfRegisterFont(fontFamily: string, fontType: string, fontPath: string) {
    await fetch(fontPath).then((fontData: Response) => {
      fontData.blob().then((blob: Blob) => {
        const fileReader: FileReader = new FileReader();
        fileReader.readAsDataURL(blob); 
        fileReader.onloadend = () => {
          const b64Font = (fileReader.result as string).split(',').slice(-1)[0];
          const fileName = `${fontFamily}-${fontType}.ttf`;

          XeoTypes.XeoPdf.API.events.push([
            'addFonts', function(this: XeoTypes.XeoPdf) {
              this.addFileToVFS(fileName, b64Font);
              this.addFont(fileName, fontFamily, fontType);
            }
          ]);
        }
      });
    });
  }
}
export default class FormCodex {
  public ResetValidationsViaRefs(refs: any) {
    Object.values(refs).forEach((ref: any) => {
      if (ref && ref.ResetValidation) {
        ref.ResetValidation();
      }
    });
  }
  public ValidateFormViaRefs(refs: any, isSilentValidation: boolean = false): boolean {
    let isValid: boolean = true;
    
    Object.values(refs).forEach((ref: any) => {
      if (ref && ref.ValidateValue) {
        isValid = ref.ValidateValue(isSilentValidation) && isValid;
      }
    });

    return isValid;
  }
}

import { Vue, Component, Mixins, Prop } from '☆Node/vue-property-decorator';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';

@Component({
  name: 'XeoPreloader'
})
export default class XeoPreloader extends Mixins(XeoBaseMixin) {
  @Prop({ default: false }) isLoading!: boolean;
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormElementMixin from '☆XeoApp/Vue/Mixins/XeoFormElementMixin';

import MaskedInput from '☆Node/vue-text-mask';
import moment from 'moment';

@Component({
  name: 'XeoFormInput',
  components: { MaskedInput },
  inheritAttrs: false
})
export default class XeoFormInput extends Mixins(XeoBaseMixin, XeoFormElementMixin) {
  $refs!: {
    TxtInput: HTMLFormElement;
  };

  @Prop() readonly customMask: any;
  @Prop({ default: 0 }) readonly decimalLimit!: number;
  private DateTimeFormats: { [key: string]: string } = 
    XeoBibliotheca.DateTimeCodex.Formats;
  private IsShowPassword: boolean = false;
  private get MaskConfigs() {
    return {
      Masks: {
        'date': [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
        'time': [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/],
        'datetime': [
          /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', 
          /\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/
        ],
        'number' : XeoBibliotheca.MaskCodex.CreateNumberMask({
          allowDecimal: this.decimalLimit > 0,
          allowNegative: this.InputLimits[0] < 0,
          decimalLimit: this.decimalLimit,
          decimalSymbol: ',',
          integerLimit: 15,
          prefix: '',
          thousandsSeparatorSymbol: '.'
        }),
        'tel': [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/]
      },
      Pipes: {
        'date': XeoBibliotheca.MaskCodex.CreateAutoCorrectedDatePipe(
          'dd/mm/yyyy', { minYear: 1900,  maxYear: 2100 }
        ),
        'time': XeoBibliotheca.MaskCodex.CreateAutoCorrectedDatePipe('HH.MM.SS'),
        'datetime': XeoBibliotheca.MaskCodex.CreateAutoCorrectedDatePipe(
          'dd/mm/yyyy HH.MM.SS', { minYear: 1900,  maxYear: 2100 }
        ),
      }
    };
  }
  private get InputClass() {
    return {
      'text-right': this.$attrs.type == 'number' && 
        (this.IsSlotExist('InputPrepend') || !this.IsSlotExist('InputAppend'))
    };
  }
  private get InputLimits(): [number, number] {
    let min = -999_999_999_999_999, max =  999_999_999_999_999;

    this.validatorQuery?.split('|').forEach((query) => {
      const queryParts = query.split(':');
      switch (queryParts[0]) {
        case 'min':   
          min = Math.max(min, Number(queryParts[1]));
          break;
        case 'max':
          max = Math.min(max, Number(queryParts[1]));
          break;
        case 'between':
          const vals = queryParts[1].split(',');
          min = Math.max(min, Number(vals[0]));
          max = Math.min(max, Number(vals[1]));
      }
    });

    /* PseudoLimit — Always strecth to 0 for input ease */
    return [ Math.min(0, min), Math.max(0, max) ];
  }
  private get InputType(): string {
    return this.$attrs.type == 'password' && this.IsShowPassword ? 
      'text' : this.$attrs.type;
  }
  private get IsMaskedType(): boolean {
    return Object.keys(this.MaskConfigs.Masks).includes(this.$attrs.type) 
      || Boolean(this.customMask);
  }
  private get IsUsingGuide(): boolean {
    return ![ 'number', 'tel' ].includes(this.$attrs.type);
  }
  private get TogPasswordIconSrc(): string {
    return require(
      `☆XeoApp/Assets/Images/Monocolor/${this.IsShowPassword ? 'Visible' : 'Hidden' }.png`
    );
  }

  protected created() {
    if (!this.value) {
      this.$emit('input', this.GetDefaultValueByType());
    }
    this.ParseValueToInputField();
  }
  protected mounted() {
    this.RootElement = this.IsMaskedType ? this.$refs.TxtInput.$el : this.$refs.TxtInput;
  }

  protected get EventListeners() {
    const self = this;

    return Object.assign({}, this.$listeners, {
      blur(event: FocusEvent) {
        /* Last Value ReFormatting */
        const type: string = self.$attrs.type;
        if (type.includes('date') || type.includes('time')) {
          if (self.value) {
            self.Value = XeoBibliotheca.DateTimeCodex.Parse(self.value)
              .format(self.GetDtFormatByType());
          }
        } else if (type == 'number') {
          self.Value = XeoBibliotheca.NumberCodex.Parse(self.Value).format('0,0[.][00]');
        }

        if (self.validatorQuery) {
          self.ValidateValue();
          self.IsValidating = false;
        }

        self.$emit('blur', event);
      },
      input(eventVal: any) {
        /* Modify Value based on Type */
        let emittedValue: any;
        const type: string = self.$attrs.type;
        if (type.includes('date') || type.includes('time')) {
          emittedValue = self.GetOverwrittenDtValue();
        } else if (type == 'number' || type == 'range') {
          emittedValue = self.LimitInput(eventVal);
          self.Value = XeoBibliotheca.NumberCodex.Format(emittedValue, '0,0[.][00]');          
        } else if (type == 'tel') {
          emittedValue = eventVal.replace(/ /g, '');
        } else {
          emittedValue = eventVal;
        }

        self.EmitAndValidateInput(emittedValue);
      },
      keydown(event: KeyboardEvent) {
        switch (self.$attrs.type) {
          case 'number':
            const allowMultisign = (self.InputLimits[0] * self.InputLimits[1]) < 0;

            if (event.key.match(/(?<!.)[0-9]/g) && self.Value == '0') {
              /* Handle 0-to-Number Edge-Case */
              event.preventDefault();
              self.EmitAndValidateInput(self.LimitInput(event.key));
            } else if (event.key == '-' && self.value != 0 && allowMultisign) {
              /* Handle Number-Negation Edge-Case */
              event.preventDefault();
              self.EmitAndValidateInput(self.LimitInput(-self.value));
            }

            break;
        }

        self.$emit('keydown', event);
      }
    });
  }
  protected TogPassword_Click() {
    this.IsShowPassword = !this.IsShowPassword;
  }
  protected InputFocus_Act() {
    this.focus();
  }

  private EmitAndValidateInput(value: any) {
    if (this.lazyInput)     this.DoDebEmitInput(value);
    else                    this.$emit('input', value);

    this.DoDebValidateValue();
  }
  private GetOverwrittenDtValue(): string | moment.Moment | null {
    if (!this.Value && this.$attrs.type != 'time') {
      return null;
    } else {
      const parsedVal: moment.Moment = XeoBibliotheca.DateTimeCodex.Parse(this.value);
      let oldValue: string = (parsedVal.isValid() ? parsedVal : moment(0))
        .startOf('date')
        .format(this.DateTimeFormats['DateTime']);
      let newValue: string = '';
      let overwrittenDtValue: string = '';
      
      const type: string = this.$attrs.type;
      if (type == 'date') {
        newValue = `${this.Value || '__/__/____'} __.__.__`;
      } else if (type == 'datetime') {
        newValue = this.Value || '__/__/____ __.__.__';
      } else if (type == 'time') {
        newValue = `__/__/____ ${this.Value || '__.__.__'}`;
      }
      
      for (let i = 0; i < newValue.length; i++) {
        overwrittenDtValue += newValue[i] == '_' ? oldValue[i] : newValue[i];
      }

      return XeoBibliotheca.DateTimeCodex
        .ParseString(overwrittenDtValue, this.DateTimeFormats['DateTime']);
    }
  }
  private GetDefaultValueByType(): string | number | null {
    const type: string = this.$attrs.type;
    if (type.includes('date') || type.includes('time')) {
      return null;
    } else if (type == 'number' || type == 'range') {
      return 0;
    } else {
      return ''; 
    }
  }
  private GetDtFormatByType(): string {
    const type: string = this.$attrs.type;
    let dtFormatName: string = '';
    if (type == 'date') {
      dtFormatName = 'Date';
    } else if (type == 'datetime') {
      dtFormatName = 'DateTime';
    } else if (type == 'time') {
      dtFormatName = 'Time';
    }

    return this.DateTimeFormats[dtFormatName];
  }
  private IsInputFieldEqualValue(value: any): boolean {
    const type: string = this.$attrs.type;
    if (type == 'number' || type == 'range') {
      return XeoBibliotheca.NumberCodex.IsEqual(this.Value, value);
    } else if (type.includes('date') || type.includes('time')) {
      return value ? value.isSame(this.GetOverwrittenDtValue()) : !this.Value;
    } else if (type == 'tel') {
      return XeoBibliotheca.NumberCodex.IsEqual(this.Value, value);
    } else {
      return value == this.Value;
    } 
  }
  private LimitInput(val: any): any {
    const type: string = this.$attrs.type;

    if (type == 'number' || type == 'range') {
      return XeoBibliotheca.NumberCodex.Limit(val, this.InputLimits[0], this.InputLimits[1]);
    } else {
      return val;
    }
  }
  private ParseValueToInputField() {
    const type: string = this.$attrs.type;
    const value: any = this.value;

    if (type == 'number') {
      this.Value = XeoBibliotheca.NumberCodex.Parse(value).format('0,0[.]0[0000000]');
    } else if (type == 'range') {
      this.Value = value?.toString() || '';
    } else if (type.includes('date') || type.includes('time')) {
      const parsedVal: moment.Moment = XeoBibliotheca.DateTimeCodex.Parse(value);
      this.Value = parsedVal.isValid() ? 
        parsedVal.format(this.GetDtFormatByType()) : '';
    } else if (type == 'tel') {
      this.Value = this.value ? 
        XeoBibliotheca.MaskCodex.MaskRawString('___ ____ _____', value) : '';
    } else {
      this.Value = value;
    }
  }

  /* Watch value - Handle External Modification */
  @Watch('value')
  private InputValue_Change(newInputValue: any) {
    if (!this.IsInputFieldEqualValue(newInputValue)) {
      this.ParseValueToInputField();
    }
  }
}

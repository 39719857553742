import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import moment from 'moment';

import Application from '@/Pages/App/—Application—.vue';
import Landing from '@/Pages/—Landing—.vue';

import Authentication from '@/Pages/Authentication.vue';
import Terms from '@/Pages/Terms.vue';

import SetupWelcome from '@/Pages/App/Setup/SetupWelcome.vue';
import SetupComplete from '@/Pages/App/Setup/SetupComplete.vue';

import Dashboard from '@/Pages/App/Dashboard.vue';
import CompanyDetails from '@/Pages/App/Company/CompanyDetails.vue';
import CompanyCuts from '@/Pages/App/Company/CompanyCuts.vue';
import CompanyStructure from '@/Pages/App/Company/CompanyStructure.vue';
import StaffList from '@/Pages/App/Staff/StaffList.vue';
import StaffAccess from '@/Pages/App/Staff/StaffAccess.vue';
import TmOvertime from '@/Pages/App/TimeManagement/TmOvertime.vue';
import TmTimeoff from '@/Pages/App/TimeManagement/TmTimeoff.vue';
import TmAttendance from '@/Pages/App/TimeManagement/TmAttendance.vue';
import PayrollSummary from '@/Pages/App/Payroll/PayrollSummary.vue';
import PayrollOtPayment from '@/Pages/App/Payroll/PayrollOtPayment.vue';
import PayrollAllowanceCuts from '@/Pages/App/Payroll/PayrollAllowanceCuts.vue';

export const SetupSteps = {
  Welcome: 0,
  Company: 10,
  CompanyCuts: 11,
  Staff: 20,
  Complete: 255
}

export default XeoBibliotheca.VueCodex.RenderRoutes([
  { path: '*', redirect: { name: 'Dashboard' } },
  { path: '', component: Landing, meta: { title: 'Landing' },
    children: [
      { path: '', name: 'Authentication', component: Authentication },
      { path: 'terms', name: 'Terms', component: Terms },
    ] 
  },
  { path: '/app', component: Application, meta: { title: 'Application' }, 
    children: [
      /*** Setup Pages ***/
      { path: 'setup', meta: { title: 'Setup' },
        children: [
          { path: '', name: 'Setup_Welcome', component: SetupWelcome, 
            meta: {
              setupStep: SetupSteps.Welcome,
              icon: require('@/Assets/Images/Brand/Base.png'),
            }
          },
          { path: 'company', name: 'Setup_Company', component: CompanyDetails, 
            props: () => ({ isSetup: true }),
            meta: {
              setupStep: SetupSteps.Company,
              icon: require('@/Assets/Images/Multicolor/Building.png')
            }
          },
          { path: 'company-cuts', name: 'Setup_CompanyCuts', component: CompanyCuts, 
            props: () => ({ isSetup: true }),
            meta: {
              setupStep: SetupSteps.CompanyCuts,
              icon: require('@/Assets/Images/Multicolor/Logo-BpjsDjp.png')
            }
          },
          { path: 'staff/:staffId?', name: 'Setup_Staff', component: StaffList, 
            props: (route: any) => {
              return { 
                isSetup: true,
                staffId: route.params.staffId 
              };
            },
            meta: { 
              setupStep: SetupSteps.Staff,
              icon: require('@/Assets/Images/Multicolor/Staff.png')
            }
          },
          { path: 'complete', name: 'Setup_Complete', component: SetupComplete,
            meta: {
              setupStep: SetupSteps.Complete,
              icon: require('@/Assets/Images/Multicolor/Flag.png')
            }
          }
        ]
      },
      /*** Main App Pages ***/
      { path: '', name: 'Dashboard', component: Dashboard,
        meta: {
          icon: require('@/Assets/Images/Brand/Simple.png')
        } 
      },
      { path: 'company',
        meta: {
          accessId: '1.1',
          title: 'Company',
          icon: require('@/Assets/Images/Multicolor/Building.png')
        },
        children: [
          { path: '', name: 'Company_Details', component: CompanyDetails,
            meta: {
              accessId: '1.1.1',
              icon: require('@/Assets/Images/Multicolor/Building-White.png')
            } 
          },
          { path: 'cuts', name: 'Company_Cuts', component: CompanyCuts,
            meta: {
              accessId: '1.1.2',
              icon: require('@/Assets/Images/Multicolor/Logo-BpjsDjp.png')
            } 
          },
          { path: 'structure', name: 'Company_Structure', component: CompanyStructure,
            meta: {
              accessId: '1.1.3',
              icon: require('@/Assets/Images/Multicolor/Organization.png')
            } 
          }
        ]
      },
      { path: 'staff',
        meta: {
          title: 'Staff',
          icon: require('@/Assets/Images/Multicolor/Staff.png')
        },
        children: [
          { path: 'list/:staffId?', name: 'Staff_List', component: StaffList,
            props: (route: any) => {
              return {
                staffId: XeoBibliotheca.NumberCodex.OptParseValue(route.params.staffId)
              };
            },
            meta: {
              accessId: '1.2.1',
              accessIgnore: true,
              icon: require('@/Assets/Images/Multicolor/StaffList.png')
            }
          },
          { path: 'access', name: 'Staff_Access', component: StaffAccess,
            meta: {
              accessId: '1.2.2',
              icon: require('@/Assets/Images/Multicolor/Access.png')
            }
          }
        ]
      },
      { path: 'time-m',
        meta: {
          title: 'TimeManagement',
          icon: require('@/Assets/Images/Multicolor/TimeList.png')
        },
        children: [
          { path: 'attendance', name: 'Tm_Attendance', 
            component: TmAttendance,
            meta: {
              accessId: '1.4.3',
              accessIgnore: true,
              icon: require('@/Assets/Images/Multicolor/Attendance.png')
            } 
          },
          { path: 'overtime', name: 'Tm_Overtime', 
            component: TmOvertime,
            props: (route: any) => {
              return {
                id: XeoBibliotheca.NumberCodex.OptParseValue(route.query.id)
              };
            },
            meta: {
              accessId: '1.4.1',
              accessIgnore: true,
              icon: require('@/Assets/Images/Multicolor/Overtime.png')
            }
          },
          { path: 'timeoff', name: 'Tm_Timeoff', 
            component: TmTimeoff,
            props: (route: any) => {
              return {
                id: XeoBibliotheca.NumberCodex.OptParseValue(route.query.id)
              };
            },
            meta: {
              accessId: '1.4.2',
              accessIgnore: true,
              icon: require('@/Assets/Images/Multicolor/Timeoff.png')
            } 
          }
        ]
      },
      { path: 'payroll',
        meta: {
          accessId: '1.3',
          title: 'Payroll',
          icon: require('@/Assets/Images/Multicolor/Payroll.png')
        },
        children: [
          { path: 'summary/:payrollId?', name: 'Payroll_Summary', 
            component: PayrollSummary,
            props: (route: any) => {
              return {
                payrollId: XeoBibliotheca.NumberCodex.OptParseValue(route.params.payrollId),
                timePeriod: XeoBibliotheca.DateTimeCodex.Parse(route.query.tp?.toString())
                  .startOf('year')
              };
            },
            meta: {
              accessId: '1.3.1',
              icon: require('@/Assets/Images/Multicolor/PayrollList.png')
            }
          },
          { path: 'otpayment/:otpId?', name: 'Payroll_OtPayment', component: PayrollOtPayment,
            props: (route: any) => {
              return {
                otpId: XeoBibliotheca.NumberCodex.OptParseValue(route.params.otpId),
                timePeriod: XeoBibliotheca.DateTimeCodex.Parse(route.query.tp?.toString())
                  .startOf('month')
              };
            },
            meta: {
              accessId: '1.3.2',
              icon: require('@/Assets/Images/Multicolor/Payroll-Cheque.png')
            }
          },
          { path: 'allowances', name: 'Payroll_AllowanceCuts',
            component: PayrollAllowanceCuts,
            meta: {
              accessId: '1.3.3',
              icon: require('@/Assets/Images/Multicolor/MoneyBag.png')
            } 
          }
        ]
      }
    ]
  }
]); 
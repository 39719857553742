
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormElementMixin from '☆XeoApp/Vue/Mixins/XeoFormElementMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';

import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';

@Component({
  name: 'XeoDropdown',
  inheritAttrs: false
})
export default class XeoDropdown extends Mixins(
  XeoBaseMixin, XeoFormElementMixin, XeoModalMixin
) {
  $refs!: {
    DdlDropdown: HTMLButtonElement,
    TxtSearch: XeoFormInput,
    BarItems: HTMLElement,
    CntItems: HTMLElement,
  }

  @Prop({ default: '-' }) readonly mode!: '-' | 'multiple';
  @Prop({ default: '-' }) readonly renderType!: '-' | 'sign';
  @Prop({ default: () => [] }) readonly items!: any;
    @Prop(String) readonly keyId!: string;
    @Prop(String) readonly valueId!: string;
      //* Key & Value Id *//
      //  Key   : Unique Id on items 
      //  Value : Field on item to be used as value
    @Prop({ default: '' }) readonly itemOrderBy!: string;
  @Prop({ default: Number.MAX_SAFE_INTEGER }) readonly maxDisplay!: number;
  @Prop(Boolean) readonly noSearch!: boolean;
  @Prop() readonly modalClass!: any;
  @Prop({ default: '—' }) readonly placeholder!: string;
    @Prop(String) readonly searchPlaceholder!: string;

  private TxtSearchValue: string = '';
  private ItemIndex: number = -1;
  private get DisplayCount(): any {
    return {
      Main: Math.min(this.value.length, this.maxDisplay),
      Editor: Math.min(this.Value.length, this.maxDisplay)
    };
  }
  private get DisplayItems() {
    let displayItems = this.NormalizedItems;

    /* Search Items */
    if (this.TxtSearchValue) {
      displayItems = this.NormalizedItems.filter((item: any) => {
        return Object.values(item).findIndex((prop) => {
          return typeof prop == 'string' 
            && prop.toLowerCase().includes(this.TxtSearchValue.toLowerCase());
        }) > -1;
      });
    }

    /* Sort Items */
    if (this.itemOrderBy) {
      const orderByParts = this.itemOrderBy.split(' '),
            orderOn = orderByParts[0],
            orderMod = orderByParts[1]?.toLowerCase() == 'desc' ? -1 : 1;

      displayItems = displayItems.sort((a, b) => {
        return orderMod * (
          typeof a[orderOn] == 'number' ? 
            a[orderOn] - b[orderOn] :
            a[orderOn].toString().localeCompare(b[orderOn].toString())
        );
      });
    }

    return displayItems;
  }
  private get HiddenCount(): any {
    return {
      Main: Math.max(this.value.length - this.DisplayCount.Main, 0),
      Editor: Math.max(this.Value.length - this.DisplayCount.Editor, 0)
    };
  }
  private get IsShowPrepend(): boolean {
    return this.IsSlotExist('DropdownPrepend') || this.renderType == 'sign';
  }
  private get IsValueEmpty(): boolean {
    return this.mode == 'multiple' ?
      this.Value.length == 0 :
      this.Value == null || !this.NormalizedItems.find(
        item => [item, item?.Id, item?.[this.keyId]].includes(this.Value)
      );
  }
  private get NormalizedItems(): any[] {
    //* Normalized Items can Handle 4 Types of items *//
    //    1. Array – [ a, b ,c ]
    //    2. Array of Object – [ { a: 0, b: 1 }]
    //    3. Object – { Id: v }
    //    4. Object of Object – { Id: { a: 0, b: 1 } }
    //∴ -->> To Array – [ { key: Id, a: 0, b: 1 } ] <<-- ∴//

    if (this._IsNonArrayObject(this.items)) {
      return Object.entries(this.items).map(([key, value]: any) => {
        if (this._IsNonArrayObject(value)) {
          /* Handle case 4 */
          value[this.keyId] = this._SafeParseNumber(key);
          return value;
        } else {
          /* Handle case 3 */
          const normItem: any = {};
          normItem[this.keyId] = this._SafeParseNumber(key);
          normItem['Value'] = value;
          return normItem;
        }
      });
    } else {
      /* Handle case 1 & 2 */
      return this.items;
    }
  }

  protected created() {
    this.Value = XeoBibliotheca.UtilCodex.DeepClone(this.value);
  }
  protected mounted() {
    this.RootElement = this.$refs.DdlDropdown;
  }

  public open() {
    this.OpenDropdownModal_Act();
  }

  protected ApplySelection_Act() {
    this._ApplySelection();
  }
  protected BtnBackspace_Click() {
    this.Value = this.mode == 'multiple' ? [] : null;
    if (this.mode != 'multiple') {
      this._ApplySelection();
    }
  }
  protected BtnDeleteItem_Click(valueIdx: number) {
    Vue.delete(this.Value, valueIdx);
  }
  protected BtnItem_Click(itemIdx: number) {
    this._InputItemByIndex(itemIdx);
  }
  protected BtnItem_MouseEnter(itemIdx: number) {
    this.ItemIndex = itemIdx;
  }
  protected ItemContainer_MouseLeave() {
    this.ItemIndex = -1;
  }
  protected Modal_Shown() {
    this._InitModalComponents();
  }
  protected OpenDropdownModal_Act() {
    if (!this.IsCoreDisabled) {
      this._InitModalState();
      this.ModalValue = true;
    }
  }
  protected TxtSearch_Blur() {
    this.ItemIndex = -1;
  }
  protected TxtSearch_KeyDown(event: any) {
    switch (event.key) {
      case 'Shift':
        /* Ignored for Shift+ operations */
        break;
      case 'ArrowDown':
        this.ItemIndex = Math.min(this.ItemIndex + 1, this.DisplayItems.length - 1);
        break;
      case 'ArrowUp':
        this.ItemIndex = Math.max(this.ItemIndex - 1, 0);
        break;
      case 'Enter':
        this._InputItemByIndex(this.ItemIndex);
        break;
      default:
        this.ItemIndex = -1;
    }

    this._ScrollToCursor(.25);
  }

  private _ApplySelection() {
    const finalValue = Array.isArray(this.Value) ?
      this.Value.map(item => this._GetEmitValue(item)) :
      this._GetEmitValue(this.Value);

    this.ModalValue = false;
    this.$emit('input', finalValue);
    this.DoDebValidateValue();

    this.$emit('change', finalValue);
  }
  private _GetEmitValue(val: any): any {
    const isEmitByItemValue: boolean = 
      this.valueId && val && typeof val == 'object' && Boolean(val);
    const emittedValue = XeoBibliotheca.UtilCodex.DeepClone(
      isEmitByItemValue ? val[this.valueId] : val
    );

    return emittedValue;
  }
  private _GetItemByItemValue(value: any): any {
    if (!this.valueId) {
      return value;
    } else  {
      return this.NormalizedItems.find((item: any) => item[this.valueId] == value) || {};
    }
  }
  private _GetSingleItemProp(value: any): any {
    return this.mode != 'multiple' ? this._GetItemByItemValue(value) : null;
  }
  private _InitModalComponents() {
    if (!this.noSearch && XeoBibliotheca.DisplayCodex.IsDisplayExceed('lg')) {
      this.$refs.TxtSearch.focus();
    }
  }
  private _InitModalState() {
    this.Value = XeoBibliotheca.UtilCodex.DeepClone(this.value);
    this.TxtSearchValue = '';
    this.ItemIndex = -1;
  }
  private _InputItemByIndex(itemIdx: number) {
    const cursorItem = this.DisplayItems[itemIdx];
    const cursorItemValue = this.valueId ? cursorItem[this.valueId] : cursorItem;

    if (cursorItem) {
      if (this.mode == 'multiple') {
        /* Set Default Value */
        this.Value = this.Value || [];

        /* Insert Selection to Value */
        if (this._IsSelected(itemIdx)) {
          const valueIdx: number = typeof cursorItemValue == 'object' ? 
            this.Value.findIndex((valueItem: any) => {
              return valueItem[this.keyId] === cursorItemValue[this.keyId];
            }) : 
            this.Value.indexOf(cursorItemValue);
          
          Vue.delete(this.Value, valueIdx);
        } else {
          this.Value.push(cursorItemValue);
        }
      } else {
        this.Value = cursorItemValue;
        this._ApplySelection();
      }
    }
  }
  private _IsNonArrayObject(val: any): boolean {
    return typeof(val) == 'object' && !Array.isArray(val);
  }
  private _IsSelected(itemIdx: number): boolean {
    const cursorItem = this.DisplayItems[itemIdx];

    if (this.mode == 'multiple') {
      if (typeof cursorItem == 'object') {
        return this.Value.find((valueItem: any) => {
          return this.valueId ?
            valueItem === cursorItem[this.valueId] :
            valueItem[this.keyId] === cursorItem[this.keyId];
        });
      } else {
        return this.Value ? this.Value.includes(cursorItem) : false;
      }
    } else {
      if (typeof cursorItem == 'object') {
        return this.valueId ?
          this.Value === cursorItem[this.valueId] :
          this.Value[this.keyId] === cursorItem[this.keyId];
      } else {
        return this.Value === cursorItem;
      }
    }
  }
  private _SafeParseNumber(n: any): number | string {
    return typeof n == 'number' || (typeof n == 'string' && !isNaN(n as any)) ?
      Number(n) : n;
  }
  private _ScrollToCursor(cursorLocation: number) {
    const simplebarScrollElement = this.SimplebarElement.getScrollElement() as HTMLElement;
    const searchBoxHeight: number = simplebarScrollElement.clientHeight;
    const itemHeight: number = this.$refs.CntItems.clientHeight / this.DisplayItems.length;

    simplebarScrollElement.scrollTop = 
      itemHeight * (this.ItemIndex + .5) 
      - Math.max(searchBoxHeight * cursorLocation , itemHeight * .5);
  }

  /* Watch value - Handle External Modification */
  @Watch('value')
  private value_Change(newValue: any) {
    if (!XeoBibliotheca.UtilCodex.DeepEqual(newValue, this.Value)) {
      this.Value = XeoBibliotheca.UtilCodex.DeepClone(newValue);
    }
  }
}

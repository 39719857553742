import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import { GetOvertimeListRequest, Overtime } from '@/Models/TimeManagementModels';

export default new class TmOvertimeQueries {
  public Axenta_GetOvertimeById(id: number): string {
    return `Axenta_GetOvertimeById(id: ${id})`;
  }
  public Axenta_GetOvertimeList(req: GetOvertimeListRequest): string {
    return `Axenta_GetOvertimeList(
      req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)}
    )`;
  }
  public Axenta_AdditOvertime(req: Overtime): string {
    return `Axenta_AdditOvertime(req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)})`;
  }
  public Axenta_DeleteOvertime(id: number): string {
    return `Axenta_DeleteOvertime(id: ${id})`;
  }
}

import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppConstantsMixin from '@/Mixins/AppConstantsMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import StaffQueries from '@/Repositories/Graphql/StaffQueries';

import * as HelperModels from '@/Models/—HelperModels—';
import * as StaffModels from '@/Models/StaffModels'

import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';
import XeoDateTimePicker from '☆XeoApp/Vue/Components/Base/XeoDateTimePicker.vue';
import XeoDropdown from '☆XeoApp/Vue/Components/Base/XeoDropdown.vue';
import CompanySelector from '@/App/Vue/Components/CompanySelector.vue';
import DivJobEditorModal from '@/Components/Company/DivJobEditorModal.vue';
import AcEditorModal from '@/Components/Company/AcEditorModal.vue';

@Component({
  name: 'SmDetailModule',
  components: { DivJobEditorModal, AcEditorModal }
})
export default class SmDetailModule extends Mixins(
  XeoBaseMixin, XeoFormMixin, 
  AppAccessMixin, AppConstantsMixin, AppRenderMixin, AppToastMixin
) {
  $refs!: {
    TxtDisplayName: XeoFormInput,
      TxtUsername: XeoFormInput,
      TxtEmailAddress: XeoFormInput,
      TxtIdentityType: XeoFormInput,
    DdlCompanyBranchId: CompanySelector,
      TxtEmployeeId: XeoFormInput,
      DtpJoinDate: XeoDateTimePicker,
    DdlSalaryType: XeoDropdown,
      TxtAccountName: XeoFormInput,
      TxtHolderName: XeoFormInput,
    TxtTaxCredential: XeoFormInput,
      DdlTaxEmploymentStatus: XeoDropdown,
      DdlTaxOriginCountryCode: XeoDropdown,
      DdlTaxMaritalStatus: XeoDropdown,
      DdlTaxCutMethod: XeoDropdown,
    DdlSsHealthcarePaidBy: XeoDropdown,
      DdlSsEmploymentPaidBy: XeoDropdown,
        DdlSseCpAccident: XeoDropdown,
        DdlSseCpOldAge: XeoDropdown,
        DdlSseCpPension: XeoDropdown,
        DdlSseCpDeath: XeoDropdown,
    
    MdlDivisionJobEditor: XeoModalMixin,
    MdlAcEditor: AcEditorModal
  }

  private get Constants() { return DataStore.Constants; }
  private get Company() { return DataStore.CompanyHq.Data; }
  private get Divisions() { return DataStore.Divisions; }
  private get Jobs() { return DataStore.Jobs; }
  private get StaffList() { return DataStore.Staffs; }
  
  @Prop(Number) value!: number;
  @Prop(String) mode!: 'Create' | 'Edit' | '-';
  @Prop(Object) staff!: StaffModels.Staff | null;
  private FormStaff: StaffModels.Staff = new StaffModels.Staff();
  private EditedAc: HelperModels.AllowanceCut = new HelperModels.AllowanceCut();
  private AddDivisionJobType: 'Divisi' | 'Jabatan' | '' = '';
  private AllowanceCuts: Record<number, HelperModels.AllowanceCut> = {};
  private FirstDisabledStep: number = Number.MAX_SAFE_INTEGER;
  private IsUserNameEdited: boolean = false;
  private StepsLength: number = 5;
  private UserNameSuffix: string = '';
  private get AcList(): HelperModels.AllowanceCut[] {
    return Object.values(this.AllowanceCuts).sort((a, b) => a.Name.localeCompare(b.Name));
  }
  private get CompanyAcSummary(): HelperModels.CompanyAllowanceCut[] {
    return this.staff ? (DataStore.CompanyHq.Data.AllowanceCuts || []).filter(
      (ac: HelperModels.CompanyAllowanceCut) => {
        return (ac.DivisionId == 0 || ac.DivisionId == this.staff!.DivisionId) && 
          (ac.JobId == 0 || ac.JobId == this.staff!.JobId);
      }
    ).sort((a, b) => b.Value - a.Value) : [];
  }
  private get IsCompanyStructFullAccess(): boolean {
    return this.GetUserAccessState('Client', 'Company_Structure') == 100;
  }
  private get IsStaffNoAccess(): boolean {
    return this.GetUserAccessState('Client', 'Staff_List') == 0;
  }
  private get TaxNonEmployeeType(): 1 | 2 | 3 | 0 {
    //* Non-Employee Types *//
    //  1: Basic
    //  2: Annual Payment
    //  3: Non-Annual Payment
    //  0: Employee

    const taxEmpStatus: number = Math.abs(this.FormStaff.TaxEmploymentStatus);
    return 2110004 <= taxEmpStatus && taxEmpStatus <= 2110007 ? 1 :
      taxEmpStatus == 2110008 ? 2 :
      taxEmpStatus == 2110009 ? 3 :
      0;
  }
  private get TaxIsExpatriate(): boolean {
    return this.FormStaff.TaxEmploymentStatus < 0
      || this.FormStaff.TaxEmploymentStatus == 2710099;
  }

  protected created() {
    if (this.staff) {
      const staff: StaffModels.Staff = XeoBibliotheca.UtilCodex.DeepClone(this.staff);
      this.UserNameSuffix = this.mode == 'Create' ?
        this.Company.CompanyCode : staff.UserName.split('@')[1];

      staff.UserName = staff.UserName.split('@')[0];
      staff.Credentials.EmailAddress = staff.Credentials.EmailAddress || staff.EmailAddress;
      staff.Credentials.PhoneNumber = 
        (staff.Credentials.PhoneNumber || staff.PhoneNumber).substring(3);
      this._NormalizeSsConfigurations(staff);
      
      this._AssignNormalizedAc(staff.AllowanceCuts || []);
      this.FormStaff = staff;
    }
  }
  protected mounted() {
    this._SetFirstDisabledStep(true);
  }

  protected ToggleChildModal_Act(dir: string) {
    this.$emit('toggle-child-modal', dir);
  }
  protected BtnAddAc_Click() {
    this.EditedAc = new HelperModels.AllowanceCut();
    this.$refs.MdlAcEditor.open();
  }
  protected BtnAddDivisionJob_Click(type: 'Divisi' | 'Jabatan') {
    this.AddDivisionJobType = type;
    this.$refs.MdlDivisionJobEditor.open();
  }
  protected BtnNavigation_Click(dirDest: string | number) {
    this._SetFirstDisabledStep();
    this._NavigateForm(dirDest);
  }
  protected DdlTaxEmploymentStatus_Change() {
    /* Non-Employee Resets */
    this.FormStaff.IsAnnualPayment = this.TaxNonEmployeeType != 3;
    if (this.TaxNonEmployeeType == 0) {
      this.FormStaff.IsMultipleEmployers = false;
    }

    /* Expatriate Resets */
    if (!this.TaxIsExpatriate) {
      this.FormStaff.OriginCountryCode = '';
    }
  }
  protected DdlTaxMaritalStatus_Change() {
    if (this.FormStaff.TaxMaritalStatus == 1) {
      this.FormStaff.TaxTotalDependents = 0;
      this.FormStaff.IsMergeUntaxedIncome = false;
    }
  }
  protected ItmStaffAc_Click(ac: HelperModels.AllowanceCut) {
    this.EditedAc = ac;
    this.$refs.MdlAcEditor.open();
  }
  protected MdlAcEditor_Delete(id: number) {
    Vue.delete(this.AllowanceCuts, id);
  }
  protected MdlAcEditor_Save(ac: HelperModels.AllowanceCut) {
    this._AssignNormalizedAc(ac);
  }
  protected TxtDisplayName_Input() {
    if (this.mode == "Create" && !this.IsUserNameEdited) {
      this.FormStaff.UserName = XeoBibliotheca.UtilCodex.ToDotTrainCase(
        this.FormStaff.DisplayName.trim().split(' ').splice(0, 3).reduce(
          (name, np, i) => name + (i == 2 ? np[0] || '' : np) + ' ', ''
        )
      );
    }
  }
  protected TxtUsername_Change() {
    this.IsUserNameEdited = true;
  }

  private _AssignNormalizedAc(
    newAc: HelperModels.AllowanceCut | HelperModels.AllowanceCut[]
  ) {
    if (Array.isArray(newAc)) {
      this.AllowanceCuts = {};
      newAc.forEach((ac: HelperModels.AllowanceCut) => {
        const id: number = Math.random();
        Vue.set(this.AllowanceCuts, id, Object.assign(ac, { Id: id }));
      });
    } else {
      const id: number = newAc.Id || Math.random();
      Vue.set(this.AllowanceCuts, id, Object.assign(newAc, { Id: id }));
    }
  }
  private _FinalizeFormStaff() {
    this.FormStaff.AllowanceCuts = this.AcList; 
  }
  private _GetPeriodTypeById(id: number): string {
    return id == 1 ? 'bulan' :
      id == 2 ? 'minggu' :
      id == 3 ? 'hari' : '?';
  }
  private _GetRefsOnStep(step: number): object[] | object {
    const refs = this.$refs;

    switch (step) {
      case 0:
        return [ 
          refs.TxtDisplayName, refs.TxtUsername, 
          refs.TxtEmailAddress, refs.TxtIdentityType 
        ];
      case 1:
        return [ refs.DdlCompanyBranchId, refs.TxtEmployeeId, refs.DtpJoinDate ];
      case 2: 
        return [ 
          refs.DdlSalaryType, refs.TxtAccountName, refs.TxtHolderName 
        ];
      case 3:
        return [ 
          refs.TxtTaxCredential, refs.DdlTaxEmploymentStatus, 
          refs.DdlTaxMaritalStatus, refs.DdlTaxCutMethod,
          refs.DdlTaxOriginCountryCode
        ];
      case 4:
        return [ 
          refs.DdlSsHealthcarePaidBy, refs.DdlSsEmploymentPaidBy,
          refs.DdlSseCpAccident, refs.DdlSseCpOldAge, refs.DdlSseCpPension, refs.DdlSseCpDeath
        ];
      default:
        return refs;
    }
  }
  private _GetSsPayersByType(type: 'SsHealthcare' | 'SsEmployment' | 'SsCustom'): any {
    const baseOpts = Object.assign({}, this.Constants.SsPayers);    
    
    if (['SsHealthcare', 'SsCustom'].includes(type))     Vue.delete(baseOpts, 100);

    return baseOpts;
  }
  private _NavigateForm(dirDest: string | number) {
    const destStep: number = XeoBibliotheca.NumberCodex.Limit(
      dirDest == '+'      ? this.value + 1 :
        dirDest == '-'    ? this.value - 1 :
        dirDest == 'save' ? this.StepsLength :
        dirDest,
      0, this.FirstDisabledStep - 1
    );

    if (destStep >= this.StepsLength) {
      this._FinalizeFormStaff();
      this._UpsertStaff();
    } else {
      this.$emit('input', destStep);
    }
  }
  
  private _NormalizeSsConfigurations(staff: StaffModels.Staff) {
    const sseConf = staff.SsConfigurations.SsEmployment,
          sshConf = staff.SsConfigurations.SsHealthcare;

    /* Employment */
    sseConf.BaseCalculationType ||= 1;
    for (const key in sseConf.CustomPaidBys) {
      (sseConf.CustomPaidBys as any)[key] ||= 1;
    }

    /* Healthcare */
    sshConf.BaseCalculationType ||= 1;
  }
  private _SetFirstDisabledStep(isTotalSilentValidation: boolean = false) {
    for (let i = 0; i < this.StepsLength; i++) {
      if (!this._ValidateByStep(i, isTotalSilentValidation || this.value != i)) {
        this.FirstDisabledStep = i + 1;
        this.$emit('set-1st-disabled-step', this.FirstDisabledStep);
        return;
      }
    }
    
    this.FirstDisabledStep = Number.MAX_SAFE_INTEGER;
    this.$emit('set-1st-disabled-step', this.FirstDisabledStep);
  }
  private _UpsertStaff() {
    const req = this.__GenerateUpsertStaffReq();

    this.IsSavingForm = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${StaffQueries.Axenta_UpsertStaff(req)}
      }
    `).xeoThen(
      (data) => {
        const staff: StaffModels.Staff = new StaffModels.Staff(data);
        DataStore.AssignStaffs(Object.assign({}, req, data.Axenta_UpsertStaff));
        this.MakeSuccessToast(this.$t('Success.save', { name: 'Data' }));
        this.$emit('save-success');
      }, 
      (errors) => {
        const dupFields = XeoBibliotheca.ErrorCodex.GetDuplicateFieldCode(errors[0]);
        
        if (dupFields.length > 0) {
          dupFields.forEach((dupField: string) => {
            if (dupField == 'user_account.UserName' || dupField == 'IsUsernameDup') {
              this.$refs.TxtUsername.AddValidationResultData(
                new XeoTypes.ValidationResultData({
                  Message: 'Username telah dipakai oleh user lain.',
                  Status: false
                })
              );
              this.$emit('input', 0);
            } else if (dupField == 'user_map.CompanyEmployeeId' || dupField == 'IsEmployeeIdDup') {
              this.$refs.TxtEmployeeId.AddValidationResultData(
                new XeoTypes.ValidationResultData({
                  Message: 'Id ini telah digunakan oleh staff lain.',
                  Status: false
                })
              );
              this.$emit('input', 1);
            } else {
              this.MakeErrorToast(this.$t('Errors.server'));
            }
          });
        } else {
          this.MakeErrorToast(this.$t('Errors.server'));
        }
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false; 
    });
  }
  private _ValidateByStep(step: number, isSilentValidation: boolean = false): boolean {
    return XeoBibliotheca.FormCodex.ValidateFormViaRefs(
      this._GetRefsOnStep(step), isSilentValidation
    );
  }

  private __GenerateUpsertStaffReq() {
    const req = XeoBibliotheca.UtilCodex.DeepClone(this.FormStaff);
    req.Credentials.PhoneNumber = `+62${req.Credentials.PhoneNumber}`;
    if (!req.LastTaxPaymentDate) {
      req.InitialNetIncome = 0;
      req.PrepaidTax = 0;
    }

    return req;
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import AppConstantsMixin from '@/Mixins/AppConstantsMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';
import _Debounce from '☆Node/lodash.debounce';
import moment from 'moment';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import CompanyQueries from '@/Repositories/Graphql/CompanyQueries';
import CompanyCutsQueries from '@/Repositories/Graphql/CompanyCutsQueries';

import * as HelperModels from '@/Models/—HelperModels—';
import { Company } from '@/Models/CompanyModels';
import * as CompanyCutsModels from '@/Models/CompanyCutsModels';
import { Staff } from '@/Models/StaffModels';
import { SetupSteps } from '@/Router/Routes';

@Component({ name: 'CompanyCuts' })
export default class CompanyCuts extends Mixins(
  XeoBaseMixin, XeoFormMixin, 
  AppAccessMixin, AppConstantsMixin, AppRenderMixin, AppToastMixin
) {
  private get Account() { return DataStore.Account; }
  private get Constants() { return DataStore.Constants; }

  @Prop() isSetup!: boolean;
  private readonly RiskGrades: string[] = [ 
    'Sangat Rendah', 'Rendah', 'Sedang', 'Tinggi', 'Sangat Tinggi' 
  ];
  private FormCompanyCuts = new CompanyCutsModels.CompanyCuts({
    SocialSecurity: new HelperModels.SocialSecurity(),
    Tax: new HelperModels.Tax()
  });
  private TransitionKey: number = 0;
  private get DayNames(): object[] {
    return [0, 1, 2, 3, 4, 5, 6].map((dayIdx: number) => {
      return {
        Id: dayIdx + 1,
        Value: moment().day(dayIdx).format('dddd')
      };
    });
  }
  private get SortedCompanyList(): Company[] {
    const companyList = Object.values(DataStore.CompanyList);

    return companyList.length > 0 ? [
      companyList.shift()!,
      ...companyList.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName))
    ] : [];
  }
  private get SsBaseCalculationTypes() {
    const types = Object.assign({}, this.Constants.SsBaseCalculationTypes);
    [1, 90].forEach((key) => Vue.delete(types, key));

    return types;
  }
  private get TaxPicList() {
    return [new Staff({
      AccountId: -1,
      DisplayName: DataStore.CompanyHq.Data.CompanyName,
      JobId: -1
    }), ...Object.values(DataStore.Staffs)];
  }

  private Mode: 'Create' | 'Edit' = 'Create'; 
  private IsChangingRiskGrade: boolean = false;
  
  protected created() {
    this.IsLoading = true;
    this.Mode = this.Account.SetupStep > SetupSteps.CompanyCuts ? 'Edit' : 'Create';
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${CompanyCutsQueries.Axenta_GetUserCompanyCutsList}
      }
    `).xeoThen(
      (data) => {
        DataStore.AssignCompanyCutsList(data.Axenta_GetUserCompanyCutsList);
        DataStore.SetCompanyAc(DataStore.CompanyHq.Cuts.AllowanceCuts);
        this._ConstructForm(DataStore.CompanyHq.Cuts);
        this.IsLoading = false;
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });
  }

  protected BtnSaveAndContinue_Click() {
    this._UpsertCompanyCutsAndContinue();
  }
  protected DdlCompanyBranch_Change(id: number) {
    if (this.FormCompanyCuts.CompanyBranchId != id) {
      this._ConstructForm(id);
      this.TransitionKey = id;
    }
  }
  protected DdlTaxPicAccountId_Change() {
    if (this.FormCompanyCuts.TaxPicAccountId == -1) {
      this.FormCompanyCuts.TaxPicType = 0;
    }
  }
  protected RngRiskGrade_Change() {
    this.IsChangingRiskGrade = true;
    this.FormCompanyCuts.SocialSecurity.Cuts.forEach((_, i) => {
      const socialSecurity = this.FormCompanyCuts.SocialSecurity;
      if (socialSecurity.Cuts[i].Name == 'Jaminan Kecelakaan Kerja') {
        socialSecurity.Cuts[i].Percentage.Company = 
          socialSecurity.AccidentCutGrades[this.FormCompanyCuts.RiskGrade];
      }
    });
    
    this.DebRiskGradeChanged();
  }

  private DebRiskGradeChanged = _Debounce(function(this: CompanyCuts) {
    this.IsChangingRiskGrade = false;
  }, 500);
  private _ConstructForm(cc: CompanyCutsModels.CompanyCuts | number) {
    this.FormCompanyCuts = new CompanyCutsModels.CompanyCuts(
      typeof cc == 'number' ? 
        DataStore.CompanyCutsList[cc] 
          || Object.assign({}, DataStore.CompanyHq.Cuts, { CompanyBranchId: cc }) :
        cc as CompanyCutsModels.CompanyCuts
    );
  }
  private _GetBasePayMethod(fieldName: 'TaxCutMethod' | 'SsPayer' | 'SsCustomPayers'): any {
    const baseOpts = Object.assign(
      {}, this.Constants[fieldName.startsWith('T') ? 'TaxCutMethods' : 'SsPayers']
    );
    
    Vue.delete(baseOpts, 1);
    if (fieldName == 'SsCustomPayers')     Vue.delete(baseOpts, 100);
    
    return baseOpts;
  }
  private _GetUntaxedIncomeType(untaxedIncome: HelperModels.TaxUntaxedIncome): string {
    if (untaxedIncome.Percentage > 0) {
      return 'Percentage';
    } else if (untaxedIncome.Value == untaxedIncome.MaxValue) {
      return 'OneTime';
    } else if (untaxedIncome.Value < untaxedIncome.MaxValue) {
      return 'Multiple';
    } 

    return '— Not Registered —';
  }
  private _IsSsAccidentOnChange(name: string): boolean {
    return name == 'Jaminan Kecelakaan Kerja' && this.IsChangingRiskGrade;
  }
  private _OptimizeRequest(req: CompanyCutsModels.CompanyCuts): CompanyCutsModels.CompanyCuts {
    let optedReq: CompanyCutsModels.CompanyCuts = Object.assign({}, req);
    if (req.IsSyncToMaster) {
      Vue.delete(optedReq, 'SocialSecurity');
      Vue.delete(optedReq, 'Tax');
    } else {
      Vue.delete(optedReq.SocialSecurity, 'AccidentCutGrades');
    }

    return optedReq;
  }
  private _PartialSumTaxLayer(layer: number): number {
    let layerSum: number = 0;
    this.FormCompanyCuts.Tax.Layers.some((taxLayer, i) => {
      if (i >= layer) {
        return true;
      } 
      layerSum += taxLayer.Chunk;
    });

    return layerSum;
  }
  private _UpsertCompanyCutsAndContinue() {
    if (XeoBibliotheca.FormCodex.ValidateFormViaRefs(this.$refs)) {
      this.IsSavingForm = true;
    
      const req = this._OptimizeRequest(this.FormCompanyCuts);
      AppRepositories.Graphql.DoAuthGraphql(`
        mutation {
          ${CompanyCutsQueries.Axenta_UpsertCompanyCuts(req)}
          ${this.Mode == 'Create' ? CompanyQueries.Xeo_UpdateCompanySetupStep(SetupSteps.Staff) : ''}
        }
      `).xeoThen(
        (data) => {
          DataStore.AssignCompanyCutsList(this.FormCompanyCuts); 
          this.MakeSuccessToast(this.$t('Success.save', { name: 'Data' }));

          if (this.isSetup) {
            DataStore.AssignAccount({
              SetupStep: Math.max(this.Account.SetupStep, SetupSteps.Staff)
            });
            this.$router.push({ name: 'Setup_Staff' });
          }
        }, 
        (errors) => {
          this.MakeErrorToast(this.$t('Errors.server'));
        }
      ).catch((err) => {
        this.MakeErrorToast(this.$t('Errors.network'), 'sd');
      }).finally(() => {
        this.IsSavingForm = false; 
      });
    }
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';

import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormElementMixin from '☆XeoApp/Vue/Mixins/XeoFormElementMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';

@Component({
  name: 'XeoDateTimePicker',
  inheritAttrs: false
})
export default class XeoDateTimePicker extends 
  Mixins(XeoBaseMixin, XeoFormElementMixin, XeoModalMixin) 
{
  $refs!: {
    DtpDateTimePicker: HTMLButtonElement,
    BarItems: HTMLElement
  }

  @Prop(String) readonly mode!: string | 'month' | 'year' | 'range' | 'multiple';
  @Prop(String) readonly type!: 'date' | 'datetime';
  @Prop(String) readonly format!: string;
  @Prop({ default: Number.MAX_SAFE_INTEGER }) readonly maxDisplay!: number;
  @Prop() readonly modalClass!: any;
  @Prop({ default: '—' }) readonly placeholder!: string;
  @Prop() readonly dateEnabler!: XeoTypes.DateEnabler;

  private TabIndex: number = 0;               //* 0: Date | 1: Time *//
    private IndexList = [ 0, 1 ];
    private get IndexEnabler() {
      return new XeoTypes.IndexEnabler({
        DisabledIndexes: !this.IsAbleToTimepick ? [ 1 ] : []
      });
    } 
  private get DisplayCount(): any {
    return {
      Main: Math.min(this.value.length, this.maxDisplay),
      Editor: Math.min(this.Value.length, this.maxDisplay)
    };
  }
  private get DtpFormat(): string {
    if (this.format) { return this.format; }

    switch (this.mode) {
      case 'month':
        return 'MMMM YYYY';
      case 'year':
        return 'YYYY';
      default:
        const isModeMultiRange: boolean = this.mode == 'multiple' || this.mode == 'range';
        const isTypeDateTime: boolean = this.type == 'datetime';
        const isDisplaySm: boolean = !XeoBibliotheca.DisplayCodex.IsDisplayExceed('sm');

        return `DD ${isModeMultiRange && (isTypeDateTime || isDisplaySm) ? 'MMM' : 'MMMM'} YYYY` 
          + `${isTypeDateTime ? ' HH.mm' : ''}`;
    }
  }
  private get HiddenCount(): any {
    return {
      Main: Math.max(this.value.length - this.DisplayCount.Main, 0),
      Editor: Math.max(this.Value.length - this.DisplayCount.Editor, 0)
    };
  }
  private get IsAbleToTimepick(): boolean {
    return this.type == 'datetime' && !this.IsValueEmpty;
  }
  private get IsValueEmpty(): boolean {
    return !this.Value || this.Value.length == 0
  }

  protected created() {
    this.Value = XeoBibliotheca.UtilCodex.DeepClone(this.value);
  }
  protected mounted() {
    this.RootElement = this.$refs.DtpDateTimePicker;
  }

  public open() {
    this.OpenDateTimeModal_Act();
  }

  protected BtnBackspace_Click() {
    this.TabIndex = 0;
    this.Value = null;
  }
  protected BtnDeleteItem_Click(valueIdx: number) {
    Vue.delete(this.Value, valueIdx);
  }
  protected NavigateSteps_Act(step: number | boolean) {
    this._NavigateModalSteps(step);
  }
  protected OpenDateTimeModal_Act() {
    if (!this.IsCoreDisabled) {
      this._InitModalState();
      this.ModalValue = true;
    }
  }

  private _GetIconPathByType() {
    return this.type == 'datetime' ? 
      require('☆XeoApp/Assets/Images/Monocolor/DateTime.png') :
      require('☆XeoApp/Assets/Images/Monocolor/Calendar.png');
  }
  private _InitModalState() {
    this.TabIndex = 0;
    this.Value = XeoBibliotheca.UtilCodex.DeepClone(this.value);
  }
  private _NavigateModalSteps(step: boolean | number) {
    /* Set TabIndex by step Type */
    this.TabIndex = 
      (typeof step == 'boolean') ? this.TabIndex + (step ? 1 : -1) :
      (typeof step == 'number') ? step : this.TabIndex;

    /* Execute Action based on TabIndex */
    if (this.TabIndex < 0) {
      this.ModalValue = false;
      return;
    } 
    if (this.__IsIncompleteRangeDate()) {
      this.Value.push(this.Value[0].clone().endOf('day'));
    } 
    if (this.__IsStepCompleted()) {
      this.ModalValue = false;
      this.$emit('input', XeoBibliotheca.UtilCodex.DeepClone(this.Value));
      this.DoDebValidateValue();
      
      this.$emit('change', XeoBibliotheca.UtilCodex.DeepClone(this.Value));
      return;
    }
  }

  private __IsIncompleteRangeDate(): boolean {
    return this.mode == 'range' && this.Value && this.Value.length == 1;
  }
  private __IsStepCompleted(): boolean {
    return this.TabIndex == (this.type == 'datetime' && !this.IsValueEmpty ? 2 : 1);
  }

  /* Watch value - Handle External Modification */
  @Watch('value')
  private value_Change(newValue: any) {
    if (!XeoBibliotheca.UtilCodex.DeepEqual(newValue, this.Value)) {
      this.Value = XeoBibliotheca.UtilCodex.DeepClone(newValue);
    }
  }
}

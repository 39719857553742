import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

import * as CompanyCutsModels from '@/Models/CompanyCutsModels';

export default new class CompanyCutsQueries {
  public Axenta_GetMasterCompanyCutsData: string = 'Axenta_GetMasterCompanyCutsData';
  public Axenta_GetUserCompanyCutsList: string = 'Axenta_GetUserCompanyCutsList';
  public Axenta_UpdateCompanyAllowances(
    req: CompanyCutsModels.UpdateCompanyAllowancesRequest
  ): string {
    return `Axenta_UpdateCompanyAllowances(
      req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)}
    )`;
  }
  public Axenta_UpsertCompanyCuts(req: CompanyCutsModels.CompanyCuts): string {
    return `Axenta_UpsertCompanyCuts(req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)})`;
  }
}
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';

export default new class PdfUtils {
  private ColorMap = XeoBibliotheca.ColorCodex.ColorMap;

  public RenderTitle(
    pdf: XeoTypes.XeoPdf, title: string, subtext: string, imageUrl: string
  ) {
    [ ['Fog', 'Dark'], null, ['Mint', 'Base'], ['Black', 'Dark'] ].forEach(
      (cs, i: number) => {
        if (cs) {
          pdf.drawShape('rect', 
            { x: 15, y: 12.5, w: 75 / Math.pow(1.618, i), h: .85 }, 
            { style: 'F', fillColor: this.ColorMap[cs[0]][cs[1]] }
          );
        }
      }
    );
    pdf.write(title, 15, 22, {
      color: this.ColorMap.Aqua.Base,
      fontFamily: 'Montserrat', fontType: 'semibold', fontSize: 12
    });
    pdf.write(subtext, 15, 27, { 
      color: this.ColorMap.Black.Dark,
      fontFamily: 'Lato', fontType: 'bold', fontSize: 10
    });

    pdf.addImageByUrl(
      imageUrl, 'PNG',
      pdf.internal.pageSize.getWidth() - 15, 20, 'auto', 14, {
        alignment: 'right-middle',
        compression: 'FAST'
      }
    );
  }
  public RenderPages(pdf: XeoTypes.XeoPdf) {
    for (let i = 1; i <= pdf.getNumberOfPages(); i++) {
      pdf.setPage(i);
      [ 
        ['Mint', 'Base'], ['Aqua', 'Base'], null, ['Blue', 'Dark'] 
      ].forEach((cs, i: number) => {
        if (cs) {
          const w: number = 21 / Math.pow(1.618, i);
          pdf.drawShape('rect',
            { x: 282 - w, y: 196.85, w, h: .65 },
            { style: 'F', fillColor: this.ColorMap[cs[0]][cs[1]] }
          );
        }
      });
      pdf.writef(i, '0,0', 279.5, 201, {
        alignment: 'right-middle',
        color: this.ColorMap.Midnight.Dark,
        fontFamily: 'Montserrat', fontType: 'semibold', fontSize: 10
      });
    }
  }
}
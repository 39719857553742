
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import StaffQueries from '@/Repositories/Graphql/StaffQueries';

import * as StaffModels from '@/Models/StaffModels';

import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';

@Component({
  name: 'SmSettingsModule'
})
export default class SmSettingsModule extends Mixins(
  XeoBaseMixin, XeoFormMixin, AppRenderMixin, AppToastMixin
) {
  $refs!: {
    TxtNewPassword: XeoFormInput,
    AmConfirmation: XeoModalMixin
  }

  @Prop(Object) staff!: StaffModels.Staff;
  private ActionType: '' | 'delete' | 'reset-password' = '';
  private NewPassword: string = '';

  protected BtnAmOpen_Click(actionType: 'delete' | 'reset-password') {
    this._OpenModal(actionType);
  }
  protected BtnAmCancel_Click() {
    this.$refs.AmConfirmation.close();
  }
  protected BtnAmOk_Click() {
    switch (this.ActionType) {
      case 'delete':
        this._DeleteStaff();
        break;
      case 'reset-password':
        this._ResetPassword();
        break;
    }
  }
  protected BtnResetPassword_Click() {
    this._OpenModal('reset-password');
  }
  protected ToggleChildModal_Act(dir: string) {
    this.$emit('toggle-child-modal', dir);
  }

  private _DeleteStaff() {
    this.IsSavingForm = true;

    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${StaffQueries.Axenta_DeleteStaff(this.staff.UserMapId)}
      }
    `).xeoThen(
        (data) => {
          DataStore.DeleteStaffByUserMapId(this.staff.UserMapId);
          this.MakeSuccessToast(this.$t('Success.delete', { name: 'Staff' }));
          this.$refs.AmConfirmation.close();
          this.$emit('staff-deleted');
        }, 
        (errors) => {
          if (errors[0].Code == 'app: cannot-delete-staff') {
            this.MakeErrorToast(this.$t('StaffMenuModule.Errors.app: cannot-delete-staff'));
            this.$refs.AmConfirmation.close();
          } else {
            this.MakeErrorToast(this.$t('Errors.server'));
          }
        }
      ).catch((err) => {
        this.MakeErrorToast(this.$t('Errors.network'), 'sd');
      }).finally(() => {
        this.IsSavingForm = false; 
      });
  }
  private _OpenModal(actionType: 'delete' | 'reset-password') {
    this.ActionType = actionType;
    this.NewPassword = this.staff.EmployeeId;
    this.$refs.AmConfirmation.open();
  }
  private _ResetPassword() {
    if (XeoBibliotheca.FormCodex.ValidateFormViaRefs(
      [ this.$refs.TxtNewPassword ]
    )) {
      this.IsSavingForm = true;
      AppRepositories.Graphql.DoAuthGraphql(`
        mutation {
          ${StaffQueries.Axenta_ResetStaffPassword({
            Id: this.staff.AccountId,
            NewPassword: this.NewPassword
          })}
        }
      `).xeoThen(
        (data) => {
          this.MakeSuccessToast(this.$t('Success.change', { name: 'Password staff' }));
          this.$refs.AmConfirmation.close();
        }, 
        (errors) => {
          if (errors[0].Code == 'app: no-staff-matched') {
            this.MakeErrorToast(this.$t('StaffMenuModule.Errors.app: no-staff-matched'));
          } else {
            this.MakeErrorToast(this.$t('Errors.server'));
          }
        }
      ).catch((err) => {
        this.MakeErrorToast(this.$t('Errors.network'), 'sd');
      }).finally(() => {
        this.IsSavingForm = false; 
      });
    }
  }
}

import Numeral from '☆Node/numeral';

/* Reference : http://numeraljs.com/ */
export default class NumberCodex {
  public NumeralJs = Numeral;

  public Format(value: string | number, format: string): string {
    return !isNaN(value as number) ? this.Parse(value).format(format) : '';
  }
  public Parse(value: string | number): any {
    return Numeral(value);
  }
  public IsEqual(valueA: any, valueB: any): boolean {
    return this.ParseValue(valueA) == this.ParseValue(valueB);
  }
  public Limit(value: any, min: any, max: any): number {
    return Math.max(
      this.ParseValue(min) ?? -Infinity, Math.min(
        this.ParseValue(value), 
        this.ParseValue(max) ?? Infinity
      )
    );
  }
  public Mod(a: number, b: number): number {
    return ((a % b) + b) % b;
  }
  public OptParseValue(value: any): any {
    return typeof value == 'undefined' ? undefined : this.ParseValue(value) ?? value;
  }
  public ParseValue(value: string | number): number {
    return Numeral(value).value();
  }
}

import Vue from 'vue';
import Vuex, { Store } from 'vuex';

import DataModule from '@/Store/Modules/DataModule';
import StateModule from '@/Store/Modules/StateModule';

Vue.use(Vuex);

const vuexStore: Store<any> = new Vuex.Store({});
export default vuexStore;

export const DataStore = new DataModule(vuexStore);
export const StateStore = new StateModule(vuexStore);



import XeoUtilities from '☆XeoApp/Typescript/XeoUtilities';

const deepEqual = require('☆Node/fast-deep-equal');
const omitEmpty = require('☆Node/omit-empty');

export default class UtilCodex {
  public ApplyMixins(mainClass: any, parentClasses: any[]) {
    parentClasses.forEach(parentClass => {
      Object.getOwnPropertyNames(parentClass.prototype).forEach(
        (name: string) => {
          Object.defineProperty(
            mainClass.prototype, name,
            Object.getOwnPropertyDescriptor(parentClass.prototype, name) as any
          );
        });
    });
  }
  public BatchReplaceString(target: string, mapReplacer: object): string {
    Object.entries(mapReplacer).forEach(([key, val]) => {
      target.replace(key, val);
    }); 

    return target;
  }
  public DeepClone(src: any): any {
    return XeoUtilities.DeepClone(src);
  }
  public DeepEqual(valueX: any, valueY: any): boolean {
    return deepEqual(valueX, valueY);
  }
  public DeepOmitEmpty(obj: object, isOmitZero: boolean = false) {
    Object.assign(obj, omitEmpty(obj, { omitZero: isOmitZero }));
  }
  public GetAcronym(targetStr: string | any): string {
    return targetStr!.split(' ').map((item: string) => item[0]).join('');
  }
  public HasHtml(str: string): boolean {
    return str.includes('<') && str.includes('>');
  }
  public ToDotTrainCase(str: string): string {
    return str ? str.toLowerCase().trim().replace(/\s/g, '.').replace(/\.+/g, '.') : '';
  }
  public SortDotNumberFunc(a: string, b: string, isDesc: boolean = false): number {
    const ap = a.split('.'),
          bp = b.split('.');
    for (let i = 0; i < Math.max(ap.length, bp.length); i++) {
      if (ap[i] != bp[i]) {
        return (+ap[i] - +bp[i]) * (isDesc ? -1 : 1);
      }
    }

    return 0; 
  }
}
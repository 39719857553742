export default new class AppConfig {
  private EndpointServers = {
    Main: process.env.NODE_ENV == 'production' ? 
      'https://api.axenta.id' : 'http://localhost:9999'
  };
  public EndpointsUrl = {
    Auth: `${this.EndpointServers.Main}/_api/auth`,
    File: `${this.EndpointServers.Main}/_api/file`,
    AuthGraphql: `${this.EndpointServers.Main}/_api/auth-graphql`,
    Graphql: `${this.EndpointServers.Main}/_api/graphql`
  };
  public GoogleAuthParam = {
    'client_id': process.env.NODE_ENV == 'production' ? 
      '19441316620-92uf7vksp82p9a3ai6theurv9b9a2jvf.apps.googleusercontent.com' : 
      '19441316620-62ni02c2jfbqnmddhapq2ovqbcd08efi.apps.googleusercontent.com',
    'plugin_name': 'Axenta'
  };
}

import moment from 'moment';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import * as HelperModels from '@/Models/—HelperModels—';
import ModelUtils from '@/Models/—ModelUtils—';

//* Attendance *//
export class Attendance {
  public Id!: number;
  public AccountId!: number;
  public DatePeriod: moment.Moment = moment().startOf('day');
  public ScheduleIn: moment.Moment = moment().startOf('day');
  public ScheduleOut: moment.Moment = moment().startOf('day');
  public ClockIn: moment.Moment = moment().startOf('day');
  public ClockOut: moment.Moment = moment().startOf('day');
  public InTimeDiff!: moment.Duration;
  public OutTimeDiff!: moment.Duration;
  public WorkTime!: moment.Duration;

  constructor(init?: Partial<Attendance>) {
    Object.assign(this, init);
    ModelUtils.InitializeMoments(this, [
      'DatePeriod', 'ScheduleIn', 'ScheduleOut', 'ClockIn', 'ClockOut'
    ]);
    ModelUtils.InitializeDurations(this, [
      'InTimeDiff', 'OutTimeDiff', 'WorkTime'
    ]);
  }

  public MapToServerReq(): Attendance {
    const atd: any = this;

    /* Set Schedule & Time's Date to DatePeriod */
    ['ScheduleIn', 'ScheduleOut', 'ClockIn', 'ClockOut'].forEach((key) => {
      atd[key].set({
        year: atd.DatePeriod.year(),      month: atd.DatePeriod.month(),  
        date: atd.DatePeriod.date()
      });
    });

    /* Adjust Time Range */
    ['Schedule', 'Clock'].forEach((type) => {
      if (atd[type + 'In'].isAfter(atd[type + 'Out'])) {
        atd[type + 'Out'].add(1, 'day');
      }
    });

    return atd;
  }
}
export class AttendanceSummary {
  public AttendanceCountRec: Record<number, number> = {};
  public WorkdayCountRec: Record<number, number> = {};

  constructor(init?: Partial<AttendanceSummary>) {
    Object.assign(this, init);
  }
}
export class GetAttendanceListRequest extends XeoTypes.Pagination {
  public AccountId!: number;
  public TpStart!: moment.Moment; 
  public TpEnd!: moment.Moment;

  constructor(init?: Partial<GetAttendanceListRequest>) {
    super();
    Object.assign(this, init);
  }
}

//* Overtime *//
export class Overtime {
  public Id!: number;
  public AccountId!: number;
  public OtDate: moment.Moment = moment();
  public OtHours: number = 1;
  public Status: HelperModels.ApprovalState = 1;
  public Description: string = '';

  constructor(init?: Partial<Overtime>) {
    Object.assign(this, init);
    ModelUtils.InitializeMoments(this, ['OtDate']);
  }
}
export class GetOvertimeListRequest {
  public AccountId!: number;
  public TpStart!: moment.Moment; 
  public TpEnd!: moment.Moment;
  public Status!: HelperModels.ApprovalState;

  constructor(init?: Partial<GetOvertimeListRequest>) {
    Object.assign(this, init);
  }
}

//* Timeoff *//
export class Timeoff {
  public Id!: number;
  public AccountId!: number;
  public Type: string = '';
  public Name: string = '';
  public StartDate: moment.Moment = moment().startOf('day');
  public EndDate: moment.Moment = moment().startOf('day');
  public Status: HelperModels.ApprovalState = 1;
  public Description: string = '';
  public ToDays: number = 1;
  public IsPaid: boolean = false;

  /* Timeoff Form Fields */
  public ToPeriod!: [moment.Moment, moment.Moment];
  public IsHalfDay!: boolean; 

  constructor(init?: Partial<Timeoff>) {
    Object.assign(this, init);
    ModelUtils.InitializeMoments(this, ['StartDate', 'EndDate']);
    this.ToPeriod = [this.StartDate, this.EndDate];
    this.IsHalfDay = this.ToDays == 0.5;
  }

  public MapToServerReq(): Timeoff {
    this.StartDate = (this.ToPeriod?.[0] || moment()).startOf('day');
    this.EndDate = (this.ToPeriod?.[1] || moment()).startOf('day');

    const toDays: number = this.EndDate.diff(this.StartDate, 'day') + 1;
    this.ToDays = toDays == 1 && this.IsHalfDay ? 0.5 : toDays;

    return this;
  }
}
export class GetTimeoffListRequest {
  public AccountId!: number;
  public TpStart!: moment.Moment; 
  public TpEnd!: moment.Moment;
  public Status!: HelperModels.ApprovalState;
  public IsPaid!: boolean;

  constructor(init?: Partial<GetTimeoffListRequest>) {
    Object.assign(this, init);
  }
}

//* Settings *//
export type AtdTemplateColumnName = 'EmployeeId' | 'DisplayName' | 'Staff' |
  'DatePeriod' | 'ScheduleIn' | 'ScheduleOut' | 'ClockIn' | 'ClockOut';

export class TmSettings {
  public ToTypeMap: Record<string, TimeoffType> = {};
  public MassAtdTemplate!: MassAtdTemplate;

  constructor(init?: Partial<TmSettings>) {
    Object.assign(this, init);
  }
}

export class MassAtdTemplate {
  public FileType: 'xlsx' = 'xlsx';
  public ColumnRec!: Record<AtdTemplateColumnName, AtdTemplateColumn>;

  constructor(init?: Partial<MassAtdTemplate>) {
    Object.assign(this, init);
  }
}
export class AtdTemplateColumn {
  public Column: number = 0;
  public TimeFormat?: string = '';

  constructor(init?: Partial<AtdTemplateColumn>) {
    Object.assign(this, init);
  }
}
export class TimeoffType {
  public Id: string = '';
  public Name: string = '';
  public IsPaid: boolean = false;
  public IsUnlimited: boolean = true;
  public OneTimeQuota: number = 0;

  constructor(init?: Partial<TimeoffType>) {
    Object.assign(this, init);
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import { DataStore } from '@/Store/—–AppStore–—';
import moment from 'moment';

import { Staff } from '@/Models/StaffModels';

@Component({
  name: 'StaffItemModule'
})
export default class StaffItemModule extends Mixins(XeoBaseMixin, AppRenderMixin) {
  @Prop({ default: () => new Staff() }) staff!: Staff;
  @Prop(Boolean) isLoading!: boolean;
  private get Account() { return DataStore.Account; }
  private get Constants() { return DataStore.Constants; }

  private get EmploymentStatus(): string {
    const empStats: any = this.Constants.EmploymentStatuses?.[this.staff.EmploymentStatus];

    return Boolean(empStats) ? 
      this.$t(`Constants.EmploymentStatuses.${empStats}`) as string :
      '';
  }
  private get IsDataComplete(): boolean {
    return Boolean(this.staff.StaffPayrollId && this.staff.EmployeeId);
  }
  private get ThemeClass() {
    const clsRec: Record<string, boolean> = {};

    if (!this.isLoading && !this.IsDataComplete)          clsRec['incomplete'] = true;
    else if (this.Account.Id == this.staff.AccountId)     clsRec['is-me'] = true;

    return clsRec;
  }
  private get WorkPeriod(): string {
    const diff: any = XeoBibliotheca.DateTimeCodex.Difference(
      this.staff.JoinDate, DataStore.ServerNow
    );
    
    return diff.asMilliseconds() >= 0 ? diff.format('Y [Tahun] M [Bulan]') : '—';
  }

  private _GetGenderColor(genderId: number): string {
    return genderId == 1 ? 'blue' :
      genderId == 2 ? 'purple' : 'mountain';
  }
}

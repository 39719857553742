import XeoUtilities from '☆XeoApp/Typescript/XeoUtilities';

export default class ColorCodex {
  public ColorMap: any = {
    Red     : { Base: '#fc5c65',  Dark: '#eb3b5a' },
    Orange  : { Base: '#fd9644',  Dark: '#fa8231' },
    Yellow  : { Base: '#fed330',  Dark: '#f7b731' },
    Green   : { Base: '#26de81',  Dark: '#20bf6b' },
    Mint    : { Base: '#2bcbba',  Dark: '#0fb9b1' },
    Aqua    : { Base: '#45aaf2',  Dark: '#2d98da' },
    Blue    : { Base: '#4b7bec',  Dark: '#3867d6' },
    Rose    : { Base: '#f673c6',  Dark: '#e757c4' },
    Purple  : { Base: '#a55eea',  Dark: '#8854d0' },
    Black   : { Base: '#778ca3',  Dark: '#4b6584' },
    Gray    : { Base: '#d1d8e0',  Dark: '#a5b1c2' },
    White   : { Base: '#ecf0f1',  Dark: '#bdc3c7' },
    
    Midnight    : { Base: '#34495e',  Dark: '#2c3e50' },
    TrueWhite   : { Base: '#ffffff',  Dark: '#f3f3f3' }
  };

  public AppendColorMap(colorMap: Record<string, Record<string, string>>) {
    Object.assign(this.ColorMap, colorMap);
  }
  public GetColorSet(
    pattern: 'base' | 'dark' | 'gradient' = 'base', rotOffset: number = 0,
    excludedColors: string[] = []
  ): string[] {
    const colorSet: string[] = [];
    const colorGroups: any[] = Object.entries(this.ColorMap).reduce(
      (cg: any[], [key, val]) => {
        if (!excludedColors.includes(key)) {
          cg.push(val);
        }

        return cg;
      }, []
    );

    /* Input Shifted Color Set */
    const cgLength: number = colorGroups.length;

    rotOffset = rotOffset >= 0 ? 
      rotOffset % cgLength : (cgLength - (-rotOffset % cgLength)) % cgLength;

    for (let i: number = 0; i < cgLength; i++) {
      const cg: any = colorGroups[(i + rotOffset) % cgLength];

      if (pattern == 'base') {
        colorSet.push(cg.Base);
      } else if (pattern == 'dark') {
        colorSet.push(cg.Dark);
      } else if (pattern == 'gradient') {
        colorSet.push(cg.Base, cg.Dark);
      }
    }

    return colorSet;
  }
  public GetColorSetByNames(names: string[]) {
    return names.reduce((set: string[], name: string) => {
      set.push(XeoUtilities.DeepObjectAccess(this.ColorMap, name));
      return set;
    }, []);
  }
}
export default class DisplayCodex {
  public WidthVariants = {
    'xs': 576,
    'sm': 768,
    'md': 992,
    'lg': 1200
  };

  public IsDisplayExceed(widthVar: 'xs' | 'sm' | 'md' | 'lg'): boolean {
    return document.documentElement.clientWidth >= this.WidthVariants[widthVar];
  }
  public ScrollToElement(
    elmnt: any, location: 'top' | 'bottom' = 'top'
  ) {
    window.scrollBy({
      top: (elmnt.$el || elmnt).getBoundingClientRect()[location]
        - 54 * (this.IsDisplayExceed('lg') ? .95 : 0.875),
      behavior: 'smooth'
    });
  }
}
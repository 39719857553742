import { Component, Mixins } from 'vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoFormElementMixin from '☆XeoApp/Vue/Mixins/XeoFormElementMixin';
import { StateStore } from '@/Store/—–AppStore–—';
import Simplebar from '☆Node/simplebar';

//∴ *** Notes for XeoModalMixin *** ∴//
//*  1. Nested Modals
//      Attach ToggleChildModal_Act to nested-modals' @toggle-child-modal.
//*  2. setTimeout on `open()` and `close()`
//      Wait for Props to be updated before @show triggered.

@Component
export default class XeoModalMixin extends Mixins(XeoFormElementMixin) {
  //  *** Pseuso-Refs list used in Modals *** //
  //  $refs!: { BarModal };

  protected readonly ModalProps = {
    'hide-header': true,      'hide-footer': true
  };
  protected readonly ModalClass: string = 'element-modal sidenav-content fade-scale';
    
  protected ModalValue: boolean = false;
  protected SimplebarElement!: Simplebar;
  protected get IsShowSideNav(): boolean { return StateStore.SideNavigation; }
  protected get ModalEventListeners() {
    const self = this;

    return {
      hidden() {
        self.$emit('toggle-child-modal', '-');
        self.$emit('modal-hidden');
      },
      hide() {
        self.$emit('modal-hide');
      },
      show() {
        self.$emit('toggle-child-modal', '+');
        self.$emit('modal-show');
      },
      shown() {
        if (self.$refs.BarModal) {
          self.SimplebarElement = new Simplebar((self.$refs as any).BarModal);
        }
        self.$emit('modal-shown');
      }
    };
  }

  public NavShieldCount: number = 0;
  protected NavigationGuardHandler!: Function;
  protected created() {
    this.NavigationGuardHandler = this.$router.beforeEach((to, from, next) => {
      const isDoNavigationGuard: boolean = this.ModalValue 
        && !XeoBibliotheca.DisplayCodex.IsDisplayExceed('lg');

      if (isDoNavigationGuard && this.NavShieldCount == 0) {
        this.ModalValue = false;
        next(false);
      } else {
        next();
      }
    });
  }
  protected destroyed() {
    this.NavigationGuardHandler();
  }
  public open() {
    setTimeout(() => this.ModalValue = true, 1);
  }
  public close() {
    setTimeout(() => this.ModalValue = false, 1);
  }
  
  protected ToggleChildModal_Act(dir: string) {
    this.NavShieldCount += (dir == '+' ? 1 : -1);
    this.$emit('toggle-child-modal', dir);
  } 
  protected CloseModal_Act() {
    this.close();
  }
}

import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import { DataStore } from '@/Store/—–AppStore–—';

@Component({
  name: 'SetupWelcome',
  filters: {
    FirstWord(str: string): string {
      return str ? str.split(' ')[0] : '–';
    }
  }
})
export default class SetupWelcome extends Vue {
  private get Account() { return DataStore.Account; }
  
  protected BtnSetup_Click() {
    this.$router.push({ name: 'Setup_Company' });
  }
}

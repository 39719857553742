import Vue from 'vue';
import { Store } from 'vuex';
import { Module, VuexModule, Mutation, Action } from '☆Node/vuex-class-modules';

import { Account } from '@/Models/AccountModels';
import { Company } from '@/Models/CompanyModels';
import { CompanyCuts } from '@/Models/CompanyCutsModels';
import { Division, Job } from '@/Models/DivisionJobModels';
import { CalendarEvent } from '@/Models/PublicModels';
import { AccessMaps } from '@/Models/RbacModels';
import { Staff } from '@/Models/StaffModels';
import { TmSettings } from '@/Models/TimeManagementModels';
import * as HelperModels from '@/Models/—HelperModels—';
import moment from 'moment';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

//* Notes *//
//  1. Use `🌎 = Object.assign({}, 🌎, ...)` for Vue reactiveness.

@Module
export default class DataModule extends VuexModule {
  constructor(store: Store<any>) {
    super({ name: 'DataModule', store });
  }

  //* Account *//
  public Account: Account = new Account();
  @Mutation
  public AssignAccount(partAcc: Partial<Account>) {
    this.Account = Object.assign({}, this.Account, partAcc);
  }

  //* Access *//
  public AccessMaps: AccessMaps = new AccessMaps();
  @Mutation public AssignAccessMaps(parts: Partial<AccessMaps>) {
    this.AccessMaps = Object.assign({}, this.AccessMaps, this._InitializeAccessStates(parts));
  }

  //* Company *//
  public CompanyList: Record<number, Company> = {};
  public CompanyCutsList: Record<number, CompanyCuts> = {};
  public get CompanyHq() { 
    const headquarterId = this._GetCompanyHqId();

    return {
      Data: this.CompanyList[headquarterId] || new Company(),
      Cuts: this.CompanyCutsList[headquarterId] || new CompanyCuts()
    };
  }

  @Mutation
  public InitializeCompanyList(partCompany: Partial<Company> | Partial<Company>[]) {
    this.CompanyList = this._AssignNormalizedItem({}, partCompany);
  }
  @Mutation
  public AssignCompanyList(partCompany: Partial<Company> | Partial<Company>[]) {
    this.CompanyList = this._AssignNormalizedItem(this.CompanyList, partCompany);
  }
  @Mutation
  public AssignCompanyCutsList(partCompanyCuts: Partial<CompanyCuts> | Partial<CompanyCuts>[]) {
    this.CompanyCutsList = this._AssignNormalizedItem(
      this.CompanyCutsList, partCompanyCuts, 'CompanyBranchId'
    );
  }
  @Mutation
  public DeleteCompanyById(id: number) {
    Vue.delete(this.CompanyList, id);
  }
  @Mutation
  public SetCompanyAc(ac: HelperModels.CompanyAllowanceCut[]) {
    this.CompanyList[this._GetCompanyHqId()].AllowanceCuts = ac;
  }

  private _GetCompanyHqId(): number {
    return Object.values(this.CompanyList)[0]?.HeadquarterId || 0;
  }

  //* Calendar Events *//
  public CalendarEvents: Record<string, CalendarEvent> = {};
  @Mutation
  public InitializeCalendarEvents(rawEvts: any[]) {
    this.CalendarEvents = rawEvts.reduce(
      (ceRec: Record<string, CalendarEvent>, rawEvt: any) => {
        const ce = new CalendarEvent(rawEvt);
        ceRec[XeoBibliotheca.DateTimeCodex.Format(ce.Date, 'Date')] = ce;
        return ceRec;
      }, {}
    );
  }

  //* Constants *//
  public Constants: any = new Object();
  @Mutation
  public AssignConstants(constantsObj: object) {
    this.Constants = Object.assign({}, this.Constants, constantsObj);
  }

  //* Divisions *//
  public Divisions: Record<number, Division> = {};
  @Mutation
  public InitializeDivisions(divisions: Division[]) {
    this.Divisions = this._AssignNormalizedItem({}, divisions);
  }
  @Mutation
  public AssignDivisions(divisions: Division | Division[]) {
    this.Divisions = this._AssignNormalizedItem(this.Divisions, divisions);
  }
  @Mutation
  public DeleteDivisionById(id: number) {
    Vue.delete(this.Divisions, id);
  }

  //* Jobs *//
  public Jobs: Record<number, Job> = {};
  @Mutation
  public InitializeJobs(jobs: Job[]) {
    this.Jobs = this._AssignNormalizedItem({}, jobs);
  }
  @Mutation
  public AssignJobs(jobs: Job | Job[]) {
    this.Jobs = this._AssignNormalizedItem(this.Jobs, jobs);
  }
  @Mutation
  public DeleteJobById(id: number) {
    Vue.delete(this.Jobs, id);
  }

  //* Server *//
  public ServerNow: moment.Moment = moment();
  @Mutation
  public AssignServerNow(st: moment.Moment) {
    this.ServerNow = st;
  }

  //* Staffs *//
  public Staffs: Record<number, Staff> = {};
  @Mutation
  public InitializeStaffs(staffs: Staff[]) {
    this.Staffs = this._AssignNormalizedItem(
      {}, staffs.map((staff: Staff) => new Staff(staff)), 'AccountId'
    );
  }
  @Mutation
  public AssignStaffs(staffs: Staff | Staff[]) {
    this.Staffs = this._AssignNormalizedItem(
      this.Staffs, Array.isArray(staffs) ?
      staffs.map((staff: Staff) => new Staff(staff)) :
      new Staff(staffs),
      'AccountId'
    );
  }
  @Mutation
  public DeleteStaffByUserMapId(userMapId: number) {
    Vue.delete(
      this.Staffs,
      Object.values(this.Staffs).find(
        (staff: Staff) => staff.UserMapId == userMapId
      )?.AccountId || -1
    );
  }
  
  //* Time Management *//
  public TmSettings: TmSettings = new TmSettings();
  @Mutation
  public AssignTmSettings(tmSett: TmSettings) {
    this.TmSettings = new TmSettings(tmSett);
  }
  
  //* 2nd Order — Helper Functions *//
  private _AssignNormalizedItem(
    src: Record<number, any>, ext: any, keyName: string = 'Id'
  ): Record<number, any> {
    if (ext) {
      const normSrc: any = XeoBibliotheca.UtilCodex.DeepClone(src);

      if (Array.isArray(ext)) {
        ext.forEach((extItem: any) => {
          const key = extItem[keyName];
          normSrc[key] = Object.assign({}, normSrc[key], extItem); 
        });
      } else {
        const key = ext[keyName];
        normSrc[key] = Object.assign({}, normSrc[key], ext); 
      }

      return normSrc;
    } else {
      return src;
    }
  }
  private _GetDivJobCompValue(type: 'd' | 'j', a: any, b: any): number {
    const nameField = type == 'd' ? 'Division' : 'Job';

    return this.__PriorityCompValue(a, b, 'CreatedBy', 'Id') ||
      a[`${nameField}Name`].localeCompare(b[`${nameField}Name`]);
  }
  private _InitializeAccessStates(parts: Partial<AccessMaps>): Partial<AccessMaps> {
    Object.entries(parts.Client || {}).forEach(([id, state]) => {
      if (state == 0 || !id.includes('.'))     return;

      id.split('.').reduce((parentId: string, idPart: string) => {
        parentId += (parentId != '' ? '.' : '') + idPart;
        parts.Client![parentId] = Math.max(parts.Client![parentId] || 0, 1) as any;
        return parentId;
      }, '');
    });

    return parts;
  }

  private __PriorityCompValue(
    a: any, b: any, p1Field: string, p2Field: string
  ): number {
    return (a[p1Field] == 1 && b[p1Field] == 1) ? a[p2Field] - b[p2Field] :
      a[p1Field] == 1 ? -1 :
      b[p1Field] == 1 ?  1 :
      0;
  }
}
import * as BaseModels from '@/Models/—BaseModels—';
import * as HelperModels from '@/Models/—HelperModels—';
import moment from 'moment';

export class CompanyCuts extends BaseModels.BaseCompanyCuts {
  constructor(init?: Partial<CompanyCuts>) {
    super();
    Object.assign(this, init);
  }
}
export class UpdateCompanyAllowancesRequest {
  public AllowanceCuts: HelperModels.CompanyAllowanceCut[] = [];

  constructor(init?: Partial<UpdateCompanyAllowancesRequest>) {
    Object.assign(this, init);
  }
}

export class PaymentTime {
  public Name: string = '';
  public DueDate!: moment.Moment;
  public TimeLeft!: moment.Duration;

  constructor(init?: Partial<PaymentTime>) {
    Object.assign(this, init);
  }
}
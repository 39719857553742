import { VNodeData } from 'vue';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

import moment from 'moment';
import { jsPDF as JsPdf, jsPDFOptions, ImageCompression, TextOptionsLight } from 'jspdf';

//* XeoBibliotheca Models *//
export class XeoError {
  public Code!: string
  public Extensions: any;
  public Message!: string;
  public Stacks!: Array<string>;

  constructor(init?: Partial<XeoError>) {
    Object.assign(this, init);
  }
}
export class XeoResponse<T> {
  public Data!: T;
  public Errors!: Array<XeoError>;

  constructor(init?: Partial<XeoResponse<T>>) {
    Object.assign(this, init);
  }
}
export class XeoPromise extends Promise<any> {
  
  constructor(p: Promise<any> | Function) {
    const isPromise = (typeof p === 'object') && (typeof p?.then === 'function');
    super(
      isPromise ? 
        (rsv, rej) => { (p as Promise<any>).then(data => rsv(data)).catch(err => rej(err)); } :
        (p as any)
    );
  }

  public xeoThen(
    dataHandler: (data: any) => any | XeoPromise,
    errorHandler: (err: Array<XeoError>) => any | XeoPromise = () => {}
  ): XeoPromise {
    return new XeoPromise( 
      this.then((resp) => {
        const axiosData = resp?.data;
        if (axiosData) {
          return axiosData.Errors ? errorHandler(axiosData.Errors) : dataHandler(axiosData.Data);
        }
      })
    );
  }
}

//* XeoPdf – Extended JsPdf *//
export class XeoPdf extends JsPdf {
  private saveName!: string;
  private _asyncI!: number;

  constructor(opts?: jsPDFOptions | undefined) {
    super(opts);
    this._asyncI = 0;

    this.addImageByUrl = (
      url: string, format: string, 
      x: number, y: number, w: number | 'auto', h: number | 'auto',
      opts?: PdfImageOptions
    ) => {
      const self = this;
      const img = new Image();
      const pageA: number = this.getCurrentPageInfo().pageNumber;

      this._asyncI++;
      img.addEventListener('load', function() {
        /* Set Page on Trigger */
        const pageB: number = self.getCurrentPageInfo().pageNumber;
        self.setPage(pageA);

        /* Adjust Image Size Proportion */
        if (typeof w == 'string' && typeof h == 'string') {
          w = this.naturalWidth;      h = this.naturalHeight;
        } else if (typeof w == 'string') {
          w = this.naturalWidth / this.naturalHeight * (h as number);
        } else if (typeof h == 'string') {
          h = this.naturalHeight / this.naturalWidth * (w as number);
        }

        /* Image Alignment */
        if (opts?.alignment) {
          const alignmentParts: any[] = opts.alignment.split('-');
          const multRec: Record<string, number> = { 
            'left': 0,  'center': .5,   'right' : 1,
            'top' : 0,  'middle': .5,   'bottom': 1 
          };

          x = x - (w as number) * multRec[alignmentParts[0]];
          y = y - (h as number) * multRec[alignmentParts[1]];
        }

        self.addImage(
          img, format, x, y, w as number, h as number, 
          opts?.alias, opts?.compression, opts?.rotation
        );

        self.setPage(pageB);
        self._asyncI--;   self._asyncSave();
      });
      img.addEventListener('error', function() {
        self._asyncI--;   self._asyncSave();
      });

      img.src = url;
    };
    this.drawShape = (
      shape: 'circle' | 'ellipse' | 'rect' | 'triangle', 
      params: any, opts?: PdfShapeOptions
    ) => {
      const p = params;
      const style = opts?.style || 'S';
      const initOpts = {
        'drawColor': this.getDrawColor(),
        'fillColor': this.getFillColor()
      };

      /* Execute One-Time Shape Formatting */
      if (opts) {
        if (opts.drawColor)     this.setDrawColor(opts.drawColor);
        if (opts.fillColor)     this.setFillColor(opts.fillColor);
      }

      switch (shape) {
        case 'circle':    this.circle(p.x, p.y, p.r, style); break;
        case 'ellipse':   this.ellipse(p.x, p.y, p.rx, p.ry, style); break;
        case 'rect':      this.rect(p.x, p.y, p.w, p.h, style); break;
        case 'triangle':  this.triangle(p.x1, p.y1, p.x2, p.y2, p.x3, p.y3, style); break;
      }

      if (opts) {
        this.setDrawColor(initOpts.drawColor);
        this.setFillColor(initOpts.fillColor);
      }
    };
    this.saveAs = (fn: string) => {
      this.saveName = fn;
      this._asyncSave();
    };
    this.write = (
      text: string, x: number, y: number, txtOpts?: PdfTextOptions
    ) => {
      if (text) {
        const initOpts = {
          'color': this.getTextColor(),
          'fontFamily': this.getFont().fontName,
          'fontType': this.getFont().fontStyle,
          'fontSize': this.getFontSize()
        };

        /* Execute One-Time Text Formatting */
        if (txtOpts) {
          if (txtOpts.alignment) {
            const alignmentParts: any[] = txtOpts.alignment.split('-');
            txtOpts.align = alignmentParts[0];
            txtOpts.baseline = alignmentParts[1];
          }
          if (txtOpts.fontFamily || txtOpts.fontType) {
            this.setFont(
              txtOpts.fontFamily || initOpts.fontFamily, 
              txtOpts.fontType || initOpts.fontType
            );
          }
          if (txtOpts.fontSize)    this.setFontSize(txtOpts.fontSize);
          if (txtOpts.color)       this.setTextColor(txtOpts.color);
        }

        this.text(text, x, y, txtOpts);

        if (txtOpts) {
          this.setFont(initOpts.fontFamily, initOpts.fontType);
          this.setFontSize(initOpts.fontSize);
          this.setTextColor(initOpts.color);
        }
      }
    };
    this.writef = (
      input: string | number | moment.Moment, 
      format: string, x: number, y: number, 
      txtOpts?: PdfTextOptions
    ) => {
      const inputText: string = 
        typeof input == 'string' ? input :
        typeof input == 'number' ? XeoBibliotheca.NumberCodex.Parse(input).format(format) :
        moment.isMoment(input) ? (input as moment.Moment).format(format) :
        '';
      
      this.write(inputText, x, y, txtOpts);
    };

    this._asyncSave = () => {
      if (this._asyncI <= 0) {
        this.save(this.saveName);
      }
    };
  }

  public addImageByUrl(
    url: string, format: string, 
    x: number, y: number, w: number | 'auto', h: number | 'auto',
    opts?: PdfImageOptions
  ) {}
  public drawShape(
    shape: 'circle' | 'ellipse' | 'rect' | 'triangle', 
    params: any, opts?: PdfShapeOptions
  ) {}
  public saveAs(fn: string) {}
  public write(
    text: string, x: number, y: number, txtOpts?: PdfTextOptions
  ) {}
  public writef(
    input: string | number | moment.Moment, 
    format: string, x: number, y: number, txtOpts?: PdfTextOptions
  ) {}

  private _asyncSave() {}
}

//* Validation Models *//
export class ValidationResult {
  public Status: boolean = false;
  public Data: ValidationResultData[] = [];

  constructor(init?: Partial<ValidationResult>) {
    Object.assign(this, init);
  }
}
export class ValidationResultData {
  public Name: string = '';
  public Status: boolean = false;
  public Message: string = '';

  constructor(init?: Partial<ValidationResultData>) {
    Object.assign(this, init);
  }
}

//* Component Models *//
export type VueElement = [
  string, VNodeData | undefined, VueElement | VueElement[] | undefined  
];
export class DateEnabler {
  public AvailableDates!: moment.Moment[][];
  public DisabledDates: moment.Moment[][] = [];
  public DisabledDays: number[] = [];

  public get MergedDisabledDates(): moment.Moment[][] {
    const disabledDates: moment.Moment[][] = 
      XeoBibliotheca.UtilCodex.DeepClone(this.DisabledDates);

    /* Inverse-Merge Available Dates to Disabled Dates */
    if (this.AvailableDates) {
      let tempRangeDate: moment.Moment[] = [];

      this.AvailableDates.sort((a, b: moment.Moment[]) => a[0].diff(b[0]))
        .forEach((avDate: moment.Moment[], i: number) => {
          if (i == 0) {
            if (avDate[0].isSame(XeoBibliotheca.DateTimeCodex.MIN_DATETIME)) {
              tempRangeDate.push(avDate[1].add(1, 'day'));
              return;
            } else {
              tempRangeDate.push(XeoBibliotheca.DateTimeCodex.MIN_DATETIME);
            }
          }

          tempRangeDate.push(avDate[0].add(-1, 'day'));
          disabledDates.push(tempRangeDate);
          tempRangeDate = [];

          tempRangeDate.push(avDate[1].add(1, 'day'));
        });

      if (tempRangeDate[0].isBefore(XeoBibliotheca.DateTimeCodex.MAX_DATETIME)) {
        tempRangeDate.push(XeoBibliotheca.DateTimeCodex.MAX_DATETIME);
        disabledDates.push(tempRangeDate);
      }
    }

    /* Flatten Disabled Dates */
    if (disabledDates && disabledDates.length > 1) {
      disabledDates.sort((a, b: moment.Moment[]) => a[0].diff(b[0]));
    
      for(let i = 0; i < disabledDates.length - 1; i++) {
        if (disabledDates[i + 1][0].diff(disabledDates[i][1], 'day') <= 1) {
          disabledDates[i][1] = moment.max(
            disabledDates[i][1], disabledDates[i + 1][1]
          );
          disabledDates.splice(i + 1, 1);
          i--;
        }
      } 
    }

    return disabledDates;
  } 

  constructor(init?: Partial<DateEnabler>) {
    Object.assign(this, init);
  }

  public IsDateDisabled(date: moment.Moment): boolean {
    return this.MergedDisabledDates?.some(
      (rangeDate: moment.Moment[]) => date.isBetween(rangeDate[0], rangeDate[1], 'day', '[]')
    ) || this.DisabledDays?.includes(date.day());
  }
  public IsRangeDateDisabled(dtRange: moment.Moment[]): boolean {
    return this.MergedDisabledDates?.some((rangeDate: moment.Moment[]) => {
      return XeoBibliotheca.DateTimeCodex.IsAllBetween(
        dtRange, rangeDate[0], rangeDate[1], 'day', '[]'
      );
    });
  }
}
export class IndexEnabler {
  public AvailableIndexes!: number[];
  public DisabledIndexes: number[] = [];

  constructor(init?: Partial<IndexEnabler>) {
    Object.assign(this, init);
  }

  public IsIndexDisabled(idx: number): boolean {
    return (this.AvailableIndexes && !this.AvailableIndexes.includes(idx)) || 
      this.DisabledIndexes.includes(idx);
  }
}
export class Pagination {
  public Page: number = 0;
  public PageSize: number = 0;
  public Limit: number = 0;
  public Offset: number = 0;

  constructor(init?: Partial<Pagination>) {
    Object.assign(this, init);
  }
}

//* 2nd Order — Helper Models & Interfaces *//
export interface PdfImageOptions {
  alias?: string;
  alignment?: 
    'left-bottom' | 'center-bottom' | 'right-bottom' |
    'left-top' | 'center-top' | 'right-top' |
    'left-middle' | 'center-middle' | 'right-middle';
  compression?: ImageCompression;
  rotation?: number; 
}
export interface PdfTextOptions extends TextOptionsLight {
  alignment?: 
    'left-alphabetic' | 'center-alphabetic' | 'right-alphabetic' | 'justify-alphabetic' |
    'left-ideographic' | 'center-ideographic' | 'right-ideographic' | 'justify-ideographic' |
    'left-bottom' | 'center-bottom' | 'right-bottom' | 'justify-bottom' | 
    'left-top' | 'center-top' | 'right-top' | 'justify-top' | 
    'left-middle' | 'center-middle' | 'right-middle' | 'justify-middle' | 
    'left-hanging' | 'center-hanging' | 'right-hanging' | 'justify-hanging';
  color?: string;
  fontFamily?: string;
  fontType?: string;
  fontSize?: number;
}
export interface PdfShapeOptions {
  drawColor?: string;
  fillColor?: string;
  style?: 'F' | 'S' | 'FD';
}

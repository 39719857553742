import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import { ApexOptions } from 'apexcharts';

export default new class ChartUtils {
  public BaseColorSet = XeoBibliotheca.ColorCodex.GetColorSet(
    'base', 3, [ 'White', 'Midnight', 'TrueWhite' ]
  );

  public GetCircColorByIdx(idx: number) {
    return this.BaseColorSet[idx % this.BaseColorSet.length];
  }
  public NewOptions(appendOpts: Partial<ApexOptions>): ApexOptions {
    return Object.assign({
      chart: {
        toolbar: { show: false }
      },
      dataLabels: { enabled: false },
      states: {
        hover: {
          filter: { 
            type: 'lighten',
            value: -0.2
          }
        },
        active: {
          filter: { 
            type: 'lighten',
            value: -0.11
          }
        }
      }
    }, appendOpts);
  }
}
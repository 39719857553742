import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import moment from 'moment';
import { PartialDeep } from 'type-fest/source/partial-deep';

import * as BaseModels from '@/Models/—BaseModels—';
import ModelUtils from '@/Models/—ModelUtils—';

//* Helper Types *//
export type AccessState = 0 | 1 | 100;
  //* Access State *//
  //  0: No Access
  //  1: Read-Only
  //  100: Full Access
export type ApprovalState = -1 | 1 | 100;
  //* Approval State *//
  //  -1: Rejected
  //  1: Draft
  //  100: Approved
export type CalculationGroup = 0 | 1 | 2 | 3;
  //* Calculation Groups – For Payslip Grouping *//
  //  0: Main / Routine Payments
  //  1: Additional / One-Time Payments
  //  2: Taxes
  //  3: Social Securities
export type PaidTo = 0 | 1;
  //* Paid To *//
  //  0: Staff
  //  1: Goverment
export type SsBaseCalculation = 1 | 2 | 3 | 90;
  //* Social Security – Base Calculations *//
  //  1 : Default
  //  2 : Salary & Allowances
  //  3 : Base Salary
  //  90: Custom Value
export type TaxCategory = 1 | 2 | 3 | 4 | 5 | 6 | 7 | -1 | 11;
  //* Tax Categories *//
  //  1 : Salary
  //  2 : TaxAccomodation
  //  3 : Overtimes
  //  4 : Honoraries
  //  5 : InsurancePremiums
  //  6 : Non-Cash
  //  7 : Bonuses
  //  -1: Untaxed
  //  11: PensionFunds 

//* Base Helper Models *//
export class BaseAllowanceCut {
  public Id: number = 0;
  public Name: string = '';
  public Value: number = 0;
  public PeriodType: number = 1;
  public Description: string = '';
  public TaxCategory: TaxCategory = 1;
  public IsHiddenOnPayslip: boolean = false

  constructor(init?: Partial<BaseAllowanceCut>) {
    Object.assign(this, init);
  }
}
export class SsEmploymentCustomPayers {
  public Accident: number = 1;
  public OldAge: number = 1;
  public Pension: number = 1;
  public Death: number = 1;

  constructor(init?: Partial<SsEmploymentCustomPayers>) {
    Object.assign(this, init);
  }
}

//* Main Helper Models *//
export class AllowanceCut extends BaseAllowanceCut {
  constructor(init?: Partial<AllowanceCut>) {
    super();
    Object.assign(this, init);
  }
}
export class CompanyAllowanceCut extends BaseAllowanceCut {
  public CompanyBranchId: number = 0;
  public DivisionId: number = 0;
  public JobId: number = 0;

  constructor(init?: Partial<CompanyAllowanceCut>) {
    super();
    Object.assign(this, init);
  }
}
export class Credentials {
  public Address: string = '';
  public BankAccount: BankAccount = new BankAccount();
  public EmailAddress: string = '';
  public PersonalId: PersonalId = new PersonalId();
  public PhoneNumber: string = '';
  public SsHealthcare: SsCredential = new SsCredential();
  public SsEmployment: SsCredential = new SsCredential();
  public Tax: string = '';

  constructor(init?: Partial<Credentials>) {
    Object.assign(this, init);
  }
}
export class OneTimePaymentItem {
  public AccountId: number = 0;
  public EmployeeId!: string;
  public Name!: string;
  public Company!: string;
  public Division!: string;
  public Job!: string;
  public Value: number = 0;
  public TaxCategory: TaxCategory = 7;
  public Description: string = '';

  constructor(init?: Partial<OneTimePaymentItem>) {
    Object.assign(this, init);
  }
}
export class PayrollSummaryData {
  public StaffPayslips: StaffPayslip[] = [];
  public SourceData: PsSourceData = new PsSourceData();
  public SourceTimestamp: moment.Moment = moment();
  
  constructor(init?: PartialDeep<PayrollSummaryData>) {
    Object.assign(this, init);
    Object.assign(this, { 
      StaffPayslips: this.StaffPayslips.map((sp) => new StaffPayslip(sp)),
      SourceData: new PsSourceData(this.SourceData)
    }); 

    ModelUtils.InitializeMoments(this, ['SourceTimestamp']);
  }
}
export class SocialSecurity {
  public AccidentCutGrades: number[] = [];
  public Cuts!: SocialSecurityCut[];

  constructor(init?: Partial<SocialSecurity>) {
    Object.assign(this, init);
  }
}
export class SsCustomPayers extends SsEmploymentCustomPayers {
  public Healthcare!: number;

  constructor(init?: Partial<SsCustomPayers>) {
    super();
    Object.assign(this, init);
  }
}
export class SsConfigurations {
  public SsHealthcare: SsHealthcareConfiguration = new SsHealthcareConfiguration();
  public SsEmployment: SsEmploymentConfiguration = new SsEmploymentConfiguration();

  constructor(init?: Partial<SsConfigurations>) {
    Object.assign(this, init);
    
  }
}
export class Tax {
  public Layers!: TaxLayer[];
  public UntaxedIncomes!: TaxUntaxedIncome[];
  public GrossUpAlwLayers!: TaxGrossUpAlwLayer[];

  constructor(init?: Partial<Tax>) {
    Object.assign(this, init);
  }
}

//* 2nd Order — Helper Models *//
export class BankAccount {
  public BankId: number = 0;
  public AccountNumber: string = '';
  public HolderName: string = '';

  constructor(init?: Partial<BankAccount>) {
    Object.assign(this, init);
  }
}
export class PayslipItem {
  public Id: number = 0;
  public Name!: string;
  public Value: number = 0;
  public IsOtpItem: boolean = false;
  public TaxCategory: TaxCategory = 1;
  public CalculationGroup: CalculationGroup = 0;
  public ExtensionData!: Partial<PayslipItemExtData>;

  constructor(init?: Partial<PayslipItem>) {
    Object.assign(this, init);
  }
}
export class PayslipItemExtData {
  public NameDesc!: string;
  public IsHiddenOnPayslip!: boolean;

  constructor(init?: Partial<PayslipItemExtData>) {
    Object.assign(this, init);
  }
} 
export class PayslipExtData {
  public MonthPeriodRange!: number[];
  public PrevNetIncome: number = 0;
  public PrevTaxableIncome: number = 0;
  public PrevPaidTax: number = 0;

  public Address!: string;
  public BirthDate!: moment.Moment;
  public Gender!: number;
  public JoinDate!: moment.Moment;
  public PersonalId!: string;

  public LastTaxPaymentDate!: moment.Moment;
  public InitialNetIncome: number = 0;
  public IsOvertimeEligible: boolean = true;
  public IsNoAttendanceCut: boolean = false;
  public PrepaidTax: number = 0;
  public SsConfigurations!: SsConfigurations;
  public SsAdditionalDependents!: number;
  public TaxCutMethod!: number;
  public TaxCredential!: string;
  public TaxMaritalStatus!: number;
  public TaxTotalDependents!: number;
  public TaxEmploymentStatus!: number;
  public IsAnnualPayment!: boolean;
  public IsMultipleEmployers!: boolean;
  public OriginCountryCode!: string;
  public IsMergeUntaxedIncome!: boolean;
  public BankAccount!: BankAccount;

  constructor(init?: Partial<PayslipExtData>) {
    Object.assign(this, init);
    ModelUtils.InitializeMoments(this, ['LastTaxPaymentDate', 'BirthDate', 'JoinDate']);
  }
}
export class PayslipSummary {
  /* Summary Items */
  public SsEmploymentSum: number = 0;
  public SsHealthcareSum: number = 0;
  public StaffSum: number = 0;
  public TaxSum: number = 0;

  /* Monthly Tax-Related Items */
  public TaxReceiptId!: number;
  public BrutoIncome: number = 0;
  public JobFee: number = 0;
  public NettIncome: number = 0;
  public TaxableIncome: number = 0;
  public LastTaxPercentage: number = 0;

  /* Yearly Tax-Related Items – For Annual-Payment */
  public YearlyNettIncome: number = 0;
	public YearlyTaxValue: number = 0;
  public YearlyTaxableIncome: number = 0;
  public YearlyUntaxedIncome: number = 0;
  public YearlyFormItemRec: Record<number, number> = {};

  constructor(init?: Partial<PayslipSummary>) {
    Object.assign(this, init);
  }
}
export class PersonalId {
  public Type: 1 | 2 = 1;     /* 1: KTP | 2: Paspor */
  public Id: string = '';

  constructor(init?: Partial<PersonalId>) {
    Object.assign(this, init);
  }
}
export class PsSourceData {
  public CompanyList: Record<number, BaseModels.BaseCompany> = {};
  public CompanyCutsList: Record<number, BaseModels.BaseCompanyCuts> = {};
  public get CompanyAllowanceCuts(): CompanyAllowanceCut[] {
    return this.CompanyHq.Cuts.AllowanceCuts;
  }
  public get CompanyHq() { 
    const headquarterId = Object.values(this.CompanyList)[0]?.HeadquarterId || 0;

    return {
      Data: this.CompanyList[headquarterId] || new BaseModels.BaseCompany(),
      Cuts: this.CompanyCutsList[headquarterId] || new BaseModels.BaseCompanyCuts()
    };
  }

  constructor(init?: Partial<PsSourceData>) {
    Object.assign(this, init);
  }

  public GetCompanyCuts(branchId: number): BaseModels.BaseCompanyCuts {
    return this.CompanyCutsList[branchId] || this.CompanyHq.Cuts;
  }
}
export class SocialSecurityCut {
  public Name: string = '';
  public Percentage: SocialSecurityCutPercentage = new SocialSecurityCutPercentage();
  public MaxBase: number = Number.MAX_SAFE_INTEGER;

  constructor(init?: Partial<SocialSecurityCut>) {
    Object.assign(this, init);
  }
}
export class SocialSecurityCutPercentage {
  public Company: number = 0;
  public Individual: number = 0;

  constructor(init?: Partial<SocialSecurityCutPercentage>) {
    Object.assign(this, init);
  }
}
export class SsCredential {
  public Id: string = '';
  public IssuedDate!: string;

  constructor(init?: Partial<SsCredential>) {
    Object.assign(this, init);
  }
}
export class SsEmploymentConfiguration {
  public PaidBy: number = 1;
  public CustomPaidBys: SsEmploymentCustomPayers = new SsEmploymentCustomPayers();
  public BaseCalculationType: SsBaseCalculation = 1;
  public CustomBcValue: number = 0;

  constructor(init?: Partial<SsEmploymentConfiguration>) {
    Object.assign(this, init);
  }
}
export class SsHealthcareConfiguration {
  public PaidBy: number = 1;
  public BaseCalculationType: SsBaseCalculation = 1;
  public CustomBcValue: number = 0;

  constructor(init?: Partial<SsHealthcareConfiguration>) {
    Object.assign(this, init);
  }
}
export class StaffPayslip {
  public AccountId!: number;
  public CompanyBranchId!: number
  public EmployeeId!: string;
  public Name!: string;
  public Company!: string;
  public Division!: string;
  public Job!: string;
  public PayslipSummary: PayslipSummary = new PayslipSummary();
  public StaffItems: PayslipItem[] = [];
  public GovermentItems: PayslipItem[] =  [];
  public ExtensionData: PayslipExtData = new PayslipExtData();

  constructor(init?: Partial<StaffPayslip>) {
    Object.assign(this, init);
    this.PayslipSummary = new PayslipSummary(this.PayslipSummary);
    this.ExtensionData = new PayslipExtData(this.ExtensionData);
  }
}
export class TaxGrossUpAlwLayer {
  public TopValue: number = 0;
  public Divisor: number = 0;

  constructor(init?: Partial<TaxLayer>) {
    Object.assign(this, init);
  }
}
export class TaxLayer {
  public Chunk: number = 0;
  public Percentage: number = 0;

  constructor(init?: Partial<TaxLayer>) {
    Object.assign(this, init);
  }
}
export class TaxUntaxedIncome {
  public Name: string = '';
  public Value: number = 0;
  public Percentage: number = 0;
  public MaxValue: number = 0;

  constructor(init?: Partial<TaxUntaxedIncome>) {
    Object.assign(this, init);
  }
}
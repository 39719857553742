
import { Vue, Component, Prop } from '☆Node/vue-property-decorator';
import { Company } from '@/Models/CompanyModels';
import { DataStore } from '@/Store/—–AppStore–—';

import XeoDropdown from '☆XeoApp/Vue/Components/Base/XeoDropdown.vue';

@Component({
  name: 'CompanySelector'
})
export default class CompanySelector extends Vue {
  $refs!: {
    DdlCompany: XeoDropdown;
  };

  @Prop(Number) readonly value!: number;
  @Prop(String) readonly type!: 'sidenav' | 'dropdown' | 'dropdown-sign';
  @Prop(Boolean) readonly creatable!: boolean;
  @Prop(Boolean) readonly isLoading!: boolean;
  private get SortedCompanyList(): Company[] {
    const companyList = Object.values(DataStore.CompanyList);
    const sortedCompanyList = companyList.length > 0 ? [
      companyList.shift()!,
      ...companyList.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName))
    ] : [];

    if (this.creatable)   sortedCompanyList.push(new Company({ Id: -1 }));

    return sortedCompanyList;
  }
  private get TypeClass(): string {
    return this.type.split('-')[1] || '';
  }

  public ValidateValue(): boolean {
    return this.$refs.DdlCompany?.ValidateValue() ?? true;
  }

  protected MiCompany_Click(id: number) {
    this.$emit('input', id);
    this.$emit('item-click', id);
  }
}

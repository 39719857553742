
import { Vue, Component, Prop } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import { StateStore } from '@/Store/—–AppStore–—';

@Component({
  name: 'XeoSideNavigation'
})
export default class XeoSideNavigation extends Vue {
  @Prop(Boolean) value!: boolean;
  private get MenuStyle() { 
    return {
      height: StateStore.Window.InnerHeight + 'px'
    };
  }

  protected Backdrop_Click() {
    this.$emit('input', false);
  }
}

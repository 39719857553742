import { Vue, Component, Mixins, Prop } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import { ValidationResultData, ValidationResult } from '☆XeoApp/Typescript/XeoTypes';
import XeoFeStateMixin from '☆XeoApp/Vue/Mixins/XeoFeStateMixin';
import _Debounce from '☆Node/lodash.debounce';
import _Throttle from '☆Node/lodash.throttle';

@Component
export default class XeoFormElementMixin extends Mixins(XeoFeStateMixin) {
  @Prop() value!: any;
  @Prop(String) readonly label!: string;
  @Prop([Boolean, Number]) readonly lazyInput!: boolean;
  @Prop(String) readonly validatorQuery!: string;

  protected Value: any = null;
  protected IsEmittingInput: boolean = false;
  protected IsValid: boolean = true;
  protected IsValidating: boolean = false;
  protected RootElement: any = null;
  protected ValidationResultData: ValidationResultData[] = [];
  protected get IsRequired(): boolean {
    return (this.validatorQuery || '').includes('required');
  }

  public focus()    { this.RootElement?.focus?.(); }
  public select()   { this.RootElement?.select?.(); }

  public AddValidationResultData(...vrd: ValidationResultData[]) {
    vrd.forEach((v) => {
      this.ValidationResultData.push(v);
      this.IsValid = this.IsValid && v.Status;
    });
  }
  public DoDebEmitInput(value: any) {
    this.IsEmittingInput = true;
    this.DebEmitInput(value);
  }
  public DoDebValidateValue() {
    this.DebResetValidation();
    
    if (this.validatorQuery) {
      this.IsValidating = true;
      this.DebValidateValue();
    }
  }
  public ResetValidation() {
    this.IsValid = true;
    this.ValidationResultData = [];
  }
  public ValidateValue(isSilentValidation: boolean = false): boolean {   
    const validationResult: ValidationResult = XeoBibliotheca.ValidatorCodex.ValidateValue(
      this.value ?? this.Value, this.validatorQuery, this._GetValidationTemplate()
    );

    if (!isSilentValidation) {
      this.IsValid = validationResult.Status;
      this.ValidationResultData = validationResult.Data;
    }

    return validationResult.Status;
  }
  
  private DebEmitInput = _Debounce(
    function(this: XeoFormElementMixin, value: any) {
      this.$emit('input', value);
      this.IsEmittingInput = false;
    }, 500
  );
  private DebResetValidation = _Debounce(
    function(this: XeoFormElementMixin) {
      this.ResetValidation();
    }, 750, { leading: true, trailing: false }
  );
  private DebValidateValue = _Debounce(
    function(this: XeoFormElementMixin) {
      this.ValidateValue();
      this.IsValidating = false;
      this.$emit('after-validate');
    }, 750
  );
  private _GetValidationTemplate(): Map<string, string> {
    const value = this.value ?? this.Value,
          type: string = (value instanceof File) ? 'file' : typeof value;

    switch (type) {
      case 'file':
        return new Map<string, string>([
          ['accepts', 'Tipe file yang diperbolehkan: {value}.'],
          ['max', 'Ukuran file lebih besar dari {value}.'],
          ['required', 'Tidak boleh kosong.']
        ]);
      case 'number':
        return new Map<string, string>([
          ['between', 'Harus bernilai diantara {between-value-min}-{between-value-max}.'],
          ['min', 'Tidak boleh bernilai kurang dari {value}.'],
          ['max', 'Tidak boleh bernilai lebih dari {value}.'],
          ['required', 'Tidak boleh kosong.'],
          ['eq', 'Nilai berbeda dari {value}.']
        ]);
      default:
        return new Map<string, string>([
          ['between', 'Harus berisi sebanyak {between-value-min}-{between-value-max} karakter.'],
          ['min', 'Harus berisi setidaknya {value} karakter.'],
          ['max', 'Harus berisi kurang dari {value} karakter.'],
          ['required', 'Tidak boleh kosong.'],
          ['email', 'Tidak sesuai dengan format Email.'],
          ['excludesall', 'Tidak dapat berisi karakter berikut: `{value}`'],
          ['eq', 'Nilai berbeda dari {value}.']
        ]);
    }
  } 
}

import { Vue, Component, Prop, Mixins } from '☆Node/vue-property-decorator';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import { DataStore } from '@/Store/—–AppStore–—';
import { RouteConfig } from 'vue-router';

@Component({
  name: 'AppMenuList'
})
export default class AppMenuList extends Mixins(AppAccessMixin) {
  @Prop() routes: any;
  @Prop({ default: 0 }) depth!: number;
  @Prop({ default: 0 }) routeDepth!: number;      /* Depth from Root-Routes to this `routes` */
  @Prop({ default: true }) isRoot!: boolean;      /* Recursion Root flag */
  private get Account() { return DataStore.Account };

  protected BtnMenuItem_Click(route: any) {
    if (Boolean(route.name)) {
      this._CloseUnnecessaryMcOnRoot();
      this.$emit('navigate', route);
    }
  }
  protected BtnMenuItemChild_Navigate(route: any) {
    this._CloseUnnecessaryMcOnRoot();
    this.$emit('navigate', route);
  }

  private _CloseUnnecessaryMcOnRoot() {
    if (this.isRoot) {
      Object.values(this.$refs).forEach((ref: any) => {
        if (ref[0] && !ref[0].isActive) {
          ref[0].SetIsCollapse(false);
        }
      });
    }
  }
  private _GenerateRefNameByRoute(route: any): string {
    return `Mi☆${this._GetRouteTitle(route)}`;
  }
  private _GetRouteTitle(route: any): string {
    return route.name || route.meta?.title;
  }
  private _GetRouteMeta(route: any, propName: string): any {
    return route.meta?.[propName];
  }
  private _IsDisplayRoute(route: any): boolean {
    return route.redirect == null && this.IsRouteAccessValid(route);
  }
  private _IsOnThisRoute(route: any): boolean {
    return this.$route.matched[this.routeDepth] &&
      this._GetRouteTitle(this.$route.matched[this.routeDepth]) == this._GetRouteTitle(route);
  }
  private _IsStepCompleted(route: RouteConfig): Boolean {
    return route.meta?.setupStep < this.Account.SetupStep;
  }
  private _IsStepLocked(route: RouteConfig): Boolean {
    return route.meta?.setupStep > this.Account.SetupStep;
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import { DataStore } from '@/Store/—–AppStore–—';
import AppAccessMixin from '@/Mixins/AppAccessMixin';

import TmAttendanceModule from '@/Components/TimeManagement/TmAttendanceModule.vue';

@Component({
  name: 'TmAttendance',
  components: { TmAttendanceModule }
})
export default class TmAttendance extends Mixins(AppAccessMixin) {
  @Prop() readonly id!: number;
  private get AccountIdByAccess(): number {
    return this.GetUserAccessState('Client', 'Tm_Attendance') == 0 ?
      DataStore.Account.Id : 0;
  }
}

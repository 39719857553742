
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppConstantsMixin from '@/Mixins/AppConstantsMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import TmTimeoffQueries from '@/Repositories/Graphql/TmTimeoffQueries';

import * as HelperModels from '@/Models/—HelperModels—';
import { Timeoff } from '@/Models/TimeManagementModels';

import XeoDropdown from '☆XeoApp/Vue/Components/Base/XeoDropdown.vue';
import XeoDateTimePicker from '☆XeoApp/Vue/Components/Base/XeoDateTimePicker.vue';
import XeoTextArea from '☆XeoApp/Vue/Components/Base/XeoTextArea.vue';

@Component({
  name: 'TimeoffEditorModal'
})
export default class TimeoffEditorModal extends Mixins(
  XeoBaseMixin, XeoFormMixin, XeoModalMixin, 
  AppAccessMixin, AppConstantsMixin, AppToastMixin
) {
  $refs!: {
    DdlAccountId: XeoDropdown,
    DdlType: XeoDropdown,
    DtpToPeriod: XeoDateTimePicker,
    TaDescription: XeoTextArea,
    AmConfirmation: XeoModalMixin
  };

  private get StaffList() { return DataStore.Staffs; }
  private get TmSettings() { return DataStore.TmSettings; }

  @Prop(Number) readonly accountId!: number;
  @Prop() readonly timeoff!: Timeoff;
  private FormTimeoff: Timeoff = new Timeoff();
  private AmMode: 'approve' | 'reject' | 'delete' = 'approve';
  private get IsEditMode(): boolean {
    return this.FormTimeoff.Id > 0 && this.FormTimeoff.Status == 1;
  }
  private get IsFormReadOnly(): boolean {
    return this.IsOtCompleted || (!this.IsToFullAccess && !this.IsMyTimeoff);
  }
  private get IsMyTimeoff(): boolean {
    return this.FormTimeoff.AccountId == DataStore.Account.Id;
  }
  private get IsOtCompleted(): boolean {
    return this.FormTimeoff.Status != 1;
  }
  private get IsToFullAccess(): boolean {
    return this.GetUserAccessState('Client', 'Tm_Timeoff') == 100;
  }

  protected BtnAmCancel_Click() {
    this.$refs.AmConfirmation.close();
  }
  protected BtnAmOk_Click() {
    switch (this.AmMode) {
      case 'approve' :   this._AdditTimeoff(100);   break;
      case 'reject'  :   this._AdditTimeoff(-1);    break;
      default        :   this._DeleteTimeoff();      
    }
  }
  protected BtnApprove_Click() {
    this.AmMode = 'approve';
    this.$refs.AmConfirmation.open();
  }
  protected BtnDelete_Click() {
    this.AmMode = 'delete';
    this.$refs.AmConfirmation.open();
  }
  protected BtnReject_Click() {
    this.AmMode = 'reject';
    this.$refs.AmConfirmation.open();
  }
  protected BtnSave_Click() {
    if (XeoBibliotheca.FormCodex.ValidateFormViaRefs(this.$refs)) {
      this._AdditTimeoff(1);
    }
  }
  protected MdlTimeoffEditor_Show() {
    this.FormTimeoff = new Timeoff(this.timeoff);
    if (!this.FormTimeoff.Id) {
      Vue.set(
        this.FormTimeoff, 'AccountId', 
        this.accountId || (!this.IsToFullAccess ? DataStore.Account.Id : 0)
      );
    }
  }

  private _AdditTimeoff(status: HelperModels.ApprovalState) {
    this.FormTimeoff.Status = status;
    
    this.IsSavingForm = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${TmTimeoffQueries.Axenta_AdditTimeoff(this.FormTimeoff.MapToServerReq())}
      }
    `).xeoThen(
      (data) => {
        this.MakeSuccessToast(
          this.$t(`Success.${this.ApprovalStateRec[status].Code}`, { name: 'Cuti' })
        );
        this.$emit('save', new Timeoff(data.Axenta_AdditTimeoff));
        this.__CloseAllModals();
      },
      (errors) => {
        switch (errors[0].Code) {
          case 'app: timeoff-zero-days':
            if (this.FormTimeoff.Status == 1) {
              this.$refs.DtpToPeriod.AddValidationResultData(
                new XeoTypes.ValidationResultData({
                  Message: this.$t(`TmTimeoffModule.Errors.${errors[0].Code}`).toString(),
                  Status: false
                })
              );
            } else {
              this.MakeWarnToast(this.$t(`TmTimeoffModule.Errors.${errors[0].Code}_modal`), 'sd');
              this.$emit('delete', this.FormTimeoff.Id);
              this.__CloseAllModals();
            }

            break;
          default:
            this.MakeErrorToast(this.$t('Errors.server'));
        }
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false;
    });
  }
  private _DeleteTimeoff() {
    this.IsSavingForm = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${TmTimeoffQueries.Axenta_DeleteTimeoff(this.FormTimeoff.Id)}
      }
    `).xeoThen(
      (data) => {
        this.MakeSuccessToast(this.$t('Success.delete', { name: 'Cuti' }));
        this.$emit('delete', this.FormTimeoff.Id);
        this.__CloseAllModals();
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false;
    });
  }

  private __CloseAllModals() {
    this.$refs.AmConfirmation.close();
    this.close();
  }
}

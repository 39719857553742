import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

import moment from 'moment';
import { OneTimePayment } from '@/Models/PayrollModels';

export default new class PayrollOtPaymentQueries {
  public Axenta_DeleteOtPayment(id: number): string {
    return `Axenta_DeleteOtPayment(id: ${id})`;
  }
  public Axenta_GetOtPaymentById(id: number): string {
    return `Axenta_GetOtPaymentById(id: ${id})`;
  }
  public Axenta_GetOtPaymentsByPeriod(
    startTp: moment.Moment, endTp: moment.Moment
  ): string {
    return `Axenta_GetOtPaymentsByPeriod(
      startTp: ${JSON.stringify(startTp)}, endTp: ${JSON.stringify(endTp)}
    )`;
  }
  public Axenta_UpsertOtPayment(req: OneTimePayment): string {
    return `Axenta_UpsertOtPayment(req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)})`;
  }
}
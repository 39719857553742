
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppConstantsMixin from '@/Mixins/AppConstantsMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';
import moment from 'moment';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import TmOvertimeQueries from '@/Repositories/Graphql/TmOvertimeQueries';
import { Overtime, GetOvertimeListRequest } from '@/Models/TimeManagementModels';

import OvertimeEditorModal from '@/Components/TimeManagement/OvertimeEditorModal.vue';

@Component({
  name: 'TmOvertimeModule',
  components: { OvertimeEditorModal }
})
export default class TmOvertimeModule extends Mixins(
  XeoBaseMixin, XeoFormMixin, XeoModalMixin, AppAccessMixin, AppConstantsMixin, AppToastMixin
) {
  $refs!: {
    MdlOvertimeEditor: OvertimeEditorModal
  };

  private get StaffList() { return DataStore.Staffs; }

  @Prop() readonly id!: number;
  @Prop(Number) readonly accountId!: number;
  private EditedOt: Overtime = new Overtime();
  private OvertimeList: Overtime[] = [];
  private FormRequest = {
    TimePeriod: moment(),
    AccountId: 0
  };
  private FormSearch = {
    Status: 0
  };
  private get IsAddEnabled(): boolean {
    return this.GetUserAccessState('Client', 'Tm_Overtime') == 100 
      || this.IsMainPage 
      || this.accountId == DataStore.Account.Id;
  }
  private get IsMainPage(): boolean {
    return this.$route.name == 'Tm_Overtime';
  }
  private get SearchedOtList(): Overtime[] {
    return this.OvertimeList.filter(
      (ot: Overtime) => !this.FormSearch.Status || ot.Status == this.FormSearch.Status
    ).sort((a, b) => a.OtDate.diff(b.OtDate));
  }

  protected created() {
    this._LoadOvertimeList();
    this._OpenEditorById();
  }
  
  protected LoadOvertimeList_Act() {
    this._LoadOvertimeList();
  }
  protected MdlOtEditor_Hidden() {
    this._NavigateOnMainPage();
  }
  protected UpsertOvertime_Act(ot: Overtime | '+') {
    this._NavigateOnMainPage((ot as Overtime)?.Id);
    this._OpenOtEditor(ot);
  }

  private _LoadOvertimeList() {
    this.IsLoading = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${TmOvertimeQueries.Axenta_GetOvertimeList(
          new GetOvertimeListRequest({
            AccountId: this.accountId || this.FormRequest.AccountId,
            TpStart: this.FormRequest.TimePeriod.clone().startOf('month'),
            TpEnd: this.FormRequest.TimePeriod.clone().endOf('month'),
          })
        )}
      }
    `).xeoThen(
      (data) => {
        this.OvertimeList = data.Axenta_GetOvertimeList.map((ot: any) => new Overtime(ot));
        this.IsLoading = false;
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });
  }
  private _NavigateOnMainPage(otId?: number) {
    if (this.IsMainPage) {
      this.$router.replace({ 
        name: 'Tm_Overtime',
        query: otId ? { id: otId.toString() } : undefined
      }).catch(_ => {});
    }
  }
  private _OpenEditorById() {
    if (this.id) {
      AppRepositories.Graphql.DoAuthGraphql(`
        query {
          ${TmOvertimeQueries.Axenta_GetOvertimeById(this.id)}
        }
      `).xeoThen(
        (data) => {
          this._OpenOtEditor(new Overtime(data.Axenta_GetOvertimeById));
        },
        (errors) => {
          this.MakeErrorToast(this.$t('Errors.not-found', { name: 'Lembur' }));
          this.$router.replace({ name: 'Tm_Overtime' });
        }
      ).catch((err) => {
        this.MakeErrorToast(this.$t('Errors.network'), 'sd');
      });
    }
  }
  private _OpenOtEditor(ot: Overtime | '+') {
    this.EditedOt = ot == '+' ? new Overtime() : ot;
    this.$refs.MdlOvertimeEditor.open();
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';
import moment from 'moment';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import PayrollSummaryQueries from '@/Repositories/Graphql/PayrollSummaryQueries';

import { PayrollSummary } from '@/Models/PayrollModels';

import XeoDateTimePicker from '☆XeoApp/Vue/Components/Base/XeoDateTimePicker.vue';

@Component({
  name: 'PayrollListModule'
})
export default class PayrollListModule extends Mixins(
  XeoBaseMixin, XeoFormMixin, AppAccessMixin, AppToastMixin
) {
  $refs!: {
    DtpPeriod: XeoDateTimePicker
  }

  private get StaffList() { return DataStore.Staffs; }

  @Prop() readonly timePeriod!: moment.Moment;
  private PayrollSummaries: PayrollSummary[] = [];
  private FormRequest = {
    TimePeriod: moment()
  };

  protected created() {
    this.FormRequest.TimePeriod = this.timePeriod;
    this._GetYearlyPayrollSummaries();
  }

  protected UpsertPayroll_Act(p: '+' | PayrollSummary) {
    const payrollId: string = (typeof p == 'object') ? p.Id.toString() : p;
    this.SamePageNavigate({ 
      name: 'Payroll_Summary', params: { payrollId } 
    });
  }
  protected DtpPeriod_Change(timePeriod: moment.Moment) {
    this._GetYearlyPayrollSummaries();
    this.$router.replace({ 
      name: 'Payroll_Summary', query: { tp: timePeriod.format('YYYY') } 
    }).catch(_ => {});
  }

  private _GetEditorDispName(id: number) {
    return this.StaffList[id]?.DisplayName || '–';
  }
  private _GetYearlyPayrollSummaries() {
    const Axenta_GetPayrollSummariesByPeriod: string = 
      PayrollSummaryQueries.Axenta_GetPayrollSummariesByPeriod(
        this.FormRequest.TimePeriod.clone().startOf('year'), 
        this.FormRequest.TimePeriod.clone().endOf('year')
      );

    this.IsLoading = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${Axenta_GetPayrollSummariesByPeriod}
      }
    `).xeoThen(
      (data) => {
        this.PayrollSummaries = data.Axenta_GetPayrollSummariesByPeriod
          .map((ps: PayrollSummary) => new PayrollSummary(ps));
        this.IsLoading = false;
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });
  }
}

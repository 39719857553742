import Validator from '☆Node/validator';
import { ValidationResultData, ValidationResult } from '☆XeoApp/Typescript/XeoTypes';
import XeoUtilities from '☆XeoApp/Typescript/XeoUtilities';

export default class ValidatorCodex {
  constructor() {}

  public ValidateValue(
    value: any, rulesQuery: string, msgTemplateMap: Map<string, string>
  ): ValidationResult {
    /* Empty RulesQuery — Return Immediately */
    if (!rulesQuery) {
      return new ValidationResult({
        Status: true,
        Data: []
      });
    }

    /* Parse & Validate Using Rules Query */
    const validationResult = new ValidationResult({
      Status: true,
    });
    rulesQuery.split('|').forEach((rule: string) => {
      const name: string =  rule.split(':')[0];
      const status: boolean = this._ValidateValueByRule(value, rule);
      const message: string = !status ?
        XeoUtilities.RenderTemplateStrByMap(msgTemplateMap.get(name) || 
          name, this._GetParameterMap(value, rule)) :
        '';

      validationResult.Status = validationResult.Status && status;
      validationResult.Data.push(new ValidationResultData({
        Name: name,
        Status: status,
        Message: message
      }));
    });

    return validationResult;
  }

  private _GetParameterMap(value: any, rule :string): Map<string, string> {
    const ruleParts: string[] = rule.split(':');
    const paramMap: Map<string, string> = new Map([
      [ 'value', ruleParts[1] ]
    ]);

    switch (ruleParts[0]) {
      case 'between':
        const betweenParts: string[] = ruleParts[1].split(',');
        paramMap.set('between-value-min', betweenParts[0]);
        paramMap.set('between-value-max', betweenParts[1]);
      case 'eqfield':
        const eqfieldParts: string[] = ruleParts[1].split('=');
        paramMap.set('eqfield-field', eqfieldParts[0]);
        paramMap.set('eqfield-value', eqfieldParts[1]);
    }

    return paramMap;
  }
  private _ValidateValueByRule(value: any, rule: string): boolean {
    const ruleParts: string[] = rule.split(':');
    const type: string = (value instanceof File) ? 'file' : 
      typeof value;

    switch (type) {
      case 'file':
        switch (ruleParts[0]) {
          case 'accepts':       /* accepts:`Value` */
            return XeoUtilities.ValidateFileType(value, ruleParts[1]);
          case 'max':           /* max:`Value` */
            return value.size <= XeoUtilities.ParseFileSizeStr(ruleParts[1]);
          case 'required':      /* required */
            return Boolean(value);
        }
        break;
      case 'number':
        switch (ruleParts[0]) {
          case 'between':       /* between:`min`,`max` */
            const paramParts: string[] = ruleParts[1].split(',');
            return Number(paramParts[0]) <= value && value <= Number(paramParts[1]);
          case 'eq':            /* eq:`Value` */
            return value == Number(ruleParts[1]);
          case 'max':           /* max:`Value` */
            return value <= Number(ruleParts[1]);
          case 'min':           /* min:`Value` */
            return value >= Number(ruleParts[1]);
          case 'required':      /* required */
            return Boolean(value);
        }
        break;
      case 'string':
        switch (ruleParts[0]) {
          case 'between':       /* between:`min`,`max` */
            const paramParts: string[] = ruleParts[1].split(',');
            return Validator.isLength(value, { 
              min: Number(paramParts[0]), 
              max: Number(paramParts[1]) 
            });
          case 'email':         /* email */
            return Validator.isEmail(value) || !value;
          case 'eq':            /* eq:`Value` */
            return Validator.equals(value, ruleParts[1]);
          case 'eqfield':       /* eqfield:`Field`=`Value` */
            const fieldValue: string = ruleParts[1].split('=')[1];
            return Validator.equals(value, fieldValue);
          case 'excludesall':   /* excludesall:`Value` */
            const excRegex: RegExp = new RegExp(`[${ruleParts[1]}]`, 'g');
            return !Validator.matches(value, excRegex);
          case 'max':           /* max:`Value` */
            return Validator.isLength(value, { max: Number(ruleParts[1]) } );
          case 'min':           /* min:`Value` */
            return Validator.isLength(value, { min: Number(ruleParts[1]) } );
          case 'required':      /* required */
            return Boolean(value);
        }
        break;
      default:
        switch (ruleParts[0]) {
          case 'required':      /* required */
            return Boolean(value);
        }
    }
    
    return true;
  }
}

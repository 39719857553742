
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import { DataStore } from '@/Store/—–AppStore–—';

import AppRepositories from '@/Repositories/—–AppRepositories–—';

import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';

@Component({
  name: 'NavAccountModule'
})
export default class SideNavAccountModule extends Mixins(
  XeoBaseMixin, AppRenderMixin, AppToastMixin
) {
  private get Account() { return DataStore.Account; }
  private IsLoggingOut: boolean = false;
  private IsPopShown: boolean = false;

  protected BtnAccount_Click() {
    this.$router[this.$route.name == 'Staff_List' ? 'replace' : 'push']({
      name: 'Staff_List', 
      params: { staffId: this.Account.Id.toString() }
    }).catch(_ => {});
    this.$bvModal.show('MdlStaffMenu');

    this.IsPopShown = false;
  }
  protected BtnLogOut_Click() {
    this.IsLoggingOut = true;
    AppRepositories.AccountRest.LogOut().xeoThen(
      (data) => {
        window.location.href = '/';
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).finally(() => {
      this.IsLoggingOut = false;
    });
  }
}

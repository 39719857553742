import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as StaffModels from '@/Models/StaffModels';

export default new class StaffQueries {
  public Axenta_DeleteStaff(userMapId: number): string {
    return `Axenta_DeleteStaff(userMapId: ${userMapId})`;
  }
  public Axenta_GetCompanyStaffDatas(req?: Partial<StaffModels.GetStaffDatasRequest>): string {
    return `Axenta_GetCompanyStaffDatas(
      req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req) || null}
    )`;
  }
  public Axenta_ResetStaffPassword(req: StaffModels.ResetPasswordRequest): string {
    return `Axenta_ResetStaffPassword(
      req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)}
    )`;
  }
  public Axenta_UpsertStaff(req: StaffModels.Staff): string {
    return `Axenta_UpsertStaff(
      req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)}
    )`;
  }
}
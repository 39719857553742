
import { Component, Mixins, Prop } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppConstantsMixin from '@/Mixins/AppConstantsMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import { DataStore } from '@/Store/—–AppStore–—';
import moment from 'moment';

import ChartUtils from '@/Utilities/ChartUtils';
import PayrollUtils from '@/Utilities/PayrollUtils';
import PayrollFormulaUtils from '@/Utilities/PayrollFormulaUtils';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import CompanyCutsQueries from '@/Repositories/Graphql/CompanyCutsQueries';
import PayrollOtPaymentQueries from '@/Repositories/Graphql/PayrollOtPaymentQueries';
import PayrollSummaryQueries from '@/Repositories/Graphql/PayrollSummaryQueries';
import StaffQueries from '@/Repositories/Graphql/StaffQueries';
import TmAttendanceQueries from '@/Repositories/Graphql/TmAttendanceQueries';
import TmOvertimeQueries from '@/Repositories/Graphql/TmOvertimeQueries';
import TmTimeoffQueries from '@/Repositories/Graphql/TmTimeoffQueries';

import * as HelperModels from '@/Models/—HelperModels—';
import { OneTimePayment, PayrollSummary } from '@/Models/PayrollModels';
import { CalendarEventType } from '@/Models/PublicModels';
import { Staff } from '@/Models/StaffModels';
import { 
  Overtime, GetOvertimeListRequest, 
  Attendance, AttendanceSummary, GetAttendanceListRequest, 
  GetTimeoffListRequest, Timeoff
} from '@/Models/TimeManagementModels';

import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';
import XeoDateTimePicker from '☆XeoApp/Vue/Components/Base/XeoDateTimePicker.vue';
import XeoTable from '☆XeoApp/Vue/Components/Base/XeoTable.vue';

import PsDetailsModal from '@/Components/Payroll/PsDetailsModal.vue';
import PsDocEbankingModal from '@/Components/Payroll/PsDocEbankingModal.vue';
import PsDocSummaryModule from '@/Components/Payroll/PsDocSummaryModule.vue';
import PsDocTaxModule from '@/Components/Payroll/PsDocTaxModule.vue';

@Component({
  name: "PayrollSummaryModule",
  components: { PsDetailsModal, PsDocEbankingModal, PsDocSummaryModule, PsDocTaxModule }
})
export default class PayrollSummaryModule extends Mixins(
  XeoBaseMixin, XeoFormMixin, AppAccessMixin, AppConstantsMixin, AppRenderMixin, AppToastMixin
) {
  $refs!: {
    DtpPsTimePeriod: XeoDateTimePicker,

    TxtSearch: XeoFormInput,
    TblPayslipItems: XeoTable,
    MdlPsDetails: PsDetailsModal,
    AmConfirmation: XeoModalMixin,
  };

  private get Account() { return DataStore.Account; }
  private get CompanyHq() { return DataStore.CompanyHq; }
  private get CompanyList() { return DataStore.CompanyList; }
  private get CompanyCutsList() { return DataStore.CompanyCutsList; }
  private get ActiveStaffs(): Staff[] { 
    return Object.values(DataStore.Staffs).filter((s) => {
      return !s.JoinDate || s.JoinDate.isSameOrBefore(this.PayrollSummary.TimePeriod, 'month');
    }); 
  }
  private get LastEditorName(): string {
    return DataStore.Staffs[this.PayrollSummary.LastModifiedBy]?.DisplayName || ''; 
  }

  @Prop() id!: '+' | number; 
  private PayrollSummary: PayrollSummary = new PayrollSummary();
  private ApprovedYearlyPs: PayrollSummary[] = [];
  private AmMode: 'approve' | 'delete' = 'approve';
  private FocusedStaffPayslipIdx: number = -1;
  private IsGeneratingPs: boolean = false;
  private IsPsGenerationMode: boolean = true;
  private TpDateEnabler: XeoTypes.DateEnabler = new XeoTypes.DateEnabler();
  private FormSearch = {
    TxtSearch: '',   DdlPsGroupBy: 'Division',
    
    _PsGroupByItems: {
      Division : 'Per Divisi',
      Company  : 'Per Perusahaan'
    } 
  };

  private get DisplayedStaffPayslips(): HelperModels.StaffPayslip[] {
    return this.PayrollSummary.SummaryData.StaffPayslips.filter(
      (sp: HelperModels.StaffPayslip) => {
        return [ 'Name', 'Company', 'Division', 'Job' ].map(
          (sc: string) => (sp as any)[sc]
        ).join('♔').toLowerCase().includes(this.FormSearch.TxtSearch.toLowerCase());
      }
    ).sort((a, b) => a.EmployeeId.localeCompare(b.EmployeeId));
  }
  private get IsNumericId(): boolean {
    return !isNaN(Number(this.id));
  }
  private get IsPsApproved(): boolean {
    return this.PayrollSummary.Status == 100;
  }
  private get IsPsEditable(): boolean {
    return this.PayrollSummary.Status < 100 && this.IsPageFullAccess;
  }
  private get PsGroupChartDatas() {
    const paymentSummary = this.PayrollSummary.SummaryData.StaffPayslips.reduce(
      (ps: Record<string, number>, sp: HelperModels.StaffPayslip) => {
        const groupId = (sp as any)[this.FormSearch.DdlPsGroupBy];

        ps[groupId] = (ps[groupId] || 0)
          + this._GetPayslipItemsSum(sp.StaffItems) 
          + this._GetPayslipItemsSum(sp.GovermentItems);

        return ps;
      }, {}
    );
    const psSorted = Object.entries(paymentSummary)
      .sort((a, b) => b[1] - a[1])
      .map(([key, value]: any, idx: number) => ({
        Name: key,
        Value: value,
        Color: ChartUtils.GetCircColorByIdx(idx)
      }));

    return {
      Series: psSorted.map(ps => ps.Value),
      Options: ChartUtils.NewOptions({
        labels: psSorted.map(ps => ps.Name),
        colors: psSorted.map(ps => ps.Color),
        legend: { show: false },
        tooltip: { enabled: false }
      })
    };
  }
  private get PsDestChartDatas() {
    const colorMap = XeoBibliotheca.ColorCodex.ColorMap;
    
    return {
      Series: this.PayrollSummary.SummaryData.StaffPayslips.reduce(
        (series: number[], sp: HelperModels.StaffPayslip) => {
          series[0] = (series[0] || 0) + this._GetPayslipItemsSum(sp.GovermentItems);
          series[1] = (series[1] || 0) + this._GetPayslipItemsSum(sp.StaffItems);

          return series;
        }, []
      ),
      Options: ChartUtils.NewOptions({
        labels: [ 'Negara', 'Staff' ],
        colors: [ colorMap.Aqua.Base, colorMap.Mint.Base ],
        legend: { show: false },
        tooltip: { enabled: false }
      })
    };
  }
  private get LastActionText(): string {
    return this.IsPsApproved ? 'Disetujui' :
      this.IsNumericId ? 'Direvisi' : 
      'Dibuat';
  }

  protected NavGuardHandler!: Function;
  protected created() {
    /* Load All Necessary Datas */
    this.IsLoading = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      query { 
        ${CompanyCutsQueries.Axenta_GetUserCompanyCutsList}
        ${this.IsNumericId ? 
            PayrollSummaryQueries.Axenta_GetPayrollSummaryById(Number(this.id)) :
            PayrollSummaryQueries.Axenta_GetPayrollDetailsByStatus(100) } 
      }
    `).xeoThen(
      (data) => {
        DataStore.AssignCompanyCutsList(data.Axenta_GetUserCompanyCutsList);

        if (this.IsNumericId) {
          this.PayrollSummary = new PayrollSummary(data.Axenta_GetPayrollSummaryById);

          if (this.PayrollSummary.Status == 1) {
            this._RegeneratePayrollSummary();
            return;
          } else if (
            this.PayrollSummary.Status == 100 
            && this.PayrollSummary.TimePeriod.month() == 11
          ) {
            this._LoadYearlyApprovedPs();
            return;
          }
        } else {
          const rawApprvPs: any[] = data.Axenta_GetPayrollDetailsByStatus;
          this.PayrollSummary.TimePeriod = PayrollUtils.GenerateInitialTp(rawApprvPs);
          this.TpDateEnabler = PayrollUtils.GenerateTpDateEnabler(rawApprvPs);
        }

        /* Navigation Guard on Browser Back */
        this.NavGuardHandler = this.$router.beforeEach((to, from, next) => {
          if (to.name == 'Authentication' && to.query.redirect) {
            next({ name: 'Payroll_Summary' });
          } else {
            next();
          }
        });

        this.IsLoading = false;
      },
      (errors) => {
        switch (errors[0].Code) {
          case 'sql: empty-dataset':
            this.$router.replace({ name: 'Payroll_Summary' });
            this.MakeErrorToast(this.$t('Errors.not-found', { name: 'Payroll' }));
            break;
          default:
            this.MakeErrorToast(this.$t('Errors.server'));
        }
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });

    this.IsPsGenerationMode = !this.IsNumericId;
  }
  protected destroyed() {
    if (this.NavGuardHandler) {
      this.NavGuardHandler();
    }
  }

  protected BtnAmCancel_Click() {
    this.$refs.AmConfirmation.close();
  }
  protected BtnAmOk_Click() {
    this.$refs.AmConfirmation.close();
    if (this.AmMode == 'approve') {
      this._SavePayrollSummary(100);
    } else {
      this._DeletePayroll();
    }
  }
  protected BtnBack_Click() {
    this.__NavigateToYearPsList();
  }
  protected BtnGeneratePs_Click() {
    if (XeoBibliotheca.FormCodex.ValidateFormViaRefs(
      [ this.$refs.DtpPsTimePeriod ]
    )) {
      this._GeneratePayrollSummary();
    }
  }
  protected BtnPsApprove_Click() {
    this.AmMode = 'approve';
    this.$refs.AmConfirmation.open();
  }
  protected BtnPsDelete_Click() {
    this.AmMode = 'delete';
    this.$refs.AmConfirmation.open();
  }
  protected BtnPsSave_Click() {
    this._SavePayrollSummary(1);
  }
  protected TblPayslipItems_ItemClick(staffPayslip: HelperModels.StaffPayslip) {
    this.FocusedStaffPayslipIdx = 
      this.PayrollSummary.SummaryData.StaffPayslips.findIndex(
        (sp: HelperModels.StaffPayslip) => sp.AccountId == staffPayslip.AccountId
      );
    this.$refs.MdlPsDetails.open();
  }
  protected TblPayslipItems_PageChange() {
    XeoBibliotheca.DisplayCodex.ScrollToElement(this.$refs.TxtSearch, 'bottom');
  }

  private _DeletePayroll() {
    this.IsSavingForm = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${
          PayrollSummaryQueries.Axenta_DeletePayrollSummary(
            this.PayrollSummary.Id, this.PayrollSummary.TimePeriod
          )
        }
      }
    `).xeoThen(
        (data) => {
          this.__NavigateToYearPsList();
          this.MakeSuccessToast(this.$t('Success.delete', { name: 'Payroll' }));
        },
        (errors) => {
          this.MakeErrorToast(this.$t('Errors.server'));
        }
      ).catch((err) => {
        this.MakeErrorToast(this.$t('Errors.network'), 'sd');
      }).finally(() => {
        this.IsSavingForm = false;
      });
  }
  private _GeneratePayrollSummary() {
    const monthTpRange = this.__GenerateAggMonthTpRange(),
          yearTpRange = [
            this.PayrollSummary.TimePeriod.clone().startOf('year'),
            this.PayrollSummary.TimePeriod.clone().endOf('year')
          ];

    this.IsGeneratingPs = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${PayrollSummaryQueries.Axenta_GetPayrollSummariesByPeriod(yearTpRange[0], yearTpRange[1])}
        ${PayrollOtPaymentQueries.Axenta_GetOtPaymentsByPeriod(monthTpRange[0], monthTpRange[1])}
        ${StaffQueries.Axenta_GetCompanyStaffDatas()}
        ${TmOvertimeQueries.Axenta_GetOvertimeList(
          new GetOvertimeListRequest({
            TpStart: monthTpRange[0],   TpEnd: monthTpRange[1],
            Status: 100
          })
        )}
        ${TmTimeoffQueries.Axenta_GetTimeoffList(
          new GetTimeoffListRequest({
            TpStart: monthTpRange[0],   TpEnd: monthTpRange[1],              
            Status: 100,
          })
        )}
      }
    `).xeoThen(
      async (data) => {
        /* Load Source Datas */
        DataStore.InitializeStaffs(data.Axenta_GetCompanyStaffDatas);

        const oneTimePayments: OneTimePayment[] = data.Axenta_GetOtPaymentsByPeriod
          .map((otp: any) => new OneTimePayment(otp));
        const overtimeList: Overtime[] = data.Axenta_GetOvertimeList
          .map((ot: any) => new Overtime(ot));
        const timeoffList: Timeoff[] = data.Axenta_GetTimeoffList
          .map((to: any) => new Timeoff(to));
        const atdSummary = await this.__AsyncGetAttendanceSummary(monthTpRange, timeoffList);

        this.ApprovedYearlyPs = data.Axenta_GetPayrollSummariesByPeriod
          .filter((ps: PayrollSummary) => ps.Status == 100)
          .map((ps: PayrollSummary) => new PayrollSummary(ps));

        /* Set Payroll Summary State */
        this.PayrollSummary = new PayrollSummary({ 
          TimePeriod: this.PayrollSummary.TimePeriod,
          SummaryData: {
            SourceData: {
              CompanyList: this.CompanyList,
              CompanyCutsList: this.CompanyCutsList
            }
          },
          LastModifiedBy: this.Account.Id,
          LastModifiedDate: moment()
        });

        /* Generate All Staff Payslips */
        this.ActiveStaffs.forEach((staff) => {
          const staffPayslip: HelperModels.StaffPayslip = 
            this.__InitializeStaffPayslip(staff);
          this.__InputPsRecurringItems(staffPayslip, staff);
            this.__InputPsOneTimePayments(staffPayslip, oneTimePayments);
            this.__InputPsOvertimePayments(staffPayslip, overtimeList);
            this.__InputPsAttendanceCuts(staffPayslip, atdSummary);
          this.__InputPsSocialSecurity(staffPayslip);
          this.__InputPsTaxHistorySummary(staffPayslip);
          this.__InputPsTax(staffPayslip);
          
          if (!this.__IsPayrollEmpty(staffPayslip)) {
            this.PayrollSummary.SummaryData.StaffPayslips.push(staffPayslip);
          }        
        }); 
        
        this.IsPsGenerationMode = false;
      },
      (errors) => {        
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      switch (err) {
        case '__AsyncGetAttendanceSummary':
          this.MakeErrorToast(`${this.$t('Errors.server')} — Kehadiran`);
          break;
        default: 
          this.MakeErrorToast(this.$t('Errors.network'), 'sd');
      }
    });
  }
  private _GetPayslipItemsSum(payslipItems: HelperModels.PayslipItem[]): number {
    return Math.max(
      payslipItems.reduce(
        (sum: number, pi: HelperModels.PayslipItem) => {
          return sum + (pi.CalculationGroup >= 0 ? pi.Value : 0);
        }, 0
      ), 0
    );
  }
  private _LoadYearlyApprovedPs() {
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${PayrollSummaryQueries.Axenta_GetPayrollSummariesByPeriod(
          this.PayrollSummary.TimePeriod.clone().startOf('year'), 
          this.PayrollSummary.TimePeriod.clone().endOf('year')
        )}
      }
    `).xeoThen(
      (data) => {
        this.ApprovedYearlyPs = data.Axenta_GetPayrollSummariesByPeriod
          .filter((ps: PayrollSummary) => ps.Status == 100)
          .map((ps: PayrollSummary) => new PayrollSummary(ps));

        this.IsLoading = false;
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });
  }
  private _RegeneratePayrollSummary() {
    const monthTpRange = this.__GenerateAggMonthTpRange(),
          yearTpRange = [
            this.PayrollSummary.TimePeriod.clone().startOf('year'),
            this.PayrollSummary.TimePeriod.clone().endOf('year')
          ];

    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${PayrollSummaryQueries.Axenta_GetPayrollSummariesByPeriod(yearTpRange[0], yearTpRange[1])}
        ${PayrollOtPaymentQueries.Axenta_GetOtPaymentsByPeriod(monthTpRange[0], monthTpRange[1])}
        ${TmOvertimeQueries.Axenta_GetOvertimeList(
          new GetOvertimeListRequest({
            TpStart: monthTpRange[0],   TpEnd: monthTpRange[1],
            Status: 100
          })
        )}
        ${TmTimeoffQueries.Axenta_GetTimeoffList(
          new GetTimeoffListRequest({
            TpStart: monthTpRange[0],   TpEnd: monthTpRange[1],              
            Status: 100,
          })
        )}
      }
    `).xeoThen(
      async (data) => {
        /* Load Source Datas */
        const oneTimePayments: OneTimePayment[] = data.Axenta_GetOtPaymentsByPeriod
          .map((otp: any) => new OneTimePayment(otp));
        const overtimeList: Overtime[] = data.Axenta_GetOvertimeList
          .map((ot: any) => new Overtime(ot));
        const timeoffList: Timeoff[] = data.Axenta_GetTimeoffList
          .map((to: any) => new Timeoff(to));
        const atdSummary = await this.__AsyncGetAttendanceSummary(
          monthTpRange, timeoffList, this.PayrollSummary.SummaryData.StaffPayslips
        );

        this.ApprovedYearlyPs = data.Axenta_GetPayrollSummariesByPeriod
          .filter((ps: PayrollSummary) => ps.Status == 100)
          .map((ps: PayrollSummary) => new PayrollSummary(ps));

        /* Regenerate Properties + Recalculation */
        this.PayrollSummary.SummaryData.StaffPayslips.forEach((
          sp: HelperModels.StaffPayslip, i: number,
          staffPayslips: HelperModels.StaffPayslip[]
        ) => {
          this.__ResetPsItemsOnRegen(staffPayslips[i]);
          this.__InputPsOneTimePayments(staffPayslips[i], oneTimePayments);
            this.__InputPsOvertimePayments(staffPayslips[i], overtimeList);
            this.__InputPsAttendanceCuts(staffPayslips[i], atdSummary);          
          this.__InputPsTaxHistorySummary(staffPayslips[i]);
          this.__InputPsTax(staffPayslips[i]);
        });

        this.IsLoading = false;
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      switch (err) {
        case '__AsyncGetAttendanceSummary':
          this.MakeErrorToast(`${this.$t('Errors.server')} — Kehadiran`);
          break;
        default: 
          this.MakeErrorToast(this.$t('Errors.network'), 'sd');
      }
    });
  }
  private _SavePayrollSummary(status: HelperModels.ApprovalState) {
    PayrollFormulaUtils.SummarizePayslips(this.PayrollSummary);
    this.PayrollSummary.Status = status;

    this.IsSavingForm = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${PayrollSummaryQueries.Axenta_UpsertPayrollSummary(this.PayrollSummary)}
      }
    `).xeoThen(
      (data) => {
        this.__NavigateToYearPsList();
        this.MakeSuccessToast(this.$t(
          `Success.${this.ApprovalStateRec[this.PayrollSummary.Status].Code}`, 
          { name: 'Payroll' }
        ));
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false;
    });
  }

  private async __AsyncGetAttendanceSummary(
    tpRange: [moment.Moment, moment.Moment], toList: Timeoff[],
    staffPayslips?: HelperModels.StaffPayslip[]
  ): Promise<AttendanceSummary> {
    const sum = new AttendanceSummary(),
            workdayCountRec = sum.WorkdayCountRec,    
            atdCountRec = sum.AttendanceCountRec,
          isNoAttendanceCutRec: Record<number, boolean> = {};
    const staffTpRangeRec: Record<number, [moment.Moment, moment.Moment]> = 
      ((staffPayslips || this.ActiveStaffs) as any).reduce(
        (sumRec: Record<number, [moment.Moment, moment.Moment]>, spOrStaff: any) => {
          sumRec[spOrStaff.AccountId] = this.__GetPayslipTpRange(spOrStaff);
          return sumRec;
        }, {}
      );

    /* Count Effective Workdays — NoAc = EffWorkdays */
    (staffPayslips || this.ActiveStaffs).forEach((s) => {
      workdayCountRec[s.AccountId] = this.__GetRangedWorkdayCount(staffTpRangeRec[s.AccountId]);
      
      if (this.__IsStaffNoAttendanceCut(s)) { 
        atdCountRec[s.AccountId] = workdayCountRec[s.AccountId];
        isNoAttendanceCutRec[s.AccountId] = true;
      }
    });

    /* Count Timeoff as Attendance — HasAtdCut++ | NoAtdCut-- */
    const atdRec: Record<string, boolean> = {};
    toList.forEach((to: Timeoff) => {
      const staffTpRange = staffTpRangeRec[to.AccountId],
            startDate = moment.max(to.StartDate, staffTpRange[0]).startOf('day'),
            endDate   = moment.min(to.EndDate, staffTpRange[1]).endOf('day');
      for (const dt = startDate.clone(); dt.isSameOrBefore(endDate); dt.add(1, 'day')) {        
        const atdRecKey = this.__GetAtdRecKey(to.AccountId, dt);

        if (!this.__GetDtEventType(dt).endsWith('holiday') && !atdRec[atdRecKey]) {
          atdCountRec[to.AccountId] = (atdCountRec[to.AccountId] || 0) + (
            !isNoAttendanceCutRec[to.AccountId] && to.IsPaid ?   1 : 
            isNoAttendanceCutRec[to.AccountId] && !to.IsPaid ?  -1 :
            0
          );

          atdRec[atdRecKey] = true;
        }
      }
    });

    /* Count Attendance — HasAc++ */
    let offset = 0,
        isBreak = false;
    while (true) {
      await AppRepositories.Graphql.DoAuthGraphql(`
        query {
          ${TmAttendanceQueries.Axenta_GetAttendanceList(
            new GetAttendanceListRequest({
              TpStart: tpRange[0],    TpEnd: tpRange[1],
              Offset: offset,         Limit: 5000
            })
          )}
        }
      `).xeoThen(
        (data) => {
          data.Axenta_GetAttendanceList.forEach((rawAtd: Attendance) => {
            const atd = new Attendance(rawAtd),
                  isAtdInTpRange = atd.DatePeriod.isBetween(...staffTpRangeRec[atd.AccountId]);

            if (isNoAttendanceCutRec[atd.AccountId] || !isAtdInTpRange) {
              return;
            }

            const atdRecKey = this.__GetAtdRecKey(atd.AccountId, atd.DatePeriod);
            if (!this.__GetDtEventType(atd.DatePeriod).endsWith('holiday') && !atdRec[atdRecKey]) {
              atdCountRec[atd.AccountId] = (atdCountRec[atd.AccountId] || 0) + 1;
            }
          });

          offset += data.Axenta_GetAttendanceList.length;
          isBreak = data.Axenta_GetAttendanceList.length == 0;
        },
        (errors) => {
          throw '__AsyncGetAttendanceSummary';
        }
      ).catch((err) => {
        throw this.$t('Errors.network');
      });

      if (isBreak)      break;
    }

    return sum;
  }
  private __InitializeStaffPayslip(staff: Staff): HelperModels.StaffPayslip {
    return PayrollFormulaUtils.InitializePayslip(this.PayrollSummary, staff, {
      CompanyRec: DataStore.CompanyList,
      DivisionRec: DataStore.Divisions,
      JobRec: DataStore.Jobs
    });
  }
  private __InputPsAttendanceCuts(sp: HelperModels.StaffPayslip, atdSum: AttendanceSummary) {
    const effWorkdayCount = atdSum.WorkdayCountRec[sp.AccountId],
          atdCount = atdSum.AttendanceCountRec[sp.AccountId] || 0,
          unpaidLeaveCount = Math.max(effWorkdayCount - atdCount, 0),
          cutValue = (unpaidLeaveCount / effWorkdayCount) * this.__GetRoutinePaymentSum(sp);

    if (cutValue) {
      sp.StaffItems.push(new HelperModels.PayslipItem({
        Name: 'Potongan Cuti',
        Value: -cutValue,
        IsOtpItem: true,
        TaxCategory: 3,
        CalculationGroup: 1,
        ExtensionData: {
          NameDesc: `${XeoBibliotheca.NumberCodex.Format(unpaidLeaveCount, '0,0[.]0')} hari`
        }
      }));
    }
  }
  private __InputPsOneTimePayments(sp: HelperModels.StaffPayslip, otpList: OneTimePayment[]) {
    otpList.forEach((otp: OneTimePayment) => {
      if (!otp.TimePayment.isBetween(...this.__GetPayslipTpRange(sp)))    return;

      const staffOtpItem = otp.PaymentList.find(
        (otpItem: HelperModels.OneTimePaymentItem) => otpItem.AccountId == sp.AccountId
      );
      
      if (staffOtpItem) {
        sp.StaffItems.push(new HelperModels.PayslipItem({
          Name: otp.Name,
          Value: staffOtpItem.Value,
          IsOtpItem: true,
          TaxCategory: staffOtpItem.TaxCategory,
          CalculationGroup: 1
        }));
      }
    });
  }
  private __InputPsOvertimePayments(sp: HelperModels.StaffPayslip, otList: Overtime[]) {
    if (!sp.ExtensionData.IsOvertimeEligible)     return;    

    const rtnPaymentSum = this.__GetRoutinePaymentSum(sp);
    const otLayersRec = {
      'workday': [
        { HoursChunk: 1,                  Multiplier: 1.5 },
        { HoursChunk: Number.MAX_VALUE,   Multiplier: 2 }
      ],
      'holiday': [
        { HoursChunk: 8,                  Multiplier: 2 },
        { HoursChunk: 1,                  Multiplier: 3 },
        { HoursChunk: Number.MAX_VALUE,   Multiplier: 4 }
      ]
    };

    const otSummary = { 
      Value: 0,   TotalHours: 0 
    };
    Object.values(otList.reduce(
      /* Summarize Overtime Hours by Date */
      (sumRec: Record<number, Overtime>, ot: Overtime) => {
        if (ot.AccountId == sp.AccountId) {
          const otDate = ot.OtDate.date();
          if (!sumRec[otDate])    sumRec[otDate] = ot;
          else                    sumRec[otDate].OtHours += ot.OtHours;
        }
        
        return sumRec;
      }, {}
    )).forEach(
      /* Summarize Overtimes to OtSummary */
      (ot: Overtime) => {
        if (!ot.OtDate.isBetween(...this.__GetPayslipTpRange(sp)))   return;

        const isPublicWorkday = this.__GetDtEventType(ot.OtDate) == 'holiday';
        otSummary.TotalHours += ot.OtHours;
        otSummary.Value = otLayersRec[isPublicWorkday ? 'workday' : 'holiday'].reduce(
          (sum, lyr) => {
            if (ot.OtHours > 0) {
              sum += Math.min(ot.OtHours, lyr.HoursChunk) / 173 * rtnPaymentSum * lyr.Multiplier;
              ot.OtHours -= lyr.HoursChunk;
            }

            return sum;
          }, 0
        );
      }
    );

    if (otSummary.Value) {
      sp.StaffItems.push(new HelperModels.PayslipItem({
        Name: 'Upah Lembur',
        Value: otSummary.Value,
        IsOtpItem: true,
        TaxCategory: 3,
        CalculationGroup: 1,
        ExtensionData: {
          NameDesc: `${XeoBibliotheca.NumberCodex.Format(otSummary.TotalHours, '0,0[.]0')} jam`
        }
      }));
    }
  }
  private __InputPsRecurringItems(sp: HelperModels.StaffPayslip, staff: Staff) {
    PayrollFormulaUtils.InputRecurringItemsToMonthlyPs(sp, this.PayrollSummary, staff);
  }
  private __InputPsSocialSecurity(sp: HelperModels.StaffPayslip) {
    PayrollFormulaUtils.InputSocialSecurityToMonthlyPs(sp, this.PayrollSummary);
  }
  private __InputPsTax(sp: HelperModels.StaffPayslip) {
    PayrollFormulaUtils.InputTaxToMonthlyPs(sp, this.PayrollSummary);
  }
  private __InputPsTaxHistorySummary(sp: HelperModels.StaffPayslip) {
    PayrollFormulaUtils.InputTaxHistorySummaryToMonthlyPs(
      sp, this.PayrollSummary, this.ApprovedYearlyPs
    );
  }
  private __ResetPsItemsOnRegen(sp: HelperModels.StaffPayslip) {
    //* Resets these items from Ps: *//
    //  1. One-Time Payments -> IsOtpItem == true   
    //  2. Tax -> CalculationGroup == 2
    
    ['StaffItems', 'GovermentItems'].forEach((key) => {
      (sp as any)[key] = (sp as any)[key].filter((pi: HelperModels.PayslipItem) => {
        return pi.CalculationGroup != 2 && !pi.IsOtpItem;
      });
    });
  }
  private __NavigateToYearPsList() {
    this.SamePageNavigate({ 
      name: 'Payroll_Summary', query: { 
        tp: this.PayrollSummary.TimePeriod?.format('YYYY')
      } 
    });
  }

  private __GenerateAggMonthTpRange(): [moment.Moment, moment.Moment] {
    const baseTp = this.PayrollSummary.TimePeriod.clone(),
          salaryCutoffDates = Object.values(this.CompanyCutsList).map(cc => cc.SalaryCutoffDate),
          dateRange = [
            Math.min(...salaryCutoffDates), Math.max(...salaryCutoffDates)
          ];
    
    return [
      baseTp.clone().add(-1, 'month').date(dateRange[0]).add(1, 'day').startOf('day'),
      baseTp.clone().date(dateRange[1]).endOf('day')
    ];
  }
  private __GetAtdRecKey(accountId: number, dt: moment.Moment): string {
    return `${accountId}·${XeoBibliotheca.DateTimeCodex.Format(dt, 'Date')}`;
  }
  private __GetDtEventType(dt: moment.Moment): CalendarEventType {
    return [0,6].includes(dt.day()) ? 'holiday' :
      (DataStore.CalendarEvents[XeoBibliotheca.DateTimeCodex.Format(dt, 'Date')]?.Type || 'workday');
  }
  private __GetPayslipTpRange(
    spOrStaff: Staff | HelperModels.StaffPayslip
  ): [moment.Moment, moment.Moment] {
    const staffCc = 
      this.PayrollSummary.SummaryData.SourceData.GetCompanyCuts(spOrStaff.CompanyBranchId)
        || this.CompanyCutsList[spOrStaff.CompanyBranchId]
        || this.CompanyHq.Cuts;

    const tpEnd = this.PayrollSummary.TimePeriod.clone().date(staffCc.SalaryCutoffDate).endOf('day');
    return [tpEnd.clone().add(-1, 'month').add(1, 'ms'), tpEnd];
  }
  private __GetRangedWorkdayCount(tpRange: [moment.Moment, moment.Moment]): number {
    let cnt = 0;

    tpRange[1].endOf('day');
    for (const dt = tpRange[0].clone(); dt.isSameOrBefore(tpRange[1]); dt.add(1, 'day')) {
      if (!this.__GetDtEventType(dt).endsWith('holiday')) {
        cnt++;
      }
    }

    return cnt;
  }
  private __GetRoutinePaymentSum(sp: HelperModels.StaffPayslip): number {
    return sp.StaffItems.reduce((sum, si) => {
      if (si.CalculationGroup == 0)     sum += si.Value;
      return sum;
    }, 0);
  }
  private __IsPayrollEmpty(sp: HelperModels.StaffPayslip): boolean {
    return !Boolean(sp.StaffItems[0].Value) && sp.GovermentItems.length == 0;
  }
  private __IsStaffNoAttendanceCut(s: Staff | HelperModels.StaffPayslip): boolean {
    return (s as Staff).IsNoAttendanceCut ??
      (s as HelperModels.StaffPayslip).ExtensionData.IsNoAttendanceCut;
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppConstantsMixin from '@/Mixins/AppConstantsMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';
import moment from 'moment';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import TmSettingsQueries from '@/Repositories/Graphql/TmSettingsQueries';
import TmTimeoffQueries from '@/Repositories/Graphql/TmTimeoffQueries';
import { Timeoff, GetTimeoffListRequest } from '@/Models/TimeManagementModels';

import TimeoffEditorModal from '@/Components/TimeManagement/TimeoffEditorModal.vue';

@Component({
  name: 'TmTimeoffModule',
  components: { TimeoffEditorModal }
})
export default class TmTimeoffModule extends Mixins(
  XeoBaseMixin, XeoFormMixin, XeoModalMixin, AppAccessMixin, AppConstantsMixin, AppToastMixin
) {
  $refs!: {
    MdlTimeoffEditor: TimeoffEditorModal
  };

  private get StaffList() { return DataStore.Staffs; }
  private get TmSettings() { return DataStore.TmSettings; }

  @Prop() readonly id!: number;
  @Prop(Number) readonly accountId!: number;
  private TimeoffList: Timeoff[] = [];
  private EditedTo: Timeoff = new Timeoff();
  private FormRequest = {
    TimePeriod: moment(),
    AccountId: 0
  };
  private FormSearch = {
    Status: 0
  };
  private get IsAddEnabled(): boolean {
    return this.GetUserAccessState('Client', 'Tm_Timeoff') == 100 
      || this.IsMainPage 
      || this.accountId == DataStore.Account.Id;
  }
  private get IsMainPage(): boolean {
    return this.$route.name == 'Tm_Timeoff';
  }
  private get SearchedToList(): Timeoff[] {
    return this.TimeoffList.filter(
      (to: Timeoff) => !this.FormSearch.Status || to.Status == this.FormSearch.Status
    ).sort((a, b) => a.StartDate.diff(b.StartDate));
  }

  protected created() {
    this._LoadTimeoffDatas(true);
    this._OpenEditorById();
  }

  protected LoadTimeoffList_Act() {
    this._LoadTimeoffDatas();
  }
  protected MdlToEditor_Hidden() {
    this._NavigateOnMainPage();
  }
  protected UpsertTimeoff_Act(to: Timeoff | '+') {
    this._NavigateOnMainPage((to as Timeoff)?.Id);
    this._OpenToEditor(to);
  }

  private _FormatToPeriod(to: Timeoff): string {
    let tpString: string = '';

    /* Timeoff Period */
    if (this.__IsTpSameOn(to, 'day')) {
      tpString += to.StartDate.format('D MMMM YYYY');
    } else if (this.__IsTpSameOn(to, 'month')) {
      tpString += `${to.StartDate.format('D')} – ${to.EndDate.format('D MMMM YYYY')}`;
    } else if (this.__IsTpSameOn(to, 'year')) {
      tpString += `${to.StartDate.format('D MMM')} – ${to.EndDate.format('D MMM YYYY')}`;
    } else {
      tpString += `${to.StartDate.format('D MMM YYYY')} – ${to.EndDate.format('D MMM YYYY')}`;
    }

    return tpString;
  }
  private _GetTimeoffName(to: Timeoff) {
    return to.Status == 1 ? this.TmSettings.ToTypeMap[to.Type]?.Name : to.Name;
  }
  private _LoadTimeoffDatas(isCreated: boolean = false) {
    this.IsLoading = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${isCreated ? TmSettingsQueries.Axenta_GetTmSettings : ''}
        ${TmTimeoffQueries.Axenta_GetTimeoffList(
          new GetTimeoffListRequest({
            AccountId: this.accountId || this.FormRequest.AccountId,
            TpStart: this.FormRequest.TimePeriod.clone().startOf('month'),
            TpEnd: this.FormRequest.TimePeriod.clone().endOf('month'),
          })
        )}
      }
    `).xeoThen(
      (data) => {
        if (isCreated)    DataStore.AssignTmSettings(data.Axenta_GetTmSettings);
        this.TimeoffList = data.Axenta_GetTimeoffList.map((to: any) => new Timeoff(to));

        this.IsLoading = false;
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });
  }
  private _NavigateOnMainPage(toId?: number) {
    if (this.IsMainPage) {
      this.$router.replace({ 
        name: 'Tm_Timeoff',
        query: toId ? { id: toId.toString() } : undefined
      }).catch(_ => {});
    }
  }
  private _OpenEditorById() {
    if (this.id) {
      AppRepositories.Graphql.DoAuthGraphql(`
        query {
          ${TmTimeoffQueries.Axenta_GetTimeoffById(this.id)}
        }
      `).xeoThen(
        (data) => {
          this._OpenToEditor(new Timeoff(data.Axenta_GetTimeoffById));
        },
        (errors) => {
          this.MakeErrorToast(this.$t('Errors.not-found', { name: 'Cuti' }));
          this.$router.replace({ name: 'Tm_Timeoff' });
        }
      ).catch((err) => {
        this.MakeErrorToast(this.$t('Errors.network'), 'sd');
      });
    }
  }
  private _OpenToEditor(to: Timeoff | '+') {
    this.EditedTo = to == '+' ? new Timeoff() : to;
    this.$refs.MdlTimeoffEditor.open();
  }

  private __IsTpSameOn(to: Timeoff, granularity: moment.unitOfTime.StartOf): boolean {
    return to.StartDate.isSame(to.EndDate, granularity);
  }
}

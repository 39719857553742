
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import { DataStore } from '@/Store/—–AppStore–—';
import AppAccessMixin from '@/Mixins/AppAccessMixin';

import TmOvertimeModule from '@/Components/TimeManagement/TmOvertimeModule.vue';

@Component({
  name: 'TmOvertime',
  components: { TmOvertimeModule }
})
export default class TmOvertime extends Mixins(AppAccessMixin) {
  @Prop() readonly id!: number;
  private get AccountIdByAccess(): number {
    return this.GetUserAccessState('Client', 'Tm_Overtime') == 0 ?
      DataStore.Account.Id : 0;
  }
}

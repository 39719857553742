
import { Vue, Component, Mixins, PropSync, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import { DataStore } from '@/Store/—–AppStore–—';
import excelJs from 'exceljs';

import { 
  CalculationGroup, PaidTo, PayslipItem, PayslipSummary, StaffPayslip 
} from '@/Models/—HelperModels—';
import * as PayrollModels from '@/Models/PayrollModels';
import PayrollFormulaUtils from '@/Utilities/PayrollFormulaUtils';
import { CompanyCuts } from '@/Models/CompanyCutsModels';

@Component({
  name: 'PsDocSummaryModule'
})
export default class PsDocSummaryModule extends Mixins(XeoBaseMixin) {
  private get Company() { return DataStore.CompanyHq.Data; }
  private get CompanyCuts() { return DataStore.CompanyHq.Cuts; }

  @PropSync('payrollSummary') syncPayrollSummary!: PayrollModels.PayrollSummary;
  private BorderRec: Record<string, excelJs.Border> = {
    'medium-aqua': { style: 'medium', color: { argb: 'ff2d98da' } },
    'medium-midnight': { style: 'medium', color: { argb: 'ff2c3e50' } },
    'medium-mint': { style: 'medium', color: { argb: 'ff0fb9b1' } },
    'thin-black': { style: 'thin', color: { argb: 'ff778ca3' } }
  };
  private get PsTimePeriod(): moment.Moment {
    return this.syncPayrollSummary.TimePeriod;
  }

  protected MiSummary_Click() {
    PayrollFormulaUtils.SummarizePayslips(this.syncPayrollSummary);
    this._AsyncGenerateSummaryExcel();
  }

  private async _AsyncGenerateSummaryExcel() {
    let wb!: excelJs.Workbook;
    await XeoBibliotheca.FileCodex.ExcelLoadFile(
      'url', require('@/Assets/Documents/PayrollSummary.xlsx')
    ).then((workbook) => { wb = workbook; });

    const psCompanySummaryList = Object.values(this.__GeneratePsCompanySummaryRecord());
    psCompanySummaryList.forEach(
      (sum: PayrollModels.PsCompanySummaryData, i: number) => {
        const ws = XeoBibliotheca.FileCodex.ExcelDuplicateWorksheet(wb, 1, sum.Company.CompanyName),
          hdrStartRow = 9, hdrStartCol = 10, tblStartRow = 12, tblStartCol = 1,
          orderedColumns = this.__GenerateOrderedColumns(sum);
        
        this._RenderDocHeader(ws, sum);
        this._RenderTableHeaderStructure(ws, hdrStartRow, hdrStartCol, orderedColumns);
          this._ApplyTableHeaderStyle(ws, hdrStartRow, hdrStartCol, orderedColumns);
        const cursorR = this._RenderTableItems(ws, sum, tblStartRow, hdrStartCol, orderedColumns);
        this._RenderTableSummary(ws, cursorR, hdrStartCol, orderedColumns);

        if (i == psCompanySummaryList.length - 1)   wb.removeWorksheet(1);
      }
    );

    await wb.xlsx.writeBuffer().then((buff) => {
      XeoBibliotheca.FileCodex.SaveFile(
        `${this.PsTimePeriod.format('YYYYMM')} — ` +
          `Rangkuman Payroll Periode ${this.PsTimePeriod.format('MMMM YYYY')}.xlsx`, 
        buff
      );
    });
  }

  private _ApplyTableHeaderStyle(
    ws: excelJs.Worksheet, row: number, startCol: number, 
    columns: PayrollModels.PsColumn[]
  ) {
    /* Main Headers */ 
    [...Array(6)].forEach((_, i: number) => {
      ws.getCell(row, i+1).border = {
        top: this.BorderRec['medium-midnight'],
        left: i == 0 ? this.BorderRec['medium-midnight'] : undefined,
        right: i == 0 ? this.BorderRec['thin-black'] : undefined,
        bottom: this.BorderRec['medium-midnight']
      };
    });

    /* Payslip Item Headers */
    columns.forEach((col: PayrollModels.PsColumn, i: number) => {
      const c = startCol + i;
      
      // L0 — Paid To
      const ptCell = ws.getCell(row, c),
            isPtTotal = col.PaidTo == 100,
            lyrZeroBorder = this.BorderRec[isPtTotal ? 'medium-midnight' : 'thin-black'];
      ptCell.font.bold = true;
      ptCell.border = {
        top: this.BorderRec[ isPtTotal ? 'medium-aqua' : 'medium-mint'],
        left: this.BorderRec['thin-black'], right: lyrZeroBorder, 
        bottom: lyrZeroBorder
      };
      if (isPtTotal) {
        ptCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'fff1f6fc' } };
        ptCell.font = { bold: true, color: { argb: 'ff3867d6' } };
        return;
      }

      // L1 — Calculation Group
      const cgCell = ws.getCell(row+1, c),
            isCgTotal = col.CalculationGroup == 100;
      cgCell.font = { bold: isCgTotal, color: { argb: 'ff2d98da' } };
      cgCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'fff1f6fc' } };
      cgCell.border = {
        top: this.BorderRec['thin-black'],
        left: this.BorderRec['thin-black'], right: this.BorderRec['thin-black'], 
        bottom: isCgTotal ? this.BorderRec['medium-midnight'] : this.BorderRec['thin-black']
      };
      if (isCgTotal)      return;

      // L2 — Base Columns
      ws.getRow(row+2).height = 35;
      ws.getCell(row+2, c).border = { 
        top: this.BorderRec['thin-black'],
        left: i == 0 ? this.BorderRec['thin-black'] : undefined,
        bottom: this.BorderRec['medium-midnight']
      };
      ws.getCell(row+2, c).fill = { 
        type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffdde2e8' } 
      };
    });
  }
  private _RenderDocHeader(ws: excelJs.Worksheet, psCompanySum: PayrollModels.PsCompanySummaryData) {
   XeoBibliotheca.FileCodex.ExcelBatchInput(ws, new Map<[number, number], any>([
    [ [3,1], `Periode ${this.PsTimePeriod.format('MMMM YYYY')}` ],
    [ [6,3], psCompanySum.CompanyCuts.TaxCredential || '—' ],
    [ [7,3], psCompanySum.CompanyCuts.SsCredential || '—' ],
    [ [5,3], psCompanySum.Company.CompanyName ],
    [ [6,5], psCompanySum.StaffPayslips.length ],
    [ [7,5], 'IDR' ]
   ]));
  }
  private _RenderTableHeaderStructure(
    ws: excelJs.Worksheet, startRow: number, startCol: number, 
    columns: PayrollModels.PsColumn[]
  ) {
    const abbrvRec = {
      'Adj.': [ 'Adjustment', 'Adjusment', 'Adjusmen' ],
      'Tnj.': [ 'Tunjangan' ],
      'Pot.': [ 'Potongan' ],
      'Kes.': [ 'Jaminan Kesehatan' ],
      'JHT' : [ 'Jaminan Hari Tua' ],
      'JKK' : [ 'Jaminan Kecelakaan Kerja' ],
      'JKM' : [ 'Jaminan Kematian' ],
      'JP'  : [ 'Jaminan Pensiun' ]
    };
    const calcGroupRec: Record<CalculationGroup | 100, string> = {
      0: 'TETAP',
      1: 'TIDAK TETAP',
      2: 'PAJAK',
      3: 'BPJS',
      100: 'TOTAL'
    };
    const paidToRec: Record<PaidTo, string> = {
      0: 'STAFF',
      1: 'NEGARA'
    };

    /* Fill Titles & V-Merge Sum Columns */
    columns.forEach((col: PayrollModels.PsColumn, i: number) => {
      const c = startCol + i;

      if (col.PaidTo == 100) {
        ws.mergeCells(startRow, c, startRow + 2, c);
        ws.getColumn(c).width! *= 1.25;
      } else if (col.CalculationGroup == 100) {
        ws.mergeCells(startRow + 1, c, startRow + 2, c);
        ws.getCell(startRow, c).value = paidToRec[col.PaidTo];
      } else {
        XeoBibliotheca.FileCodex.ExcelBatchInput(ws, new Map<[number, number], any>([
          [ [startRow,c], paidToRec[col.PaidTo] ],
          [ [startRow + 1, c], calcGroupRec[col.CalculationGroup] ]
        ]));
      }

      ws.getCell(startRow + 2, c).value = Object.entries(abbrvRec).reduce(
        (name: string, [abbrv, baseList]) => baseList.reduce(
          (n: string, base: string) => n.replaceAll(base, abbrv), name
        ), col.Name
      );
    });
    
    /* H-Merge Column Groups */
    const startPointRec: Record<string, any> = {
      'PaidTo':           { r: startRow,      c: startCol,  value: null as any },
      'CalculationGroup': { r: startRow + 1,  c: startCol,  value: null as any }
    }
    columns.forEach((col: PayrollModels.PsColumn, i: number) => {
      const c: number = startCol + i;
      if (i == 0) {
        startPointRec.PaidTo.value = ws.getCell(startPointRec.PaidTo.r, c).value;
        startPointRec.CalculationGroup.value = ws.getCell(startPointRec.CalculationGroup.r, c).value;
        return;
      }

      Object.entries(startPointRec).forEach(([key, pt]) => {
        const cellValue = ws.getCell(pt.r, c).value;
        
        if (cellValue != pt.value) {
          if (pt.c != c - 1) {
            ws.mergeCells(pt.r, pt.c, pt.r, c - 1);
          }
          startPointRec[key] = { r: pt.r,  c: c,  value: cellValue };
        }
      });
    });
  }
  private _RenderTableItems(
    ws: excelJs.Worksheet, psCompanySum: PayrollModels.PsCompanySummaryData, 
    startRow: number, hdrStartCol: number, hdrColumns: PayrollModels.PsColumn[]
  ): number {
    let cursorR = startRow, 
        lastDivision = '—☆—',
        incr = 1;
    const sortedPayslips = psCompanySum.StaffPayslips.sort((a, b) => {
      return a.Division.localeCompare(b.Division) 
        || b.PayslipSummary.StaffSum - a.PayslipSummary.StaffSum;
    });

    for (let i = 0; i < sortedPayslips.length; i++) {
      const sp = sortedPayslips[i];

      //* Render Datas *//
      if (sp.Division != lastDivision) {
        // Input Division Separator
        ws.mergeCells(cursorR, 2, cursorR, 5);
        ws.getRow(cursorR).height = 17.5;
        ws.getCell(cursorR, 2).font = { bold: true };
        ws.getCell(cursorR, 2).value = sp.Division.toUpperCase();

        lastDivision = sp.Division;   i--;
      } else {
        // Input Staff Infos
        ws.mergeCells(cursorR, 4, cursorR, 5);
        XeoBibliotheca.FileCodex.ExcelBatchInput(ws, new Map<[number, number],any>([
          [ [cursorR, 1], incr ],
          [ [cursorR, 2], sp.EmployeeId ],
          [ [cursorR, 3], sp.Name ],
          [ [cursorR, 4], sp.Job || '—' ],
          [ [cursorR, 6], '—' ],
          [ [cursorR, 7], '—' ],
          [ [cursorR, 8], '—' ],
          [ [cursorR, 9], '—' ]
        ]));
        if (sp.ExtensionData.JoinDate) {
          const workPeriod = XeoBibliotheca.DateTimeCodex.Difference(
            sp.ExtensionData.JoinDate, DataStore.ServerNow
          );

          XeoBibliotheca.FileCodex.ExcelBatchInput(ws, new Map<[number, number],any>([
            [ [cursorR, 6], sp.ExtensionData.JoinDate?.format('DD-MMM-YYYY') || '—' ],
            [ [cursorR, 7], workPeriod.years() ],
            [ [cursorR, 8], workPeriod.months() ],
            [ [cursorR, 9], workPeriod.days() ],
          ]));
        }

        // Input Payslip Items
        const piColRec = this.__AppendPsItemsToColumnRec(sp);
        hdrColumns.forEach((col: PayrollModels.PsColumn, i: number) => {
          ws.getCell(cursorR, hdrStartCol + i).value = piColRec[col.Key]?.Value || 0;
        });

        incr++;
      }

      //* Render Styles *//
      // Render on Staff Infos
      ws.getCell(cursorR, 1).border = { 
        left: this.BorderRec['medium-midnight'], right: this.BorderRec['thin-black']
      };
      ws.getCell(cursorR, 4).border = { right: this.BorderRec['thin-black'] };
      
      // Render on Payslip Items
      hdrColumns.forEach((col: PayrollModels.PsColumn, i: number) => {
        const cell = ws.getCell(cursorR, hdrStartCol + i);
        let border: any = {};

        if (i == 0) {        
          border = { left: this.BorderRec['thin-black'] };
        } else if (col.PaidTo == 100) {   
          border = { left: this.BorderRec['thin-black'], right: this.BorderRec['medium-midnight'] };
        } else if (col.CalculationGroup == 100) {
          border = { left: this.BorderRec['thin-black'], right: this.BorderRec['thin-black'] };
        }

        cell.border = border;
        if (i == hdrColumns.length - 1)     cell.font = { bold: true };
      });
      
      // Apply Row Pattern
      if (cursorR % 2 == 0) {
        for (let i = 1; i < hdrStartCol + hdrColumns.length; i++) {
          ws.getCell(cursorR, i).fill = { 
            type: 'pattern', pattern: 'solid', fgColor: { argb: 'fff5f5f5' } 
          };
        }
      }

      cursorR++;
    }

    return cursorR;
  }
  private _RenderTableSummary(
    ws: excelJs.Worksheet, summaryRow: number, hdrStartCol: number, 
    hdrColumns: PayrollModels.PsColumn[]
  ) {
    /* Input Items & Apply Style */
    hdrColumns.forEach((col: PayrollModels.PsColumn, i: number) => {
      ws.getCell(summaryRow, hdrStartCol + i).value = col.Value;
    });
    for (let i = 1; i < hdrStartCol + hdrColumns.length; i++) {
      const cell = ws.getCell(summaryRow, i);
      const hdrCol: PayrollModels.PsColumn = hdrColumns[i - hdrStartCol];

      const cellBorder: any = { top: this.BorderRec['thin-black'] };
      if (i < 6)
        cellBorder.top = this.BorderRec['medium-midnight'];
      if (i == 5)             
        cellBorder.right = this.BorderRec['medium-midnight'];
      if (i >= 6)             
        cellBorder.bottom = this.BorderRec['medium-midnight'];
      if (i == hdrStartCol)
        cellBorder.left = this.BorderRec['thin-black'];
      if (hdrCol?.CalculationGroup == 100)  
        Object.assign(cellBorder, {
          left: this.BorderRec['thin-black'], right: this.BorderRec['thin-black'] 
        });  
      if (hdrCol?.PaidTo == 100)
        Object.assign(cellBorder, {
          left: this.BorderRec['thin-black'], right: this.BorderRec['medium-midnight'] 
        }); 

      cell.border = cellBorder;     cell.font = { bold: true };
    }

    /* Input Title & Apply Style */
    const titleCell = ws.getCell(summaryRow, 6);
    titleCell.value = 'GRAND TOTAL';
      ws.getRow(summaryRow).height = 20;
      ws.mergeCells(summaryRow, 6, summaryRow, 9);
      titleCell.font = { bold: true, color: { argb: 'ff3867d6' } };
      titleCell.fill = { 
        type: 'pattern', pattern: 'solid', fgColor: { argb: 'fff1f6fc' } 
      };
  }

  private __AppendPsItemsToColumnRec(
    sp: StaffPayslip, colRec: Record<string, PayrollModels.PsColumn> = {}
  ): Record<string, PayrollModels.PsColumn> {
    const orderWeightRec: Record<string, number> = {
      'Gaji Pokok': -1,
      'Lain-lain': 1,
      'Jaminan Kesehatan': -1
    };

    /* Add Payslip Items */
    [sp.StaffItems, sp.GovermentItems].forEach((piList: PayslipItem[], i: number) => {
      const paidTo = i as PaidTo;
      piList.forEach((pi: PayslipItem) => {
        if (pi.Value == 0)    return '';
        const key = this.__GenerateColumnKey(paidTo, pi);

        if (!colRec[key]) {
          colRec[key] = new PayrollModels.PsColumn({
            Key: key,
            PaidTo: paidTo,
            OrderWeight: orderWeightRec[pi.Name] || 0,
            Value: pi.Value,
            CalculationGroup: pi.CalculationGroup,
            Name: pi.Name,
          });
        } else {
          colRec[key].Value += pi.Value;
        }
      });
    });

    /* Add Payslip Summaries */
    ([ 
      { 
        Key: '*:TakeHomePay', PaidTo: 0, CalculationGroup: 100, 
        Name: 'TAKE-HOME\r\nPAY', Value: sp.PayslipSummary.StaffSum
      },
      { 
        Key: '*:Tax', PaidTo: 1, CalculationGroup: 100,
        Name: 'TOTAL\r\nPAJAK', Value: sp.PayslipSummary.TaxSum
      },
      { 
        Key: '*:SsHealthcare', PaidTo: 1, CalculationGroup: 100, OrderWeight: 1,
        Name: 'TOTAL\r\nBPJS-Kes.', Value: sp.PayslipSummary.SsHealthcareSum
      },
      { 
        Key: '*:SsEmployment', PaidTo: 1, CalculationGroup: 100, OrderWeight: 2,
        Name: 'TOTAL\r\nBPJS-Tk.', Value: sp.PayslipSummary.SsEmploymentSum
      },
      {
        Key: '*:GrandTotal', PaidTo: 100, CalculationGroup: 100, 
        Name: 'GRAND TOTAL', Value: sp.PayslipSummary.StaffSum 
          + sp.PayslipSummary.TaxSum + sp.PayslipSummary.SsHealthcareSum 
          + sp.PayslipSummary.SsEmploymentSum
      }
    ] as PayrollModels.PsColumn[]).forEach((col: PayrollModels.PsColumn) => {
      if (!colRec[col.Key])     colRec[col.Key] = col;
      else                      colRec[col.Key].Value += col.Value;
    });

    return colRec;
  }
  private __GenerateColumnKey(paidTo: PaidTo, pi: PayslipItem): string {
    return `${paidTo}:${pi.CalculationGroup}:${pi.Name}`;
  }
  private __GenerateOrderedColumns(
    psCompanySum: PayrollModels.PsCompanySummaryData
  ): PayrollModels.PsColumn[] {
    const ocRec: Record<string, PayrollModels.PsColumn> = {};
    psCompanySum.StaffPayslips.forEach((sp: StaffPayslip) => {
      this.__AppendPsItemsToColumnRec(sp, ocRec);
    });

    return Object.values(ocRec).sort((a, b) =>
      a.PaidTo - b.PaidTo || 
        a.CalculationGroup - b.CalculationGroup ||
        a.OrderWeight - b.OrderWeight ||
        a.Name.localeCompare(b.Name)
    );
  } 
  private __GeneratePsCompanySummaryRecord(): Record<number, PayrollModels.PsCompanySummaryData> {
    const psSummary = this.syncPayrollSummary.SummaryData,
          psSource = psSummary.SourceData;

    return psSummary.StaffPayslips.reduce(
      (sum: Record<number, PayrollModels.PsCompanySummaryData>, sp: StaffPayslip) => {
        if (!sum[sp.CompanyBranchId]) {
          sum[sp.CompanyBranchId] = new PayrollModels.PsCompanySummaryData({
            Company: psSource.CompanyList[sp.CompanyBranchId] || psSource.CompanyHq.Data,
            CompanyCuts: psSource.CompanyCutsList[sp.CompanyBranchId] || psSource.CompanyHq.Cuts
          });
        }

        sum[sp.CompanyBranchId].StaffPayslips.push(sp);

        return sum;
      }, {}
    );
  }
}

import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';

export default class ErrorCodex {
  public GetDuplicateFieldCode(xeoError: XeoTypes.XeoError): string[] {
    if (xeoError.Code == 'sql: execute-query-failed' && xeoError.Message.includes('Error 1062:')) {
      return [ xeoError.Message.match("key '(.*)_UNIQUE'")![1] ];
    } else if (xeoError.Code == 'app: dup-fields') {
      return Object.entries(xeoError.Extensions)
        .map(([key, val]) => val ? key : null)
        .filter((field: any) => Boolean(field)) as string[];
    }

    return [];
  }
  public IsJwtError(errors: XeoTypes.XeoError[] | null): boolean {
    return Boolean(errors) && errors!.findIndex(
      (err: XeoTypes.XeoError) => err.Code.startsWith('jwt: ')
    ) != -1;
  }
}

import { Vue, Component, Prop } from '☆Node/vue-property-decorator';
import * as HelperModels from '@/Models/—HelperModels—';

@Component({
  name: 'AcDetailsIcons'
})
export default class AcDetailsIcons extends Vue {
  @Prop() readonly ac!: HelperModels.AllowanceCut;
}

import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';

import axios, { AxiosPromise, AxiosRequestConfig } from '☆Node/axios';

export default class AxiosCodex {
  public Options: AxiosRequestConfig = {
    withCredentials: true
  }

  public Get(url: string): XeoTypes.XeoPromise {
    return new XeoTypes.XeoPromise(axios.get(url, this.Options));
  }
  public Post(url: string, body: any, opts?: AxiosRequestConfig): XeoTypes.XeoPromise {
    return new XeoTypes.XeoPromise(
      axios.post(url, body, Object.assign({}, this.Options, opts))
    );
  }
}
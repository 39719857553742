
import { Vue, Component, Prop } from '☆Node/vue-property-decorator';

@Component({
  name: 'XeoTimer'
})
export default class XeoAlert extends Vue {
  @Prop(String) readonly type!: 'loop' | 'once';
  @Prop(Number) readonly interval!: number;
  @Prop(Boolean) readonly tickOnStart!: boolean;
  private _Id!: number | null;
 
  protected destroyed() {
    this.Stop();
  }

  public Start() {
    if (Boolean(this._Id)) {
      return;
    } else if (this.type == 'loop') {
      if (this.tickOnStart) {
        this.$emit('tick');
      }
      this._Id = setInterval(() => this.$emit('tick'), this.interval) as any;
    } else {
      this._Id = setTimeout(
        () => { this.$emit('tick'); this._Id = null; }, this.interval
      ) as any;
    }
  }
  public Stop() {
    this.type == 'loop' ? clearInterval(this._Id as any) : clearTimeout(this._Id as any);
    this._Id = null;
  }
}

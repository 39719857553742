import { Vue, Component } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoToastMixin from '☆XeoApp/Vue/Mixins/XeoToastMixin';

@Component
export default class AppToastMixin extends XeoToastMixin {
  public MakeErrorToast(htmlContent: any, width: 'sm' | 'sd' | 'md' | 'mg' | 'lg' = 'sm') {
    this.MakeSimpleToast(
      require('@/Assets/Images/Multicolor/Cross.png'), this._WrapToHtml(htmlContent),
      { autoHideDelay: 5000, closeType: 'inline', toastClass: `red w-${width}` }
    );
  }
  public MakeSuccessToast(htmlContent: any, width: 'sm' | 'sd' | 'md' | 'mg' | 'lg' = 'sm') {
    this.MakeSimpleToast(
      require('@/Assets/Images/Multicolor/Check.png'), this._WrapToHtml(htmlContent), 
      { closeType: 'full', toastClass: `green w-${width}` }
    );
  }
  public MakeWarnToast(htmlContent: any, width: 'sm' | 'sd' | 'md' | 'mg' | 'lg' = 'sm') {
    this.MakeSimpleToast(
      require('@/Assets/Images/Multicolor/Alert.png'), this._WrapToHtml(htmlContent), 
      { autoHideDelay: 4250, closeType: 'full', toastClass: `yellow w-${width}` }
    );
  }

  private _WrapToHtml(htmlContent: string) {
    return !XeoBibliotheca.UtilCodex.HasHtml(htmlContent) ? 
      `<div class='toast-title'>${htmlContent}</div>` : htmlContent;
  }
}

import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';
import _Debounce from '☆Node/lodash.debounce';
import moment from 'moment';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import CompanyQueries from '@/Repositories/Graphql/CompanyQueries';
import CompanyCutsQueries from '@/Repositories/Graphql/CompanyCutsQueries';
import DivisionQueries from '@/Repositories/Graphql/DivisionQueries';
import JobQueries from '@/Repositories/Graphql/JobQueries';
import StaffQueries from '@/Repositories/Graphql/StaffQueries';

import * as StaffModels from '@/Models/StaffModels';
import { SetupSteps } from '@/Router/Routes';

import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';
import XeoDivider from '☆XeoApp/Vue/Components/Base/XeoDivider.vue';
import XeoDropdown from '☆XeoApp/Vue/Components/Base/XeoDropdown.vue';
import StaffItemModule from '@/Components/Staff/StaffItemModule.vue';
import StaffMenuModal from '@/Components/Staff/StaffMenuModal.vue';

@Component({
  name: 'StaffList',
  components: { StaffItemModule, StaffMenuModal }
})
export default class StaffList extends Mixins(
  XeoBaseMixin, XeoFormMixin, AppAccessMixin, AppRenderMixin, AppToastMixin
) {
  $refs!: {
    TxtSearch: XeoFormInput,
    DdlSort: XeoDropdown,
    DivStaffs: XeoDivider,
    MdlStaffMenu: XeoModalMixin,
  }

  @Prop(Boolean) isSetup!: boolean;
  @Prop() staffId!: '+' | number;
  private get Account() { return DataStore.Account; }
  private get Constants() { return DataStore.Constants; }
  private get StaffList() { return DataStore.Staffs; }

  private FormSearch = {  
    TxtSearch: '',
    OrderBy: '',
    OrderDir: 1,

    OrderByItems: {
      'DisplayName': 'Nama',
      'DivisionId': 'Divisi',
      'JobId': 'Jabatan',
      'JoinDate': 'Tanggal Bergabung',
      'EmploymentStatus': 'Status Kepegawaian' 
    }
  };
  private Pagination: XeoTypes.Pagination = new XeoTypes.Pagination({
    PageSize: 12,
  });
  private get DisplayStaffs(): StaffModels.Staff[] {
    let searchedStaffs: StaffModels.Staff[] = Object.values(this.StaffList);

    /* Match Staffs Against Keyword */
    if (this.FormSearch.TxtSearch) {
      const searchKeywords: string[] = this.FormSearch.TxtSearch.toLowerCase().split(' ');
      const searchColumns: string[] = [ 'EmployeeId', 'DisplayName' ];

      searchedStaffs = searchedStaffs.filter((staff: StaffModels.Staff) => {
        const joinedSrcColumnsStr: string = searchColumns.map(
          (srcCol: string) => (staff as any)[srcCol]
        ).concat(
          this._GetCompanyName(staff.CompanyBranchId),
          this._GetDivJobName('d', staff.DivisionId),
          this._GetDivJobName('j', staff.JobId),
          this._GetDisplayValue(staff, 'EmploymentStatus')
        ).join('♔').toLowerCase();

        return !searchKeywords.find(
          (keyword: string) => {
            return !joinedSrcColumnsStr.includes(keyword);   
          }
        );
      });
    }

    /* Sort Staffs */
    searchedStaffs.sort((a, b: StaffModels.Staff) => {
      if (!this.FormSearch.OrderBy) {
        return a.DisplayName.localeCompare(b.DisplayName);
      } else {
        const valA = this._GetDisplayValue(a, this.FormSearch.OrderBy as any),
              valB = this._GetDisplayValue(b, this.FormSearch.OrderBy as any);
        const sortValue: number = 
          moment.isMoment(valA) ? valA.diff(valB as moment.Moment) : 
          typeof valA == 'string' ? valA.localeCompare(valB as string) :
          valA - (valB as number);

        return (sortValue * this.FormSearch.OrderDir);
      }
    });

    /* Put Myself on the 1st Place */
    const meIdx = searchedStaffs.findIndex(s => s.AccountId == this.Account.Id);
    if (meIdx >= 0) {
      const myself = searchedStaffs.splice(meIdx, 1);
      searchedStaffs.unshift(myself[0]);
    }

    return searchedStaffs;
  }

  protected created() {
    this.IsLoading = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${CompanyCutsQueries.Axenta_GetUserCompanyCutsList}
        ${DivisionQueries.Xeo_GetCompanyDivisions}
        ${JobQueries.Xeo_GetCompanyJobs}
        ${StaffQueries.Axenta_GetCompanyStaffDatas()}
      }
    `).xeoThen(
      (data) => {
        DataStore.SetCompanyAc(data.Axenta_GetUserCompanyCutsList.AllowanceCuts);
        DataStore.InitializeDivisions(data.Xeo_GetCompanyDivisions);
        DataStore.InitializeJobs(data.Xeo_GetCompanyJobs);
        DataStore.InitializeStaffs(data.Axenta_GetCompanyStaffDatas);

        if (this.staffId != null) {
          if (this._IsStaffIdValid(this.staffId)) {
            this.$refs.MdlStaffMenu.open();
          } else {
            if (typeof this.staffId == 'number') {
              this.MakeErrorToast(this.$t('Errors.not-found', {name: 'Staff'}), 'sd');
            }
            this._RenavToStaffPage();
          }
        }
        this.IsLoading = false;
      }, 
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });
  }

  protected BtnDoUpsertStaff_Click(staffId: number | '+') {
    if (this._IsStaffIdValid(staffId)) {
      this._RenavToStaffPage(staffId);
      this.$refs.MdlStaffMenu.open();
    }
  }
  protected BtnCompleteStaffRegist_Click() {
    this._CompleteStaffRegistration();
  }
  protected BtnOrderDir_Click() {
    this.FormSearch.OrderDir *= -1;
  }
  protected PageReset_Act() {
    this.Pagination.Page = 0;
  }
  protected PagStaffs_PageChanged() {
    XeoBibliotheca.DisplayCodex.ScrollToElement(this.$refs.DivStaffs, 'bottom');
  }

  private _CompleteStaffRegistration() {
    /* Check Incomplete Staff data */
    if (Object.values(this.StaffList).find(s => !s.EmployeeId || !s.StaffPayrollId)) {
      this.MakeErrorToast(this.$t(`StaffListPage.Errors.incomplete`));
      return;
    }
    
    /* Update SetupStep */
    this.IsSavingForm = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${CompanyQueries.Xeo_UpdateCompanySetupStep(SetupSteps.Complete)}
      }
    `).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false; 

      if (this.isSetup) {
        DataStore.AssignAccount({
          SetupStep: Math.max(this.Account.SetupStep, SetupSteps.Complete)
        });
        this.$router.push({ name: 'Setup_Complete' });
      }
    });
  }
  private _GetDisplayValue(
    staff: StaffModels.Staff, key: string
  ): string | moment.Moment | number {
    switch (key) {
      case 'DisplayName'      : return staff.DisplayName;
      case 'DivisionId'       : return this._GetDivJobName('d', staff.DivisionId);
      case 'JobId'            : return this._GetDivJobName('j', staff.JobId);
      case 'JoinDate'         : return staff.JoinDate;
      case 'EmploymentStatus' : return this.$t(
          'Constants.EmploymentStatuses.' +
          this.Constants.EmploymentStatuses[staff.EmploymentStatus]
        ) as string;
      default                 : return '';
    }
  }
  private _GetPgStaffItem(type: 'index' | 'staff' | 'class', pgnIdx: number): any {
    const i = this.Pagination.Page * this.Pagination.PageSize + (pgnIdx - 1);
    if (type == 'index')      return i;

    const staff = this.DisplayStaffs[i] || new StaffModels.Staff();
    switch (type) {
      case 'staff'    : return staff;
      case 'class'    : return { 
        'no-pointer-events': this.IsPageNoAccess && staff.AccountId != this.Account.Id 
      }
    }
  }
  private _IsStaffIdValid(staffId: number | '+'): boolean {
    return (staffId == '+' && this.IsPageFullAccess) || (
      (staffId == this.Account.Id || !this.IsPageNoAccess) && staffId in DataStore.Staffs 
    );
  }
  private _RenavToStaffPage(staffId?: number | '+') {
    this.$router.replace({ 
      name: this.isSetup ? 'Setup_Staff' : 'Staff_List', 
      params: staffId ? { staffId: staffId.toString() } : undefined
    }).catch(_ => {});
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppConstantsMixin from '@/Mixins/AppConstantsMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import TmOvertimeQueries from '@/Repositories/Graphql/TmOvertimeQueries';

import * as HelperModels from '@/Models/—HelperModels—';
import { Overtime } from '@/Models/TimeManagementModels';

import XeoDropdown from '☆XeoApp/Vue/Components/Base/XeoDropdown.vue';
import XeoDateTimePicker from '☆XeoApp/Vue/Components/Base/XeoDateTimePicker.vue';
import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';
import XeoTextArea from '☆XeoApp/Vue/Components/Base/XeoTextArea.vue';

@Component({
  name: 'OvertimeEditorModal'
})
export default class OvertimeEditorModal extends Mixins(
  XeoBaseMixin, XeoFormMixin, XeoModalMixin, 
  AppAccessMixin, AppConstantsMixin, AppToastMixin
) {
  $refs!: {
    DdlAccountId: XeoDropdown,
    DtpOtDate: XeoDateTimePicker,
    TxtOtHours: XeoFormInput,
    TaDescription: XeoTextArea,
    AmConfirmation: XeoModalMixin
  };

  private get StaffList() { return DataStore.Staffs; }

  @Prop(Number) readonly accountId!: number;
  @Prop() readonly overtime!: Overtime;
  private FormOvertime: Overtime = new Overtime();
  private AmMode: 'approve' | 'reject' | 'delete' = 'approve';
  private get IsEditMode(): boolean {
    return this.FormOvertime.Id > 0 && this.FormOvertime.Status == 1;
  }
  private get IsFormReadOnly(): boolean {
    return this.IsOtCompleted || (!this.IsOtFullAccess && !this.IsMyOvertime);
  }
  private get IsMyOvertime(): boolean {
    return this.FormOvertime.AccountId == DataStore.Account.Id;
  }
  private get IsOtCompleted(): boolean {
    return this.FormOvertime.Status != 1;
  }
  private get IsOtFullAccess(): boolean {
    return this.GetUserAccessState('Client', 'Tm_Overtime') == 100;
  }

  protected BtnAmCancel_Click() {
    this.$refs.AmConfirmation.close();
  }
  protected BtnAmOk_Click() {
    switch (this.AmMode) {
      case 'approve' :   this._AdditOvertime(100);   break;
      case 'reject'  :   this._AdditOvertime(-1);    break;
      default        :   this._DeleteOvertime();      
    }
  }
  protected BtnApprove_Click() {
    this.AmMode = 'approve';
    this.$refs.AmConfirmation.open();
  }
  protected BtnDelete_Click() {
    this.AmMode = 'delete';
    this.$refs.AmConfirmation.open();
  }
  protected BtnReject_Click() {
    this.AmMode = 'reject';
    this.$refs.AmConfirmation.open();
  }
  protected BtnSave_Click() {
    if (XeoBibliotheca.FormCodex.ValidateFormViaRefs(this.$refs)) {
      this._AdditOvertime(1);
    }
  }
  protected MdlOvertimeEditor_Show() {
    this.FormOvertime = new Overtime(this.overtime);
    if (!this.FormOvertime.Id) {
      Vue.set(
        this.FormOvertime, 'AccountId', 
        this.accountId || (!this.IsOtFullAccess ? DataStore.Account.Id : 0)
      );
    }
  }

  private _AdditOvertime(status: HelperModels.ApprovalState) {
    this.FormOvertime.Status = status;
    
    this.IsSavingForm = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${TmOvertimeQueries.Axenta_AdditOvertime(this.FormOvertime)}
      }
    `).xeoThen(
      (data) => {
        this.MakeSuccessToast(
          this.$t(`Success.${this.ApprovalStateRec[status].Code}`, { name: 'Lembur' })
        );
        this.$emit('save', new Overtime(data.Axenta_AdditOvertime));
        this.$refs.AmConfirmation.close();
        this.close();
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false;
    });
  }
  private _DeleteOvertime() {
    this.IsSavingForm = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${TmOvertimeQueries.Axenta_DeleteOvertime(this.FormOvertime.Id)}
      }
    `).xeoThen(
      (data) => {
        this.MakeSuccessToast(this.$t('Success.delete', { name: 'Lembur' }));
        this.$emit('delete', this.FormOvertime.Id);
        this.$refs.AmConfirmation.close();
        this.close();
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false;
    });
  }
}

import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

import moment from 'moment';
import { PayrollSummary } from '@/Models/PayrollModels';

export default new class PayrollSummaryQueries {
  public Axenta_DeletePayrollSummary(
    id: number, timePeriod: moment.Moment
  ): string {
    return `Axenta_DeletePayrollSummary(
      id: ${id}, timePeriod: ${JSON.stringify(timePeriod)}
    )`;
  }
  public Axenta_GetPayrollDetailsByStatus(status: number): string {
    return `Axenta_GetPayrollDetailsByStatus(status: ${status})`;
  }
  public Axenta_GetPayrollSummariesByPeriod(
    startTp: moment.Moment, endTp: moment.Moment
  ): string {
    return `Axenta_GetPayrollSummariesByPeriod(
      startTp: ${JSON.stringify(startTp)}, endTp: ${JSON.stringify(endTp)}
    )`;
  }
  public Axenta_GetPayrollSummaryById(id: number): string {
    return `Axenta_GetPayrollSummaryById(id: ${id})`;
  }
  public Axenta_UpsertPayrollSummary(req: PayrollSummary): string {
    return `Axenta_UpsertPayrollSummary(
      req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)}
    )`;
  }
}
import moment from '☆Node/moment';
import MomentDurationFormat from '☆Node/moment-duration-format';

MomentDurationFormat(moment);
(moment.duration as any).fn.toJSON = function() {
  return `${this.asHours()}h`;
};

/* Reference : https://momentjs.com/ */
export default class DateTimeCodex {
  public MomentJs = moment;   /* Accessible Core Library */
  public MIN_DATETIME = moment('01/01/1000 00.00.00', 'DD/MM/YYYY HH.mm.ss');
  public MAX_DATETIME = moment('31/12/9999 23.59.59', 'DD/MM/YYYY HH.mm.ss');
  public Formats: Record<string, string> = {
    'Date': 'DD/MM/YYYY',
    'DateTime': 'DD/MM/YYYY HH.mm.ss',
    'Time': 'HH.mm.ss',
  };

  constructor() {
    moment.fn.toJSON = function() { return this.format(); }
  }

  public Difference(dtStart: moment.Moment, dtEnd: moment.Moment): moment.Duration {
    return this.Duration(moment(dtEnd).diff(moment(dtStart)));
  }
  public Duration(value: any): moment.Duration {
    return moment.duration(value);
  }
  public EqualByFormat(
    valA: moment.Moment, valB: moment.Moment, format: string
  ): boolean {
    return this.Format(valA, format) == this.Format(valB, format);
  }
  public Format(value: moment.Moment | string, format: string): string {
    return moment(value).format(this.Formats.hasOwnProperty(format) ? 
      this.Formats[format] : format
    );
  }
  public IsAllBetween(
    value: moment.Moment | moment.Moment[], 
    startDt: moment.Moment, endDt: moment.Moment, 
    granularity: moment.unitOfTime.StartOf = 'ms', 
    inclusivity: '()' | '(]' | '[)' | '[]' = '()'
  ): boolean {
    if (Array.isArray(value)) {
      return !value.some(
        (val: moment.Moment) => !val.isBetween(startDt, endDt, granularity, inclusivity)
      );
    } else {
      return value.isBetween(startDt, endDt, granularity, inclusivity);
    }
  }
  public Parse(value: any, fallback: moment.Moment = moment()): moment.Moment {
    const m = moment(value);
    return m.isValid() ? m : fallback;
  }
  public ParseArray(valArray: any[]): moment.Moment[] {
    return this.Sort(valArray.map((dt: any) => moment(dt)));
  }
  public ParseString(value: string, format: string): moment.Moment {
    return moment(value, format);
  }
  public NamedDaysInMonth(dt: moment.Moment, dayIdx: number): number {
    return Math.floor(
      (dt.daysInMonth() - (1 + (7 + dayIdx - dt.startOf('month').day()) % 7)) / 7
    ) + 1;
  }
  public Sort(dateTimes: moment.Moment[], isAsc: boolean = true): moment.Moment[] {
    return dateTimes.sort((a: moment.Moment, b: moment.Moment) => {
      return isAsc ? (a.unix() - b.unix()) : (b.unix() - a.unix());
    }) 
  }
}
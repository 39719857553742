import { VNode, VNodeChildren } from 'vue';
import { Vue, Component } from 'vue-property-decorator';
import { VueElement } from '☆XeoApp/Typescript/XeoTypes';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

@Component
export default class XeoToastMixin extends Vue {
  public MakeToast(body: string | VueElement | VueElement[], options: any) {
    const bodyElmts: any[] = Array.isArray(body[0]) ? body as VueElement[] : [ body ];
    options = Object.assign({
      id: Math.random().toString(),
      autoHideDelay: 3000,
      toaster: 'XeoToaster',
      closeType: ''   /* '' | 'full' | 'inline' | 'corner' */
    }, options);

    if (!options.closeType) {
      /* Ignore close render */
    } else if (options.closeType == 'full') {
      bodyElmts.push(['div', { 
        class: [ 'toast-close-full' ], 
        on: { click: () => this.$bvToast.hide(options.id) }
      }]);
    } else {
      bodyElmts.push(['XeoIcon', {
        class: [ `toast-close-${options.closeType}`, 'tc-icon' ],
        props: {
          src: require("☆XeoApp/Assets/Images/Monocolor/Close.png")
        },
        nativeOn: { 
          click: () => this.$bvToast.hide(options.id) 
        }
      }]);
    };

    this.$root.$bvToast.toast(XeoBibliotheca.VueCodex.RenderElement(this, bodyElmts), options);
  }
  public MakeSimpleToast(icon: any, htmlContent: string, options: any = {}) {
    this.MakeToast(
      ['div', { class: 'd-flex-center text-center' }, [
        ['img', { class: 'toast-icon', domProps: { src: icon } }],
        ['div', { class: 'flex-grow-1 toast-text', domProps: { innerHTML: htmlContent } }],
        ['img', { class: 'toast-icon invisible' }],
      ]] as any, options
    );
  }

  private _RenderElement(
    jsElmt: string | VueElement | VueElement[] | undefined
  ): string | VNode | VNode[] {
    if (typeof jsElmt == 'string' || !jsElmt) {
      return jsElmt || '';
    } else if (Array.isArray(jsElmt[0])) {
      return (jsElmt as VueElement[]).map((elmt: VueElement) => {
        return this.$createElement(
          elmt[0], elmt[1], this._RenderElement(elmt[2]) as VNodeChildren
        );
      });
    } else {
      return this.$createElement(
        jsElmt[0], (jsElmt as VueElement)[1], this._RenderElement(jsElmt[2]) as VNodeChildren
      );
    }
  }
}
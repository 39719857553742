
import { Vue, Component, Mixins, Prop } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';
import moment from 'moment';

import { PaymentTime } from '@/Models/CompanyCutsModels';
import AppRepositories from '@/Repositories/—–AppRepositories–—';
import CompanyCutsQueries from '@/Repositories/Graphql/CompanyCutsQueries';

@Component({
  name: 'PaymentScheduleModule'
})
export default class PaymentScheduleModule extends Mixins(
  XeoBaseMixin, XeoFormMixin, AppToastMixin
) {
  private get ServerNow() { return DataStore.ServerNow; }

  private PaymentIconRec: Record<string, any> = {
    'Gaji Bulanan': require('@/Assets/Images/Multicolor/MoneyBag.png'),
    'Upah Mingguan': require('@/Assets/Images/Multicolor/Cheque.png'),
    'Iuran BPJS': require('@/Assets/Images/Multicolor/Ss-Employment.png'),
    'Pajak Penghasilan': require('@/Assets/Images/Multicolor/Tax.png')
  };
  private get PaymentTimes(): PaymentTime[] {
    const companyCuts = DataStore.CompanyHq.Cuts;

    return Object.entries({
      'Gaji Bulanan': companyCuts.SalaryPaymentDate,
      'Upah Mingguan': companyCuts.SalaryPaymentDay,
      'Iuran BPJS': companyCuts.SsPaymentDate,
      'Pajak Penghasilan': companyCuts.TaxPaymentDate
    }).map(([key, val]) => {
      const isWeekly: boolean = key == 'Upah Mingguan';
      const dueDate: moment.Moment = this._GetDueDate(val, isWeekly);

      return new PaymentTime({
        Name: key,
        DueDate: dueDate,
        TimeLeft: XeoBibliotheca.DateTimeCodex.Difference(this.ServerNow, dueDate)
      });
    }).sort(
      (a: PaymentTime, b: PaymentTime) => a.DueDate.diff(b.DueDate)
    );
  }

  protected created() {
    this.IsLoading = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${CompanyCutsQueries.Axenta_GetUserCompanyCutsList}
      }
    `).xeoThen(
      (data) => {
        DataStore.AssignCompanyCutsList(data.Axenta_GetUserCompanyCutsList);
        this.IsLoading = false;
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });
  }

  private _GetAlertState(timeLeft: moment.Duration): 0 | 1 | 2 {
    /* 0: — | 1: Warning | 2: Critical */
    const daysLeft: number = Math.floor(timeLeft.asDays());

    return daysLeft == 0 ? 2 :
      daysLeft <= 5 ? 1 : 
      0;
  }
  private _GetDueDate(d: number, isWeekly: boolean): moment.Moment {
    const dd: moment.Moment = isWeekly ? 
      this.ServerNow.clone().day(d - 1).endOf('day') : 
      this.ServerNow.clone().date(d).endOf('day');

    if (dd.diff(this.ServerNow) <= 0) {
      dd.add(1, isWeekly ? 'week' : 'month');
    }

    return dd;
  }
}

import Vue from 'vue';
import AcDetailsIcons from '@/App/Vue/Components/AcDetailsIcons';
import ApprovalStateBadge from '@/App/Vue/Components/ApprovalStateBadge';
import CompanySelector from '@/App/Vue/Components/CompanySelector';
import EmptyListPlaceholder from '@/App/Vue/Components/EmptyListPlaceholder';
import PreloaderIcon from '@/App/Vue/Components/PreloaderIcon';

export default new class AppComponents {
  public RegisterAllToVue() {
    Vue.component('AcDetailsIcons', AcDetailsIcons);
    Vue.component('ApprovalStateBadge', ApprovalStateBadge);
    Vue.component('CompanySelector', CompanySelector);
    Vue.component('EmptyListPlaceholder', EmptyListPlaceholder);
    Vue.component('PreloaderIcon', PreloaderIcon);
  }
}
import { Vue } from '☆Node/vue-property-decorator';
import Meta from '☆Node/vue-meta';
import App from '@/App.vue';
import AppI18n from '@/App/Typescript/AppI18n';
import Router from '@/Router/Router';
import Store from '@/Store/—–AppStore–—';
import './registerServiceWorker';

import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoComponents from '☆XeoApp/Vue/Components/—–XeoComponents–—';

import AppComponents from '@/App/Vue/—–AppComponents–—';

Vue.config.productionTip = false;

Vue.use(AppI18n);
Vue.use(Meta);

XeoComponents.RegisterAllToVue();
AppComponents.RegisterAllToVue();

XeoBibliotheca.ColorCodex.AppendColorMap({
  Fog         : { Base: '#f1f6fb',  Dark: '#eaf2fa' },
  Mountain    : { Base: '#305577',  Dark: '#25476a' },
  Sky         : { Base: '#c2f6f6',  Dark: '#3fcaff' }
});

const vue = new Vue({
  i18n: new AppI18n(),
  router: Router,
  store: Store,
  render: (h: any) => h(App)
}).$mount('#Axenta');


import { Vue, Component, Mixins, Prop } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';

import moment from 'moment';
import ChartUtils from '@/Utilities/ChartUtils';
import PayrollUtils from '@/Utilities/PayrollUtils';
import { default as pfUtils } from '@/Utilities/PayrollFormulaUtils';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import CompanyCutsQueries from '@/Repositories/Graphql/CompanyCutsQueries';
import PayrollOtPaymentQueries from '@/Repositories/Graphql/PayrollOtPaymentQueries';
import PayrollSummaryQueries from '@/Repositories/Graphql/PayrollSummaryQueries';
import StaffQueries from '@/Repositories/Graphql/StaffQueries';

import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import * as HelperModels from '@/Models/—HelperModels—';
import { OneTimePayment, OtpCreationForm, PayrollSummary } from '@/Models/PayrollModels';
import { Staff } from '@/Models/StaffModels'; 

import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';
import XeoDateTimePicker from '☆XeoApp/Vue/Components/Base/XeoDateTimePicker.vue';
import XeoDropdown from '☆XeoApp/Vue/Components/Base/XeoDropdown.vue';
import XeoTable from '☆XeoApp/Vue/Components/Base/XeoTable.vue';
import OtpItemEditorModal from '@/Components/PayrollOtPayment/OtpItemEditorModal.vue';

@Component({
  name: "PayrollOtpSummaryModule",
  components: { OtpItemEditorModal }
})
export default class PayrollOtpSummaryModule extends Mixins(
  XeoBaseMixin, XeoFormMixin, AppAccessMixin, AppRenderMixin, AppToastMixin
) {
  $refs!: {
    TxtOtpName: XeoFormInput,
    DtpOtpTimePayment: XeoDateTimePicker,
    TxtOtpBaseValue: XeoFormInput,
    TxtOtpSourcePercentage: XeoFormInput,
    DdlOtpTaxCategory: XeoDropdown,

    TxtSearch: XeoFormInput,
    TblOtPaymentItems: XeoTable,
    MdlOtpItemEditor: OtpItemEditorModal,
    AmConfirmation: XeoModalMixin
  };

  private get Account() { return DataStore.Account; }
  private get Company() { return DataStore.CompanyHq.Data; }
  private get CompanyList() { return DataStore.CompanyList; }
  private get Constants() { return DataStore.Constants; }
  private get StaffList() { return DataStore.Staffs; }

  private get Divisions() { return DataStore.Divisions; }
  private get Jobs() { return DataStore.Jobs; }

  @Prop() id!: '+' | number; 
  private OtpSummary: OneTimePayment = new OneTimePayment();
  private FormOtpCreation: OtpCreationForm = new OtpCreationForm();
  private FocusedOtpItemAccId: number | '+' = -1;
  private IsGeneratingOtp: boolean = false;
  private IsOtpGenerationMode: boolean = true;
  private TpDateEnabler: XeoTypes.DateEnabler = new XeoTypes.DateEnabler();
  private FormSearch = {
    TxtSearch: '',   DdlPsGroupBy: 'Division',
    
    _PsGroupByItems: {
      Division : 'Per Divisi',
      Company  : 'Per Perusahaan'
    } 
  };

  private get DisplayedPaymentList(): HelperModels.OneTimePaymentItem[] {
    return this.OtpSummary.PaymentList.filter(
      (pi: HelperModels.OneTimePaymentItem) => {
        return [ 'Name', 'Company', 'Division', 'Job' ].map(
          (sc: string) => (pi as any)[sc]
        ).join('♔').toLowerCase().includes(this.FormSearch.TxtSearch.toLowerCase());
      }
    ).sort((a, b) => a.EmployeeId.localeCompare(b.EmployeeId));
  }
  private get IsNumericId(): boolean  {
    return !isNaN(Number(this.id));
  }
  private get IsOtpApproved(): boolean  {
    return this.OtpSummary.Status == 100;
  }
  private get IsOtpEditable(): boolean  {
    return this.OtpSummary.Status < 100 && this.IsPageFullAccess;
  }
  private get IsOtpItemEmpty(): boolean {
    return this.OtpSummary.PaymentList.length == 0;
  }
  private get LastActionText(): string {
    return this.IsOtpApproved ? 'Disetujui' :
      this.IsNumericId ? 'Direvisi' : 
      'Dibuat';
  }
  private get LastEditorName(): string {
    const lmStaff: Staff = this.StaffList[this.OtpSummary.LastModifiedBy];
    
    return lmStaff ? lmStaff.DisplayName : ''; 
  }
  private get OtPaymentsSum(): number {
    return this.OtpSummary.PaymentList.reduce(
      (sum: number, pi: HelperModels.OneTimePaymentItem) => {
        return sum + pi.Value;
      }, 0
    );
  }
  private get OtpReceivers(): Staff[] {
    return (Object.values(this.StaffList) as Staff[]).filter((s: Staff) => {
      return this.FormOtpCreation.StaffSelection.IsStaffSelected(s) &&
        (!s.JoinDate || s.JoinDate.isSameOrBefore(this.FormOtpCreation.TimePayment, 'month'));
    });
  }
  private get PsChartDatas(): any {
    const paymentSummary = this.OtpSummary.PaymentList.reduce(
      (ps: Record<string, number>, otpItem: HelperModels.OneTimePaymentItem) => {
        const groupId = (otpItem as any)[this.FormSearch.DdlPsGroupBy];
        ps[groupId] = (ps[groupId] || 0) + otpItem.Value;

        return ps;
      }, {}
    );
    const psSorted = Object.entries(paymentSummary)
      .sort((a, b: any) => b[1] - a[1])
      .map(([key, value]: any, idx: number) => ({
        Name: key,
        Value: value,
        Color: ChartUtils.GetCircColorByIdx(idx)
      }));

    return {
      Series: psSorted.map(ps => ps.Value),
      Options: ChartUtils.NewOptions({
        labels: psSorted.map(ps => ps.Name),
        colors: psSorted.map(ps => ps.Color),
        legend: { show: false },
        tooltip: { enabled: false }
      })
    };
  }
  private get SimulationRec(): Record<string, any> {
    const baseVal: number = 10_000_000;
    const otpVal: number = this.FormOtpCreation.BaseValue 
      + baseVal * (this.FormOtpCreation.SourcePercentage / 100);

    return {
      BaseValue: baseVal,
      OtpValue: otpVal,
      OtpType: otpVal >= 0 ? 'tambahan' : 'potongan'
    };
  }
  private get SourceTypeName(): string {
    return this.$t(`Constants.OtpSourceTypes.${
        this.Constants.OtpSourceTypes[this.FormOtpCreation.SourceType]
    }`).toString();
  }
  private get TaxCategories() {
    return Object.entries(DataStore.Constants.TaxCategories || {})
      .map(([key, val]) => ({ Id: Number(key), Value: val }))
      .filter((tc: any) => tc.Id != 2 && tc.Id < 11);
  }

  protected NavGuardHandler!: Function;
  protected created() {
    /* Load Existing Payroll */
    const otpSummaryQuery: string = this.IsNumericId ? 
      PayrollOtPaymentQueries.Axenta_GetOtPaymentById(Number(this.id)) :
      PayrollSummaryQueries.Axenta_GetPayrollDetailsByStatus(100);

    this.IsLoading = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${otpSummaryQuery}
      }
    `).xeoThen(
      (data) => {
        if (this.IsNumericId) {
          this.OtpSummary = new OneTimePayment(data.Axenta_GetOtPaymentById);
        } else {
          const rawApprvPs: any[] = data.Axenta_GetPayrollDetailsByStatus;
          this.FormOtpCreation.TimePayment = PayrollUtils.GenerateInitialTp(rawApprvPs);
          this.TpDateEnabler = PayrollUtils.GenerateTpDateEnabler(rawApprvPs);
        }

        /* Navigation Guard on Browser Back */
        this.NavGuardHandler = this.$router.beforeEach((to, from, next) => {
          if (to.name == 'Authentication' && to.query.redirect) {
            next({ name: 'Payroll_OtPayment' });
          } else {
            next();
          }
        });

        this.IsLoading = false;
      },
      (errors) => {
        switch (errors[0].Code) {
          case 'sql: empty-dataset':
            this.$router.replace({ name: 'Payroll_OtPayment' });  
            this.MakeErrorToast(this.$t('Errors.not-found', { name: 'Payroll' }));
            break;
          default:
            this.MakeErrorToast(this.$t('Errors.server'));
        }
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });

    this.IsOtpGenerationMode = !this.IsNumericId;
  }
  protected destroyed() {
    if (this.NavGuardHandler) {
      this.NavGuardHandler();
    }
  }

  protected BtnAmCancel_Click() {
    this.$refs.AmConfirmation.close();
  }
  protected BtnAmOk_Click() {
    this._DeletePayroll();
  }
  protected BtnBack_Click() {
    this.__NavigateToMonthOtpList();  
  }
  protected BtnGenerateOtp_Click() {
    this._GenerateOneTimePayment();
  }
  protected BtnOtpDelete_Click() {
    this.$refs.AmConfirmation.open();
  }
  protected BtnOtpSave_Click() {
    this._SaveOneTimePayment();
  }
  protected MdlOtpItemEditor_Delete(accId: number) {
    this._DeleteOtpItemByAccId(accId);
  }
  protected MdlOtpItemEditor_Save(otpItem: HelperModels.OneTimePaymentItem) {
    this._SaveOtpItem(otpItem);
  }
  protected TblOtPaymentItems_PageChange() {
    XeoBibliotheca.DisplayCodex.ScrollToElement(this.$refs.TxtSearch, 'bottom');
  }
  protected UpsertOtpItem_Act(otpItemOpt: HelperModels.OneTimePaymentItem | '+') {
    if (this.IsOtpEditable) {
      this.FocusedOtpItemAccId = typeof otpItemOpt == 'string' ? 
        otpItemOpt : otpItemOpt.AccountId;
      this.$refs.MdlOtpItemEditor.open();
    }
  }

  private _DeleteOtpItemByAccId(accId: number) {
    Vue.delete(
      this.OtpSummary.PaymentList,
      this.OtpSummary.PaymentList.findIndex(
        (otpi: HelperModels.OneTimePaymentItem) => otpi.AccountId == accId
      )
    );
  }
  private _DeletePayroll() {
    this.IsSavingForm = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${PayrollOtPaymentQueries.Axenta_DeleteOtPayment(this.OtpSummary.Id)}
      }
    `).xeoThen(
      (data) => {
        this.__NavigateToMonthOtpList();
        this.MakeSuccessToast(this.$t('Success.delete', { name: 'Payroll' }));
        this.$refs.AmConfirmation.close();
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false;
    });
  }
  private _GenerateOneTimePayment() {
    if (!this.__ValidateOtpForm())    return;

    this.OtpSummary = new OneTimePayment({
      Name: this.FormOtpCreation.Name,
      TimePayment: this.FormOtpCreation.TimePayment,
      LastModifiedDate: moment(),
      LastModifiedBy: this.Account.Id
    });

    if (this.FormOtpCreation.IsAutoGenerate) {      
      this.IsGeneratingOtp = true;
      AppRepositories.Graphql.DoAuthGraphql(`
        query {
          ${CompanyCutsQueries.Axenta_GetUserCompanyCutsList}
          ${StaffQueries.Axenta_GetCompanyStaffDatas()}
        }
      `).xeoThen(
        (data) => {
          DataStore.AssignCompanyCutsList(data.Axenta_GetUserCompanyCutsList);
          DataStore.InitializeStaffs(data.Axenta_GetCompanyStaffDatas);

          const payrollSummary = new PayrollSummary({
            TimePeriod: this.FormOtpCreation.TimePayment.startOf('month'),
            SummaryData: {
              SourceData: {
                CompanyList: DataStore.CompanyList,
                CompanyCutsList: DataStore.CompanyCutsList
              }
            }
          });

          this.OtpSummary.PaymentList = this.OtpReceivers.map((staff: Staff) =>
            new HelperModels.OneTimePaymentItem({
              AccountId: staff.AccountId,
              EmployeeId: staff.EmployeeId,
              Name: staff.DisplayName,
              Company: this._GetCompanyName(staff.CompanyBranchId),
              Division: this._GetDivJobName('d', staff.DivisionId),
              Job: this._GetDivJobName('j', staff.JobId),
              Value: this.FormOtpCreation.BaseValue + this.__GetSourceValue(payrollSummary, staff)
            })
          );
          
          this.IsOtpGenerationMode = false;
        }, 
        (errors) => {
          this.MakeErrorToast(this.$t('Errors.server'));
        }
      ).catch((err) => {
        this.MakeErrorToast(this.$t('Errors.network'), 'sd');
      }).finally(() => {
        this.IsGeneratingOtp = false;
      });
    } else {
      this.IsOtpGenerationMode = false;
    }
  }

  private _SaveOneTimePayment() {
    this.IsSavingForm = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${PayrollOtPaymentQueries.Axenta_UpsertOtPayment(this.OtpSummary)}
      }
    `).xeoThen(
      (data) => {
        this.__NavigateToMonthOtpList();
        this.MakeSuccessToast(this.$t('Success.save', { name: 'Payroll' }));
      }, 
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false;
    });
  }
  private _SaveOtpItem(otpItem: HelperModels.OneTimePaymentItem) {
    const itemIdx: number = this.OtpSummary.PaymentList.findIndex(
      (otpi: HelperModels.OneTimePaymentItem) => otpi.AccountId == otpItem.AccountId
    );

    if (itemIdx > -1) {
      Vue.set(this.OtpSummary.PaymentList, itemIdx, otpItem);
    } else {
      this.OtpSummary.PaymentList.push(otpItem);
    }
  }

  private __GetSourceValue(payrollSummary: PayrollSummary, staff: Staff): number {
    let srcVal = 0;
    switch (this.FormOtpCreation.SourceType) {
      case 2:   /* Take-Home Pay */
        const sp: HelperModels.StaffPayslip = pfUtils.InitializePayslip(
          payrollSummary, staff, { IsClearTaxHistory: true }
        );

        pfUtils.InputRecurringItemsToMonthlyPs(sp, payrollSummary, staff);
        pfUtils.InputSocialSecurityToMonthlyPs(sp, payrollSummary)
        pfUtils.InputTaxToMonthlyPs(sp, payrollSummary);

        srcVal = sp.StaffItems.reduce((sum, si) => sum + si.Value, 0);
        break;
      default:  /* Base Salary */
        srcVal = staff.BaseSalary;
    }

    return srcVal * this.FormOtpCreation.SourcePercentage / 100;
  }
  private __NavigateToMonthOtpList() {
    this.SamePageNavigate({ 
      name: 'Payroll_OtPayment', query: { 
        tp: this.OtpSummary.TimePayment?.format('YYYY-MM')
      } 
    });
  }
  private __ValidateOtpForm(): boolean {
    const mainRefs: any[] = [
      this.$refs.TxtOtpName, this.$refs.DtpOtpTimePayment, this.$refs.DdlOtpTaxCategory
    ];
    const valueRefs: any[] = [
      this.$refs.TxtOtpBaseValue, this.$refs.TxtOtpSourcePercentage
    ];

    if (this.FormOtpCreation.IsAutoGenerate && this.FormOtpCreation.IsValueZero) {
      valueRefs.forEach((valueRef: XeoFormInput) => { 
        valueRef.AddValidationResultData(
          new XeoTypes.ValidationResultData({
            Message: 'Salah satu item harus diisi.',
            Status: false
          })
        );
      });
    }

    return XeoBibliotheca.FormCodex.ValidateFormViaRefs(mainRefs) && (
      !this.FormOtpCreation.IsAutoGenerate || (
        !this.FormOtpCreation.IsValueZero && this.OtpReceivers.length > 0
      )
    );
  }
}

import Vue, { VNode, VNodeChildren } from 'vue';
import { RouteConfigSingleView as RouteConfig } from 'vue-router/types/router';

import { VueElement } from '☆XeoApp/Typescript/XeoTypes';

export default class VueCodex {
  private _passthroughPage = { render(c: any) { return c('router-view') } };

  public RenderElement(
    vue: Vue, jsElmt: string | VueElement | VueElement[] | undefined
  ): string | VNode | VNode[] {
    if (typeof jsElmt == 'string' || !jsElmt) {
      return jsElmt || '';
    } else if (Array.isArray(jsElmt[0])) {
      // @ts-ignore: VueElement[]
      return jsElmt.map((elmt: VueElement) => vue.$createElement(
        elmt[0], elmt[1], this.RenderElement(vue, elmt[2]) as VNodeChildren
      ));
    } else {
      // @ts-ignore
      return vue.$createElement(jsElmt[0], jsElmt[1], this.RenderElement(vue, jsElmt[2]));
    }
  }
  public RenderRoutes(routes: RouteConfig[]): RouteConfig[] {
    /* Normalize Route Component — Recursive */
    routes.forEach((r: RouteConfig, i: number, rts: RouteConfig[]) => {
      if (r.children?.length) {
        if (!r.component)
          rts[i].component = this._passthroughPage;
        if (!r.name && r.children.findIndex(c => c.path == '') == -1)
          rts[i].children?.push({ path: '', redirect: { name: 'Dashboard' } });

        rts[i].children = this.RenderRoutes(r.children!);
      }
    });

    return routes;
  }
}
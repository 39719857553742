
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';

import { DataStore } from '@/Store/—–AppStore–—';

import * as HelperModels from '@/Models/—HelperModels—';
import * as RbacModels from '@/Models/RbacModels';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import RbacQueries from '@/Repositories/Graphql/RbacQueries';

@Component({
  name: 'StaffAccess'
})
export default class StaffAccess extends Mixins(
  XeoBaseMixin, XeoFormMixin, XeoModalMixin, AppAccessMixin, AppRenderMixin, AppToastMixin
) {
  $refs!: {
    DdlAccessState: XeoModalMixin
  }

  private get AccessControl() { return DataStore.Constants.AccessControl; }
  private get Divisions() { return DataStore.Divisions; }
  private get Jobs() { return DataStore.Jobs; }
  private get StaffList() { return DataStore.Staffs; }

  private FormAccess = new RbacModels.Rbac();
  private ActiveAccess = { Id: '', State: 0 };
  private Mode: 'Grup' | 'Individu' = 'Grup';
    private ModeList = ['Grup', 'Individu'];
  private IconRec: Record<string, any> = {
    Axenta: require('@/Assets/Images/Brand/Base.png')
  };
  private get AccessList(): any[] {
    return Object.entries(this.AccessControl.Map).map(([key, name]) => {
      return {
        Id: key,
        Name: name,
        Depth: [...key].reduce((sum, c) => sum + (c == '.' ? 1 : 0), 0),
        HasChildren: Boolean(this.AccessControl.Map[key + '.1'])
      };
    }).sort(
      (a, b) => XeoBibliotheca.UtilCodex.SortDotNumberFunc(a.Id, b.Id)
    );
  }
  private get RoleType(): 'empty' | 'owner' | 'normal' {
    const fa = this.FormAccess;

    return !(fa.AccountId || fa.DivisionId || fa.JobId) ? 'empty' :
      fa.DivisionId == 1 && fa.JobId == 1 ? 'owner' :
      'normal';
  }

  protected BtnSaveForm_Click() {
    this._UpsertAccess();
  }
  protected DdlAccessState_Change(state: HelperModels.AccessState) {
    this.FormAccess.AccessMap[this.ActiveAccess.Id] = state;
  }
  protected DdlRoles_Change() {
    if (this.RoleType == 'normal') {
      this._GetAccessByRole();
    }
  }
  protected DdlAccessState_Hidden() {
    this._SetActiveAccessById('Reset');
  }
  protected MiAccess_Click(id: string) {
    this._SetActiveAccessById(id);
    this.$refs.DdlAccessState.open();
  }
  protected TabMode_Change() {
    this.FormAccess = new RbacModels.Rbac();
  }

  private _GetAccessByRole() {
    this.IsLoading = true;

    const req = new RbacModels.GetRbacRequest(this.FormAccess);
      req.Mode = 'exact';
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${RbacQueries.Xeo_GetAccessByIds(req)}
      }
    `).xeoThen(
      (data) => {
        this.FormAccess.AccessMap = data.Xeo_GetAccessByIds.AccessMap || {};
        this.IsLoading = false;
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });
  }
  private _SetActiveAccessById(id: string | 'Reset') {
    if (id != 'Reset') {
      this.ActiveAccess = {
        Id: id,
        State: this.FormAccess.AccessMap[id] || 0
      };
    } else {
      this.ActiveAccess =  { Id: '', State: 0 };
    }
  }
  private _UpsertAccess() {
    this.IsSavingForm = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${RbacQueries.Xeo_UpsertAccess(this.FormAccess)}
      }
    `).xeoThen(
      (data) => {
        this.FormAccess = data.Xeo_UpsertAccess;
        this.MakeSuccessToast(this.$t('Success.save', { name: 'Akses' }));
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false;
    });
  }
}

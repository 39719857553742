
import { Vue, Component, Mixins, Prop } from '☆Node/vue-property-decorator';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import { DataStore } from '@/Store/—–AppStore–—';

import PaymentScheduleModule from '@/Components/Dashboard/PaymentScheduleModule.vue';

@Component({
  name: 'Dashboard',
  components: { PaymentScheduleModule }
})
export default class Dashboard extends Mixins(AppAccessMixin) {
  private get Account() { return DataStore.Account; }
  private get ServerNow() { return DataStore.ServerNow; }

  private get TimeState(): 'pagi' | 'siang' | 'sore' | 'malam' {
    const serverHour: number = this.ServerNow.hour();
    const dayTimeParts = {
      'pagi': [ 5, 11 ],
      'siang': [ 11, 14 ],
      'sore': [ 14, 18 ]
    };

    return Object.entries(dayTimeParts).find((part: any) => {
      return part[1][0] <= serverHour && serverHour < part[1][1];
    })?.[0] as any || 'malam';
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import { DataStore } from '@/Store/—–AppStore–—';
import AppAccessMixin from '@/Mixins/AppAccessMixin';

import TmTimeoffModule from '@/Components/TimeManagement/TmTimeoffModule.vue';

@Component({
  name: 'TmTimeoff',
  components: { TmTimeoffModule }
})
export default class TmTimeoff extends Mixins(AppAccessMixin) {
  @Prop() readonly id!: number;
  private get AccountIdByAccess(): number {
    return this.GetUserAccessState('Client', 'Tm_Timeoff') == 0 ?
      DataStore.Account.Id : 0;
  }
}


import { Vue, Component, Prop, Mixins } from '☆Node/vue-property-decorator';
import AppConstantsMixin from '@/Mixins/AppConstantsMixin';
import * as HelperModels from '@/Models/—HelperModels—';

@Component({ name: 'ApprovalStateBadge' })
export default class ApprovalStateBadge extends Mixins(AppConstantsMixin) {
  @Prop({ default: 1 }) readonly status!: HelperModels.AccessState;
  @Prop(String) readonly text!: string;
}

import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';

import AppConfig from '@/App/Typescript/AppConfig';
import * as UploadModels from '@/Models/UploadModels';

export default class UploadRest {
  public Uploader(req: UploadModels.UploaderRequest): XeoTypes.XeoPromise {
    const formData = new FormData();
    formData.append('file', req.File);

    return XeoBibliotheca.AxiosCodex.Post(
      `${AppConfig.EndpointsUrl.File}/Uploader?type=${req.Type}&${req.QueryParams || ''}`, formData
    );
  }
}
import { render, staticRenderFns } from "./SideNavAccountModule.vue?vue&type=template&id=78d0a4fc&lang=pug&"
import script from "./SideNavAccountModule.vue?vue&type=script&lang=ts&"
export * from "./SideNavAccountModule.vue?vue&type=script&lang=ts&"
import style0 from "./SideNavAccountModule.vue?vue&type=style&index=0&id=78d0a4fc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
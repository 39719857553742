
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFeStateMixin from '☆XeoApp/Vue/Mixins/XeoFeStateMixin';

@Component({
  name: 'XeoMenuItem'
})
export default class XeoMenuItem extends Mixins(XeoBaseMixin, XeoFeStateMixin) {
  @Prop(Number) readonly depth!: number;  
  @Prop(Boolean) readonly isActive!: boolean;
  @Prop(Boolean) readonly hasPrependLine!: boolean;
  @Prop() readonly linkTo!: any;
  private IsCollapse: boolean = false;
  private get HasSlotCollapse(): boolean { 
    return !this.IsRouterLink && this.IsSlotExist('ItemCollapse'); 
  }
  private get IsRouterLink(): boolean { return Boolean(this.linkTo); }
  private get ItemClass() {
    return {
      'active': this.isActive,
      'disabled': this.disabled,    'read-only': this.readOnly,
      'is-collapse': this.IsCollapse, 
    };
  }

  protected mounted() {
    this.SetIsCollapse(this.isActive);
  }

  protected MenuItemMain_Click() {
    if (!this.IsCoreDisabled) {
      this.SetIsCollapse(!this.IsCollapse);
      this.$emit('click');
      if (this.IsRouterLink)    this.$router.push(this.linkTo).catch(_ => {});
    }
  }

  public SetIsCollapse(cond: boolean) {
    if (this.HasSlotCollapse) {
      this.IsCollapse = cond;
    }
  }

  @Watch('isActive')
  protected isActive_Change(newValue: boolean) {
    this.SetIsCollapse(newValue);
  }
}

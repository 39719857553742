
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppConstantsMixin from '@/Mixins/AppConstantsMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';
import excelJs from 'exceljs';
import moment from 'moment';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import TmAttendanceQueries from '@/Repositories/Graphql/TmAttendanceQueries';

import { Attendance, AtdTemplateColumnName } from '@/Models/TimeManagementModels';

import XeoDropdown from '☆XeoApp/Vue/Components/Base/XeoDropdown.vue';
import XeoDateTimePicker from '☆XeoApp/Vue/Components/Base/XeoDateTimePicker.vue';
import XeoFilePicker from '☆XeoApp/Vue/Components/Base/XeoFilePicker.vue';

@Component({
  name: 'AttendanceEditorModal',
  filters: {
    RowErrorsFormat(errs: any[], name: AtdTemplateColumnName): string {
      if (!errs?.length)    return '';

      let limit!: number,
          msgSubjectTxt: [string, string] = ['', ''],
          strErrs!: string[];
      if (name == 'Staff') {
        limit = 20;
        msgSubjectTxt = ['', ' staff'];
        strErrs = errs.slice(0, limit);
      } else {
        limit = 50;
        msgSubjectTxt = ['Baris ', ' baris'];
        strErrs = errs.slice(0, limit).map(r => XeoBibliotheca.NumberCodex.Format(r, '0,0'));
      }
      
      if (1 < errs.length && errs.length <= limit) {
        strErrs[strErrs.length - 1] = `dan ${strErrs[strErrs.length - 1]}`;
      }

      return msgSubjectTxt[0]
        + strErrs.join(errs.length > 2 ? ', ' : ' ') 
        + (errs.length > limit ? 
          `, dan ${XeoBibliotheca.NumberCodex.Format(errs.length - limit, '0,0')}`
            + `${msgSubjectTxt[1]} lainnya` : 
          ''
        );
    }
  }
})
export default class AttendanceEditorModal extends Mixins(
  XeoBaseMixin, XeoFormMixin, XeoModalMixin, 
  AppAccessMixin, AppConstantsMixin, AppRenderMixin, AppToastMixin
) {
  $refs!: {
    DdlAccountId: XeoDropdown,
    DtpDatePeriod: XeoDateTimePicker,
    DtpScheduleRange: XeoDateTimePicker,
    DtpTimeRange: XeoDateTimePicker,

    FpAttendanceList: XeoFilePicker
  };

  private get StaffList() { return DataStore.Staffs; }
  private get TmSettings() { return DataStore.TmSettings; }

  @Prop() readonly attendance!: Attendance;
  private Mode: '' | 'one' | 'many' = '';
  private FormAttendance: Attendance = new Attendance();
  private MassAttendance = {
    Form: [] as Attendance[],
    CrErrorRec: {} as Partial<Record<AtdTemplateColumnName, any[]>>,
    IsLoadingFile: false,
    IsSavingForm: false,
      SaveProgress: { Value: 0, Max: 0 },
      IsSavePaused: false,
      SaveErrorMessage: ''
  };
  private ColumnNameRec = {
    EmployeeId: 'Id Karyawan',
    DisplayName: 'Nama Staff',
    DatePeriod: 'Tanggal',
    ScheduleIn: 'Jadwal Masuk',
    ScheduleOut: 'Jadwal Keluar',
    ClockIn: 'Waktu Masuk',
    ClockOut: 'Waktu Keluar'
  };
  private get IsAtdFullAccess(): boolean {
    return this.GetUserAccessState('Client', 'Tm_Attendance') == 100;
  }
  private get IsDisableClose(): boolean {
    const massAtd = this.MassAttendance;
    return massAtd.IsSavingForm && !massAtd.IsSavePaused;
  }
  private get IsSavingAnyForm(): boolean {
    return this.IsSavingForm || (
      this.MassAttendance.IsSavingForm && !this.MassAttendance.IsSavePaused
    );
  }
  private get IsShowAtdSummary(): boolean {
    return !this.MassAttendance.IsLoadingFile && 
      Object.values(this.MassAttendance.CrErrorRec).reduce(
        (c, rows) => c + rows.length, this.MassAttendance.Form.length
      ) > 0;
  }
  private get IsUniqueReadOnly(): boolean {
    return !this.IsAtdFullAccess || Boolean(this.FormAttendance.Id);
  }

  protected BtnBack_Click() {
    if (this.Mode && !this.FormAttendance.Id)     this.Mode = '';
    else                                          this.close(); 
  }
  protected BtnSave_Click() {
    if (XeoBibliotheca.FormCodex.ValidateFormViaRefs(this.$refs)) {
      if (this.Mode == 'one')         this._AdditAttendance();
      else if (this.Mode == 'many')   this._AdditAttendanceList();
    }
  }
  protected FpAttendanceList_Change(atdFile?: File) {
    if (this.$refs.FpAttendanceList.ValidateValue()) {
      this.MassAttendance.IsLoadingFile = true;
      this._AsyncLoadMappedFormAtdListFromFile(atdFile!).catch(err => {
        this.$refs.FpAttendanceList.AddValidationResultData(
          new XeoTypes.ValidationResultData({
            Message: 'File tidak dapat dibaca oleh sistem',
            Status: false
          })
        );
      }).finally(() => {
        this.MassAttendance.IsLoadingFile = false;
      });
    } else {
      this.__ResetMassAttendance();
    }
  }
  protected LnkTemplate_Click() {
    this._AsyncDownloadAtdTemplate();
  }
  protected MdlAtdEditor_Show() {
    this.Mode = this.attendance.Id ? 'one' : '';
    this.FormAttendance = new Attendance(this.attendance);
    this.__ResetMassAttendance();
  }
  protected SetMode_Act(mode: 'one' | 'many') {
    this.Mode = mode;
  }

  private _AdditAttendance() {
    this.IsSavingForm = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${TmAttendanceQueries.Axenta_AdditAttendance(this.FormAttendance.MapToServerReq())}
      }
    `).xeoThen(
      (data) => {
        this.MakeSuccessToast(this.$t(`Success.save`, { name: 'Kehadiran' }));
        this.$emit('save');
        this.close();
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false;
    });
  }
  private async _AdditAttendanceList() {
    const massAtd = this.MassAttendance,
          batchSize = 500;
    let isBreak = false,
        totalAddited = 0;
    
    this.__SetMassAttendanceSavingState();
    while (true) {
      await AppRepositories.Graphql.DoAuthGraphql(`
        mutation { ${
          TmAttendanceQueries.Axenta_BatchAdditAttendance(
            massAtd.Form.slice(0, batchSize)
          )
        } }
      `).xeoThen(
        (data) => {
          massAtd.SaveProgress.Value += batchSize;
          massAtd.Form.splice(0, batchSize);
          totalAddited += data.Axenta_BatchAdditAttendance.AttendanceCreated;

          if (massAtd.Form.length == 0) {
            this.MakeSuccessToast(
              this.$t(`Success.save`, { 
                name: `${XeoBibliotheca.NumberCodex.Format(totalAddited, '0,0')} Kehadiran` }
              )
            );
            this.$emit('save');
            massAtd.IsSavingForm = false;
            this.close();
            isBreak = true;
          }
        },
        (errors) => {
          massAtd.IsSavePaused = true;
          massAtd.SaveErrorMessage = this.$t('Errors.server').toString();
          isBreak = true;
        }
      ).catch((err) => {
        massAtd.IsSavePaused = true;
        massAtd.SaveErrorMessage = this.$t('Errors.network-sm').toString();
        isBreak = true;
      });

      if (isBreak)    break;
    }
  }
  private async _AsyncDownloadAtdTemplate() {
    let wb!: excelJs.Workbook;
    await XeoBibliotheca.FileCodex.ExcelLoadFile(
      'url', require('@/Assets/Documents/AttendanceList.xlsx')
    ).then((workbook) => { wb = workbook; });

    //* Render Attendance Template *//
    const ws = wb.getWorksheet(1),
          widthRec: Record<any, number> = {
            EmployeeId:   24.25,
            DisplayName:  33,
            '—':          15
          };
    let   maxColumnIdx = 0;

    /* Fill & Format Existing Headers */
    Object.entries(this.TmSettings.MassAtdTemplate.ColumnRec).forEach(([colName, col]) => {
      if (col.Column == 0)    return;
      
      const headerRt: excelJs.RichText[] = [];
        headerRt.push({ text: `${(this.ColumnNameRec as any)[colName]}` });
        if (col.TimeFormat) {
          headerRt.push({ font: { color: { argb: 'ff2d98da' } }, text: `\r\n${col.TimeFormat}` });
        }
      ws.getCell(1, col.Column).value = { richText: headerRt };
      ws.getColumn(col.Column).width = widthRec[colName] || widthRec['—'];

      if (maxColumnIdx < col.Column)    maxColumnIdx = col.Column;
    });

    /* Format Empty Columns & Header Bottom Border */
    for (let i = 1; i <= maxColumnIdx; i++) {
      if (!ws.getCell(1, i).value)    ws.getColumn(i).width = 0;
      ws.getCell(1, i).border = {
        bottom: { style: 'medium', color: { argb: 'ff2c3e50' } }
      };
    }

    await wb.xlsx.writeBuffer().then((buff) => {
      XeoBibliotheca.FileCodex.SaveFile('Template – Data Absensi.xlsx', buff);
    });
  }
  private async _AsyncLoadMappedFormAtdListFromFile(atdFile: File) {
    let ws!: excelJs.Worksheet;
    await XeoBibliotheca.FileCodex.ExcelLoadFile('input-file', atdFile)
      .then(wb => { ws = wb.getWorksheet(1); })
      .catch(err => {
        this.__ResetMassAttendance();    
        throw err;
      });
      
    /* Load Form & CrErrorsRec */
    const atdList: Attendance[] = [],   
          crErrorRec: Partial<Record<AtdTemplateColumnName, any[]>> = {
            'Staff': [],
            'DatePeriod': [],
            'ScheduleIn': [],     'ScheduleOut': [],
            'ClockIn': [],         'ClockOut': [] 
          },
          staffErrorRec: Record<string, boolean> = {};
    const columnRec = this.TmSettings.MassAtdTemplate.ColumnRec,
          staffList = Object.values(this.StaffList), 
          staffRvrsRec: Record<string, number> = {};    // DisplayName | EmployeeId to AccountId
    for (let row: number = 2; true; row++) {
      const atd = new Attendance();
      let   hasError: boolean = false;

      /* On these conditions — Break & Continue */
      const isStaffEmpty = 
        (columnRec.EmployeeId.Column == 0 || !ws.getCell(row, columnRec.EmployeeId.Column).value) &&
        (columnRec.DisplayName.Column == 0 || !ws.getCell(row, columnRec.DisplayName.Column).value);
      if (isStaffEmpty)   break;

      const isClockEmpty = !ws.getCell(row, columnRec.ClockIn.Column).value &&
        !ws.getCell(row, columnRec.ClockOut.Column).value;
      if (isClockEmpty)   continue;

      /* Staff — via EmployeeId & DisplayName — break on Empty */
      let excValue: any;
      ['EmployeeId', 'DisplayName'].forEach((colName) => {
        if ((columnRec as any)[colName].Column == 0 || atd.AccountId)    return;

        excValue = ws.getCell(row, (columnRec as any)[colName].Column).value?.toString();

        if (!(excValue in staffRvrsRec)) {
          staffRvrsRec[excValue] = staffList.find((s: any) => {
            return colName == 'DisplayName' ? 
              s[colName].replace(/[\s\.]+/g, '').localeCompare(
                excValue.replace(/[\s\.]+/g, ''), undefined, { sensitivity: 'base' }
              ) == 0 :
              s[colName] == excValue;
          })?.AccountId || -1;
        }
        if (staffRvrsRec[excValue] > 0) {
          atd.AccountId = staffRvrsRec[excValue];
        }
      });

      if (!atd.AccountId) { 
        staffErrorRec[excValue] = true; 
        hasError = true; 
      }

      /* Other Columns */
      ['DatePeriod', 'ScheduleIn', 'ScheduleOut', 'ClockIn', 'ClockOut'].forEach((colName) => {
        const anyAtd = atd as any,
              excValue = ws.getCell(row, (columnRec as any)[colName].Column).value?.toString();

        if (!excValue && colName.startsWith('Clock'))    return;

        anyAtd[colName] = moment(excValue, (columnRec as any)[colName].TimeFormat);
        if (!anyAtd[colName].isValid())   { 
          (crErrorRec as any)[colName].push(row); 
          hasError = true; 
        }
      });

      if (!hasError) { 
        if (!ws.getCell(row, columnRec.ClockIn.Column).value)    atd.ClockIn = atd.ClockOut.clone();
        if (!ws.getCell(row, columnRec.ClockOut.Column).value)   atd.ClockOut = atd.ClockIn.clone();

        atdList.push(atd.MapToServerReq());
      }
    }

    this.MassAttendance.Form = atdList;
    this.MassAttendance.CrErrorRec = Object.assign(
      crErrorRec, { Staff: Object.keys(staffErrorRec) }
    );
  }
  
  private __ResetMassAttendance() {
    this.MassAttendance = {
      Form: [] as Attendance[],
      CrErrorRec: {} as Partial<Record<AtdTemplateColumnName, any[]>>,
      IsLoadingFile: false,
      IsSavingForm: false,
        SaveProgress: { Value: 0, Max: 0 },
        IsSavePaused: false,
        SaveErrorMessage: ''
    };
  }
  private __SetMassAttendanceSavingState() {
    const massAtd = this.MassAttendance;
    if (!massAtd.IsSavePaused) {
      massAtd.SaveProgress = { Value: 0, Max: this.MassAttendance.Form.length };
    }

    massAtd.IsSavingForm = true;
    massAtd.IsSavePaused = false;
    massAtd.SaveErrorMessage = '';
  }
}

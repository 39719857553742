import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';

import AppConfig from '@/App/Typescript/AppConfig';

export default class Graphql {
  private $router!: any;

  constructor($router: any) {
    this.$router = $router;
  }

  public DoAuthGraphql(query: string): XeoTypes.XeoPromise {
    return new XeoTypes.XeoPromise(
      XeoBibliotheca.AxiosCodex.Post(
        AppConfig.EndpointsUrl.AuthGraphql, { query }
      ).then((resp) => {
        /* Jwt Token Error — Redirect to Auth Page */
        const isRedirect = XeoBibliotheca.ErrorCodex.IsJwtError(resp.data.Errors) &&
          this.$router.currentRoute.name != 'Authentication';

        if (isRedirect) {
          this.$router.replace({  
            name: 'Authentication', query: {  
              redirect: `${this.$router.currentRoute.path}`  
            }  
          }); 
          return null;
        }
      
        return resp;
      })
    );
  }
  public DoGraphql(query: string): XeoTypes.XeoPromise {
    return XeoBibliotheca.AxiosCodex.Post(
      AppConfig.EndpointsUrl.Graphql, { query }
    );
  }
}

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'XeoGoogleIdentity'
})
export default class XeoGoogleIdentity extends Vue {
  //* References *//
  //  https://developers.google.com/identity/gsi/web/guides/display-button#javascript
  //  https://developers.google.com/identity/gsi/web/reference/html-reference

  @Prop(String) clientId!: string;
  @Prop({default: 'button'}) mode!: 'all' | 'button' | 'one-tap';
  @Prop() btnParams!: object;

  protected mounted() {
    const self = this;

    document.getElementById('☆_script')!.onload = function() {
      // @ts-ignore: `google` Object
      const g_id = google.accounts.id;

      g_id.initialize({
        client_id: self.clientId,
        callback: (resp: any) => { self.$emit('success', resp); }
      });

      if (['all', 'button'].includes(self.mode)) {
        g_id.renderButton(
          document.getElementById('☆_container'), 
          Object.assign({ locale: self.$i18n.locale, shape: 'pill' }, self.btnParams)
        );
      }

      if (['all', 'one-tap'].includes(self.mode)) {
        g_id.prompt();
      }
    };
  }
}

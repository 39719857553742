
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';
import moment from 'moment';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import PayrollOtPaymentQueries from '@/Repositories/Graphql/PayrollOtPaymentQueries';
import { OneTimePayment } from '@/Models/PayrollModels';

import XeoDateTimePicker from '☆XeoApp/Vue/Components/Base/XeoDateTimePicker.vue';

@Component({
  name: 'PayrollOtpListModule'
})
export default class PayrollOtpListModule extends Mixins(
  XeoBaseMixin, XeoFormMixin, AppAccessMixin, AppToastMixin
) {
  $refs!: {
    DtpPeriod: XeoDateTimePicker
  }

  private get StaffList() { return DataStore.Staffs; }

  @Prop() timePeriod!: moment.Moment;
  private OneTimePayments: OneTimePayment[] = [];
  private FormRequest = {
    TimePeriod: moment()
  };

  protected created() {
    this.FormRequest.TimePeriod = this.timePeriod;
    this._GetMonthlyPayrollOtPayments();
  }

  protected UpsertPayroll_Act(p: OneTimePayment | '+') {
    const otpId: string = (typeof p == 'object') ? p.Id.toString() : p;
    this.SamePageNavigate({ 
      name: 'Payroll_OtPayment', params: { otpId } 
    });
  }
  protected DtpPeriod_Change(timePeriod: moment.Moment) {
    this._GetMonthlyPayrollOtPayments();
    this.$router.replace({ 
      name: 'Payroll_OtPayment', query: { tp: timePeriod.format('YYYY-MM') } 
    }).catch(_ => {});
  }

  private _GetEditorDispName(id: number) {
    return this.StaffList[id]?.DisplayName || '–';
  }
  private _GetMonthlyPayrollOtPayments() {
    this.IsLoading = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${PayrollOtPaymentQueries.Axenta_GetOtPaymentsByPeriod(
          this.FormRequest.TimePeriod.clone().startOf('month'), 
          this.FormRequest.TimePeriod.clone().endOf('month')
        )}
      }
    `).xeoThen(
      (data) => {
        this.OneTimePayments = data.Axenta_GetOtPaymentsByPeriod
          .map((otp: OneTimePayment) => new OneTimePayment(otp));
        this.IsLoading = false;
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });
  }
}

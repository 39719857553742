import { Vue, Component } from '☆Node/vue-property-decorator';
import { DataStore, StateStore } from '@/Store/—–AppStore–—';

import { AccessState } from '@/Models/—HelperModels—';

@Component
export default class AppAccessMixin extends Vue {
  public get IsPageNoAccess(): boolean { return StateStore.PageAccess == 0; }
  public get IsPageReadonly(): boolean { return StateStore.PageAccess == 1; }
  public get IsPageFullAccess(): boolean { return StateStore.PageAccess == 100; }
  private get AccessRvrsMap(): Record<string, string> {
    const map: Record<string, string> = {};
    Object.entries(DataStore.Constants.AccessControl?.Map || {}).forEach(
      ([accessId, pageId]) => { map[pageId as string] = accessId; }
    );
    
    return map;
  }
  private get IsOwnerAcc(): boolean {
    const ownerIds = [null, 1];
    return ownerIds.includes(DataStore.Account.DivisionId) && 
      ownerIds.includes(DataStore.Account.JobId);
  }

  public GetUserAccessState(type: 'Client' | 'Server', accessId: string): AccessState {
    const userAccessMap = DataStore.AccessMaps[type];
    accessId = this._NormalizeAccessId(accessId);

    return userAccessMap['*'] || (accessId ? userAccessMap[accessId] || 0 : 100);
  }
  public IsRouteAccessValid(route: any): boolean {
    const pageAccessState = this.GetUserAccessState('Client', route.meta?.accessId);
    
    return (pageAccessState > 0 || route.meta?.accessIgnore) &&
      (!route.name?.startsWith('Setup_') || this.IsOwnerAcc);
  }

  private _NormalizeAccessId(accessId: string): string {
    return !accessId || !isNaN(Number(accessId.replaceAll('.', ''))) ? 
      accessId : this.AccessRvrsMap[accessId];
  }
}
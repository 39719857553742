
import { Vue, Component, Mixins, Prop } from '☆Node/vue-property-decorator';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';

@Component({
  name: 'XeoCard'
})
export default class XeoCard extends Mixins(XeoBaseMixin) {
  @Prop(String) noPadding!: 'all' | 'x' | 'y';
  private get LayoutClass() {
    return {
      'p-0': this.noPadding == 'all',
      'px-0': this.noPadding == 'x',
      'py-0': this.noPadding == 'y'
    };
  }
}

import Vue from 'vue';
import * as bv from '☆Node/bootstrap-vue';
import VueApexcharts from '☆Node/vue-apexcharts';
import VueSimplebar from 'simplebar-vue';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

export default new class XeoComponents {
  public RegisterAllToVue() {
    this.RegisterOptBootstrapVue();
    this.RegisterXeoBaseComponents();
    this.RegisterApexchart();

    Vue.component('Simplebar', VueSimplebar);
  }

  public RegisterApexchart() {
    Vue.use(VueApexcharts);
    Vue.component('Apexchart', VueApexcharts);

    /* @ts-ignore */
    const apex: any = Apex;
    apex.colors = XeoBibliotheca.ColorCodex.GetColorSet('base', 3);
  }
  public RegisterOptBootstrapVue() {
    const bvPlugins: bv.BvPlugin[] = [
      bv.CollapsePlugin, bv.FormCheckboxPlugin, bv.FormFilePlugin, bv.FormInputPlugin, 
      bv.FormRadioPlugin, bv.FormTextareaPlugin, bv.LinkPlugin, bv.ModalPlugin, 
      bv.OverlayPlugin, bv.PopoverPlugin, bv.ProgressPlugin, bv.SkeletonPlugin, 
      bv.SpinnerPlugin, bv.ToastPlugin
    ];

    bvPlugins.forEach(bvPlugin => Vue.use(bvPlugin));
  }
  public RegisterXeoBaseComponents() {
    const requireComponent = require.context(
      '☆XeoApp/Vue/Components', true, /X\w+\.vue$/
    );

    requireComponent.keys().forEach((fileName: string) => {
      const componentConfig = requireComponent(fileName);

      if (fileName) {
        const componentName = fileName
          .replace('./', '')
          .replace(/^\w+\//, '')
          .replace(/\.\w+$/, '');

        Vue.component(componentName, componentConfig.default || componentConfig);
      }
    });
  }
}
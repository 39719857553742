import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import { GetAttendanceListRequest, Attendance } from '@/Models/TimeManagementModels';

export default new class TmAttendanceQueries {
  public Axenta_GetAttendanceList(req: GetAttendanceListRequest): string {
    return `Axenta_GetAttendanceList(
      req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)}
    )`;
  }
  public Axenta_AdditAttendance(req: Attendance): string {
    return `Axenta_AdditAttendance(req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)})`;
  }
  public Axenta_BatchAdditAttendance(req: Attendance[]): string {
    return `Axenta_BatchAdditAttendance(
      req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)}
    )`;
  }
}
const deepClone = require('☆Node/rfdc')({ proto: true });

export default new class XeoUtilities {
  public CharacterSetMap: Map<string, string> = new Map<string, string>([
    ['alpha', 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'],
    ['alpha-num', '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'],
    ['num', '0123456789']
  ]);
  
  public DeepClone(src: any): any {
    return deepClone(src);
  }
  public DeepObjectAccess(obj: object, accessor: string): any {
    return accessor.split('.').reduce((o: any, n: string) => o[n], obj);
  }
  public RenderTemplateStrByMap(tmpltStr: string, tmpltMap: Map<string, string>): string {
    tmpltMap.forEach((val, key) => {
      tmpltStr = tmpltStr.replace(new RegExp(`{${key}}`, 'g'), val);
    });

    return tmpltStr;
  }
  public ParseFileSizeStr(fs: string): number {
    const prefixRec: Record<string, number> = {
      'B': 1,           'KB': 1 << 10,          'MB': 1 << 20,
      'GB': 1 << 30,    'TB': 1 << 40 
    };

    return Number(fs.replace(/[A-Za-z]/g, '')) 
      * prefixRec[fs.replace(/[0-9.]/g, '').toUpperCase()];
  }
  public ValidateFileType(file: File, acceptedFt: string): boolean {
    return acceptedFt == '' || acceptedFt == '*' || 
      acceptedFt.split(',').some((ft: string) => {
        return ft.endsWith('*') ? file.type.startsWith(ft.replace('*', '')) : file.type == ft;
      });
  }
}

import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';

@Component({
  name: 'SetupComplete'
})
export default class SetupComplete extends Vue {
  protected BtnDone_Click() {
    this.$router.push({ name: 'Dashboard' });
  }
}

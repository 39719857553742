import { Store } from 'vuex';
import { Module, VuexModule, Mutation } from '☆Node/vuex-class-modules';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

import { AccessState } from '@/Models/—HelperModels—';

type AppStateKey = 'PageAccess' | 'Preloader' | 'SideNavigation';
type WindowStateKey = 'InnerWidth' | 'InnerHeight' | 'ScrollX' | 'ScrollY';

@Module
export default class StateModule extends VuexModule {
  constructor(store: Store<any>) {
    super({ name: 'StateModule', store });
  }

  public PageAccess: AccessState = 100;
  public Preloader: boolean = false;
  public SideNavigation: boolean = XeoBibliotheca.DisplayCodex.IsDisplayExceed('lg');
  public Window = {
    InnerWidth: window.innerWidth,      InnerHeight: window.innerHeight,
    ScrollX   : window.scrollX,         ScrollY    : window.scrollY
  };

  @Mutation
  public SetAppStates(stateRec: Partial<Record<AppStateKey, any>>) {
    Object.entries(stateRec).forEach(([key, state]) => {
      (this as any)[key] = state;
    });
  }
  @Mutation
  public SetWindowStates(stateRec: Partial<Record<WindowStateKey, any>>) {
    Object.entries(stateRec).forEach(([key, state]) => {
      (this.Window as any)[key] = state;
    });
  }
}
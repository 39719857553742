import VueI18n from '☆Node/vue-i18n';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

/* App Internationalization */
export default class AppI18n extends VueI18n {
  constructor() {
    /* Initialize VueI18n */
    super({
      locale: 'id',
      fallbackLocale: 'en',
      messages: {
        en: {
          global: {
            Brand: 'Axenta',
            Or: 'Or',
          },
          AuthModule: {
            FormLogIn: {
              TxtUsernameOrEmail: 'Username or Email',
              TxtPassword: 'Password',
              BtnLogIn: 'Log In',
              LabToSignUp: "Don't have an account?",
              LnkToSignUp: 'Sign Up now',
            },
            FormSignUp: {
              TxtFullname: 'Full Name',
              TxtUsername: 'Username',
              TxtEmailAddress: 'Email Address',
              TxtPassword: 'Password',
              BtnSignUp: 'Sign Up',
              LabToLogIn: 'Already have an account?',
              LnkToLogIn: 'Log In now',
            },
            Title: {
              LogIn: 'Log In',
              SignUp: 'Sign Up',
              ContinueAs: 'Continue As'
            },
            Errors: {
              'app: user-not-found': 'Username or Email not found',
              'app: wrong-password': 'Wrong Password',
              'popup_closed_by_user': 'Google Sign-In cancelled',
            }
          },
          Errors: {
            'network': 'Problem connecting with Server <br>'
              + 'Please check your internet connection and try again'
          }
        },
        id: {
          global: {
            Brand: 'Axenta',
            Or: 'Atau',
          },
          AppMenuList: {
            Terms: 'Syarat & Ketentuan',
            Setup: 'Konfigurasi Awal',
            Setup_Welcome: 'Halo!',
            Setup_Company: 'Data Perusahaan',
            Setup_CompanyCuts: 'Detail Pembayaran',
            Setup_Staff: 'Karyawan & Staff',
            Setup_Complete: 'Selesai',
            Dashboard: 'Dashboard',
            Company: 'Perusahaan',
            Company_Details: 'Data Perusahaan',
            Company_Cuts: 'Detail Pembayaran',
            Company_Structure: 'Struktur Organisasi',
            Staff: 'Karyawan & Staff',
            Staff_List: 'Daftar Staff',
            Staff_Access: 'Managemen Akses',
            TimeManagement: 'Managemen Waktu',
            Tm_Overtime: 'Lembur',
            Tm_Timeoff: 'Izin & Cuti',
            Tm_Attendance: 'Kehadiran',
            Payroll: 'Payroll',
            Payroll_Summary: 'Daftar Payroll',
            Payroll_OtPayment: 'Payroll Non-Rutin',
            Payroll_AllowanceCuts: 'Tunjangan & Potongan',
            Account: 'Akun Saya',
            Account_Details: 'Detail Akun',
            Account_ChangePassword: 'Ganti Password'
          },
          AuthModule: {
            Form: {
              LogIn: {
                TxtUsernameOrEmail: 'Username atau Email',
                TxtPassword: 'Password',
                BtnLogIn: 'Masuk',
                NavLink: {
                  Prefix: 'Belum punya akun?',
                  Text: 'Buat sekarang →'
                }
              },
              SignUp: {
                TxtFullname: 'Nama Lengkap',
                TxtUsername: 'Username',
                TxtEmailAddress: 'Alamat Email',
                TxtPassword: 'Password',
                BtnSignUp: 'Buat Akun',
                NavLink: {
                  Prefix: 'Sudah punya akun?',
                  Text: 'Masuk sekarang →'
                }
              },
              ContinueAs: {
                BtnContinueAs: 'Masuk ke Aplikasi',
                NavLink: {
                  Prefix: 'Bukan anda?',
                  Text: 'Masuk dengan akun lain →'
                }
              }
            },
            Title: {
              LogIn: 'Log In',
              SignUp: 'Buat Akun',
              ContinueAs: 'Akun Saat Ini'
            },
            Errors: {
              'app: user-not-found': 'Username atau Email tidak ditemukan.',
              'app: user-deleted': 'Akun ini telah ditutup.',
              'app: wrong-password': `Password yang dimasukkan salah`,
              'popup_closed_by_user': `Autentikasi Google dibatalkan`
            }
          },
          CompanyDetails: {
            Errors: {
              'http: invalid-file-type': 'Tipe file harus berupa gambar',
              'http: file-size-exceeded': 'Ukuran file terlalu besar',
              'update-brand-failed': `
                <div class='toast-title'>Data berhasil disimpan</div>
                <div>Namun logo perusahaan gagal diperbaharui</div>
              `
            }
          },
          DivJobEditorModal: {
            Errors: {
              'app: circular-parent': 'Induk adalah turunan dari/atau {type} ini.',
              'app: dj-has-children': '{type} masih memiliki turunan',
              'app: dj-on-use': '{type} masih digunakan oleh beberapa staff'
            }
          },
          StaffListPage: {
            SalaryType: {
              AsItem : {
                Daily: 'Harian',
                Weekly: 'Mingguan',
                Monthly: 'Bulanan'
              },
              AsSuffix : {
                Daily: 'hari',
                Weekly: 'minggu',
                Monthly: 'bulan'
              }
            },
            Errors: {
              'incomplete': 'Data staff belum semuanya lengkap'
            }
          },
          StaffMenuModule: {
            Errors: {
              'app: cannot-delete-staff': `Staff ini tidak dapat dihapus dari sistem`,
              'app: no-staff-matched': `Password staff ini tidak dapat di-reset`,
            }
          },
          TmTimeoffModule: {
            Errors: {
              'app: timeoff-zero-days': 'Tidak ada hari kerja dalam rentang cuti ini',
              'app: timeoff-zero-days_modal': `
                <div class='toast-title'>Cuti tidak disimpan</div>
                <div>Tidak ada hari kerja dalam rentang cuti ini</div>
              `
            }
          },
          Constants: {
            AccessStates: {
              '—': 'Kosong',
              'Read-Only': 'Baca',
              'Full Access': 'Akses Lengkap'
            },
            EmploymentStatuses: {
              'Permanent': 'Permanen',
              'Contract': 'Kontrak',
              'Probation': 'Masa Percobaan'
            },
            MaritalStatuses: {
              'Single': 'Belum Kawin',
              'Married': 'Kawin',
              'Widow': 'Cerai Mati',
              'Widower': 'Cerai Hidup'
            },
            OtpSourceTypes: {
              'Take-Home Pay': 'Take-Home Pay',
              'Base Salary': 'Gaji Pokok'
            },
            Religions: {
              'Islam': 'Islam',
              'Christian': 'Kristen Protestan',
              'Catholic': 'Kristen Katolik',
              'Buddhism': 'Buddha',
              'Hinduism': 'Hindu',
              'Confucianism': 'Konfusianisme'
            },
            SalaryTypes: {
              'Monthly': 'Bulanan',
              'Weekly': 'Mingguan',
              'Daily': 'Harian'
            },
            SsBaseCalculationTypes: {
              'Default': 'Sesuai aturan perusahaan',
              'Salary & Allowances': 'Gaji & Tunjangan Tetap',
              'Base Salary': 'Gaji Pokok',
              'Custom Value': 'Nominal Kustom'
            },
            SsPayers: {
              'Default': 'Sesuai aturan perusahaan',
              'Split': 'Perusahaan & Individu',
              'Company': 'Perusahaan',
              'Staff': 'Individu',
              'Not Paid': 'Tidak Dibayarkan',
              'Custom': 'Kustom'
            },
            TaxCategories: {
              'Salary': 'Gaji',
              'TaxAccomodation': 'Tunjangan PPh 21',
              'Overtimes': 'Upah Lembur',
              'Honoraries': 'Honorarium',
              'InsurancePremiums': 'Premi Asuransi',
              'Non-Cash': 'Natura & Bukan Uang',
              'Bonuses': 'Bonus, Gratifikasi, Jasa Produksi, dan THR',
              'PensionFunds': 'Iuran Pensiun',
              'Untaxed': 'Bukan Objek PPh 21'
            },
            TaxCutMethods: {
              'Default': 'Sesuai aturan perusahaan',
              'Gross': 'Gross',
              'Nett': 'Nett',
              'Gross Up': 'Gross Up',
              'Not Paid': 'Tidak Dibayarkan'
            },
            TaxMaritalStatuses: {
              'Single': 'Tidak Kawin',
              'Married': 'Kawin',
              'Widows': 'Hidup Berpisah'
            },
          },
          Errors: {
            'not-found': `{name} tidak ditemukan`,
            'network': `
              <div class='toast-title'>Koneksi ke Server terputus</div>
              <div class='d-none d-md-block'>Cek koneksi Internet anda dan coba kembali</div>
            `,
            'network-sm': 'Koneksi ke Server terputus',
            'server': `Terjadi kesalahan pada server`
          },
          Success: {
            'approve': `{name} berhasil disetujui`,
            'change': `{name} berhasil diubah`,
            'delete': `{name} berhasil dihapus`,
            'reject': `{name} berhasil ditolak`,
            'save': `{name} berhasil disimpan`
          },
          NumeralFormat: {
            delimiters: {
              thousands: '.',
              decimal: ','
            },
            abbreviations: {
              thousand: 'Ribu',
              million: 'Juta',
              billion: 'Milyar',
              trillion: 'Triliun'
            },
            currency: {
              symbol: 'Rp. '
            }
          }
        },
      },
    });

    /* Register Numeral Formats */
    Object.entries(this.messages).forEach(([key, val]) => {
      if (val.NumeralFormat) {
        XeoBibliotheca.NumberCodex.NumeralJs.register('locale', key, val.NumeralFormat);
      }
    });

    /* Configure XeoBibliotheca Locale */
    XeoBibliotheca.SetLocale(this.locale);
  }
}

import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppUtils from '@/Utilities/AppUtils';
import moment from 'moment';

import PayrollOtpListModule from '@/Components/PayrollOtPayment/PayrollOtpListModule.vue';
import PayrollOtpSummaryModule from '@/Components/PayrollOtPayment/PayrollOtpSummaryModule.vue';

@Component({
  name: 'PayrollOtPayment',
  components: { PayrollOtpListModule, PayrollOtpSummaryModule }
})
export default class PayrollOtPayment extends Mixins(AppAccessMixin) {
  @Prop() otpId!: '+' | number;
  @Prop() timePeriod!: moment.Moment;
  private get HasValidId(): boolean {
    return AppUtils.IsRouteIdValid(this.otpId, this.IsPageFullAccess);
  }

  protected created() {
    if (!this.HasValidId) {
      this.$router.replace({ name: 'Payroll_OtPayment' });
    }
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';

//∴ *** XeoActionModal Usage Notes *** ∴//
//  1.  Unstackable – Use XeoModalMixin to create stackable modals.

@Component({
  name: 'XeoActionModal'
})
export default class XeoActionModal extends Mixins(XeoBaseMixin, XeoModalMixin) {
  @Prop(String) noPadding!: 'x' | 'y' | 'xy';
  private get Attributes() {
    return Object.assign({}, this.ModalProps, this.$attrs);
  }
  private get AmClass() {
    return [
      this.ModalClass, 'XeoActionModal-modal', { 'sidenav-active': this.IsShowSideNav }, 
      this.$attrs['modal-class']
    ];
  }
  private get LayoutClass() {
    return {
      'px-0': this.noPadding == 'x',
      'py-0': this.noPadding == 'y',
      'p-0': this.noPadding == 'xy'
    };
  }
}

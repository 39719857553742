import { Vue, Component, Prop } from '☆Node/vue-property-decorator';

@Component
export default class XeoFeStateMixin extends Vue {
  @Prop(Boolean) readonly disabled!: boolean;
    @Prop(Boolean) readonly readOnly!: boolean;

  protected get IsCoreDisabled(): boolean {
    return this.disabled || this.readOnly;
  }
}
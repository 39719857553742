import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as HelperModels from '@/Models/—HelperModels—';
import ModelUtils from '@/Models/—ModelUtils—';
import moment from 'moment';

export class BaseAccount {
  public UserTierRank: number = 0;
  public UserTierName: string = '';
  public UserName: string = '';
  public DisplayName: string = '';
  public EmailAddress: string = '';
  public PhoneNumber: string = '';
  public Gender: number = 1;
  public BirthDate!: moment.Moment;
  public IsGeneratedPass: boolean = false;
  public CompanyName!: string;
  public DivisionId!: number;
  public JobId!: number;
  public SetupStep!: number;

  constructor(init?: Partial<BaseAccount>) {
    Object.assign(this, init);
    ModelUtils.InitializeMoments(this, ['BirthDate']);
  }
}
export class BaseCompany {
  public HeadquarterId!: number;
  public CompanyName: string = '';
  public CompanyCode: string = '';
  public BrandUrl: string = '';
  public Address: string = '';
  public City: string = '';
  public EmailAddress: string = '';
  public PhoneNumber: string = '';
  public SetupStep: number = 0; 
  public UsageCount: number = 0;

  constructor(init?: Partial<BaseCompany>) {
    Object.assign(this, init);
  }
}
export class BaseCompanyCuts {
  public CompanyBranchId: number = 0;
  public MinimumWage: number = 0;
  public SalaryPaymentDate: number = 25;
  public SalaryPaymentDay: number = 2;
  public SalaryCutoffDate: number = 20;
  public TaxCredential: string = '';
  public TaxPicAccountId: number = -1;
  public TaxPicType: number = 0;
  public TaxCutMethod: number = 2;
  public TaxPaymentDate: number = 1;
  public SsCredential: string = '';
  public RiskGrade: number = 0;
  public SsBaseCalculationType: number = 1;
  public SsPayer: number = 2;
  public SsCustomPayers: HelperModels.SsCustomPayers = new HelperModels.SsCustomPayers();
  public SsPaymentDate: number = 1;
  public IsSyncToMaster!: boolean;
  public AllowanceCuts: HelperModels.CompanyAllowanceCut[] = [];
  public SocialSecurity!: HelperModels.SocialSecurity;
  public Tax!: HelperModels.Tax;

  constructor(init?: Partial<BaseCompanyCuts>) {
    Object.assign(this, init);
  }
}
export class BaseStaffPayroll {
  public StaffPayrollId!: number;
  public UserMapId: number = 0;
  public BaseSalary: number = 0;
  public SalaryType: number = 1;
  public IsOvertimeEligible: boolean = true;
  public IsNoAttendanceCut: boolean = false;
  public AllowanceCuts: HelperModels.AllowanceCut[] = [];
  public AccountBalance: number = 0;
  public SalaryPaymentDate!: number;
  public TaxCutMethod: number = 1;
  public TaxMaritalStatus: number = 1;
  public TaxTotalDependents: number = 0;
  public IsMergeUntaxedIncome: boolean = false;
  public TaxEmploymentStatus: number = 2110001;
  public IsAnnualPayment: boolean = true;
  public IsMultipleEmployers: boolean = false;
  public OriginCountryCode: string = '';
  public LastTaxPaymentDate!: moment.Moment;
  public InitialNetIncome: number = 0;
  public PrepaidTax: number = 0;
  public SsAdditionalDependents: number = 0;
  public SsConfigurations: HelperModels.SsConfigurations = 
    new HelperModels.SsConfigurations();
  public SsPaymentDate!: number;

  constructor(init?: Partial<BaseStaffPayroll>) {
    Object.assign(this, init);
    ModelUtils.InitializeMoments(this, ['LastTaxPaymentDate']);
  }
}
export class BaseUserMap {
  public AccountId: number = 0;
  public CompanyId: number = 0;
  public CompanyBranchId: number = 0;
  public DivisionId: number = 0;
  public JobId: number = 0;
  public EmployeeId: string = '';
  public BirthPlace: string = '';
  public Religion!: number;
  public MaritalStatus!: number;
  public EmploymentStatus: number = 0;
  public EndEmploymentDate!: moment.Moment;
  public Shift: number = 0;
  public ReportToAccountId: number | null = 0;
  public JoinDate: moment.Moment = moment();
  public ResignDate!: moment.Moment;
  public Credentials: HelperModels.Credentials = new HelperModels.Credentials();
  public IsDeleted: boolean = false;

  constructor(init?: Partial<BaseUserMap>) {
    Object.assign(this, init);
    ModelUtils.InitializeMoments(
      this, ['EndEmploymentDate', 'JoinDate', 'ResignDate']
    );
  }
}

import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppUtils from '@/Utilities/AppUtils';
import moment from 'moment';

import PayrollListModule from '@/Components/Payroll/PayrollListModule.vue';
import PayrollSummaryModule from '@/Components/Payroll/PayrollSummaryModule.vue';

@Component({
  name: 'PayrollSummary',
  components: { PayrollListModule, PayrollSummaryModule }
})
export default class PayrollSummary extends Mixins(AppAccessMixin) {
  @Prop() readonly payrollId!: undefined | '+' | number;
  @Prop() readonly timePeriod!: moment.Moment;
  private get HasValidId(): boolean {
    return AppUtils.IsRouteIdValid(this.payrollId, this.IsPageFullAccess);
  }

  protected created() {
    if (!this.HasValidId) {
      this.$router.replace({ name: 'Payroll_Summary' });
    }
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';

import { Division, Job, TreeItem } from '@/Models/DivisionJobModels';
import { DataStore } from '@/Store/—–AppStore–—';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import DivisionQueries from '@/Repositories/Graphql/DivisionQueries';
import JobQueries from '@/Repositories/Graphql/JobQueries';

import DivJobEditorModal from '@/Components/Company/DivJobEditorModal.vue';
import AppRenderMixin from '@/Mixins/AppRenderMixin';

@Component({
  name: 'CompanyStructure',
  components: { DivJobEditorModal }
})
export default class CompanyStructure extends Mixins(
  XeoFormMixin, XeoModalMixin, AppAccessMixin, AppRenderMixin, AppToastMixin
) {
  $refs!: {
    MdlDivisionJobEditor: XeoModalMixin
  };

  private get Divisions() { return DataStore.Divisions; }
  private get Jobs() { return DataStore.Jobs; }

  private Mode: 'Divisi' | 'Jabatan' = 'Divisi';
    private ModeList = ["Divisi", "Jabatan"];
  private ActiveId: number = 0;
  private SearchText: string = '';
  private FormSearch = {
    TxtSearch: ''
  };
  private get DivJobTreeSequence(): TreeItem[] {
    return this.IsSearchTxtEmpty ? 
      this._GenerateTreeSequence() :
      this._GenerateTreeSequence().filter((item: TreeItem) => {
        return item.Name.toLowerCase().includes(this.FormSearch.TxtSearch.toLowerCase());
      });
  }
  private get IsSearchTxtEmpty(): boolean { 
    return !Boolean(this.FormSearch.TxtSearch); 
  }

  protected created() {
    this.IsLoading = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${DivisionQueries.Xeo_GetCompanyDivisions}
        ${JobQueries.Xeo_GetCompanyJobs}
      }
    `).xeoThen(
      (data) => {
        DataStore.InitializeDivisions(data.Xeo_GetCompanyDivisions);
        DataStore.InitializeJobs(data.Xeo_GetCompanyJobs);
        this.IsLoading = false;
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });
  }

  protected AdditItem_Act(item: TreeItem | null = null) {
    this.ActiveId = item?.Id || 0;
    this.$refs.MdlDivisionJobEditor.open();
  }
  protected MdlDivisionJobEditor_Hidden() {
    this.ActiveId = 0;
  }
  protected ResetSearch_Act() {
    this.FormSearch.TxtSearch = '';
  }

  private _GenerateTreeSequence(
    _parentRec: Record<any, TreeItem[]> = this.__GenerateGroupedParentRec(), 
    _usedIdSet: Set<number> = new Set<number>(),
    _parentId: number | null = null, _depth: number = 0
  ): TreeItem[] {
    if (!_parentRec[_parentId as any]) {
      return [];
    }

    const treeSeq: TreeItem[] = [];
    _parentRec[_parentId as any].forEach((item: TreeItem) => {
      if (_usedIdSet.has(item.Id)) {
        return;
      }

      _usedIdSet.add(item.Id);

      const childTreeSeq: TreeItem[] = 
        this._GenerateTreeSequence(_parentRec, _usedIdSet, item.Id, _depth + 1);
      item.Depth = _depth;
      item.HasChildren = childTreeSeq.length > 0;

      treeSeq.push(item);
      treeSeq.push(...childTreeSeq);
    });

    return treeSeq;
  }

  private __GenerateGroupedParentRec(): Record<any, TreeItem[]> {
    const djItems = this.Mode == 'Divisi' ?
      Object.values(this.Divisions).sort((a, b) => a.DivisionName.localeCompare(b.DivisionName)) :
      Object.values(this.Jobs).sort((a, b) => a.JobName.localeCompare(b.JobName));

    return (djItems as any[]).reduce((parentRec: Record<any, TreeItem[]>, item: Division | Job) => {
      if (!parentRec[item.ParentId]) {
        parentRec[item.ParentId] = [];
      }

      parentRec[item.ParentId].push(new TreeItem({
        Id: item.Id,
        Name: (item as Division).DivisionName || (item as Job).JobName,
        ParentId: item.ParentId,
        UsageCount: item.UsageCount
      }));

      return parentRec;
    }, {});
  }
}

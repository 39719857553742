
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormElementMixin from '☆XeoApp/Vue/Mixins/XeoFormElementMixin';

@Component({
  name: 'XeoTextArea',
  inheritAttrs: false
})
export default class XeoTextArea extends Mixins(XeoBaseMixin, XeoFormElementMixin) {
  $refs!: {
    TaTextarea: HTMLFormElement;
  };

  @Prop({ default: 4 }) minRow!: number;
  private get Rows(): number {
    return Math.max(this.minRow, this.Value.split('\n').length);
  }

  protected created() {
    if (!this.value) {
      this.$emit('input', '');
    }
    this.Value = this.value;
  }
  protected mounted() {
    this.RootElement = this.$refs.TaTextarea;
  }

  private get EventListeners() {
    const self = this;

    return Object.assign({}, this.$listeners, {
      blur(event: FocusEvent) {
        if (self.validatorQuery) {
          self.ValidateValue();
          self.IsValidating = false;
        }

        self.$emit('blur', event);
      },
      input(eventVal: any) {
        self.$emit('input', eventVal);

        self.DoDebValidateValue();
      }
    });
  }
  private InputFocus_Act() {
    this.focus();
  }

  /* Watch value - Handle External Modifications */
  @Watch('value')
  private value_Change(newValue: any) {
    if (newValue != this.Value) {
      this.Value = newValue;
    }
  }
}

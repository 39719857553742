import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

import * as CompanyModels from '@/Models/CompanyModels';

export default new class CompanyQueries {
  public Xeo_GetUserCompanyList: string = `Xeo_GetUserCompanyList`;

  public Xeo_DeleteCompany(id: number): string {
    return `Xeo_DeleteCompany(id: ${id})`;
  }
  public Xeo_UpdateCompanySetupStep(setupStep: number): string {
    return `Xeo_UpdateCompanySetupStep(setupStep: ${setupStep})`;
  }
  public Xeo_UpsertCompany(req: CompanyModels.Company): string {
    return `Xeo_UpsertCompany(req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)})`;
  }
}
export class FormStepItem {
  public Title: string = '';
  public Icon: any;
  public Depth: number = 0;
  public IsHidden: boolean = false;
  public IsHeader: boolean = false;

  constructor(init?: Partial<FormStepItem>) {
    Object.assign(this, init);
  }
}
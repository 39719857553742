
import { Vue, Component, Prop } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';

@Component({
  name: 'XeoTab'
})
export default class XeoTab extends Vue {
  @Prop() value!: any;
  @Prop() readonly items!: any[];
  @Prop() readonly indexEnabler!: XeoTypes.IndexEnabler;

  private get UnderlineStyle() {
    const tabWidth: number = 100 / this.items.length;
    const valIdx: number = this.items.findIndex(
      (item: any) => XeoBibliotheca.UtilCodex.DeepEqual(item, this.value)
    );

    return {
      left: `${tabWidth * valIdx}%`,
      visibility: valIdx > -1 ? 'visible' : 'hidden',
      width: `${tabWidth}%`
    };
  }

  protected BtnItem_Click(item: any) {
    this.$emit('input', item);
    this.$emit('change', item);
  }

  private GetTabItemClass(item: any, i: number): object {
    return {
      'active': XeoBibliotheca.UtilCodex.DeepEqual(item, this.value),
      'disabled': this.indexEnabler?.IsIndexDisabled(i)
    };
  }
}


import { Vue, Component, Prop, Mixins } from '☆Node/vue-property-decorator';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';

@Component({
  name: 'XeoStep'
})
export default class XeoStep extends Mixins(XeoBaseMixin) {
  @Prop(Number) readonly value!: number;
  @Prop([Array, Object, Number]) readonly items: any;
  @Prop(String) readonly mode!: 'horizontal' | 'vertical' | 'form';
  @Prop(Boolean) readonly isLoading!: boolean;
  private get NormalizedItems(): [any, any][] {
    const normItems = Object.entries(
      /* @ts-ignore */
      !isNaN(this.items) ? new Array(this.items).fill() : (this.items || [])
    );

    return normItems.map(
      ([key, val]) => [ Array.isArray(normItems) ? Number(key) : key, val ]
    );
  }

  protected BtnStepIcon_Click(stepIndex: number) {
    this.$emit('input', stepIndex);
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import AppToastMixin from '@/Mixins/AppToastMixin';
import * as HelperModels from '@/Models/—HelperModels—';
import { DataStore } from '@/Store/—–AppStore–—';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import CompanyCutsQueries from '@/Repositories/Graphql/CompanyCutsQueries';

import * as CompanyModels from '@/Models/CompanyModels';
import * as CompanyCutsModels from '@/Models/CompanyCutsModels';

import AcEditorModal from '@/Components/Company/AcEditorModal.vue';

@Component({
  name: 'PayrollAllowanceCuts',
  components: { AcEditorModal }
})
export default class PayrollAllowanceCuts extends Mixins(
  XeoBaseMixin, XeoFormMixin, AppAccessMixin, AppRenderMixin, AppToastMixin
) {
  $refs!: {
    "MdlCompanyAcEditor": AcEditorModal
  };

  private get CompanyList() { return DataStore.CompanyList; }
  private get Divisions() { return DataStore.Divisions; }
  private get Jobs() { return DataStore.Jobs; }

  private AllowanceCuts: any = {};
  private EditedAc: HelperModels.CompanyAllowanceCut | null = null;
  private AcIndex: number = -1;
  private IsAcEdited: boolean = false;
  private get AcList(): HelperModels.CompanyAllowanceCut[] {
    return Object.values(this.AllowanceCuts);
  }

  protected created() {
    this.IsLoading = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${CompanyCutsQueries.Axenta_GetUserCompanyCutsList}
      }
    `).xeoThen(
      (data) => {
        DataStore.AssignCompanyCutsList(data.Axenta_GetUserCompanyCutsList);
        DataStore.SetCompanyAc(DataStore.CompanyHq.Cuts.AllowanceCuts);
        this._AssignNormalizedAc(DataStore.CompanyHq.Data.AllowanceCuts);
        this.IsLoading = false;
      },
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    });
  }

  protected BtnAddAc_Click() {
    this.EditedAc = new HelperModels.CompanyAllowanceCut();
    this.$refs.MdlCompanyAcEditor.open();
  }
  protected BtnResetAc_Click() {
    this._AssignNormalizedAc(DataStore.CompanyHq.Data.AllowanceCuts);
    this.IsAcEdited = false;
  }
  protected BtnSaveAc_Click() {
    this._UpdateCompanyAc();
  }
  protected TblAllowanceCuts_ItemClick(ac: HelperModels.CompanyAllowanceCut) {
    this.EditedAc = ac;
    this.$refs.MdlCompanyAcEditor.open();
  }
  protected MdlCompanyAcEditor_Delete(id: number) {
    Vue.delete(this.AllowanceCuts, id);
    this.IsAcEdited = true;
  }
  protected MdlCompanyAcEditor_Save(ac: HelperModels.CompanyAllowanceCut) {
    this._AssignNormalizedAc(ac);
    this.IsAcEdited = true;
  }

  private _AssignNormalizedAc(
    newAc: HelperModels.CompanyAllowanceCut | HelperModels.CompanyAllowanceCut[]
  ) {
    if (Array.isArray(newAc)) {
      this.AllowanceCuts = {};
      newAc.forEach((ac: HelperModels.CompanyAllowanceCut) => {
        const id: number = Math.random();
        Vue.set(this.AllowanceCuts, id, Object.assign(ac, { Id: id }));
      });
    } else {
      const id: number = newAc.Id || Math.random();
      Vue.set(this.AllowanceCuts, id, Object.assign(newAc, { Id: id }));
    }
  }
  private _GetPeriodTypeById(id: number): string {
    return id == 1 ? 'bulan' :
      id == 2 ? 'minggu' :
      id == 3 ? 'hari' : '';
  }
  private _UpdateCompanyAc() {
    const req = new CompanyCutsModels.UpdateCompanyAllowancesRequest({
      AllowanceCuts: this.AcList
    });

    this.IsSavingForm = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      mutation {
        ${CompanyCutsQueries.Axenta_UpdateCompanyAllowances(req)}
      }
    `).xeoThen(
      (data) => {
        DataStore.SetCompanyAc(data.Axenta_UpdateCompanyAllowances.AllowanceCuts);
        this._AssignNormalizedAc(data.Axenta_UpdateCompanyAllowances.AllowanceCuts);
        this.IsAcEdited = false;
        this.MakeSuccessToast(this.$t('Success.save', { name: 'Data' }));
      }, 
      (errors) => {
        this.MakeErrorToast(this.$t('Errors.server'));
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => {
      this.IsSavingForm = false; 
    });
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';
import moment from 'moment';

@Component({
  name: 'XeoDatePicker'
})
export default class XeoDatePicker extends Mixins(XeoBaseMixin) {
  //* Mode–Value Variants *//
  //   1. empty or Single – date
  //   2. Range – [ dateStart, dateEnd ] 
  //   3. Multiple – [ dateA, dateB, ..., dateX ]

  @Prop() value!: any; 
  @Prop(String) readonly mode!: string | 'month' | 'year' | 'range' | 'multiple';
  @Prop() readonly dateEnabler!: XeoTypes.DateEnabler;

  private WeekdayList: string[] = XeoBibliotheca.DateTimeCodex.MomentJs.weekdaysShort();
  private MonthList: string[] = XeoBibliotheca.DateTimeCodex.MomentJs.months();

  private Value: any = null;
  private DpLayer: 0 | 1 | 2 = this.MinDpLayer;       //* 0: Day | 1: Month | 2: Year *//
  private CursorDate: moment.Moment = moment();
  private CursorMonthYear: moment.Moment = moment();
  private MouseDate: moment.Moment | null = null;
  private YpStartShift: number = -4;    
  private RenderKey: string = ''; 
  private get ActiveDaysInCursorDate(): number[] {
    if (!this.Value) {
      return [];
    }

    const activeDays: number[] = [];
    switch (this.mode) {
      case "range":   
        this.Value.forEach((rangeDate: moment.Moment) => {
          if (this.__EqualMonthYear(rangeDate, this.CursorDate)) {
            activeDays.push(rangeDate.date());
          }
        });

        if (this.Value && this.Value.length == 1 && this.MouseDate) {
          activeDays.push(this.MouseDate.date());
        }
        break;  
      case "multiple":
        this.Value.forEach((val: moment.Moment) => {
          if (this.__EqualMonthYear(val, this.CursorDate)) {
            activeDays.push(val.date());
          }
        });
        break;
      default:
        if (this.__EqualMonthYear(this.Value, this.CursorDate)) {
          activeDays.push(this.Value.date());
        }
    }
    return activeDays;
  }
  private get DpTitle(): string {
    switch (this.DpLayer) {
      case 0:
        return XeoBibliotheca.DateTimeCodex.Format(this.CursorDate, 'MMMM YYYY');
      case 1:
        return this.CursorMonthYear.year().toString();
      case 2:
        const yearStart: number = this.CursorMonthYear.year() + this.YpStartShift;
        return `${yearStart} — ${yearStart + 11}`;
      default:
        return '';
    }
  }
  private get FirstWeekdayIdx(): number {
    return this.CursorDate.startOf('month').day();
  } 
  private get MinDpLayer(): 0 | 1 | 2 {
    return this.mode == 'month' ? 1 :
      this.mode == 'year' ? 2 :
      0;
  }
  private get RangedDaysInCursorDate() {
    /* Do-Not-Render-Range Condition – Return null */
    if (!this.__IsRenderRange()) {
      return null;
    }

    /* Generate Ranged Dates on Datepicker Window */
    const moment = XeoBibliotheca.DateTimeCodex.MomentJs;
    const dateRange: moment.Moment[] =
      XeoBibliotheca.DateTimeCodex.Sort([ 
        this.Value[0], this.Value.length == 2 ? this.Value[1] : this.MouseDate
      ], true);
    const dpDateRange: moment.Moment[] = [
      moment.max(this.CursorDate.clone().startOf('month'), dateRange[0]),
      moment.min(this.CursorDate.clone().endOf('month'), dateRange[1])
    ];

    /* Return Ranged Dates */
    if (dpDateRange[0].isBefore(dpDateRange[1])) {
      return [ 
        dateRange[0].isBefore(dpDateRange[0]) ? 0 : dpDateRange[0].date(), 
        dateRange[1].isAfter(dpDateRange[1]) ? 32 : dpDateRange[1].date()
      ];
    } else {
      return null;
    }
  }
  private get TotalDaysInMonth(): number {
    return this.CursorDate.daysInMonth();
  }

  protected created() {
    this._InitDpState();
  }

  protected BtnBackReset_Click() {
    this._BackOrResetDpCursor();
  }
  protected BtnBackward_Click() {
    this._NavigateCalendar(false);
  }
  protected BtnForward_Click() {
    this._NavigateCalendar(true);
  }
  protected BtnDateItem_Click(itemIdx: number) {
    this._PickDateItem(itemIdx);
  }
  protected BtnDayItem_MouseEnter(dayIdx: number) {
    this.MouseDate = this.CursorDate.clone()
      .startOf('month').set('date', dayIdx);
  }
  protected CntCalendar_MouseLeave() {
    this.MouseDate = null;
  }
  protected LabTitle_Click() {
    this._GoToNextLayer();
  }

  private GetDateCellClass(dayIdx: number): object {
    const dcClass: any = {};
    const cellDate: moment.Moment = this.CursorDate.clone().date(dayIdx);
    const now: moment.Moment = moment();

    if (this.mode != 'range') {
      Object.assign(dcClass, {
        'dc-active': this.ActiveDaysInCursorDate.includes(dayIdx)
      });
    } else {
      /* Start & End of Ranged Dates */
      if (this.ActiveDaysInCursorDate.includes(dayIdx)) {
        Object.assign(dcClass, {
          'dc-ranging-active': this.Value.length == 1,
          'dc-active': this.Value.length == 2
        });
      }

      /* Range of Ranged Dates */
      if (Boolean(this.RangedDaysInCursorDate)) {
        Object.assign(dcClass, {
          'dc-range-start': dayIdx == this.RangedDaysInCursorDate![0],
          'dc-range-mid': this.RangedDaysInCursorDate![0] < dayIdx && 
            dayIdx < this.RangedDaysInCursorDate![1],
          'dc-range-end': dayIdx == this.RangedDaysInCursorDate![1]
        });
      }

      /* Smoothen Cross-Month Transition */
      Object.assign(dcClass, {
        'dc-rounded-start': 
          (this.FirstWeekdayIdx + dayIdx) % 7 == 1 || dayIdx == 1,
        'dc-rounded-end': 
          (this.FirstWeekdayIdx + dayIdx) % 7 == 0 || dayIdx == this.TotalDaysInMonth,
      });
    }

    /* Today */
    Object.assign(dcClass, {
      'dc-today': this.__EqualMonthYear(now, this.CursorDate) && now.date() == dayIdx
    });

    /* Date & Day Enabler */
    dcClass['disabled'] = this.dateEnabler?.IsDateDisabled(cellDate);

    return dcClass;
  }
  private GetMonthCellClass(monthIdx: number): object {
    const mcRange: moment.Moment[] = [
      this.CursorMonthYear.clone().month(monthIdx).startOf('month'),
      this.CursorMonthYear.clone().month(monthIdx).endOf('month')
    ];

    return {
      'disabled': this.dateEnabler?.IsRangeDateDisabled(mcRange),
      'my-active': this.CursorDate.month() == monthIdx && 
        this.CursorDate.year() == this.CursorMonthYear.year()
    };
  }
  private GetYearCellClass(yearIdx: number): object {
    const ycRange: moment.Moment[] = [
      this.CursorMonthYear.clone().add(yearIdx - 1, 'years').startOf('year'),
      this.CursorMonthYear.clone().add(yearIdx - 1, 'years').endOf('year')
    ];

    return { 
      'disabled': Boolean(this.dateEnabler?.IsRangeDateDisabled(ycRange)),
      'my-active': this.CursorDate.year() == this.CursorMonthYear.year() - 1 + yearIdx
    };
  }
  private GetNavArrowClass(isForward: boolean): object {
    if (this.dateEnabler?.MergedDisabledDates?.length > 0) {
      const navCursor: moment.Moment = this.DpLayer == 0 ? 
        this.CursorDate : this.CursorMonthYear;
      const dirType = this.DpLayer == 0 ? 'month' : 'year';
      const navStep: number = this.DpLayer == 0 ? 1 : Math.pow(12, this.DpLayer - 1);
      const navRange: moment.Moment[] = isForward ? [ 
        navCursor.clone().add(navStep, dirType).startOf(dirType), 
        XeoBibliotheca.DateTimeCodex.MAX_DATETIME
      ] : [
        XeoBibliotheca.DateTimeCodex.MIN_DATETIME,
        navCursor.clone().add(-1, dirType).endOf(dirType)
      ];

      return {
        'disabled': this.dateEnabler.IsRangeDateDisabled(navRange)
      };
    }

    return {};
  }

  private _BackOrResetDpCursor() {
    if (this.DpLayer == this.MinDpLayer) {
      this.CursorDate = moment();
    } else {
      this.DpLayer = this.MinDpLayer;
      this.CursorMonthYear = this.CursorDate.clone();
    }

    this.RenderKey = Math.random().toString();
  }
  private _GoToNextLayer() {
    if (this.DpLayer < 2) {
      this.DpLayer++;
      if (this.DpLayer == 2) {
        const cursor: moment.Moment = this.CursorMonthYear.clone();
        this.CursorMonthYear = this.__LimitYear(cursor);
      }
      this.RenderKey = Math.random().toString();
    }
  }
  private _InitDpState() {
    if (this.value) {
      if (this.mode == 'range' || this.mode == 'multiple') {
        if (this.value.length) {
          this.Value = XeoBibliotheca.DateTimeCodex.ParseArray(this.value);
          this.CursorDate = XeoBibliotheca.DateTimeCodex.Parse(this.Value[0]); 
          this.CursorMonthYear = XeoBibliotheca.DateTimeCodex.Parse(this.Value[0]);
        }
      } else {
        this.Value = XeoBibliotheca.DateTimeCodex.Parse(this.value);
        this.CursorDate = XeoBibliotheca.DateTimeCodex.Parse(this.value); 
        this.CursorMonthYear = XeoBibliotheca.DateTimeCodex.Parse(this.value);
      }
    }
  }
  private _NavigateCalendar(isForward: boolean) {
    if (this.DpLayer == 0) {      /* Date Calendar Navigation */
      this.CursorDate = this.__LimitYear(
        this.CursorDate.clone().add(isForward ? 1 : -1, 'months')
      );
    } else {                      /* Month & Year Navigation */
      this.CursorMonthYear = this.__LimitYear(
        this.CursorMonthYear.clone()
          .add(Math.pow(12, this.DpLayer - 1) * (isForward ? 1 : -1), 'years')
      );
    }

    this.RenderKey = Math.random().toString();
  }
  private _PickDateItem(itemIdx: number) {
    switch (this.DpLayer) {
      case 0:     /* Date-Pick Algorithm */
        const cursor: moment.Moment = this.CursorDate.clone()
          .startOf('month').set('date', itemIdx);
        switch (this.mode) {
          case 'range':
            if (this.Value?.length == 1) {
              this.Value.push(cursor);
              XeoBibliotheca.DateTimeCodex.Sort(this.Value);
              this.Value[1].endOf('day');
            } else {
              this.Value = [ cursor ];
            }
            
            break;
          case 'multiple':
            if (!this.Value || !this.Value.length) {
              this.Value = [];
            } 

            const valIdx: number = this.Value.findIndex(
              (dateItem: moment.Moment) => dateItem.valueOf() == cursor.valueOf()
            );
            if (valIdx > -1) {
              Vue.delete(this.Value, valIdx);
            } else {
              this.Value.push(cursor);
            }

            XeoBibliotheca.DateTimeCodex.Sort(this.Value);
            break;
          default:
            this.Value = cursor;
        }

        this.$emit('input', XeoBibliotheca.UtilCodex.DeepClone(this.Value));
        if (this.__IsCompleteValue(this.Value))     this.$emit('complete');
        break;
      case 1:     /* Month-Pick Algorithm */
        this.CursorMonthYear.set('month', itemIdx);
        this.CursorDate = this.CursorMonthYear.clone();
        if (this.mode == 'month') {
          this.$emit('input', this.CursorMonthYear.clone().startOf('month'));
        } else {
          this.DpLayer--;
        }
      
        break;
      default:    /* Year-Pick Algorithm */
        if (this.mode == 'year') {
          const myCursor: moment.Moment = this.CursorMonthYear.clone().add(
            Math.pow(12, this.DpLayer - 2) * itemIdx, 'years'
          );
          this.CursorDate = myCursor.clone();
          this.$emit('input', myCursor.clone().startOf('year'));
        } else {
          this.CursorMonthYear.add(
            Math.pow(12, this.DpLayer - 2) * itemIdx, 'years'
          );
          this.DpLayer--;
        }

        break;
    }
  }

  private __EqualMonthYear(valueA: moment.Moment, valueB: moment.Moment): boolean {
    return XeoBibliotheca.DateTimeCodex.EqualByFormat(valueA, valueB, 'MMYYYY');
  }
  private __IsCompleteValue(val: any): boolean {
    switch (this.mode) {
      case 'range':
        return val?.length == 2;
      case 'multiple':
        return false;
      default:
        return Boolean(val);
    }
  }
  private __IsRenderRange() {
    return this.mode == 'range' && this.Value 
      && (this.Value.length == 2 || (this.Value.length == 1 && this.MouseDate));
  } 
  private __LimitYear(dt: moment.Moment): moment.Moment{
    return moment.min(
      moment.max(XeoBibliotheca.DateTimeCodex.MIN_DATETIME, dt), 
      XeoBibliotheca.DateTimeCodex.MAX_DATETIME
    );
  }

  /* Watch value - Handle External Modification */
  @Watch('value')
  private value_Change(newValue: any) {
    if (!XeoBibliotheca.UtilCodex.DeepEqual(newValue, this.Value)) {
      this.Value = XeoBibliotheca.UtilCodex.DeepClone(newValue);
    }
  }
}

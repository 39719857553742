import * as BaseModels from '@/Models/—BaseModels—';
import * as HelperModels from '@/Models/—HelperModels—';

export class Rbac {
  public DivisionId!: number;
  public JobId!: number;
  public AccountId!: number;
  public AccessMap: Record<string, HelperModels.AccessState> = {};

  constructor(init?: Partial<Rbac>) {
    Object.assign(this, init);
  }
}
export class AccessMaps {
  public Client: Record<string, HelperModels.AccessState> = {};
  public Server: Record<string, HelperModels.AccessState> = {};

  constructor(init?: Partial<AccessMaps>) {
    Object.assign(this, init);
  }
}

export class GetRbacRequest {
  public DivisionId!: number;
  public JobId!: number;
  public AccountId!: number;
  public Mode!: 'exact' | 'merged' | 'merged-dep';

  constructor(init?: Partial<GetRbacRequest>) {
    Object.assign(this, init);
  }
}


import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

import * as BaseModels from '@/Models/—BaseModels—';
import * as HelperModels from '@/Models/—HelperModels—';
import { Company } from '@/Models/CompanyModels';
import { Division, Job } from '@/Models/DivisionJobModels';
import { PayrollSummary } from '@/Models/PayrollModels';
import { Staff } from '@/Models/StaffModels';

type PayslipOpts = {
  CompanyRec: Record<number, Company>
  DivisionRec: Record<number, Division>;
  JobRec: Record<number, Job>;
  IsClearTaxHistory: boolean;
}

export default new class PayrollFormulaUtils {
  //∴ -->>  Main Functions  <<-- ∴//
  public EmptyTaxCredentialValue: string = '00.000.000.0-000.000';

  public InitializePayslip(
    payrollSummary: PayrollSummary, staff: Staff, opts: Partial<PayslipOpts>
  ) {
    const isIncludePrepaidTax: boolean = 
      !opts.IsClearTaxHistory &&
      payrollSummary.TimePeriod.year() == staff.LastTaxPaymentDate?.year();

    return new HelperModels.StaffPayslip({
      AccountId: staff.AccountId,
      CompanyBranchId: staff.CompanyBranchId,
      EmployeeId: staff.EmployeeId,
      Name: staff.DisplayName,
      Company: opts.CompanyRec?.[staff.CompanyBranchId]?.CompanyName || '—',
      Division: opts.DivisionRec?.[staff.DivisionId]?.DivisionName || '—',
      Job: opts.JobRec?.[staff.JobId]?.JobName || '—',
      ExtensionData: new HelperModels.PayslipExtData({
        Address: staff.Credentials.Address,
        BirthDate: staff.BirthDate,
        Gender: staff.Gender,
        JoinDate: staff.JoinDate,
        PersonalId: staff.Credentials.PersonalId.Id,

        LastTaxPaymentDate: staff.LastTaxPaymentDate,
        InitialNetIncome: isIncludePrepaidTax ? staff.InitialNetIncome : 0,
        IsOvertimeEligible: staff.IsOvertimeEligible,
        IsNoAttendanceCut: staff.IsNoAttendanceCut,
        PrepaidTax: isIncludePrepaidTax ? staff.PrepaidTax : 0,
        SsConfigurations: new HelperModels.SsConfigurations(staff.SsConfigurations),
        TaxCutMethod: staff.TaxCutMethod,
        SsAdditionalDependents: staff.SsAdditionalDependents,
        TaxCredential: staff.Credentials.Tax || this.EmptyTaxCredentialValue,
        TaxMaritalStatus: staff.TaxMaritalStatus,
        TaxTotalDependents: staff.TaxTotalDependents,
        TaxEmploymentStatus: staff.TaxEmploymentStatus,
        IsAnnualPayment: staff.IsAnnualPayment,
        IsMultipleEmployers: staff.IsMultipleEmployers,
        OriginCountryCode: staff.OriginCountryCode,
        IsMergeUntaxedIncome: staff.IsMergeUntaxedIncome,
        BankAccount: staff.Credentials.BankAccount
      })
    });
  }
  public InputRecurringItemsToMonthlyPs(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary, staff: Staff
  ) {
    /* Base Salary */
    sp.StaffItems.push(new HelperModels.PayslipItem({
      Name: 'Gaji Pokok',
      Value: staff.BaseSalary * this._GetAcMultiplier(sp, payrollSummary, staff.SalaryType)
    }));

    /* Staff Allowances & Cuts */
    staff.AllowanceCuts.forEach((ac: HelperModels.AllowanceCut) => {
      sp.StaffItems.push(new HelperModels.PayslipItem({
        Name: ac.Name,
        Value: ac.Value * this._GetAcMultiplier(sp, payrollSummary, ac.PeriodType),
        TaxCategory: ac.TaxCategory,
        ExtensionData: {
          IsHiddenOnPayslip: ac.IsHiddenOnPayslip
        }
      }));
    });

    /* Company Allowances & Cuts */
    payrollSummary.SummaryData.SourceData.CompanyAllowanceCuts.forEach(
      (ac: HelperModels.CompanyAllowanceCut) => {
        if (
          (ac.CompanyBranchId == 0 || ac.CompanyBranchId == staff.CompanyBranchId) && 
          (ac.DivisionId == 0 || ac.DivisionId == staff.DivisionId) && 
          (ac.JobId == 0 || ac.JobId == staff.JobId)
        ) {
          sp.StaffItems.push(new HelperModels.PayslipItem({
            Name: ac.Name,
            Value: ac.Value * this._GetAcMultiplier(sp, payrollSummary, ac.PeriodType),
            TaxCategory: ac.TaxCategory,
            ExtensionData: {
              IsHiddenOnPayslip: ac.IsHiddenOnPayslip
            }
          }));
        }
      }
    );
  }
  public InputSocialSecurityToMonthlyPs(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary
  ) {
    /* Social-Security Items Calculation */ 
    this.___GetCompanyCutsSrc(sp, payrollSummary).SocialSecurity.Cuts.forEach(
      (ssc: HelperModels.SocialSecurityCut) => {
        if (this._IsJaminanPensiunAndIneligible(sp, payrollSummary, ssc))   return;

        const ssPayer: number = this._GetSsPayer(sp, payrollSummary, ssc);           
        if (ssPayer != 5) {   /* 5: Not Paid */
          const ssBase: number = this._GetSsBaseCalculation(sp, payrollSummary, ssc);
          const ssIndividualPercentage: number = ssc.Percentage.Individual +
            (ssc.Name == 'Jaminan Kesehatan' ? sp.ExtensionData.SsAdditionalDependents : 0);

          /* StaffItems – Deduct Individual Cost by SsPayer */
          switch (ssPayer) {
            case 2:   /* Split */
              sp.StaffItems.push(new HelperModels.PayslipItem({
                Name: ssc.Name,
                Value: -ssBase * ssIndividualPercentage / 100,
                TaxCategory: this._GetSsTaxCategory('stf', ssc),
                CalculationGroup: 3
              }));
              break;
            case 4:   /* Fully-Paid by Staff */
              sp.StaffItems.push(new HelperModels.PayslipItem({
                Name: ssc.Name,
                Value: -ssBase * (ssIndividualPercentage + ssc.Percentage.Company) / 100,
                TaxCategory: this._GetSsTaxCategory('stf', ssc),
                CalculationGroup: 3
              }));
              break;
          }

          /* GovermentItems – Total Social-Security Paid */
          sp.GovermentItems.push(new HelperModels.PayslipItem({
            Name: ssc.Name,
            Value: ssBase * (ssIndividualPercentage + ssc.Percentage.Company) / 100,
            TaxCategory: this._GetSsTaxCategory('gov', ssc),
            CalculationGroup: 3
          }));
        }
      }
    );
  }
  public InputTaxToMonthlyPs(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary
  ) {
    /* Resets Tax items from Ps */
    ['StaffItems', 'GovermentItems'].forEach((key) => {
      (sp as any)[key] = (sp as any)[key].filter((pi: HelperModels.PayslipItem) => {
        return pi.CalculationGroup != 2;
      });
    });

    /* Tax Items Calculation */
    const taxCutMethod: number = this.___GetTaxCutMethod(sp, payrollSummary);
    const taxValue: number = this._GenerateMonthlyTaxValue(sp, payrollSummary);

    if (taxValue > 0) {
      if (taxCutMethod == 2 || taxCutMethod == 4) {
        /* Cut Tax on Gross & Gross-Up */
        sp.StaffItems.push(new HelperModels.PayslipItem({
          Name: 'PPh 21',
          Value: -taxValue,
          TaxCategory: -1,
          CalculationGroup: 2,
        }));
      }
      sp.GovermentItems.push(new HelperModels.PayslipItem({
        Name: 'PPh 21',
        Value: taxValue,
        CalculationGroup: 2
      }));

      if (taxCutMethod == 4) {
        /* Input Gross-Up Allowance on Gross-Up */
        sp.StaffItems.push(
          new HelperModels.PayslipItem({
            Name: 'Tunjangan PPh 21',
            Value: taxValue,
            TaxCategory: 2,
            CalculationGroup: 2,
          })
        );
      }
    }
  }
  public InputTaxHistorySummaryToMonthlyPs(
    staffPayslip: HelperModels.StaffPayslip, payrollSummary: PayrollSummary,
    approvedYearlyPs: PayrollSummary[]
  ) {
    /* Initialize Tax History Summary */
    const spExtData = staffPayslip.ExtensionData;
    spExtData.MonthPeriodRange = [
      payrollSummary.TimePeriod.month(), payrollSummary.TimePeriod.month()
    ];
    spExtData.PrevNetIncome = spExtData.InitialNetIncome;
    spExtData.PrevPaidTax = spExtData.PrepaidTax;

    /* Summarize Tax History */
    let isFirstPayslip: boolean = true;

    approvedYearlyPs.forEach((payrollSum: PayrollSummary, i: number) => {
      const sp = payrollSum.SummaryData.StaffPayslips.find(
        (sp: HelperModels.StaffPayslip) => sp.AccountId == staffPayslip.AccountId
      );

      if (sp) {
        spExtData.PrevNetIncome += sp.PayslipSummary.NettIncome;
        spExtData.PrevTaxableIncome += sp.PayslipSummary.TaxableIncome;
        spExtData.PrevPaidTax += sp.PayslipSummary.TaxSum;

        if (isFirstPayslip) {
          const monthPeriod: number = payrollSum.TimePeriod.month();

          spExtData.MonthPeriodRange[0] = monthPeriod;
          spExtData.PrevNetIncome += this.___IsTaxAnnualized(staffPayslip) ?
            this._GenerateMonthlyNettIncome(sp, payrollSum, false) * monthPeriod : 0;

          isFirstPayslip = false;
        }
      }
    });

    if (isFirstPayslip && this.___IsTaxAnnualized(staffPayslip)) {
      spExtData.PrevNetIncome +=
        this._GenerateMonthlyNettIncome(staffPayslip, payrollSummary, false)
        * payrollSummary.TimePeriod.month();
    }
  }
  public SummarizePayslips(payrollSummary: PayrollSummary) {
    if (payrollSummary.Status == 100)    return;

    const formOrdinalRec: Record<string, number> = {
      '1721-A1': 1,
      '1721-VI': 1,
      '1721-VII': 1
    }; 

    payrollSummary.SummaryData.StaffPayslips.forEach(
      (sp: HelperModels.StaffPayslip, idx: number, sps: HelperModels.StaffPayslip[]) => {
        /* Input Payslip Summary */
        sps[idx].PayslipSummary = this._GeneratePayslipSummary(sp, payrollSummary);
        this._InputIncTaxReceiptId(sps[idx], payrollSummary, formOrdinalRec);
      }
    );
  }

  public GetTaxType(
    sp: HelperModels.StaffPayslip | null
  ): 'Annual' | 'NonFinal' | 'Final' | '' {
    return this.___GetTaxTypeFromPayslip(sp);
  }
  public HasTaxCredential(sp: HelperModels.StaffPayslip): boolean {
    return this.___HasTaxCredential(sp);
  }

  //∗ --> Payslip Helpers <-- ∗//
  private _GeneratePayslipSummary(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary
  ): HelperModels.PayslipSummary {
    const spExtData: HelperModels.PayslipExtData = sp.ExtensionData;
    const monthlyNettIncome: number =
      this._GenerateMonthlyNettIncome(sp, payrollSummary, true);
    const annualizedMod: number = this.___IsTaxAnnualized(sp) ?
      (spExtData.MonthPeriodRange[1] - spExtData.MonthPeriodRange[0] + 1) / 12 : 1;

    /* Tax Payslip Summary */
    const payslipSum = new HelperModels.PayslipSummary({
      BrutoIncome: this.__GenerateMonthlyBrutoIncome(sp, true),
      JobFee: this.__GenerateMonthlyJobFee(sp, payrollSummary, true),
      NettIncome: monthlyNettIncome,
      TaxableIncome: this._GenerateNonAnnMonthlyTaxableIncome(sp, payrollSummary),
      LastTaxPercentage: this._GenerateLastTaxPercentage(sp, payrollSummary),

      YearlyNettIncome: this.___IsTaxAnnualized(sp) ?
        this.__GenerateAnnYearlyNettIncome(sp, payrollSummary, true) :
        spExtData.PrevNetIncome + monthlyNettIncome,
      YearlyTaxableIncome: this.__GenerateYearlyTaxableIncome(sp, payrollSummary),
      YearlyUntaxedIncome: this.__GenerateYearlyUntaxedIncome(sp, payrollSummary)
    });

    /* Staff & Goverment Items */
    sp.StaffItems.forEach((si: HelperModels.PayslipItem) => {
      payslipSum.StaffSum += si.Value;
    });
    sp.GovermentItems.forEach((gi: HelperModels.PayslipItem) => {
      if (gi.CalculationGroup == 2) {
        payslipSum.TaxSum += gi.Value;
      } else if (gi.CalculationGroup == 3) {
        if (gi.Name == 'Jaminan Kesehatan') {
          payslipSum.SsHealthcareSum += gi.Value;
        } else {
          payslipSum.SsEmploymentSum += gi.Value;
        }
      }
    });

    /* Recapitulized Yearly Tax Value */
    const yearlyTaxableIncome: number = this.___RoundToThousand(
      payslipSum.YearlyNettIncome - payslipSum.YearlyUntaxedIncome
    );

    payslipSum.YearlyTaxValue =
      this.___GenerateLayeredTaxValue(sp, payrollSummary, yearlyTaxableIncome) * 
        annualizedMod * 
        this.___GetEmptyTcTaxMod(sp) * 
        this.___GetGrossUpTaxMod(sp, payrollSummary, yearlyTaxableIncome);
    payslipSum.YearlyNettIncome += this.___GetTaxCutMethod(sp, payrollSummary) == 4 ?
      payslipSum.YearlyTaxValue : 0;

    return payslipSum;
  }
  private _GetAcMultiplier(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary, periodType: number
  ): number {
    switch (periodType) {
      case 2:       /* Weekly */
        return XeoBibliotheca.DateTimeCodex.NamedDaysInMonth(
          payrollSummary.TimePeriod, 
          this.___GetCompanyCutsSrc(sp, payrollSummary).SalaryPaymentDay - 1
        );
      default:      /* Daily & Default */
        return 1;
    }
  }

  //∗ --> Social Security Helpers <-- ∗//
  private SocialSecurityVars: Record<string, any> = { 
    PartRec: {
      'Jaminan Kesehatan': 'Healthcare',
      'Jaminan Hari Tua': 'OldAge',
      'Jaminan Kecelakaan Kerja': 'Accident',
      'Jaminan Kematian': 'Death',
      'Jaminan Pensiun': 'Pension'
    },
    PayerRouteRec: {
      'Company-🌎': { 100: 'Company-⚄' },
      'Staff-🌎': { 1 : 'Company-🌎', 100: 'Staff-⚄' },
      'Staff-⚄': { 1: 'Company-🌎' }
    }
  }

  private _GetSsBaseCalculation(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary,
    ssc: HelperModels.SocialSecurityCut
  ): number {
    const ccSrc = this.___GetCompanyCutsSrc(sp, payrollSummary),
          ssType = ssc.Name == 'Jaminan Kesehatan' ? 'SsHealthcare' : 'SsEmployment',
          ssBaseCalcType = this.___CoalesceDefault(
            sp.ExtensionData.SsConfigurations[ssType].BaseCalculationType, 
            ccSrc.SsBaseCalculationType 
          );

    const minBc = ssBaseCalcType == 90 ? 0 : ccSrc.MinimumWage,
          maxBc = ssc.MaxBase || Number.MAX_SAFE_INTEGER;

    return XeoBibliotheca.NumberCodex.Limit(
      ssBaseCalcType    == 90 ? sp.ExtensionData.SsConfigurations[ssType].CustomBcValue :
        ssBaseCalcType  == 3  ? sp.StaffItems[0].Value :
        sp.StaffItems.reduce((sum, si) => sum + (si.CalculationGroup == 0 ? si.Value : 0), 0),
      minBc, maxBc
    );
  }
  private _GetSsPayer(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary, 
    ssc: HelperModels.SocialSecurityCut
  ): number {
    const ccSrc = this.___GetCompanyCutsSrc(sp, payrollSummary),
          ssPart  = this.SocialSecurityVars.PartRec[ssc.Name],
          ssGroup = ssPart == 'Healthcare' ? 'SsHealthcare' : 'SsEmployment',
          ssPayerRec: Record<string, number> = {
            'Company-🌎': ccSrc.SsPayer,
            'Company-⚄': (ccSrc.SsCustomPayers as any)[ssPart],
            'Staff-🌎': sp.ExtensionData.SsConfigurations[ssGroup].PaidBy,
            'Staff-⚄': (sp.ExtensionData.SsConfigurations[ssGroup] as any).CustomPaidBys?.[ssPart]
          };

    let path = 'Staff-🌎';
    while (ssPayerRec[path] in (this.SocialSecurityVars.PayerRouteRec[path] || {})) {
      path = this.SocialSecurityVars.PayerRouteRec[path][ssPayerRec[path]];
    }

    return ssPayerRec[path];
  }
  private _GetSsTaxCategory(
    type: 'stf' | 'gov', ssc: HelperModels.SocialSecurityCut
  ): HelperModels.TaxCategory {
    //* Reference : SocialSecurity in Tax *//
    //  See —DevelopmentData—/Sidenotes/Ss–InTaxCalculation.jpg

    return ({
      'Jaminan Kesehatan': 5,
      'Jaminan Kecelakaan Kerja': 5,
      'Jaminan Hari Tua': type == 'stf' ? 11 : -1,
      'Jaminan Pensiun': type == 'stf' ? 11 : -1,
      'Jaminan Kematian': 5
    } as any)[ssc.Name] || -1;
  }
  private _IsJaminanPensiunAndIneligible(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary, 
    ssc: HelperModels.SocialSecurityCut
  ): boolean {
    const tp = payrollSummary.TimePeriod,
          staffAge = tp.diff(sp.ExtensionData.BirthDate, "years");

    const isEligible = tp.isBefore('2015-07-01') ? false :
      tp.isBefore('2019-01-01')                  ? staffAge < 56 :
      staffAge < Math.min((57 + Math.floor(tp.diff('2019-01-01', 'years') / 3)), 65);
  
    return ssc.Name == 'Jaminan Pensiun' && !isEligible;
  }

  //∗ --> Tax Helpers <-- ∗// 
  private TaxVars: Record<string, any> = {
    EmptyTcMod: 1.2,
    ExpatriateRate: .2
  };

  private _GenerateLastTaxPercentage(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary
  ): number {
    if (sp.ExtensionData.TaxCutMethod == 5) {   /* No Tax Cut */
      return 0;
    } else if (this.___IsTaxCodeExpatriate(sp)) {
      return this.TaxVars.ExpatriateRate * 100;
    } else {
      let taxableIncome: number =
        this._GenerateTaxableIncome(sp, payrollSummary, true);

      return this.___GetCompanyCutsSrc(sp, payrollSummary).Tax.Layers.find(
        (taxLayer: HelperModels.TaxLayer) => {
          taxableIncome -= taxLayer.Chunk;
          return taxableIncome <= 0 || !taxLayer.Chunk;
        }
      )?.Percentage || 0;
    }
  }
  private _GenerateMonthlyNettIncome(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary,
    isIncludeOtac: boolean
  ): number {
    return this.__GenerateMonthlyBrutoIncome(sp, isIncludeOtac)
      * (this.___IsTaxCodeNonEmployee(sp) ? .5 : 1)
      - this.__GenerateMonthlyJobFee(sp, payrollSummary, isIncludeOtac)
      - this.__GetMonthlyPensionFunds(sp);
  }
  private _GenerateNonAnnMonthlyTaxableIncome(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary
  ): number {
    if (this.___IsTaxCodeNonEmployee(sp)) {
      return this._GenerateTaxableIncome(sp, payrollSummary, true)
        - sp.ExtensionData.PrevTaxableIncome;
    } else if (this.___IsTaxCodeExpatriate(sp)) {
      return this._GenerateTaxableIncome(sp, payrollSummary, true);
    }

    return 0;
  }
  private _GenerateMonthlyTaxValue(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary
  ): number {
    if (this.___IsTaxCodeNonEmployee(sp)) {
      return this.__GenerateYearlyTaxValue(sp, payrollSummary)
        - sp.ExtensionData.PrevPaidTax;
    } else if (this.___IsTaxCodeExpatriate(sp)) {
      return this.__GenerateExpatriateTaxValue(sp, payrollSummary);
    } else {
      const taxCompleteValue: number =
        this.__GenerateYearlyTaxValue(sp, payrollSummary);
      const taxRoutineValue: number =
        this.__GenerateYearlyTaxValue(sp, payrollSummary, false);
      const annualizedMod: number = this.___IsTaxAnnualized(sp) ?
        1 - sp.ExtensionData.MonthPeriodRange[0] / 12 : 1;

      const taxOneTimeValue: number = taxCompleteValue - taxRoutineValue;
      const taxOwedRoutineValue: number =
        taxRoutineValue * annualizedMod - sp.ExtensionData.PrevPaidTax;
      const periodMonthLeft: number = 12 - payrollSummary.TimePeriod.month();

      return taxOneTimeValue + taxOwedRoutineValue / periodMonthLeft;
    }
  }
  private _GenerateTaxableIncome(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary,
    isIncludeOtac: boolean
  ): number {
    let taxableIncome: number = 0;

    if (this.___IsTaxCodeNonEmployee(sp)) {
      /* Yearly – Non-Employee */
      taxableIncome = sp.ExtensionData.PrevTaxableIncome + Math.max(
        this._GenerateMonthlyNettIncome(sp, payrollSummary, true) - (
          this.___IsApplyUntaxedIncome(sp) ?
            this.__GenerateYearlyUntaxedIncome(sp, payrollSummary) / 12 : 0
        ), 0
      );
    } else if (this.___IsTaxCodeExpatriate(sp)) {
      /* Monthly – Expatriate */
      taxableIncome = this._GenerateMonthlyNettIncome(sp, payrollSummary, true);
    } else {
      /* Yearly – Others Employee Types */
      taxableIncome = Math.max(
        this.__GenerateAnnYearlyNettIncome(sp, payrollSummary, isIncludeOtac)
        - this.__GenerateYearlyUntaxedIncome(sp, payrollSummary),
        0
      );
    }

    /* Calculate Yearly Taxable Income */
    return this.___GetTaxTypeFromPayslip(sp) == 'Annual' ?
      this.___RoundToThousand(taxableIncome) : taxableIncome;
  }
  private _InputIncTaxReceiptId(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary,
    foRec: Record<string, number>
  ) {
    const taxType = this.___GetTaxTypeFromPayslip(sp);
    const isEndYearPs: boolean = payrollSummary.TimePeriod.month() == 11;
    const formType: string = 
      taxType == 'Annual' && isEndYearPs ? '1721-A1' :
      taxType == 'NonFinal' ? '1721-VI' :
      taxType == 'Final' ? '1721-VII' : 
      '';

    if (formType != '') {
      sp.PayslipSummary.TaxReceiptId = foRec[formType];
      foRec[formType]++;
    }
  }

  private __GenerateAnnYearlyNettIncome(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary,
    isIncludeOtac: boolean
  ): number {
    const currentMonthNettoIncome: number =
      this._GenerateMonthlyNettIncome(sp, payrollSummary, isIncludeOtac);
    const routineMonthNettoIncome: number =
      this._GenerateMonthlyNettIncome(sp, payrollSummary, false);
    const periodMonthLeft: number = 12 - payrollSummary.TimePeriod.month();

    return sp.ExtensionData.PrevNetIncome
      + currentMonthNettoIncome + routineMonthNettoIncome * (periodMonthLeft - 1);
  }
  private __GenerateExpatriateTaxValue(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary
  ): number {
    return this._GenerateTaxableIncome(sp, payrollSummary, true)
      * this.TaxVars.ExpatriateRate
      * this.___GetEmptyTcTaxMod(sp)
      * this.___GetGrossUpTaxMod(sp, payrollSummary);
  }
  private __GenerateMonthlyBrutoIncome(
    sp: HelperModels.StaffPayslip, isIncludeOtac: boolean
  ): number {
    return sp.StaffItems.reduce(
      (sum: number, pi: HelperModels.PayslipItem) => {
        return sum + (
          0 <= pi.TaxCategory && pi.TaxCategory != 2 && pi.TaxCategory < 10
            && (pi.CalculationGroup != 1 || isIncludeOtac) ? pi.Value : 0
        );
      }, 0
    ) + sp.GovermentItems.reduce(
      (sum: number, pi: HelperModels.PayslipItem) => {
        return sum + (pi.TaxCategory == 5 ? pi.Value : 0);
      }, 0
    );
  }
  private __GenerateMonthlyJobFee(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary,
    isIncludeOtac: boolean
  ): number {
    if (this.___IsTaxCodeEmployee(sp)) {
      const monthlyBrutoIncome: number =
        this.__GenerateMonthlyBrutoIncome(sp, isIncludeOtac);
      const jobUti: HelperModels.TaxUntaxedIncome =
        this.___GetCompanyCutsSrc(sp, payrollSummary).Tax.UntaxedIncomes.filter(
          (uti: HelperModels.TaxUntaxedIncome) => uti.Name == 'Biaya Jabatan'
        )[0] || new HelperModels.TaxUntaxedIncome();

      return Math.min(monthlyBrutoIncome * jobUti.Percentage / 100, jobUti.MaxValue);
    } else {
      return 0;
    }
  }
  private __GenerateYearlyTaxableIncome(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary
  ): number {
    return this._GenerateTaxableIncome(sp, payrollSummary, true)
      + (this.___IsTaxCodeExpatriate(sp) ? sp.ExtensionData.PrevTaxableIncome : 0);
  }
  private __GenerateYearlyTaxValue(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary,
    isIncludeOtac: boolean = true
  ): number {
    if (this.___GetTaxCutMethod(sp, payrollSummary) == 5) {
      return 0;
    } else {
      const taxableIncome: number =
        this._GenerateTaxableIncome(sp, payrollSummary, isIncludeOtac);

      return this.___GenerateLayeredTaxValue(sp, payrollSummary, taxableIncome)
        * this.___GetEmptyTcTaxMod(sp)
        * this.___GetGrossUpTaxMod(sp, payrollSummary, taxableIncome);
    }
  }
  private __GenerateYearlyUntaxedIncome(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary
  ): number {
    return this.___GetCompanyCutsSrc(sp, payrollSummary).Tax.UntaxedIncomes.reduce(
      (sum: number, uti: HelperModels.TaxUntaxedIncome) => {
        switch (uti.Name) {
          case 'Pribadi':
            return sum + uti.Value;
          case 'Menikah':
            return sum + (sp.ExtensionData.TaxMaritalStatus == 2 ? uti.Value : 0);
          case 'Tanggungan':
            return sum + Math.min(
              uti.Value * sp.ExtensionData.TaxTotalDependents, uti.MaxValue
            );
          case 'Penggabungan Pajak':
            return sum + (sp.ExtensionData.IsMergeUntaxedIncome ? uti.Value : 0);
          default:
            return sum;
        }
      }, 0
    );
  }
  private __GetMonthlyPensionFunds(sp: HelperModels.StaffPayslip): number {
    return this.___IsTaxCodeEmployee(sp) ?
      sp.StaffItems.reduce(
        (sum: number, pi: HelperModels.PayslipItem) => {
          return sum + (pi.TaxCategory == 11 ? Math.abs(pi.Value) : 0);
        }, 0
      ) : 0;
  }

  private ___GenerateLayeredTaxValue(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary, taxableIncome: number
  ): number {
    return this.___GetCompanyCutsSrc(sp, payrollSummary).Tax.Layers.reduce(
      (taxValue: number, tl: HelperModels.TaxLayer) => {
        if (taxableIncome > 0) {
          const chunkSize: number = Math.min(taxableIncome, tl.Chunk || Number.MAX_VALUE);
          taxValue += chunkSize * tl.Percentage / 100;
          taxableIncome -= chunkSize;
        }

        return taxValue;
      }, 0
    );
  }
  private ___GetCompanyCutsSrc(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary
  ): BaseModels.BaseCompanyCuts {
    return payrollSummary.SummaryData.SourceData.GetCompanyCuts(sp.CompanyBranchId);
  }
  private ___GetEmptyTcTaxMod(sp: HelperModels.StaffPayslip): number {
    return !this.___HasTaxCredential(sp) ? this.TaxVars.EmptyTcMod : 1;
  }
  private ___GetGrossUpTaxMod(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary,
    taxableIncome: number = 0
  ): number {
    //* Tax Gross-Up Allowance Formula *//
    //  See —DevelopmentData—/Sidenotes/Tax–GuaEquation.png

    if (this.___GetTaxCutMethod(sp, payrollSummary) != 4) {
      return 1;
    } else if (this.___IsTaxCodeExpatriate(sp)) {
      return 1 / (1 - this.TaxVars.ExpatriateRate * this.___GetEmptyTcTaxMod(sp));
    } else {
      const guaMultiplier: number =
        this.___GetNonEmployeeBrutoMod(sp) * this.___GetEmptyTcTaxMod(sp);
      const guaLayer: HelperModels.TaxGrossUpAlwLayer =
        this.___GetCompanyCutsSrc(sp, payrollSummary).Tax.GrossUpAlwLayers.find(
          (gl: HelperModels.TaxGrossUpAlwLayer) =>
            taxableIncome < (gl.TopValue || Number.MAX_VALUE)
        )!;

      return 1 / (1 - (1 - guaLayer.Divisor) * guaMultiplier);
    }
  }
  private ___GetNonEmployeeBrutoMod(sp: HelperModels.StaffPayslip): number {
    return this.___IsTaxCodeNonEmployee(sp) ? .5 : 1;
  }
  private ___GetTaxCutMethod(
    sp: HelperModels.StaffPayslip, payrollSummary: PayrollSummary
  ): 1 | 2 | 3 | 4 | 5 {
    return this.___CoalesceDefault(
      sp.ExtensionData.TaxCutMethod, this.___GetCompanyCutsSrc(sp, payrollSummary).TaxCutMethod
    ) as any;
  }
  private ___GetTaxTypeFromPayslip(
    sp: HelperModels.StaffPayslip | null
  ): 'Annual' | 'NonFinal' | 'Final' | '' {
    if (!sp) { return ''; }

    const taxEs: number = Math.abs(sp.ExtensionData.TaxEmploymentStatus);
    return [2110001, 2110002].includes(taxEs)                     ? 'Annual' :
      (2110003 <= taxEs && taxEs <= 2110099) || taxEs == 2710099  ? 'NonFinal' :
      2140000 <= taxEs && taxEs <= 2149999                        ? 'Final' :
      '';
  }
  private ___HasTaxCredential(sp: HelperModels.StaffPayslip): boolean {
    return Boolean(sp.ExtensionData.TaxCredential)
      && sp.ExtensionData.TaxCredential != this.EmptyTaxCredentialValue;
  }
  private ___IsApplyUntaxedIncome(sp: HelperModels.StaffPayslip): boolean {
    return this.___IsTaxCodeExpatriate(sp) ? false :
      this.___IsTaxCodeNonEmployee(sp) ? this.___HasTaxCredential(sp)
        && sp.ExtensionData.IsAnnualPayment && !sp.ExtensionData.IsMultipleEmployers :
        true;
  }
  private ___IsTaxAnnualized(sp: HelperModels.StaffPayslip): boolean {
    return this.___IsTaxCodeWnaExpatriate(sp);
  }
  private ___IsTaxCodeEmployee(sp: HelperModels.StaffPayslip): boolean {
    return Math.abs(sp.ExtensionData.TaxEmploymentStatus) == 2110001;
  }
  private ___IsTaxCodeExpatriate(sp: HelperModels.StaffPayslip): boolean {
    return sp.ExtensionData.TaxEmploymentStatus == 2710099;
  }
  private ___IsTaxCodeNonEmployee(sp: HelperModels.StaffPayslip): boolean {
    const spTaxCode: number = sp.ExtensionData.TaxEmploymentStatus;
    return 2110004 <= spTaxCode && spTaxCode <= 2110009;
  }
  private ___IsTaxCodeWnaExpatriate(sp: HelperModels.StaffPayslip): boolean {
    return sp.ExtensionData.TaxEmploymentStatus < 0;
  }
  
  //∗ --> Utils Functions <-- ∗//
  private ___CoalesceDefault(...values: number[]): number {
    return values.find(v => v != 1) || 0;
  }
  private ___RoundToThousand(val: number): number {
    return Math.floor(val / 1000) * 1000;
  } 
}
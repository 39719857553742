
import { Vue, Component, Mixins } from '☆Node/vue-property-decorator';
import AppToastMixin from '@/Mixins/AppToastMixin';
import { DataStore } from '@/Store/—–AppStore–—';

import AppRepositories from '@/Repositories/—–AppRepositories–—';
import AccountQueries from '@/Repositories/Graphql/AccountQueries';

import AuthModule from '@/Components/Account/AuthModule.vue';

@Component({
  name: 'Authentication',
  components: { AuthModule },
  metaInfo: {
    title: `Aplikasi HR & Payroll`
  }
})
export default class Authentication extends Mixins(AppToastMixin) {
  protected IsUserLoading: boolean = false;
  private HeroTags: string[] = [
    'HR, Payroll, PPh 21, dan BPJS dalam satu aplikasi',
    `<span class="text-semibold">Gratis<span class="text-red">*</span></span> 
      — selama masa percobaan Axenta`
  ];

  protected created() {
    this.IsUserLoading = true;
    AppRepositories.Graphql.DoAuthGraphql(`
      query {
        ${AccountQueries.Xeo_GetUserContext}
      }
    `).xeoThen(
      (data) => {
        DataStore.AssignAccount(data.Xeo_GetUserContext.User);
      }
    ).catch((err) => {
      this.MakeErrorToast(this.$t('Errors.network'), 'sd');
    }).finally(() => { 
      this.IsUserLoading = false;
    });
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import { DataStore } from '@/Store/—–AppStore–—';

import * as HelperModels from '@/Models/—HelperModels—';
import { OneTimePayment } from '@/Models/PayrollModels';
import { Staff } from '@/Models/StaffModels';

import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';
import XeoDropdown from '☆XeoApp/Vue/Components/Base/XeoDropdown.vue';
import XeoTextArea from '☆XeoApp/Vue/Components/Base/XeoTextArea.vue';

@Component({
  name: 'OtpItemEditorModal'
})
export default class OtpItemEditorModal extends Mixins(
  XeoBaseMixin, XeoFormMixin, XeoModalMixin, AppRenderMixin
){
  $refs!: {
    BarModalBody: HTMLElement,

    DdlStaff: XeoDropdown,
    TxtValue: XeoFormInput,
    TaDescription: XeoTextArea,
    DdlTaxCategory: XeoDropdown
  };

  private get Constants() { return DataStore.Constants; }
  private get Divisions() { return DataStore.Divisions; }
  private get Jobs() { return DataStore.Jobs; }
  private get StaffList() { return DataStore.Staffs; }
  private get TaxCategories() {
    return Object.entries(DataStore.Constants.TaxCategories || {})
      .map(([key, val]) => ({ Id: Number(key), Value: val }))
      .filter((tc: any) => tc.Id != 2 && tc.Id < 11);
  }

  @Prop() readonly otpSummary!: OneTimePayment;
  @Prop({ default: 0 }) readonly accountId!: number | '+';
  private FormOtpItem: HelperModels.OneTimePaymentItem = new HelperModels.OneTimePaymentItem();
  private get IsOtpEditable() {
    return this.otpSummary.Status < 100;
  }

  protected BtnDelete_Click() {
    this.$emit('delete', this.FormOtpItem.AccountId);
    this.close();
  }
  protected BtnSave_Click() {
    if (XeoBibliotheca.FormCodex.ValidateFormViaRefs(this.$refs)) {
      this.$emit('save', new HelperModels.OneTimePaymentItem(this.FormOtpItem));
      this.close();
    }
  }
  protected DdlStaff_Change() {
    const staff: Staff = this.StaffList[this.FormOtpItem.AccountId];

    if (staff) {
      this.FormOtpItem = new HelperModels.OneTimePaymentItem(
        Object.assign({},
          this.otpSummary.PaymentList.find(
            (otpItem: HelperModels.OneTimePaymentItem) => 
              otpItem.AccountId == this.FormOtpItem.AccountId
          ), {
            AccountId: staff.AccountId,
            CompanyBranchId: staff.CompanyBranchId,
            EmployeeId: staff.EmployeeId,
            Name: staff.DisplayName,
            Company: this._GetCompanyName(staff.CompanyBranchId),            
            Division: this._GetDivJobName('d', staff.DivisionId),
            Job: this._GetDivJobName('j', staff.JobId),
          }
        )
      );
    }
  }
  protected MdlOtpItemEditor_Show() {
    this.FormOtpItem = this._GetOtpItemByAccId(this.accountId);
  }

  private _GetOtpItemByAccId(accId: number | '+'): HelperModels.OneTimePaymentItem {
    return accId == '+' ? new HelperModels.OneTimePaymentItem() :
      new HelperModels.OneTimePaymentItem(
        this.otpSummary.PaymentList.find(
          (otpItem: HelperModels.OneTimePaymentItem) => otpItem.AccountId == accId
        )
      );
  }
}


import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import { Vue, Component } from 'vue-property-decorator';
import { RawLocation, Route } from 'vue-router';
import moment from 'moment';
import { TranslateResult } from 'vue-i18n';

@Component({
  filters: {
    Acronymize(name: string, length: number = 2): string {
      return name ? XeoBibliotheca.UtilCodex.GetAcronym(name)
        .toUpperCase().slice(0, length) : '';
    },
    DateTimeFormat(value: moment.Moment, format: string): string {
      return value ? XeoBibliotheca.DateTimeCodex.Format(value, format) : '';
    },
    EmptyPlaceholder(o: any): string {
      return o || '—';
    },
    FirstLine(str: string): string {
      return str.split('\n').map(s => s.trim()).filter(s => Boolean(s))?.[0] || '';
    },
    NumeralFormat(value: number, format: string): string {
      return XeoBibliotheca.NumberCodex.Format(value, format);
    },
    TrimCommas(commaText: string, itemLength: number = 2): string {
      let trimmedText: string = '';
      const txtParts: string[] = commaText.split(',');
      itemLength = Math.min(itemLength, txtParts.length);

      for (let i = 0; i < itemLength - 1; i++) {
        trimmedText += txtParts[i] + (itemLength > 2 ? ',' : '');
      }

      return trimmedText + txtParts[txtParts.length - 1];
    }
  }
})
export default class XeoBaseMixin extends Vue {
  public GetNumberSignColor(val: number): string {
    return val < 0 ? 'red' : 'mint';
  }
  public IsSlotExist(slotName: string): boolean {
    return Boolean(this.$scopedSlots[slotName]);
  }
  public IsVarEmpty(obj: any): boolean {
    return obj && typeof obj == 'object' ?
      Object.keys(obj).length == 0 : !Boolean(obj);
  }
  public SamePageNavigate(to: RawLocation): Promise<Route> {
    const type = XeoBibliotheca.DisplayCodex.IsDisplayExceed('md') ? 'replace' : 'push';
    return this.$router[type](to);
  }

  /* i18n Extensions */
  public $t_fb(path: string, values?: object): TranslateResult {
    return this.$te(path) ? this.$t(path, values) : '—';
  }
}
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import { GetTimeoffListRequest, Timeoff } from '@/Models/TimeManagementModels';

export default new class TmTimeoffQueries {
  public Axenta_GetTimeoffById(id: number): string {
    return `Axenta_GetTimeoffById(id: ${id})`;
  }
  public Axenta_GetTimeoffList(req: GetTimeoffListRequest): string {
    return `Axenta_GetTimeoffList(
      req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)}
    )`;
  }
  public Axenta_AdditTimeoff(req: Timeoff): string {
    return `Axenta_AdditTimeoff(req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)})`;
  }
  public Axenta_DeleteTimeoff(id: number): string {
    return `Axenta_DeleteTimeoff(id: ${id})`;
  }
}
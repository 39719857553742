export class Division {
  public Id: number = 0;
  public DivisionName: string = '';
  public ParentId!: number;
  public UsageCount: number = 0;

  constructor(init?: Partial<Division>) {
    Object.assign(this, init);
  }
}
export class Job {
  public Id: number = 0;
  public JobName: string = '';
  public ParentId!: number;
  public UsageCount: number = 0;

  constructor(init?: Partial<Job>) {
    Object.assign(this, init);
  }
}

export class TreeItem {
  public Id: number = 0;
  public Name: string = '';
  public ParentId!: number;
  public UsageCount: number = 0;
  public Depth: number = 0;
  public HasChildren: boolean = false;

  constructor(init?: Partial<TreeItem>) {
    Object.assign(this, init);
  }

  public IsOwner(): boolean {
    return this.Id == 1;
  }
}

import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import AppAccessMixin from '@/Mixins/AppAccessMixin';
import AppRenderMixin from '@/Mixins/AppRenderMixin';
import { DataStore } from '@/Store/—–AppStore–—';

import * as FormModels from '@/Models/FormModels';
import * as StaffModels from '@/Models/StaffModels';

import SmChangePassModule from '@/Components/Staff/SmChangePassModule.vue';
import SmDetailModule from '@/Components/Staff/SmDetailModule.vue';
import SmSettingsModule from '@/Components/Staff/SmSettingsModule.vue';
import TmOvertimeModule from '@/Components/TimeManagement/TmOvertimeModule.vue';

@Component({
  name: 'StaffMenuModal',
  components: { SmChangePassModule, SmDetailModule, SmSettingsModule, TmOvertimeModule }
})
export default class StaffMenuModal extends Mixins(
  XeoBaseMixin, XeoFormMixin, XeoModalMixin, AppAccessMixin, AppRenderMixin
) {
  $refs!: {
    BarFormStaff: HTMLElement,
    SmDetailModule: SmDetailModule
  }

  private get Account() { return DataStore.Account; }
  private get CompanyHq() { return DataStore.CompanyHq; }
  private get Jobs() { return DataStore.Jobs; }
  private get StaffList() { return DataStore.Staffs; }

  @Prop() staffId!: '+' | number;
  @Prop(Boolean) isSetup!: boolean;
  private Mode: 'Create' | 'Edit' | '-'  = '-';
  
  private FirstDisabledStep: number = Number.MAX_SAFE_INTEGER;
  private FormStep: number | string = 0;
  private IsShowStaffSideNav: boolean = false;
  private get IsMyself(): boolean {
    return this.staffId == this.Account.Id;
  }
  private get StaffData(): StaffModels.Staff {
    return new StaffModels.Staff(
      this.staffId == '+' ? 
        { CompanyBranchId: this.CompanyHq.Data.Id } : 
        this.StaffList[this.staffId as number]
    );
  }
  private FormStepItems: FormModels.FormStepItem[] = [
    new FormModels.FormStepItem({
      Title: 'Identitas Diri',
      Icon: require('@/Assets/Images/Multicolor/Staff.png')
    }),
    new FormModels.FormStepItem({
      Title: 'Data Perusahaan',
      Icon: require('@/Assets/Images/Multicolor/Building.png')
    }),
    new FormModels.FormStepItem({
      Title: 'Penghasilan',
      Icon: require('@/Assets/Images/Multicolor/MoneyBag.png')
    }),
    new FormModels.FormStepItem({
      Title: 'Pajak Penghasilan',
      Icon: require('@/Assets/Images/Multicolor/Tax.png'),
      Depth: 1
    }),
    new FormModels.FormStepItem({
      Title: 'Jaminan Sosial',
      Icon: require('@/Assets/Images/Multicolor/Ss-Employment.png'),
      Depth: 1
    })
  ];
  private get StaffMenuItems(): Record<string, FormModels.FormStepItem> {
    const otAccessState = this.GetUserAccessState('Client', 'Tm_Overtime');

    return {
      'Administration': new FormModels.FormStepItem({
        Title: 'Administrasi',
        Icon: require('@/Assets/Images/Multicolor/Administration.png'),
        IsHidden: !this.IsPageFullAccess
      }),
      '—⚀—': new FormModels.FormStepItem({
        Title: 'Managemen Waktu',
        Icon: require('@/Assets/Images/Multicolor/TimeList.png'),
        IsHeader: true,
        IsHidden: !(this.IsMyself || otAccessState > 0)
      }),
      'Overtime': new FormModels.FormStepItem({
        Title: 'Lembur',
        Icon: require('@/Assets/Images/Multicolor/Overtime.png'),
        Depth: 1,
        IsHidden: !(this.IsMyself || otAccessState > 0)
      }),
      'ChangePassword': new FormModels.FormStepItem({
        Title: 'Ganti Password',
        Icon: require('@/Assets/Images/Multicolor/ChangePass.png'),
        IsHidden: !this.IsMyself
      }),
    };
  }  

  protected MenuNavGuardHandler!: Function;
  protected created() {
    this.MenuNavGuardHandler = this.$router.beforeEach((to, from, next) => {
      if (this.IsShowStaffSideNav) {
        this.IsShowStaffSideNav = false;
        next(false);
      } else {
        next();
      }
    });
  }
  protected destroyed() {
    this.MenuNavGuardHandler();
  }

  protected BtnNavigation_Click(dirDest: string | number) {
    this._NavigateMenu(dirDest);
    this.PageChange_Act();
  }
  protected ToggleMenu_Act() {
    if (!XeoBibliotheca.DisplayCodex.IsDisplayExceed('md')) {
      this.IsShowStaffSideNav = !this.IsShowStaffSideNav;
    } 
  }
  protected MdlStaffUpsert_Hidden(e: any) {
    this._NavigateToStaffList();
  }
  protected MdlStaffUpsert_Hide(e: any) {
    if (this.IsShowStaffSideNav) {
      e.preventDefault();
      this.IsShowStaffSideNav = false;
    }
  }
  protected MdlStaffUpsert_Show() {
    this._ResetFormState();
  }
  protected PageChange_Act() {
    this.IsShowStaffSideNav = false;
    this.SimplebarElement.getScrollElement()!.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  protected SmDetailModule_Set1stDisabledStep(value: number) {
    this.FirstDisabledStep = value;
  }

  private _IsMenuItemDisabled(miIdx: number): boolean {
    return this.Mode == 'Create' && 
      miIdx < this.FormStepItems.length && miIdx >= this.FirstDisabledStep;
  }
  private _NavigateMenu(dirDest: string | number) { 
    this.FormStep = typeof dirDest == 'number' ? 
      XeoBibliotheca.NumberCodex.Limit(dirDest, 0, this.FormStepItems.length - 1) :
      dirDest;
  }
  private _NavigateToStaffList() {
    this.$router.replace({ name: this.isSetup ? 'Setup_Staff' : 'Staff_List' });
  }
  private _ResetFormState() {
    this.Mode = this.StaffData.AccountId > 0 ? 'Edit' : 'Create';
    this.IsShowStaffSideNav = false;
    this.FormStep = 0;
  }

  /* Watch Handlers */
  @Watch('IsShowStaffSideNav')
  private isShowStaffSideNav_Change(newValue: boolean) {
    this.ToggleChildModal_Act(newValue ? '+' : '-');
  }
}


import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFormMixin from '☆XeoApp/Vue/Mixins/XeoFormMixin';
import XeoModalMixin from '☆XeoApp/Vue/Mixins/XeoModalMixin';
import { DataStore } from '@/Store/—–AppStore–—';
import moment from 'moment';

import * as HelperModels from '@/Models/—HelperModels—';
import * as PayrollModels from '@/Models/PayrollModels';

import XeoDateTimePicker from '☆XeoApp/Vue/Components/Base/XeoDateTimePicker.vue';
import XeoDropdown from '☆XeoApp/Vue/Components/Base/XeoDropdown.vue';
import XeoFormInput from '☆XeoApp/Vue/Components/Base/XeoFormInput.vue';

@Component({
  name: 'PsDocEbankingModal'
})
export default class PsDocEbankingModal extends 
  Mixins(XeoBaseMixin, XeoFormMixin, XeoModalMixin) 
{
  $refs!: {
    BarEbanking: HTMLElement,
    DdlBankId: XeoDropdown,
    TxtAccountNumber: XeoFormInput,
    DtpTransferDate: XeoDateTimePicker
  };

  private get Constants() { return DataStore.Constants; }
  private get StaffList() { return DataStore.Staffs; }

  @Prop() payrollSummary!: PayrollModels.PayrollSummary;
  private FormEbanking: PayrollModels.PsEbankingForm = 
    new PayrollModels.PsEbankingForm({
      Description: this.__GetPayrollDesc()
    });
  private get BankList() {
    return Object.entries(this.Constants.BankList).reduce((sum: any, [key, val]) => {
      if (['1', '2', '3'].includes(key))      sum[key] = val;
      return sum;
    }, {});
  }

  protected BtnGenerateForm_Click() {
    this._DownloadEbankingFile();
  }
  protected MdlEbankingForm_Show() {
    this._InitializeForm();
  }
  protected MiEbankingForm_Click() {
    this.open();
  }

  private _InitializeForm() {
    this.FormEbanking = new PayrollModels.PsEbankingForm({
      Description: this.__GetPayrollDesc()
    });
  }
  private _DownloadEbankingFile() {
    if (!XeoBibliotheca.FormCodex.ValidateFormViaRefs(this.$refs)) {
      return;
    }
    
    const ebankingFile: any = {
      Name: `eBanking` + `–${
          this.Constants.BankList[this.FormEbanking.CompanyBankId]
            .Name.replace(' ', '_')
        }` + `–${this.payrollSummary.TimePeriod.format('MMMM_YYYY')}.csv`,
      Content: ''
    };
    const ebankingPayslips: HelperModels.StaffPayslip[] = 
      this.payrollSummary.SummaryData.StaffPayslips.filter(
        (sp: HelperModels.StaffPayslip) => {
          return this.__IsValidBankAccount(sp.ExtensionData.BankAccount)
            && this.FormEbanking.IsUniversalTransfer && (
              this.FormEbanking.CompanyBankId > 1 || 
              this.FormEbanking.CompanyBankId == sp.ExtensionData.BankAccount.BankId
            );
        }
      ).sort((a, b) => {
        const aBankId = a.ExtensionData.BankAccount.BankId,
              bBankId = b.ExtensionData.BankAccount.BankId;

        return aBankId == this.FormEbanking.CompanyBankId ? -1 :
          bBankId == this.FormEbanking.CompanyBankId ?       1 :
          aBankId - bBankId;
      });

    switch (this.FormEbanking.CompanyBankId) {
      case 1:   /* BCA */
        ebankingPayslips.forEach(
          (sp: HelperModels.StaffPayslip) => {
            const ba: HelperModels.BankAccount = sp.ExtensionData.BankAccount;
            ebankingFile.Content += `${ba.AccountNumber},${sp.Name},IDR,`
              + `${sp.PayslipSummary.StaffSum},`
              + `${this.FormEbanking.TransferDate.format('DD-MM-YYYY')},`
              + `${ba.HolderName},${this.FormEbanking.Description}`
              + `\r\n`;
          }
        );

        break;
      case 2:   /* Mandiri */
        const paymentSum: number = ebankingPayslips.reduce(
          (sum: number, sp: HelperModels.StaffPayslip) => {
            return sum + sp.PayslipSummary.StaffSum;
          }, 0
        );

        ebankingFile.Content += `P,${this.FormEbanking.TransferDate.format('YYYYMMDD')},`
          + `${this.FormEbanking.CompanyAccountNumber},`
          + `${ebankingPayslips.length},${paymentSum},`
          + `,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,`
          + `\r\n`; 

        ebankingPayslips.forEach(
          (sp: HelperModels.StaffPayslip) => {
            const ba: HelperModels.BankAccount = sp.ExtensionData.BankAccount;
            const bankDetails: any = this.Constants.BankList[ba.BankId];
            const isInternalTransfer: boolean = 
              ba.BankId == this.FormEbanking.CompanyBankId;

            ebankingFile.Content += `${ba.AccountNumber},`
              + `${ba.HolderName},OTHER,,,IDR,${sp.PayslipSummary.StaffSum},`
              + `${this.FormEbanking.Description},,`
              + `${isInternalTransfer ? `IBU` : `LBU`},`
              + `${isInternalTransfer ? '' : bankDetails.ClearingCode},`
              + `${bankDetails.Name},`
              + `,,,,N,,,,,,,,,,,,,,,,,,,,,,OUR,,E`
              + `\r\n`;
          }
        );

        break;
      case 3:   /* Bank Permata */
        ebankingPayslips.forEach((sp: HelperModels.StaffPayslip) => {
          const ba: HelperModels.BankAccount = sp.ExtensionData.BankAccount;
          const bankDetails: any = this.Constants.BankList[ba.BankId];
          const isInternalTransfer: boolean = 
            ba.BankId == this.FormEbanking.CompanyBankId;

          ebankingFile.Content += `${bankDetails.Name},,,`
            + `${isInternalTransfer ? '13' : Number(bankDetails.ClearingCode)},`
            + `${ba.HolderName},${ba.AccountNumber},IDR,`
            + `${sp.PayslipSummary.StaffSum},${this.FormEbanking.Description},,`
            + `${this.StaffList[sp.AccountId].Credentials.EmailAddress},`
            + `${isInternalTransfer ? 'OBV' : 'LLG'},`
            + `0,0`
            + `\r\n`;
        });

        break;
    }

    XeoBibliotheca.FileCodex.SaveFile(ebankingFile.Name, ebankingFile.Content);
  }

  private __GetPayrollDesc(): string {
    return `Gaji ${
      (this.payrollSummary.TimePeriod || moment()).format('MMMM YYYY')
    }`;
  }
  private __IsValidBankAccount(ba: HelperModels.BankAccount): boolean {
    return ba && Boolean(ba.BankId) && 
      Boolean(ba.AccountNumber) && Boolean(ba.HolderName);
  }
}

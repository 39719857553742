
import { Vue, Component, Prop } from '☆Node/vue-property-decorator';
import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

@Component({
  name: 'XeoPagination'
})
export default class XeoPagination extends Vue {
  @Prop(Number) value!: number;
  @Prop(Number) readonly itemLength!: number; 
  @Prop(Number) readonly pageSize!: number;
  @Prop({ default: 5 }) readonly pgnNavSize!: number;
  @Prop(Boolean) readonly isNextPageLoading!: boolean;
  private get MaxPage(): number {
    return Math.max(Math.ceil(this.itemLength / this.pageSize) - 1, 0);
  }
  private get PageNavStart(): number {
    const navIndent: number = Math.floor(this.pgnNavSize / 2);
    return XeoBibliotheca.NumberCodex.Limit(
      this.value - navIndent, 
      0, this.MaxPage - (this.pgnNavSize - 1)
    );
  }

  protected BtnPageNav_Click(pageIdx: number) {
    this._NavigateToPage(pageIdx);
  }

  private _GetPageNavValue(n: number): number {
    return this.PageNavStart + n - 1;
  }
  private _NavigateToPage(pageIdx: number | string) {
    pageIdx = XeoBibliotheca.NumberCodex.Limit(pageIdx, 0, this.MaxPage);
    if (pageIdx != this.value) {
      this.$emit('input', pageIdx);
      this.$emit('page-change');
      if (pageIdx == this.MaxPage)      this.$emit('last-page', pageIdx, this.pageSize);
    }
  }
}


import { Vue, Component, Prop, Mixins, Watch } from '☆Node/vue-property-decorator';
import XeoBaseMixin from '☆XeoApp/Vue/Mixins/XeoBaseMixin';
import XeoFeStateMixin from '☆XeoApp/Vue/Mixins/XeoFeStateMixin';

@Component({
  name: 'XeoTable'
})
export default class XeoTable extends Mixins(XeoBaseMixin, XeoFeStateMixin) {
  @Prop([Array, Object]) readonly items: any;
  @Prop({ default: Number.MAX_SAFE_INTEGER }) readonly pageSize!: number;
  @Prop({ default: 5 }) readonly pgnNavSize!: number; 
  @Prop(Boolean) readonly isLoading!: boolean;
  @Prop({ default: null }) readonly isNextPageLoading!: boolean;
  private page: number = 0;
  private get HasInfiniteScroll(): boolean {
    return this.isNextPageLoading !== null;
  }
  private get ItemClass(): Record<string, boolean> {
    return {
      'is-clickable': Boolean(this.$listeners?.['item-click']) && !this.IsCoreDisabled
    };
  }
  private get RenderedItems() {
    return Object.entries(this.items).slice(
      this.page * this.pageSize, (this.page + 1) * this.pageSize
    );
  }

  protected BtnTableItem_Click(item: any) {
    if (!this.IsCoreDisabled) {
      this.$emit('item-click', item);
    }
  }

  /* Watch items - Reset page on change */
  @Watch('items')
  private items_Change(newValue: any, oldValue: any) {
    if (!this.HasInfiniteScroll || newValue.length < oldValue.length) {
      this.page = 0;
    }
  }
}

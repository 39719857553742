import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';

import * as RbacModels from '@/Models/RbacModels';

export default new class CompanyQueries {
  public Xeo_GetAccessByIds(req: RbacModels.GetRbacRequest): string {
    return `Xeo_GetAccessByIds(
      req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)}
    )`;
  }
  public Xeo_UpsertAccess(req: RbacModels.Rbac): string {
    return `Xeo_UpsertAccess(req: ${XeoBibliotheca.JsonCodex.ConvertToGraphqlReq(req)})`;
  }
}
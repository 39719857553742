import * as BaseModels from '@/Models/—BaseModels—';

export class Account extends BaseModels.BaseAccount {
  public Id: number = 0;
  public CompanyName: string = '';
  public DivisionId: number = 0;
  public JobId: number = 0;
  public SetupStep: number = 0;

  constructor(init?: Partial<Account>) {
    super();
    Object.assign(this, init);
  }
}
export class GoogleAuthRequest {
  public IdToken: string = '';

  constructor(init?: Partial<GoogleAuthRequest>) {
    Object.assign(this, init);
  }
}
export class LogInRequest {
  public UserNameOrEmail: string = '';
  public Password: string = '';

  constructor(init?: Partial<LogInRequest>) {
    Object.assign(this, init);
  }
}
export class SignUpRequest {
  public DisplayName: string = '';
  public UserName: string = '';
  public EmailAddress: string = '';
  public Password: string = '';

  constructor(init?: Partial<SignUpRequest>) {
    Object.assign(this, init);
  }
}
export class ChangePasswordRequest {
  public Password: string = '';
  public NewPassword: string = '';
  public ConfirmPassword: string = '';
 
  constructor(init?: Partial<ChangePasswordRequest>) {
    Object.assign(this, init);
  }

  public IsPasswordMatch(): boolean {
    return this.NewPassword == this.ConfirmPassword;
  }
}
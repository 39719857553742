
import { Vue, Component, Mixins, Prop, Watch } from '☆Node/vue-property-decorator';
import XeoFormElementMixin from '☆XeoApp/Vue/Mixins/XeoFormElementMixin';

@Component({
  name: 'XeoFormImage'
})
export default class XeoIcon extends Mixins(XeoFormElementMixin) {
  $refs!: {
    FileImage: HTMLInputElement,
    ImgDisplay: HTMLImageElement
  }

  @Prop(String) baseSrc!: string;
  @Prop() emptySrc!: any;
  @Prop(Boolean) isLoading!: boolean;
  private ImgLoadState: -1 | 0 | 1 = 0;
  private get ImgClass(): Record<string, boolean> {
    return {
      'invalid': !this.IsValid,
      'loading': this.isLoading || this.ImgLoadState == 0 || this.IsValidating,
    };
  }

  protected CntImage_Click() {
    this.$refs.FileImage.click();
  }
  protected FileImage_Change(e: any) {
    this.$refs.ImgDisplay.src = URL.createObjectURL(e.target.files[0]);
    ['input', 'change'].forEach((eventName: string) => {
      this.$emit(eventName, e.target.files[0]);
    });
    this.ValidateValue();
  }
  protected ImgDisplay_Error() {
    this.ImgLoadState = -1;
  }
  protected ImgDisplay_Load() {
    this.ImgLoadState = 1;
  }

  /* Watch baseSrc - Reload Display Image */
  @Watch('baseSrc')
  private baseSrc_Change() {
    this.ImgLoadState = 0;
  }
}

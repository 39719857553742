import AxiosCodex from '☆XeoApp/Typescript/Codixes/AxiosCodex';
import ColorCodex from '☆XeoApp/Typescript/Codixes/ColorCodex';
import CryptoCodex from '☆XeoApp/Typescript/Codixes/CryptoCodex';
import DateTimeCodex from '☆XeoApp/Typescript/Codixes/DateTimeCodex';
import DisplayCodex from '☆XeoApp/Typescript/Codixes/DisplayCodex';
import ErrorCodex from '☆XeoApp/Typescript/Codixes/ErrorCodex';
import FileCodex from '☆XeoApp/Typescript/Codixes/FileCodex';
import FormCodex from '☆XeoApp/Typescript/Codixes/FormCodex';
import JsonCodex from '☆XeoApp/Typescript/Codixes/JsonCodex';
import MaskCodex from '☆XeoApp/Typescript/Codixes/MaskCodex';
import NumberCodex from '☆XeoApp/Typescript/Codixes/NumberCodex';
import UtilCodex from '☆XeoApp/Typescript/Codixes/UtilCodex';
import ValidatorCodex from '☆XeoApp/Typescript/Codixes/ValidatorCodex';
import VueCodex from '☆XeoApp/Typescript/Codixes/VueCodex';

export default new class XeoBibliotheca {
  public AxiosCodex: AxiosCodex = new AxiosCodex();
  public ColorCodex: ColorCodex = new ColorCodex();
  public CryptoCodex: CryptoCodex = new CryptoCodex();
  public DateTimeCodex: DateTimeCodex = new DateTimeCodex();
  public DisplayCodex: DisplayCodex = new DisplayCodex();
  public ErrorCodex: ErrorCodex = new ErrorCodex();
  public FileCodex: FileCodex = new FileCodex();
  public FormCodex: FormCodex = new FormCodex();
  public JsonCodex: JsonCodex = new JsonCodex();
  public MaskCodex: MaskCodex = new MaskCodex();
  public NumberCodex: NumberCodex = new NumberCodex();
  public UtilCodex: UtilCodex = new UtilCodex();
  public ValidatorCodex: ValidatorCodex = new ValidatorCodex();
  public VueCodex: VueCodex = new VueCodex();

  public SetLocale(locale: string) {
    this.DateTimeCodex.MomentJs.locale(locale);
    this.NumberCodex.NumeralJs.locale(locale);
  }
}

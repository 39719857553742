import { Vue, Component } from '☆Node/vue-property-decorator';
import * as HelperModels from '@/Models/—HelperModels—';

@Component
export default class AppConstantsMixin extends Vue {
  public NpwpMask: any[] = [ /\d/, /\d/, '.', 
    /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, '-', 
    /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/
  ];
  public ApprovalStateRec = {
    '-1':   { Code: 'reject',   Text: 'Ditolak',    Color: 'gray-dark' },
    1:      { Code: 'save',     Text: 'Draft',      Color: 'aqua' },
    100:    { Code: 'approve',  Text: 'Disetujui',  Color: 'mint' }
  };
  public AmActionRec: Record<string, string> = {
    'approve': 'menyetujui',
    'reject' : 'menolak',
    'delete' : 'menghapus'
  };
}
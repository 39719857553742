import * as BaseModels from '@/Models/—BaseModels—';
import * as HelperModels from '@/Models/—HelperModels—';

export class Company extends BaseModels.BaseCompany {
  public Id: number = 0;
  public AllowanceCuts: HelperModels.CompanyAllowanceCut[] = [];

  constructor(init?: Partial<Company>) {
    super();
    Object.assign(this, init);
  }
}

import XeoBibliotheca from '☆XeoApp/Typescript/—–XeoBibliotheca–—';
import * as XeoTypes from '☆XeoApp/Typescript/XeoTypes';

import AppConfig from '@/App/Typescript/AppConfig';
import * as AccountModels from '@/Models/AccountModels';

export default class AccountRest {
  public GoogleAuth(req: AccountModels.GoogleAuthRequest): XeoTypes.XeoPromise {
    return XeoBibliotheca.AxiosCodex.Post(
      `${AppConfig.EndpointsUrl.Auth}/GoogleAuth`, req
    );
  }
  public LogIn(logInRequest: AccountModels.LogInRequest): XeoTypes.XeoPromise {
    return XeoBibliotheca.AxiosCodex.Post(
      `${AppConfig.EndpointsUrl.Auth}/LogIn`, logInRequest
    );
  }
  public LogOut(): XeoTypes.XeoPromise {
    return XeoBibliotheca.AxiosCodex.Post(
      `${AppConfig.EndpointsUrl.Auth}/LogOut`, null
    );
  }
  public SignUp(signUpRequest: AccountModels.SignUpRequest): XeoTypes.XeoPromise {
    return XeoBibliotheca.AxiosCodex.Post(
      `${AppConfig.EndpointsUrl.Auth}/SignUp`, signUpRequest
    );
  }
}